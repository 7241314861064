import { CheckIcon } from "src/components/Svg";
import onBoardingImage from "src/assets/images/Beclarified-onBoarding.png";

const features = [
	"It is a long established fact that a reader will be distracted by the readable.",
	"It is a long established fact that a reader will be distracted by the readable.",
	"It is a long established fact that a reader will be distracted by the readable.",
	"It is a long established fact that a reader will be distracted by the readable.",
	"It is a long established fact that a reader will be distracted by the readable.",
];

export function Advertisement() {
	return (
		<section className="relative w-[23.8125rem] bg-background py-[4.25rem] ps-8 pe-9">
			<header className="mb-12">
				<h2 className="mb-4 text-xl font-normal leading-6 text-dark">
					<span className="font-bold text-primary">45</span>
					-day
					<span className="font-bold text-primary"> free </span>
					trial for our
					<span className="font-bold text-primary"> pro </span>
					plan
				</h2>

				<p className="text-base leading-[1.1875rem] text-gray-800">
					Good news! enjoy 45-day free trail for our pro plan which encludes
				</p>
			</header>

			<section>
				{features.map((feature, index) => (
					<div key={index} className="mb-4 flex items-center gap-4 last:mb-0">
						<span className="text-gray-800">
							<CheckIcon width={19} height={19} />
						</span>

						<p className="text-sm leading-[1.125rem] text-gray-800">
							{feature}
						</p>
					</div>
				))}
			</section>

			<div className="absolute bottom-0 left-0 right-0 w-full">
				<img src={onBoardingImage} alt="on boarding Image" className="w-full" />
			</div>
		</section>
	);
}
