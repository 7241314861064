import { formattedDate } from "./formattedDate";

export function getStartAndEndDate(
	startDate: Date,
	endTime: Date,
	startTime: Date
) {
	const starts = new Date(startDate);

	starts.setHours(startTime.getHours());
	starts.setMinutes(startTime.getMinutes());
	const ends = new Date(startDate);

	ends.setHours(endTime.getHours());
	ends.setMinutes(endTime.getMinutes());

	return { starts: formattedDate(starts), ends: formattedDate(ends) };
}
