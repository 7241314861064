import { Link } from "react-router-dom";
import logo from "src/assets/images/logo.png";
import collapsedLogo from "src/assets/images/collapsed-logo.png";

type CollapsableLogoProps = {
	isCollapsed: boolean;
	route: string;
};

export const CollapsableLogo = ({
	isCollapsed,
	route,
}: CollapsableLogoProps) => {
	return (
		<div className={!isCollapsed ? "px-6" : ""}>
			<Link to={route}>
				{isCollapsed ? (
					<img
						className="w-32 object-cover 2xl:w-36 3xl:w-44"
						src={collapsedLogo}
						alt="logo"
					/>
				) : (
					<img
						className="mx-auto h-20 w-32 object-cover 2xl:w-36 3xl:w-44"
						src={logo}
						alt="logo"
					/>
				)}
			</Link>
		</div>
	);
};
