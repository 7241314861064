import { ReactNode } from "react";

type ErrorMSgProps = {
	children: ReactNode;
};
export const ErrorMsg = ({ children }: ErrorMSgProps) => {
	return (
		<p className="mt-3 text-sm font-semibold text-red2 ms-4">{children}</p>
	);
};
