import { Fragment } from "react";
import { UseFormSetValue } from "react-hook-form";
import { UseFormWatch } from "react-hook-form/dist/types";
import { TEETH } from "./teeth";
import { TeethRow } from "./TeethRow";

type ViewType = {
	type: "view";
	selectedTeeth: number[];
	setValue?: never;
	watch?: never;
};

type SelectType = {
	type: "select";
	setValue: UseFormSetValue<any>;
	watch: UseFormWatch<any>;
	selectedTeeth?: never;
};

type TeethSelectProps = ViewType | SelectType;

export function TeethSelect({
	type,
	setValue,
	watch,
	selectedTeeth: selectedTeethProp,
}: TeethSelectProps) {
	const selectedTeeth = selectedTeethProp?.reduce((acc, curr) => {
		acc[curr] = true;

		return acc;
	}, {} as Record<number, boolean>);

	return (
		<div className="w-max" style={{ direction: "ltr" }}>
			<header className="flex items-center justify-between">
				<span className="text-base leading-[1.1875rem] text-muted">R</span>
				<span className="text-base leading-[1.1875rem] text-muted">L</span>
			</header>

			<section className="ltr -before:translate-y-1/2 -after:translate-x-1/2 relative grid grid-cols-2 before:absolute before:top-1/2 before:left-0 before:block before:h-[1px] before:w-full before:bg-muted after:absolute after:left-1/2 after:top-0 after:block after:h-full after:w-[1px] after:bg-muted">
				{TEETH.map((quarter, index) => (
					<Fragment key={index}>
						{type === "view" && (
							<TeethRow
								type="view"
								quarter={quarter}
								selectedTeeth={selectedTeeth as Record<number, boolean>}
							/>
						)}

						{type === "select" && (
							<TeethRow
								type="select"
								quarter={quarter}
								setValue={setValue as UseFormSetValue<any>}
								watch={watch as UseFormWatch<any>}
							/>
						)}
					</Fragment>
				))}
			</section>
		</div>
	);
}
