import {
	ProcedureStatusEnum,
	ProcedureStatusType,
	StatusRequest,
} from "src/@types";

type ProcedureStatusRequestType = StatusRequest<ProcedureStatusType>;

const PLANNED: ProcedureStatusRequestType = {
	label: "planned",
	value: ProcedureStatusEnum.PLANNED,
	backgroundColor: "#FEE4E2",
	color: "#C83B48",
};

const IN_PROGRESS: ProcedureStatusRequestType = {
	label: "inprogress",
	value: ProcedureStatusEnum.INPROGRESS,
	backgroundColor: "#FCF3D7",
	color: "#CF912D",
};

const COMPLETED: ProcedureStatusRequestType = {
	label: "completed",
	value: ProcedureStatusEnum.COMPLETED,
	backgroundColor: "#DCF7E3",
	color: "#21913E",
};

export const PROCEDURE_STATUS: ProcedureStatusRequestType[] = [
	PLANNED,
	IN_PROGRESS,
	COMPLETED,
];

export const PROCEDURE_STATUS_OBJECT: {
	[key: number]: ProcedureStatusRequestType;
} = {
	[ProcedureStatusEnum.PLANNED]: PLANNED,
	[ProcedureStatusEnum.INPROGRESS]: IN_PROGRESS,
	[ProcedureStatusEnum.COMPLETED]: COMPLETED,
};
