import {
	base,
	getAssistantAndAdmin,
	getSharedRoutes,
	lab,
	patient,
	patients,
	procedures,
} from "./global";
import { getPatientRoutes } from "./patientRoutes";
import { getSettingsRoutes } from "./settingsRoutes";

const adminBase = base + "/admin";

// * Start Overview Routes
const adminOverview = adminBase + "/overview";

const OVERVIEW = {
	BASE: adminOverview,
	PATIENTS: adminOverview + patients,
	CLINICS: adminOverview + "/clinics",
	APPOINTMENTS: adminOverview + "/appointments",
	DENTISTS: adminOverview + "/dentists",
	LAB: adminOverview + lab,
	PROCEDURES: adminOverview + procedures,
	FINANCE_ANALYSIS: adminOverview + "/finance-analysis",
	EXPENSES: adminOverview + "/finance-analysis/expenses",
	REVENUES: adminOverview + "/finance-analysis/revenues",
	BUSINESS_ASSISTANCE: adminOverview + "/business-assistance",
};

// * End Overview Routes
// * Start Lab Routes

const adminLab = adminBase + lab;
const adminLabMyLabs = adminLab + "/my-labs";
const tasks = "/tasks";
const invoiceHistory = "/invoice-history";
const services = "/services";

const LAB = {
	BASE: adminLab,
	TASKS: adminLab + tasks,
	INVOICE_HISTORY: adminLab + invoiceHistory,
	MY_LABS: {
		BASE: adminLabMyLabs,
		SERVICES: {
			ROUTE: adminLabMyLabs + "/:id" + services,
			LINK: (id: string | number) => adminLabMyLabs + "/" + id + services,
		},
		TASKS: {
			ROUTE: adminLabMyLabs + "/:id" + tasks,
			LINK: (id: string | number) => adminLabMyLabs + "/" + id + tasks,
		},
		INVOICE_HISTORY: {
			ROUTE: adminLabMyLabs + "/:id" + invoiceHistory,
			LINK: (id: string | number) => adminLabMyLabs + "/" + id + invoiceHistory,
		},
	},
};

// * End Lab Routes
// * Start Feedback Routes

const adminFeedback = adminBase + "/feedback";
const adminFeedbackCenter = adminFeedback + "/feedbackCenter";

const FEEDBACK = {
	BASE: adminFeedback,
	FAQ: adminFeedback + "/faq",
	CENTER: {
		BASE: adminFeedbackCenter,
		PATIENT: {
			ROUTE: adminFeedbackCenter + patient + "/:id",
			LINK: (id: string | number) => adminFeedbackCenter + patient + "/" + id,
		},
	},
};

// * End Feedback Routes
// * Start Insurance Routes

const adminInsurance = adminBase + "/insurance";

const INSURANCE = {
	BASE: adminInsurance,
	COMPANIES: {
		BASE: adminInsurance,
	},
	TTT_REQUEST: {
		BASE: adminInsurance + "/ttt-request",
	},
	MANAGE: {
		ROUTE: adminInsurance + "/manage/:id",
		LINK: (id: string | number) => adminInsurance + "/manage/" + id,
	},
	REVIEW: {
		ROUTE: adminInsurance + "/review/:id",
		LINK: (id: string | number) => adminInsurance + "/review/" + id,
	},
	REPORT: {
		ROUTE: adminInsurance + "/report/:id",
		LINK: (id: string | number) => adminInsurance + "/report/" + id,
	},
};

// * End Insurance Routes
// * Start Settings Routes

const dentistSettings = adminBase + "/settings";

const SETTINGS = {
	...getSettingsRoutes(adminBase).SETTINGS,

	CLINIC_SETTINGS: {
		BASE: dentistSettings + "/clinic-settings",
	},
	STAFF: {
		BASE: dentistSettings + "/staff",
		USER: {
			ROUTE: dentistSettings + "/staff" + "/:id",
			LINK: (id: string | number) => dentistSettings + "/staff" + "/" + id,
		},
	},
	SERVICES: dentistSettings + "/services",
	PLANS: dentistSettings + "/plans",
};

// * End Settings Routes
// * Start Admin Routes

export const ADMIN = {
	BASE: adminBase,
	HOME: adminBase + "/home",

	OVERVIEW,
	LAB,
	FEEDBACK,
	INSURANCE,
	SETTINGS,

	...getPatientRoutes(adminBase),
	...getSharedRoutes(adminBase),
	...getAssistantAndAdmin(adminBase),
};

// * End Admin Routes
