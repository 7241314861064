import { RootState } from "../../store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LabDictionaryType, LabType } from "src/@types";
import axios from "src/config/axios";

export const fetchLabs = createAsyncThunk<
	LabType[],
	void,
	{ rejectValue: string }
>("labs/fetchLabs", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get<LabType[]>("store/labs/", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

interface InitialState {
	loading: boolean;
	errorMsg: string;
	labs: LabType[] | null;
	labsDictionary: LabDictionaryType | null;
}

const initialState: InitialState = {
	loading: true,
	errorMsg: "",
	labs: null,
	labsDictionary: [],
};

const labsSlice = createSlice({
	name: "labs",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchLabs.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchLabs.rejected, (state, action) => {
			state.errorMsg = action.payload || "";
			state.loading = false;
		});

		builder.addCase(fetchLabs.fulfilled, (state, action) => {
			state.labs = action.payload;
			state.labsDictionary = action.payload.reduce(
				(obj, lab) => ({ ...obj, [lab.id]: lab }),
				{}
			);
			state.loading = false;
		});
	},
});

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const labsSelector = (state: RootState) => state.labs;
export const labsReducer = labsSlice.reducer;
