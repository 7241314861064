import moment from "moment";
import { NavigateAction, SlotInfo, View } from "react-big-calendar";
import { Event } from "src/@types";
import {
	eventsActions,
	fetchEvents,
	schedulerActions,
	updateEvent,
	updateEventResizing,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import { MoveArgsType } from "./@types/DragAndDrop.type";

type UseCalenderProps = {
	setPopup: (value: React.SetStateAction<boolean>) => void;
	setCurrentDate: (value: React.SetStateAction<Date | undefined>) => void;
	setCurrentView: (value: React.SetStateAction<View>) => void;
};

export function useCalender({
	setPopup,
	setCurrentDate,
	setCurrentView,
}: UseCalenderProps) {
	const dispatch = useAppDispatch();
	const {
		events: { events, selectedEvent },
	} = useAppSelector((s) => s);

	const handleSelectEvent = (
		event: Event,
		e: React.SyntheticEvent<HTMLElement>
	) => {
		setPopup(true);
		dispatch(eventsActions.selectedEvent(event));
	};

	const moveEvent = ({ event, start, end, resourceId }: MoveArgsType) => {
		const updatedEvent: Event = {
			...event,
			resource: {
				...event.resource,
				clinic: resourceId,
			},
		};

		const oldEvents = [...events];

		setCurrentDate(new Date(start));

		// dispatch(
		// 	updateEventMoving({
		// 		event: updatedEvent,
		// 		start,
		// 		end,
		// 		resourceId,
		// 		oldEvents,
		// 	})
		// ).then(() => {
		// 	console.log("updated");
		// });
		dispatch(
			updateEvent({
				eventId: event.resource.id,
				updated: {
					starts: moment(start).format("YYYY-MM-DD HH:mm:ss"),
					ends: moment(end).format("YYYY-MM-DD HH:mm:ss"),
					clinic: resourceId,
				},
			})
		)
			.then(() => {
				console.log("updated");
			})
			.catch(() => {
				console.log("error");
			});
	};

	const resizeEvent = ({ event, isAllDay, start, end }: MoveArgsType) => {
		const oldEvents = [...events];

		if (event) {
			dispatch(updateEventResizing({ event, start, end, oldEvents }));
		}
		setCurrentDate(new Date(start));
	};

	const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
		setCurrentDate(newDate);
		setCurrentView(view);
		if (view === "day") {
			if (
				action === "NEXT" &&
				(moment(newDate).isSame(moment(newDate).startOf("week"), "day") ||
					moment(newDate).isSame(moment(newDate).endOf("week"), "day"))
			) {
				dispatch(
					fetchEvents({
						startDate: moment(newDate).startOf("week").format("YYYY-MM-DD"),
						endDate: moment(newDate).endOf("week").format("YYYY-MM-DD"),
					})
				);
			} else if (
				action === "PREV" &&
				moment(newDate).isSame(moment(newDate).endOf("week"), "day")
			) {
				dispatch(
					fetchEvents({
						startDate: moment(newDate).startOf("week").format("YYYY-MM-DD"),
						endDate: moment(newDate).endOf("week").format("YYYY-MM-DD"),
					})
				);
			}
		} else if (view === "week") {
			if (action === "NEXT" || action === "PREV") {
				dispatch(
					fetchEvents({
						startDate: moment(newDate).startOf("week").format("YYYY-MM-DD"),
						endDate: moment(newDate).endOf("week").format("YYYY-MM-DD"),
					})
				);
			}
		}
	};

	const onDrillDown = (date: Date, view: View) => {
		setCurrentDate(date);
		setCurrentView(view);
	};

	const addNewEventHandler = ({ end, start, resourceId }: SlotInfo) => {
		dispatch(
			eventsActions.addAppointmentFromSlots({
				start: start.toString(),
				end: end.toString(),
			})
		);

		dispatch(
			schedulerActions.openAddAppointmentModal({
				selectedClinic: parseInt(`${resourceId}`),
			})
		);

		const newEvent = {
			start: start.toString(),
			end: end.toString(),
			allDay: false,
		};

		dispatch(eventsActions.addSelectableEvent(newEvent));
	};

	return {
		handleSelectEvent,
		moveEvent,
		resizeEvent,
		onNavigate,
		onDrillDown,
		addNewEventHandler,
	};
}
