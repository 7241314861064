import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux";
import { ProfileImg, CalendarIcon, ClockIcon } from "src/components";

export const AllDetails = () => {
	const { t } = useTranslation("scheduler");
	const { i18n } = useTranslation();
	const onChange = () => {};
	const event = useAppSelector((state) => state.events.selectedEvent);

	return (
		event && (
			<div className="px-5 py-6">
				<div className="mb-5 flex flex-shrink-0 content-center items-center justify-center">
					<div className="flex w-[50%]">
						<span className="text-muted">
							<CalendarIcon width="20" height="20" />
						</span>
						&nbsp;
						{event.start
							? event.start.toLocaleDateString(i18n.language, {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric",
							  })
							: ""}
					</div>
					<div className="flex w-[50%]">
						<span className="text-muted">
							<ClockIcon width="20" height="20" />
						</span>
						&nbsp;
						{event.start
							? event.start.toLocaleTimeString(i18n.language, {
									hour: "2-digit",
									minute: "2-digit",
							  })
							: ""}{" "}
						-
						{event.end
							? event.end.toLocaleTimeString(i18n.language, {
									hour: "2-digit",
									minute: "2-digit",
							  })
							: ""}
					</div>
				</div>
				<div className="mb-5 flex flex-shrink-0 content-center items-center justify-center">
					{event.resource?.dentist && (
						<div className="w-[50%] flex-col">
							<h5 className="mb-2 font-normal text-gray-800">
								{t("Appointments.Appointment_details.AllDetails.Dentist")}
							</h5>
							<div className="align-center">
								<ProfileImg
									imgsrc={event.resource.dentist.picture}
									username={event.resource.dentist.name}
									style={{ width: "30px", height: "30px" }}
								/>
								<p className="font-semibold text-dark ms-1">
									{event.resource.dentist.name}
								</p>
							</div>
						</div>
					)}

					{typeof event.resource?.clinic === "number" && (
						<div className="w-[50%] flex-col ">
							<h5 className="mb-2 font-normal text-gray-800">
								{t("Appointments.Appointment_details.AllDetails.Clinic")}
							</h5>
							<p className="font-semibold text-dark ms-1">
								{t("Appointments.Appointment_details.AllDetails.ClinicNo.")}{" "}
								{event.resource.clinic}
							</p>
						</div>
					)}
				</div>
				<div className="flex ">
					<div className="flex-col">
						<h5 className="mb-2 font-normal text-gray-800">
							{t("Appointments.Appointment_details.AllDetails.Notes")}
						</h5>
						<p className="font-semibold text-dark ms-1">
							{event.resource?.notes
								? event.resource.notes
								: t("Appointments.Appointment_details.AllDetails.No_Notes")}
						</p>
					</div>
				</div>
			</div>
		)
	);
};
