import { ReactNode } from "react";
import { Style } from "src/@types";

type ListItemProps = Style & {
	value?: any;
	children: ReactNode;
	displayedName?: any;
	onClick?: React.MouseEventHandler<HTMLLIElement> | undefined;
};

export const ListItem = ({
	value,
	displayedName,
	className,
	style,
	children,
	onClick,
}: ListItemProps) => {
	return (
		<li
			value={value}
			onClick={onClick}
			data-displayed={displayedName}
			className={`cursor-pointer px-2 py-1.5 transition hover:bg-gray-200 ${className}`}
			style={style}
			tabIndex={1}
		>
			{children}
		</li>
	);
};
