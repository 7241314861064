import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupBase, OptionProps } from "react-select";
import { StatusRequest } from "src/@types";

type StatusDropdownItemProps<StatusVariants extends string> = {
	optionProps: React.PropsWithChildren<
		OptionProps<
			StatusRequest<StatusVariants>,
			false,
			GroupBase<StatusRequest<StatusVariants>>
		>
	>;
	statusList: StatusRequest<StatusVariants>[];
	statusObject: { [key: number]: StatusRequest<StatusVariants> };
};

export function StatusDropdownItem<StatusVariants extends string>({
	optionProps,
	statusList,
	statusObject,
}: StatusDropdownItemProps<StatusVariants>) {
	const { i18n } = useTranslation();
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div onClick={() => optionProps.selectOption(optionProps.data)}>
			<span
				className="block w-28 cursor-pointer rounded-md bg-nativeWhite py-[0.1875rem] px-[0.9375rem] text-xs font-semibold capitalize leading-[1.1875rem]"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{
					direction: i18n.dir(),
					backgroundColor:
						optionProps.isSelected || isHovered
							? statusObject[optionProps?.data.value].backgroundColor
							: "transparent",
					color: statusObject[optionProps?.data.value].color,
				}}
			>
				{optionProps?.data.label}
			</span>

			{optionProps.data.value !== statusList[statusList.length - 1].value && (
				<div className="px-2.5">
					<div className="my-[0.1875rem] h-[1px] w-full bg-[#9E9E9E3B]"></div>
				</div>
			)}
		</div>
	);
}
