import { SkeletonElement } from "./SkeletonElement";

export const PatientGroupsSkeleton = () => {
	return (
		<div className="grid min-h-screen grid-cols-3 grid-rows-3 gap-5">
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
		</div>
	);
};
export const GroupCardSkeleton = () => {
	return (
		<div className="flex flex-col justify-between divide-y divide-lightGray rounded-lg bg-white shadow">
			<div className=" py-2 px-4">
				<SkeletonElement type="title" />
			</div>
			<div className="px-4 py-2">
				{[0, 1].map((_, i) => (
					<div className={"align-center justify-between   "} key={i}>
						<div className="w-3/4">
							<div className="align-center">
								<span className="me-2">
									<SkeletonElement type="icon" />
								</span>
								<SkeletonElement type="text" className="h-6" />
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="center py-1">
				<SkeletonElement type="title" />
			</div>
		</div>
	);
};
