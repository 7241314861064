import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Btn, Input } from "src/components";
import { RowInput } from "src/features";

type FormValues = {
	text: string;
};

const schema = yup.object().shape({
	text: yup.string().default("-").required("Required"),
});

type EditableCellProps = {
	currentValue: string;
	handleSuccess: (text: string) => void;
};

export function EditableCell({
	currentValue,
	handleSuccess,
}: EditableCellProps) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isValid, isSubmitSuccessful },
	} = useForm<FormValues>({
		mode: "onSubmit",
		resolver: yupResolver(schema),
		defaultValues: { text: currentValue },
	});

	const onSubmit = (data: FormValues) => {
		handleSuccess(data.text);
		reset({ text: data.text });
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex min-w-[16rem] items-center justify-between gap-3"
		>
			<RowInput
				type="text"
				register={register}
				id="text"
				error={errors.text}
				placeholder="Enter text"
			/>

			{isDirty && (
				<div className="flex items-center gap-x-3">
					<Btn type="submit" variant="success" size="xs" className="!text-sm">
						Save
					</Btn>

					<Btn
						type="button"
						variant="secondary"
						size="xs"
						className="!text-sm"
						onClick={() => reset()}
					>
						Cancel
					</Btn>
				</div>
			)}
		</form>
	);
}
