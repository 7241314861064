import { SVGProps } from "react";

export const EyeOffIcon = ({ width, height }: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.7665 11.7668C11.5376 12.0124 11.2616 12.2094 10.955 12.346C10.6483 12.4827 10.3172 12.5561 9.98157 12.5621C9.64589 12.568 9.31246 12.5062 9.00117 12.3805C8.68987 12.2548 8.40709 12.0676 8.1697 11.8302C7.9323 11.5928 7.74515 11.31 7.61942 10.9987C7.49368 10.6874 7.43193 10.354 7.43785 10.0183C7.44378 9.68267 7.51725 9.35162 7.65389 9.04496C7.79053 8.73829 7.98754 8.46229 8.23316 8.23342M14.9498 14.9501C13.5253 16.0359 11.7907 16.6375 9.99983 16.6667C4.16649 16.6667 0.83316 10.0001 0.83316 10.0001C1.86974 8.06833 3.30744 6.38059 5.04983 5.05008L14.9498 14.9501ZM8.24983 3.53342C8.82344 3.39915 9.41071 3.33203 9.99983 3.33342C15.8332 3.33342 19.1665 10.0001 19.1665 10.0001C18.6606 10.9464 18.0574 11.8374 17.3665 12.6584L8.24983 3.53342Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M0.83316 0.83342L19.1665 19.1668"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const EyeOnIcon = ({ width, height }: SVGProps<SVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
