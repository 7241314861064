export enum GenderEnum {
	MALE = 0,
	FEMALE = 1,
}

export enum PaymentChoiceEnum {
	CASH = 0,
	INSTALLMENT = 1,
}

export enum DiscountEnum {
	EG = "EG",
	PERCENTAGE = "PERCENTAGE",
}
