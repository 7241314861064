type SkeletonElementProps = {
	type?:
		| "text"
		| "title"
		| "avatar"
		| "col"
		| "row"
		| "square"
		| "squreSm"
		| "rect"
		| "icon"
		| "card";
	className?: string | undefined;
};

export const SkeletonElement = ({ type, className }: SkeletonElementProps) => {
	const renderType = () => {
		let classess = "";

		switch (type) {
			case "text":
				classess += "w-full h-4 mb-2";
				break;
			case "title":
				classess += "w-1/2 h-6 ";
				break;
			case "avatar":
				classess += "w-10 h-10 rounded-full mb-3";
				break;
			case "icon":
				classess += "w-8 h-8 rounded-lg mb-3";
				break;
			case "square":
				classess += "w-20 h-20 rounded-xl  ";
				break;
			case "squreSm":
				classess += "w-20 h-10 rounded  ";
				break;
			case "rect":
				classess += "w-20 h-10 rounded-xl  ";
				break;
			case "col":
				classess += "h-full  w-full";
				break;
			case "row":
				classess += "h-full w-full";
				break;
			case "card":
				classess += "h-full w-full";
				break;
			default:
				break;
		}

		return classess;
	};

	return (
		<div
			className={`animate-pulse   rounded bg-gray-300 ${renderType()} ${className}`}
		></div>
	);
};
