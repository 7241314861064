export function UsersBigIcon() {
	return (
		<svg
			width="43"
			height="40"
			viewBox="0 0 43 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_935_3146)">
				<path
					d="M12.3841 34.4916V31.2235C12.3841 29.4901 13.1256 27.8276 14.4457 26.6019C15.7657 25.3761 17.5561 24.6875 19.4229 24.6875H33.5005C35.3673 24.6875 37.1577 25.3761 38.4777 26.6019C39.7977 27.8276 40.5393 29.4901 40.5393 31.2235V34.4916"
					stroke="url(#paint0_linear_935_3146)"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M26.4614 18.1522C22.574 18.1522 19.4226 15.2259 19.4226 11.6161C19.4226 8.00636 22.574 5.08008 26.4614 5.08008C30.3489 5.08008 33.5002 8.00636 33.5002 11.6161C33.5002 15.2259 30.3489 18.1522 26.4614 18.1522Z"
					stroke="url(#paint1_linear_935_3146)"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.82551 34.492V31.224C1.82667 29.7758 2.34575 28.369 3.30125 27.2245C4.25676 26.0799 5.59456 25.2624 7.10461 24.9004"
					stroke="url(#paint2_linear_935_3146)"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.1437 5.29199C12.6296 5.65197 11.2876 6.46963 10.3293 7.61606C9.37094 8.7625 8.85077 10.1725 8.85077 11.6238C8.85077 13.0751 9.37094 14.4851 10.3293 15.6315C11.2876 16.7779 12.6296 17.5956 14.1437 17.9556"
					stroke="url(#paint3_linear_935_3146)"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_935_3146"
					x1="26.4617"
					y1="24.6875"
					x2="26.4617"
					y2="34.4916"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0077B4" />
					<stop offset="1" stopColor="#0077B4" stopOpacity="0.3" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_935_3146"
					x1="26.4614"
					y1="5.08008"
					x2="26.4614"
					y2="18.1522"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0077B4" />
					<stop offset="1" stopColor="#0077B4" stopOpacity="0.3" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_935_3146"
					x1="4.46506"
					y1="24.9004"
					x2="4.46506"
					y2="34.492"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0077B4" />
					<stop offset="1" stopColor="#0077B4" stopOpacity="0.3" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_935_3146"
					x1="11.4972"
					y1="5.29199"
					x2="11.4972"
					y2="17.9556"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0077B4" />
					<stop offset="1" stopColor="#0077B4" stopOpacity="0.3" />
				</linearGradient>
				<clipPath id="clip0_935_3146">
					<rect
						width="42.2329"
						height="39.2162"
						fill="white"
						transform="matrix(-1 0 0 1 42.2988 0.177734)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
