type InputNoteProps = {
	children: React.ReactNode;
};

export function InputNote({ children }: InputNoteProps) {
	return (
		<p
			className="my-2 text-xs text-gray-800"
			style={{ lineHeight: "0.875rem" }}
		>
			{children}
		</p>
	);
}
