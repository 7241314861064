import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import {
	AssistantOverviewType,
	AssistantTableOverviewType,
	DentistsTableOverviewType,
	ExpensesTableOverviewType,
	LabOrdersTableOverviewType,
	ReportsFetch,
} from "../../../@types/Overview.type";
import { FromToDatesDto } from "../../../@types/dto/from-to-dates.dto";
import { AssistantTableDto } from "../../../@types/dto/assistant-table.dto";

export const fetchReports = createAsyncThunk<ReportsFetch, FromToDatesDto>(
	"reports/fetchReports",
	async ({ from_date, to_date }, thunkAPI) => {
		try {
			const { data }: { data: ReportsFetch } = await axios.post<ReportsFetch>(
				"reports",
				{
					from_date,
					to_date,
				}
			);

			return data;
		} catch (error) {
			return thunkAPI.rejectWithValue("Something went wrong, try again later");
		}
	}
);

export const fetchDentistsTable = createAsyncThunk<
	DentistsTableOverviewType,
	FromToDatesDto
>("reports/fetchDentistsTable", async ({ from_date, to_date }, thunkAPI) => {
	try {
		const { data }: { data: DentistsTableOverviewType } =
			await axios.post<DentistsTableOverviewType>("reports/dentists/table", {
				from_date,
				to_date,
			});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

export const fetchLabOrdersTable = createAsyncThunk<
	LabOrdersTableOverviewType,
	FromToDatesDto
>("reports/fetchLabOrdersTable", async ({ from_date, to_date }, thunkAPI) => {
	try {
		const { data }: { data: LabOrdersTableOverviewType } =
			await axios.post<LabOrdersTableOverviewType>("reports/lab-works/table", {
				from_date,
				to_date,
			});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

export const fetchExpensesTable = createAsyncThunk<
	ExpensesTableOverviewType,
	FromToDatesDto
>("reports/fetchExpensesTable", async ({ from_date, to_date }, thunkAPI) => {
	try {
		const { data }: { data: ExpensesTableOverviewType } =
			await axios.post<ExpensesTableOverviewType>(
				"reports/finance/expenses/table",
				{
					from_date,
					to_date,
				}
			);

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

export const fetchBusinessAssistant = createAsyncThunk<
	AssistantOverviewType,
	FromToDatesDto
>("reports/fetchAssistant", async ({ from_date, to_date }, thunkAPI) => {
	try {
		const { data }: { data: AssistantOverviewType } =
			await axios.post<AssistantOverviewType>("reports/summary/assistant", {
				from_date,
				to_date,
			});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

export const fetchBusinessAssistantTable = createAsyncThunk<
	AssistantTableOverviewType,
	AssistantTableDto
>(
	"reports/fetchAssistantTable",
	async ({ from_date, to_date, gender, procedures, status, age }, thunkAPI) => {
		try {
			const query: AssistantTableDto = {
				from_date,
				to_date,
			};

			if (gender !== undefined) {
				query.gender = gender;
			}

			if (procedures !== undefined) {
				query.procedures = procedures;
			}

			if (status !== undefined) {
				query.status = status;
			}

			if (age !== undefined) {
				query.age = age;
			}

			const { data }: { data: AssistantTableOverviewType } =
				await axios.post<AssistantTableOverviewType>(
					"reports/summary/assistant/table",
					query
				);

			return data;
		} catch (error) {
			return thunkAPI.rejectWithValue("Something went wrong, try again later");
		}
	}
);
