import { RolesNumberEnum } from "src/@types";

export function renderRole(role: RolesNumberEnum) {
	switch (role) {
		case RolesNumberEnum.ADMIN:
			return "Roles.Admin";
		case RolesNumberEnum.DENTIST:
			return "Roles.Dentist";
		case RolesNumberEnum.ASSISTANT:
			return "Roles.Assistant";
		default:
			return "Unknown";
	}
}
