import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import noLabsImg from "src/assets/svg/no_labs.svg";

type NoLabsProps = {
	clickHandler: () => void;
};

export function NoLabs({ clickHandler }: NoLabsProps) {
	const { t } = useTranslation("patientFile");

	return (
		<NoData
			imgAlt="no labs"
			imgSrc={noLabsImg}
			notFoundMsg={t("PatientFile.Labs.No_Labs")}
			btn={t("PatientFile.Labs.Add_Request")}
			clickHandler={clickHandler}
		/>
	);
}
