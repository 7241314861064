import { Style } from "src/@types";

type StatusStatisticCardProps = {
	card: {
		title: string;
		color: string;
		label: string | null;
		value: number | string;
		icon: React.ReactNode;
	};
	styles?: {
		container?: Style;
		value?: Style;
		label?: Style;
		title?: Style;
	};
};

export function StatusStatisticCard({
	card,
	styles,
}: StatusStatisticCardProps) {
	return (
		<div
			className={`
				flex w-full flex-col items-center justify-center gap-2 rounded-md bg-nativeWhite px-4 pt-4 pb-3
				${styles?.container?.className}
			`}
			style={styles?.container?.style}
		>
			<span
				className={`font-bold leading-5 ${styles?.value?.className}`}
				style={{
					fontSize: "1.5625rem",
					color: card.color,
					...styles?.value?.style,
				}}
			>
				{card.value}
			</span>

			<div className="flex items-center justify-center">
				<span>{card.icon}</span>

				<h3
					className={`
						ml-2 mr-1 font-poppins text-sm font-medium capitalize leading-6
						${styles?.title?.className}
					`}
					style={{ color: card.color, ...styles?.title?.style }}
				>
					{card.title}
				</h3>

				{card.label && (
					<span
						className={`block font-poppins font-medium capitalize ${styles?.label?.className}`}
						style={{
							fontSize: "0.625rem",
							lineHeight: "0.9375rem",
							color: card.color,
							...styles?.label?.style,
						}}
					>
						(new order)
					</span>
				)}
			</div>
		</div>
	);
}
