import { NavbarItem } from "src/@types";
import { ROUTES } from "../../constants";
import {
	BillIcon,
	HomeIcon,
	LaboratoryIcon,
	SchedulerIcon,
	StarIcon,
	StockIcon,
	TasksIcon,
	UsersIcon,
	DashboardIcon,
	InsuranceIcon,
} from "src/components";

const Patients = (route: "ADMIN" | "ASSISTANT" | "DENTIST"): NavbarItem => ({
	navItem: "Sidebar.Patients",
	navLink: ROUTES[route].PATIENTS,
	navIcon: <UsersIcon width={22.5} height={22.5} />,
});

const Financial = (route: "ADMIN" | "ASSISTANT"): NavbarItem => ({
	navItem: "Sidebar.Financial",
	navLink: ROUTES[route].FINANCIAL.BASE,
	navIcon: <BillIcon width="25" height="25" />,
});

const Lab = (route: "ADMIN" | "ASSISTANT" | "DENTIST"): NavbarItem => ({
	navItem: "Sidebar.Lab",
	navLink: ROUTES[route].LAB.BASE,
	navIcon: <LaboratoryIcon width={22.5} height={22.5} />,
});

const Tasks = (
	route: "ADMIN" | "ASSISTANT" | "DENTIST",
	count: number
): NavbarItem => ({
	navItem: "Sidebar.Tasks",
	navLink: ROUTES[route].TASKS.BASE,
	navIcon: <TasksIcon width={22.5} height={22.5} />,
	count: count > 0 ? count : undefined,
});

const Stock = (route: "ADMIN" | "ASSISTANT"): NavbarItem => ({
	navItem: "Sidebar.Stock",
	navLink: ROUTES[route].STOCK.BASE,
	navIcon: <StockIcon width="25" height="25" />,
});

const Feedback = (route: "ADMIN"): NavbarItem => ({
	navItem: "Sidebar.Feedback",
	navLink: ROUTES[route].FEEDBACK.BASE,
	navIcon: <StarIcon width={22.5} height={22.5} />,
});

const Insurance = (route: "ADMIN"): NavbarItem => ({
	navItem: "Sidebar.Insurance",
	navLink: ROUTES[route].INSURANCE.BASE,
	navIcon: <InsuranceIcon width={22.5} />,
});

const Overview = (route: "ADMIN"): NavbarItem => ({
	navItem: "Sidebar.Overview",
	navLink: ROUTES[route].OVERVIEW.BASE,
	navIcon: <DashboardIcon width={22.5} height={22.5} />,
	exact: true,
});

const Scheduler = (route: "ADMIN" | "DENTIST"): NavbarItem => ({
	navItem: "Sidebar.Scheduler",
	navLink: ROUTES[route].SCHEDULER,
	navIcon: <SchedulerIcon width={22.5} height={22.5} />,
});

type NavItemsParamsType = { tasksCount: number };

export const assistantNavItems = ({
	tasksCount,
}: NavItemsParamsType): NavbarItem[] => [
	{
		navItem: "Sidebar.Main",
		navLink: ROUTES.ASSISTANT.SCHEDULER,
		navIcon: <HomeIcon width={22.5} height={22.5} />,
		exact: true,
	},
	Patients("ASSISTANT"),
	Financial("ASSISTANT"),
	Lab("ASSISTANT"),
	Tasks("ASSISTANT", tasksCount),
	Stock("ASSISTANT"),
];

export const dentistNavItems = ({
	tasksCount,
}: NavItemsParamsType): NavbarItem[] => [
	{
		navItem: "Sidebar.Main",
		navLink: ROUTES.DENTIST.HOME,
		navIcon: <HomeIcon width={22.5} height={22.5} />,
		exact: true,
	},
	Scheduler("DENTIST"),
	Patients("DENTIST"),
	Lab("DENTIST"),
	Tasks("DENTIST", tasksCount),
];

export const adminItems = ({ tasksCount }: NavItemsParamsType): NavbarItem[] =>
	process.env.NODE_ENV === "development"
		? [
				{
					navItem: "Sidebar.Main",
					navLink: ROUTES.ADMIN.HOME,
					navIcon: <HomeIcon width={22.5} height={22.5} />,
					exact: true,
				},
				Overview("ADMIN"),
				Scheduler("ADMIN"),
				Patients("ADMIN"),
				Financial("ADMIN"),
				Lab("ADMIN"),
				Tasks("ADMIN", tasksCount),
				Stock("ADMIN"),
				Feedback("ADMIN"),
				Insurance("ADMIN"),
		  ]
		: [
				{
					navItem: "Sidebar.Main",
					navLink: ROUTES.ADMIN.HOME,
					navIcon: <HomeIcon width={22.5} height={22.5} />,
					exact: true,
				},
				Overview("ADMIN"),
				Scheduler("ADMIN"),
				Patients("ADMIN"),
				Financial("ADMIN"),
				Lab("ADMIN"),
				Tasks("ADMIN", tasksCount),
				Stock("ADMIN"),
				// Feedback("ADMIN"),
				// Insurance("ADMIN"),
		  ];
