import { PatientFilePlanType, PatientFileType } from "src/@types";
import { updatePlan } from "../plan";

type ChangeAllTeethTypeParamsType = {
	patientId: PatientFileType["id"];
	teethType: PatientFilePlanType["teeth"][number]["type"];
};

export const changeAllTeethType = ({
	teethType,
}: ChangeAllTeethTypeParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			return {
				...planToUpdate,
				teeth: planToUpdate.teeth.map((tooth) => ({
					...tooth,
					type: teethType,
				})),
			};
		},
	});
};
