import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Btn, Popup, TrashIcon } from "src/components";

type DeletePopupProps = {
	isOpen: boolean;
	closeDeletePopup: () => void;
	onDelete: () => void;
	desc?: string;
};

export function DeletePopup({
	isOpen,
	closeDeletePopup,
	onDelete,
	desc,
}: DeletePopupProps) {
	const { t } = useTranslation("global");

	return (
		<Popup
			show={isOpen}
			close={closeDeletePopup}
			width="446px"
			className="rounded-[10px] bg-nativeWhite py-7 px-[3.75rem]"
		>
			<div className="m-auto mb-[1.3125rem] flex h-[75px] w-[75px] place-content-center rounded-full bg-[#E12D3A] text-nativeWhite">
				<TrashIcon width={24} />
			</div>

			<h2 className="mb-2.5 text-center font-poppins text-xl font-medium capitalize leading-[1.375rem] text-dark">
				{t("Delete_Popup.Title")}
			</h2>

			<p className="leading-31.375rem mb-10 text-center font-poppins text-sm font-medium capitalize text-gray-600">
				{t("Delete_Popup.Desc", {
					desc: desc || t("Delete_Popup.Default_Desc"),
				})}
			</p>

			<div className="flex place-content-center gap-4">
				<Btn
					variant="secondary"
					size="md"
					className="bg- !w-[9.6875rem] bg-[#C1C1C1] !py-[0.90625rem] text-base font-medium leading-5 text-nativeWhite"
					rounded="rounded-md"
					onClick={closeDeletePopup}
				>
					{t("Delete_Popup.Buttons.Cancel")}
				</Btn>

				<Btn
					type="button"
					variant="secondary"
					size="md"
					className="bg- !w-[9.6875rem] bg-[#E12D3A] !py-[0.90625rem] text-base font-medium leading-5 text-nativeWhite"
					rounded="rounded-md"
					onClick={onDelete}
				>
					{t("Delete_Popup.Buttons.Delete")}
				</Btn>
			</div>
		</Popup>
	);
}

export function useDeletePopup<DeleteDataType = any>() {
	const [deletePopup, setDeletePopup] = useState<{
		isOpen: boolean;
		deleteData: DeleteDataType | null;
	}>({
		isOpen: false,
		deleteData: null,
	});

	const openDeletePopup = (deleteData?: DeleteDataType) => {
		setDeletePopup({ isOpen: true, deleteData: deleteData || null });
	};

	const closeDeletePopup = () => {
		setDeletePopup({ isOpen: false, deleteData: null });
	};

	return { deletePopup, openDeletePopup, closeDeletePopup };
}
