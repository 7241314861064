import { Route, Switch } from "react-router-dom";
import {
	LoginPage,
	ForgotPassword,
	LoginSuccessfully,
	RestPassword,
	VerificationCode,
	Register,
} from "../pages";
import { ROUTES } from "../constants/index";

export const AuthApp = () => {
	return (
		<Switch>
			<Route path={ROUTES.AUTH.LOGIN} exact>
				<LoginPage />
			</Route>
			<Route path={ROUTES.AUTH.FORGOT_PASSWORD}>
				<ForgotPassword />
			</Route>
			<Route path={ROUTES.AUTH.VERIFICATION_CODE}>
				<VerificationCode />
			</Route>
			<Route path={ROUTES.AUTH.RESET_PASSWORD}>
				<RestPassword />
			</Route>
			<Route path={ROUTES.AUTH.LOGIN_SUCCESSFULLY}>
				<LoginSuccessfully />
			</Route>
			<Route path={ROUTES.AUTH.REGISTER}>
				<Register />
			</Route>
		</Switch>
	);
};
