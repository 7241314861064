import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { toast } from "react-toastify";
import { PatientFilePlanType, PatientFileType } from "src/@types";
import { RootState } from "src/redux/store/store";
import { createEmptyPlan } from "../../utils";
import { AxiosResponse } from "axios";

export const createPatientPlan = createAsyncThunk<
	{ plan: PatientFilePlanType },
	{
		patientId: PatientFileType["id"];
		planName: string;
		planType: "new" | "basedOnLastDiagnosis";
	},
	{ rejectValue: string; state: RootState }
>(
	"patientProfile/createPatientPlan",
	async ({ patientId, planName, planType }, thunkAPI) => {
		try {
			const lastDiagnoses =
				thunkAPI.getState().patientProfile?.selectedPlan?.diagnoses;

			const { id, ...planWithoutId } = createEmptyPlan(patientId, planName);

			const { data: plan } = await axios.post<
				PatientFilePlanType,
				AxiosResponse<PatientFilePlanType>,
				Omit<PatientFilePlanType, "id">
			>(`/patients/${patientId}/plans`, {
				...planWithoutId,
				diagnoses: planType === "new" ? [] : lastDiagnoses || [],
			});

			return { plan: plan };
		} catch (error) {
			toast.error("Error creating plan");

			return thunkAPI.rejectWithValue("Error creating plan");
		}
	}
);
