import { RootState } from "../../store/store";
import { createSlice } from "@reduxjs/toolkit";
import { ServiceType } from "src/@types";
import {
	createService,
	deleteService,
	fetchServices,
	updateService,
} from "./servicesAsyncActions";

interface InitialState {
	loading: boolean;
	createLoading: boolean;
	updateLoading: boolean;
	deleteLoading: boolean;

	errorMsg: string;
	createErrorMsg: string;
	updateErrorMsg: string;
	deleteErrorMsg: string;

	services: ServiceType[] | null;
	serviceDictionary: { [key: ServiceType["id"]]: ServiceType } | null;
	serviceDictionaryByName: { [key: ServiceType["name"]]: ServiceType } | null;
}

const initialState: InitialState = {
	loading: true,
	createLoading: false,
	updateLoading: false,
	deleteLoading: false,

	errorMsg: "",
	createErrorMsg: "",
	updateErrorMsg: "",
	deleteErrorMsg: "",

	services: null,
	serviceDictionary: null,
	serviceDictionaryByName: null,
};

const servicesSlice = createSlice({
	name: "services",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchServices.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchServices.rejected, (state, action) => {
			state.errorMsg = action.payload || "";
			state.loading = false;
		});

		builder.addCase(fetchServices.fulfilled, (state, action) => {
			state.services = action.payload;

			state.serviceDictionary = action.payload.reduce(
				(acc, service) => ({ ...acc, [service.id]: service }),
				{}
			);

			state.serviceDictionaryByName = action.payload.reduce(
				(acc, service) => ({ ...acc, [service.name]: service }),
				{}
			);

			state.loading = false;
		});

		builder.addCase(createService.pending, (state) => {
			state.createLoading = true;
		});

		builder.addCase(createService.rejected, (state, action) => {
			state.createErrorMsg = action.payload || "";
			state.createLoading = false;
		});

		builder.addCase(createService.fulfilled, (state, action) => {
			state.services?.unshift(action.payload);

			if (state.serviceDictionary) {
				state.serviceDictionary[action.payload.id] = action.payload;
			}

			if (state.serviceDictionaryByName) {
				state.serviceDictionaryByName[action.payload.name] = action.payload;
			}

			state.createLoading = false;
		});

		builder.addCase(updateService.pending, (state) => {
			state.updateLoading = true;
		});

		builder.addCase(updateService.rejected, (state, action) => {
			state.updateErrorMsg = action.payload || "";
			state.updateLoading = false;
		});

		builder.addCase(updateService.fulfilled, (state, action) => {
			const serviceIndex = state.services?.findIndex(
				(service) => service.id === action.payload.id
			);

			if (serviceIndex !== undefined && serviceIndex !== -1 && state.services) {
				state.services[serviceIndex] = action.payload;
			}

			if (state.serviceDictionary) {
				state.serviceDictionary[action.payload.id] = action.payload;
			}

			if (state.serviceDictionaryByName) {
				state.serviceDictionaryByName[action.payload.name] = action.payload;
			}

			state.updateLoading = false;
		});

		builder.addCase(deleteService.pending, (state) => {
			state.deleteLoading = true;
		});

		builder.addCase(deleteService.rejected, (state, action) => {
			state.deleteErrorMsg = action.payload || "";
			state.deleteLoading = false;
		});

		builder.addCase(deleteService.fulfilled, (state, action) => {
			const serviceIndex = state.services?.findIndex(
				(service) => service.id === action.payload.id
			);

			if (serviceIndex !== undefined && serviceIndex !== -1 && state.services) {
				state.services.splice(serviceIndex, 1);
			}

			if (state.serviceDictionary) {
				delete state.serviceDictionary[action.payload.id];
			}

			if (state.serviceDictionaryByName) {
				delete state.serviceDictionaryByName[action.payload.name];
			}

			state.deleteLoading = false;
		});
	},
});

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const servicesSelector = (state: RootState) => state.services;
export const servicesReducer = servicesSlice.reducer;
