import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateInput, Btn } from "../..";
import { ROUTES } from "src/constants/index";
import { useAssistantDentistRoute } from "src/hooks";
import { PopupHeader } from "src/components";

type PrintPopupProps = {
	close: () => void;
};

export const PrintPopup = ({ close }: PrintPopupProps) => {
	const { i18n, t } = useTranslation("scheduler");
	const route = useAssistantDentistRoute();
	const { control, handleSubmit } = useForm({
		defaultValues: {
			dayToPrint: new Date(),
		},
	});

	const onSelectDate = (data: any) => {
		const url = `${ROUTES[route].PRINT_APPOINTMENTS}?date=${data.dayToPrint}`;

		window.open(url);
	};

	return (
		<div className="divide-y px-6">
			<PopupHeader title={t("Scheduler.Print_Popup.Day_Print")} close={close} />

			<form
				onSubmit={handleSubmit(onSelectDate)}
				className="flex items-center gap-8 py-8"
			>
				<div className="w-1/2">
					<Controller
						control={control}
						name="dayToPrint"
						render={({
							field: { onChange, onBlur, value, name },
							fieldState: { error },
						}) => (
							<DateInput
								onChange={onChange}
								value={value}
								name={name}
								error={error}
								onBlur={onBlur}
								variant="filled"
								placeholder={"اليوم، شهر"}
								dateFormat={
									i18n.language === "ar" ? "EEEE, d LLLL" : "EEE, d LLLL"
								}
								textDirection={i18n.language === "ar" ? "rtl" : "ltr"}
								popperPlacement={"auto"}
								withPortal
							/>
						)}
					/>
				</div>

				<Btn type="submit" variant="primary-outline" size="xs">
					{t("Buttons.Print", { ns: "global" })}
				</Btn>
			</form>
		</div>
	);
};
