import { useTranslation } from "react-i18next";
import { NoData } from "../NoData";
import { SendIcon } from "src/components";
import NoConsumptionImg from "src/assets/svg/no_consumption.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/constants";
import { useAdminAssistantRoute } from "src/hooks";

export function NoConsumption() {
	const { t } = useTranslation("stock");
	const route = useAdminAssistantRoute();
	const history = useHistory();

	return (
		<NoData
			imgAlt="no consumption"
			imgSrc={NoConsumptionImg}
			notFoundMsg={t("Stock.No_Table_Data.No_Consumption")}
			desc={t("Stock.No_Table_Data.ConsumptionMsg")}
			btn={t("Stock.No_Table_Data.Send_To_Clinic")}
			icon={<SendIcon />}
			clickHandler={() => history.push(ROUTES[route].STOCK.BASE)}
		/>
	);
}
