import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LabOrderStatusType, LabOrderType } from "src/@types";
import { StatusDropdown } from "src/components";
import { LAB_ORDER_STATUS, LAB_ORDER_STATUS_OBJECT } from "src/constants";
import { useAxios } from "src/hooks";

type LabStatusDropdownProps = {
	orderId: number;
	currentStatus: number;
	onChange?: (labOrder: LabOrderType) => void;
};

export function LabStatusDropdown({
	orderId,
	currentStatus,
	onChange,
}: LabStatusDropdownProps) {
	const { t } = useTranslation("lab");
	const { makeRequest } = useAxios<LabOrderType>({ runNow: false });

	return (
		<StatusDropdown<LabOrderStatusType>
			currentStatus={currentStatus}
			statusList={LAB_ORDER_STATUS(t)}
			statusObject={LAB_ORDER_STATUS_OBJECT(t)}
			handleChange={(selectedOption) => {
				makeRequest({
					configs: {
						url: `/labs/orders/${orderId}?order_status=${selectedOption?.value}`,
						method: "PUT",
					},
					handleSuccess: (data) => {
						onChange?.(data);
						toast.success(t("Orders.Messages.Order_Status_Updated"));
					},
				});
			}}
		/>
	);
}
