import { useTableBodyContext } from "../TableBodyProvider";
import { getClassName, getStyle } from "../utils";
import { TBodyCeil } from "./TBodyCeil";

export function TBody() {
	const { rows, getTableBodyProps, prepareRow, styles, otherProps } =
		useTableBodyContext();

	return (
		<tbody
			{...getTableBodyProps()}
			className={styles?.tbody?.className}
			style={styles?.tbody?.style}
		>
			{rows.map((row) => {
				prepareRow(row);

				const { key, ...restRowProps } = row.getRowProps();

				return (
					<tr
						key={key}
						{...restRowProps}
						className={getClassName(styles?.tbody?.tr?.className, row)}
						style={getStyle(styles?.tbody?.tr?.style, row)}
						{...otherProps?.rowProps?.(row)}
					>
						<TBodyCeil row={row} />
					</tr>
				);
			})}
		</tbody>
	);
}
