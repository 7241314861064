export function ClockBigIcon() {
	return (
		<svg
			width="81"
			height="78"
			viewBox="0 0 81 78"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.4389 77.6328C31.5339 77.6328 40.5282 68.6785 40.5282 57.6328C40.5282 46.5871 31.5339 37.6328 20.4389 37.6328C9.34389 37.6328 0.349609 46.5871 0.349609 57.6328C0.349609 68.6785 9.34389 77.6328 20.4389 77.6328Z"
				fill="#E6F2FF"
			/>
			<path
				d="M29.8211 24.3828C36.4782 24.3828 41.8747 19.0102 41.8747 12.3828C41.8747 5.7554 36.4782 0.382812 29.8211 0.382812C23.1641 0.382812 17.7676 5.7554 17.7676 12.3828C17.7676 19.0102 23.1641 24.3828 29.8211 24.3828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M66.5724 54.3828C74.3389 54.3828 80.6349 48.1148 80.6349 40.3828C80.6349 32.6508 74.3389 26.3828 66.5724 26.3828C58.8059 26.3828 52.5099 32.6508 52.5099 40.3828C52.5099 48.1148 58.8059 54.3828 66.5724 54.3828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M38.3026 28.6819C37.7388 28.6819 37.295 28.2281 37.295 27.6788V23.1652C37.295 22.6039 37.7508 22.1621 38.3026 22.1621C38.8663 22.1621 39.3101 22.6159 39.3101 23.1652V27.6788C39.3221 28.2401 38.8663 28.6819 38.3026 28.6819Z"
				fill="#AACED6"
			/>
			<path
				d="M46.8406 30.9606C46.6727 30.9606 46.4927 30.9128 46.3368 30.8292C45.8571 30.5546 45.6891 29.9336 45.965 29.456L48.2319 25.5513C48.5078 25.0736 49.1315 24.9065 49.6113 25.1811C50.0911 25.4558 50.259 26.0767 49.9831 26.5543L47.7162 30.459C47.5362 30.7814 47.1884 30.9606 46.8406 30.9606Z"
				fill="#AACED6"
			/>
			<path
				d="M53.1043 37.1838C52.7564 37.1838 52.4086 37.0047 52.2287 36.6823C51.9528 36.2046 52.1207 35.5837 52.6005 35.3091L56.5226 33.0522C57.0024 32.7776 57.6261 32.9447 57.902 33.4224C58.1779 33.9 58.0099 34.521 57.5301 34.7956L53.608 37.0524C53.4521 37.148 53.2722 37.1838 53.1043 37.1838Z"
				fill="#AACED6"
			/>
			<path
				d="M59.9162 45.6858H55.3824C54.8187 45.6858 54.3749 45.232 54.3749 44.6827C54.3749 44.1334 54.8307 43.6797 55.3824 43.6797H59.9162C60.48 43.6797 60.9238 44.1334 60.9238 44.6827C60.9238 45.232 60.48 45.6858 59.9162 45.6858Z"
				fill="#AACED6"
			/>
			<path
				d="M57.0264 56.4436C56.8585 56.4436 56.6785 56.3958 56.5226 56.3122L52.6005 54.0554C52.1207 53.7807 51.9528 53.1598 52.2287 52.6821C52.5045 52.2045 53.1282 52.0373 53.608 52.312L57.5301 54.5688C58.0099 54.8435 58.1779 55.4644 57.902 55.942C57.7101 56.2644 57.3742 56.4436 57.0264 56.4436Z"
				fill="#AACED6"
			/>
			<path
				d="M49.1075 64.3258C48.7597 64.3258 48.4118 64.1467 48.2319 63.8243L45.965 59.9196C45.6891 59.4419 45.8571 58.821 46.3368 58.5464C46.8166 58.2717 47.4403 58.4389 47.7162 58.9165L49.9831 62.8212C50.259 63.2989 50.0911 63.9198 49.6113 64.1944C49.4554 64.278 49.2874 64.3258 49.1075 64.3258Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3026 67.2034C37.7388 67.2034 37.295 66.7496 37.295 66.2003V61.6866C37.295 61.1254 37.7508 60.6836 38.3026 60.6836C38.8663 60.6836 39.3101 61.1374 39.3101 61.6866V66.2003C39.3221 66.7616 38.8663 67.2034 38.3026 67.2034Z"
				fill="#AACED6"
			/>
			<path
				d="M43.5081 26.3656C43.4241 26.3656 43.3281 26.3537 43.2442 26.3298C42.7044 26.1865 42.3806 25.6372 42.5245 25.0999L42.9203 23.6431C43.0642 23.1057 43.628 22.7833 44.1557 22.9266C44.6955 23.0699 45.0193 23.6192 44.8754 24.1565L44.4796 25.6133C44.3716 26.0671 43.9519 26.3656 43.5081 26.3656Z"
				fill="#AACED6"
			/>
			<path
				d="M52.5127 31.5348C52.2488 31.5348 51.9969 31.4393 51.793 31.2363C51.3972 30.8422 51.3972 30.2094 51.793 29.8153L52.8606 28.7526C53.2564 28.3585 53.8921 28.3585 54.2879 28.7526C54.6837 29.1466 54.6837 29.7795 54.2879 30.1735L53.2204 31.2363C53.0405 31.4393 52.7766 31.5348 52.5127 31.5348Z"
				fill="#AACED6"
			/>
			<path
				d="M57.7207 40.5158C57.2769 40.5158 56.8691 40.2172 56.7491 39.7635C56.6052 39.2261 56.9291 38.6769 57.4688 38.5336L58.9321 38.1395C59.4718 37.9962 60.0236 38.3186 60.1675 38.856C60.3114 39.3933 59.9876 39.9426 59.4478 40.0859L57.9845 40.4799C57.9006 40.5038 57.8046 40.5158 57.7207 40.5158Z"
				fill="#AACED6"
			/>
			<path
				d="M59.1842 51.2598C59.1003 51.2598 59.0043 51.2479 58.9203 51.224L57.457 50.8299C56.9173 50.6866 56.5935 50.1374 56.7374 49.6C56.8813 49.0627 57.4331 48.7522 57.9728 48.8836L59.4361 49.2776C59.9759 49.4209 60.2997 49.9702 60.1558 50.5075C60.0358 50.9613 59.628 51.2598 59.1842 51.2598Z"
				fill="#AACED6"
			/>
			<path
				d="M53.5802 60.9118C53.3163 60.9118 53.0645 60.8162 52.8606 60.6133L51.793 59.5505C51.3972 59.1565 51.3972 58.5236 51.793 58.1295C52.1889 57.7355 52.8246 57.7355 53.2204 58.1295L54.2879 59.1923C54.6837 59.5863 54.6837 60.2192 54.2879 60.6133C54.108 60.8043 53.8441 60.9118 53.5802 60.9118Z"
				fill="#AACED6"
			/>
			<path
				d="M43.9036 66.475C43.4598 66.475 43.052 66.1765 42.9321 65.7227L42.5362 64.2659C42.3923 63.7286 42.7162 63.1793 43.2559 63.036C43.7956 62.8927 44.3474 63.2151 44.4913 63.7525L44.8871 65.2093C45.0311 65.7466 44.7072 66.2959 44.1675 66.4392C44.0715 66.4631 43.9876 66.475 43.9036 66.475Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3025 49.4955C35.6398 49.4955 33.4688 47.3342 33.4688 44.6833C33.4688 42.0324 35.6398 39.8711 38.3025 39.8711C40.9653 39.8711 43.1362 42.0324 43.1362 44.6833C43.1482 47.3342 40.9773 49.4955 38.3025 49.4955ZM38.3025 41.8891C36.7553 41.8891 35.4959 43.1429 35.4959 44.6833C35.4959 46.2237 36.7553 47.4775 38.3025 47.4775C39.8498 47.4775 41.1092 46.2237 41.1092 44.6833C41.1212 43.1429 39.8618 41.8891 38.3025 41.8891Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3026 41.8895C37.7388 41.8895 37.295 41.4357 37.295 40.8865V33.089C37.295 32.5278 37.7508 32.0859 38.3026 32.0859C38.8663 32.0859 39.3101 32.5397 39.3101 33.089V40.8865C39.3221 41.4357 38.8663 41.8895 38.3026 41.8895Z"
				fill="#AACED6"
			/>
			<path
				d="M29.7605 30.9612C29.4127 30.9612 29.0649 30.7821 28.8849 30.4597L26.618 26.555C26.3421 26.0773 26.4981 25.4564 26.9898 25.1818C27.4696 24.9071 28.0933 25.0624 28.3692 25.5519L30.6361 29.4566C30.912 29.9343 30.7441 30.5552 30.2643 30.8299C30.1084 30.9254 29.9404 30.9612 29.7605 30.9612Z"
				fill="#AACED6"
			/>
			<path
				d="M23.5088 37.1838C23.3409 37.1838 23.161 37.136 23.005 37.0524L19.0829 34.7956C18.6031 34.521 18.4352 33.9 18.7111 33.4224C18.987 32.9447 19.6107 32.7776 20.0904 33.0522L24.0126 35.3091C24.4924 35.5837 24.6603 36.2046 24.3844 36.6823C24.2045 37.0047 23.8567 37.1838 23.5088 37.1838Z"
				fill="#AACED6"
			/>
			<path
				d="M21.225 45.6858H16.6912C16.1275 45.6858 15.6837 45.232 15.6837 44.6827C15.6837 44.1334 16.1395 43.6797 16.6912 43.6797H21.225C21.7888 43.6797 22.2326 44.1334 22.2326 44.6827C22.2326 45.232 21.7888 45.6858 21.225 45.6858Z"
				fill="#AACED6"
			/>
			<path
				d="M19.5906 56.4436C19.2428 56.4436 18.8949 56.2644 18.715 55.942C18.4391 55.4644 18.6071 54.8435 19.0868 54.5688L23.0089 52.312C23.4887 52.0373 24.1125 52.2045 24.3883 52.6821C24.6642 53.1598 24.5083 53.7807 24.0165 54.0554L20.0943 56.3122C19.9384 56.4077 19.7585 56.4436 19.5906 56.4436Z"
				fill="#AACED6"
			/>
			<path
				d="M27.4949 64.3258C27.327 64.3258 27.147 64.278 26.9911 64.1944C26.5113 63.9198 26.3434 63.2989 26.6193 62.8212L28.8862 58.9165C29.1621 58.4389 29.7858 58.2717 30.2656 58.5464C30.7453 58.821 30.9133 59.4419 30.6374 59.9196L28.3705 63.8243C28.1905 64.1467 27.8547 64.3258 27.4949 64.3258Z"
				fill="#AACED6"
			/>
			<path
				d="M33.1087 26.3656C32.6649 26.3656 32.2571 26.0671 32.1371 25.6133L31.7413 24.1565C31.5974 23.6192 31.9212 23.0699 32.461 22.9266C33.0007 22.7833 33.5525 23.1057 33.6964 23.6431L34.0922 25.0999C34.2362 25.6372 33.9123 26.1865 33.3726 26.3298C33.2766 26.3537 33.1926 26.3656 33.1087 26.3656Z"
				fill="#AACED6"
			/>
			<path
				d="M24.088 31.5348C23.8242 31.5348 23.5723 31.4393 23.3684 31.2363L22.3009 30.1735C21.905 29.7795 21.905 29.1466 22.3009 28.7526C22.6967 28.3585 23.3324 28.3585 23.7282 28.7526L24.7957 29.8153C25.1915 30.2094 25.1915 30.8422 24.7957 31.2363C24.6038 31.4393 24.3519 31.5348 24.088 31.5348Z"
				fill="#AACED6"
			/>
			<path
				d="M18.8932 40.5137C18.8093 40.5137 18.7133 40.5018 18.6294 40.4779L17.1661 40.0838C16.6263 39.9405 16.3025 39.3913 16.4464 38.8539C16.5903 38.3166 17.1421 38.0061 17.6818 38.1375L19.1451 38.5315C19.6849 38.6748 20.0087 39.2241 19.8648 39.7614C19.7448 40.2152 19.337 40.5137 18.8932 40.5137Z"
				fill="#AACED6"
			/>
			<path
				d="M17.4316 51.2619C16.9878 51.2619 16.58 50.9633 16.4601 50.5096C16.3161 49.9722 16.64 49.4229 17.1797 49.2797L18.643 48.8856C19.1828 48.7423 19.7345 49.0647 19.8785 49.6021C20.0224 50.1394 19.6985 50.6887 19.1588 50.832L17.6955 51.226C17.6115 51.2499 17.5156 51.2619 17.4316 51.2619Z"
				fill="#AACED6"
			/>
			<path
				d="M23.0205 60.9118C22.7566 60.9118 22.5048 60.8162 22.3009 60.6133C21.905 60.2192 21.905 59.5863 22.3009 59.1923L23.3684 58.1295C23.7642 57.7355 24.3999 57.7355 24.7957 58.1295C25.1915 58.5236 25.1915 59.1565 24.7957 59.5505L23.7282 60.6133C23.5363 60.8043 23.2844 60.9118 23.0205 60.9118Z"
				fill="#AACED6"
			/>
			<path
				d="M32.7131 66.475C32.6292 66.475 32.5332 66.4631 32.4493 66.4392C31.9095 66.2959 31.5857 65.7466 31.7296 65.2093L32.1254 63.7525C32.2693 63.2151 32.8331 62.8927 33.3608 63.036C33.9005 63.1793 34.2244 63.7286 34.0805 64.2659L33.6847 65.7227C33.5647 66.1765 33.1569 66.475 32.7131 66.475Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3036 70.7029C23.8985 70.7029 12.1681 59.0366 12.1681 44.6835C12.1681 30.3424 23.8865 18.6641 38.3036 18.6641C52.7088 18.6641 64.4392 30.3304 64.4392 44.6835C64.4392 59.0246 52.7208 70.7029 38.3036 70.7029ZM38.3036 20.6821C25.0139 20.6821 14.1951 31.4529 14.1951 44.6835C14.1951 57.9141 25.0139 68.6849 38.3036 68.6849C51.5933 68.6849 62.4122 57.9141 62.4122 44.6835C62.4122 31.4529 51.6053 20.6821 38.3036 20.6821Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3017 75.4419C21.2578 75.4419 7.40439 61.6381 7.40439 44.6819C7.40439 27.7137 21.2698 13.9219 38.3017 13.9219C55.3456 13.9219 69.199 27.7257 69.199 44.6819C69.211 61.65 55.3456 75.4419 38.3017 75.4419ZM38.3017 15.9399C22.3733 15.9399 9.41942 28.8362 9.41942 44.6938C9.41942 60.5395 22.3733 73.4358 38.3017 73.4358C54.2301 73.4358 67.184 60.5395 67.184 44.6938C67.184 28.8362 54.2301 15.9399 38.3017 15.9399Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3026 41.8895C37.7388 41.8895 37.295 41.4357 37.295 40.8865V33.089C37.295 32.5278 37.7508 32.0859 38.3026 32.0859C38.8663 32.0859 39.3101 32.5397 39.3101 33.089V40.8865C39.3221 41.4357 38.8663 41.8895 38.3026 41.8895Z"
				fill="#AACED6"
			/>
			<path
				d="M34.4889 45.6858H29.7752C29.2115 45.6858 28.7677 45.232 28.7677 44.6827C28.7677 44.1334 29.2234 43.6797 29.7752 43.6797H34.4889C35.0527 43.6797 35.4965 44.1334 35.4965 44.6827C35.4965 45.232 35.0407 45.6858 34.4889 45.6858Z"
				fill="#AACED6"
			/>
			<path
				d="M38.3026 27.1428C37.7388 27.1428 37.295 26.6891 37.295 26.1398V21.6261C37.295 21.0649 37.7508 20.623 38.3026 20.623C38.8663 20.623 39.3101 21.0768 39.3101 21.6261V26.1398C39.3221 26.701 38.8663 27.1428 38.3026 27.1428Z"
				fill="#046289"
			/>
			<path
				d="M46.8406 29.4215C46.6727 29.4215 46.4927 29.3737 46.3368 29.2901C45.8571 29.0155 45.6891 28.3946 45.965 27.9169L48.2319 24.0122C48.5078 23.5346 49.1315 23.3674 49.6113 23.6421C50.0911 23.9167 50.259 24.5376 49.9831 25.0153L47.7162 28.92C47.5362 29.2424 47.1884 29.4215 46.8406 29.4215Z"
				fill="#046289"
			/>
			<path
				d="M53.1043 35.6447C52.7564 35.6447 52.4086 35.4656 52.2287 35.1432C51.9528 34.6656 52.1207 34.0446 52.6005 33.77L56.5226 31.5131C57.0024 31.2385 57.6261 31.4057 57.902 31.8833C58.1779 32.361 58.0099 32.9819 57.5301 33.2565L53.608 35.5134C53.4521 35.6089 53.2722 35.6447 53.1043 35.6447Z"
				fill="#046289"
			/>
			<path
				d="M59.9162 44.1467H55.3824C54.8187 44.1467 54.3749 43.693 54.3749 43.1437C54.3749 42.5944 54.8307 42.1406 55.3824 42.1406H59.9162C60.48 42.1406 60.9238 42.5944 60.9238 43.1437C60.9238 43.693 60.48 44.1467 59.9162 44.1467Z"
				fill="#046289"
			/>
			<path
				d="M57.0264 54.9045C56.8585 54.9045 56.6785 54.8567 56.5226 54.7731L52.6005 52.5163C52.1207 52.2417 51.9528 51.6207 52.2287 51.1431C52.5045 50.6654 53.1282 50.4983 53.608 50.7729L57.5301 53.0298C58.0099 53.3044 58.1779 53.9253 57.902 54.403C57.7101 54.7254 57.3742 54.9045 57.0264 54.9045Z"
				fill="#046289"
			/>
			<path
				d="M49.1075 62.7867C48.7597 62.7867 48.4118 62.6076 48.2319 62.2852L45.965 58.3805C45.6891 57.9029 45.8571 57.2819 46.3368 57.0073C46.8166 56.7326 47.4403 56.8998 47.7162 57.3775L49.9831 61.2822C50.259 61.7598 50.0911 62.3807 49.6113 62.6554C49.4554 62.739 49.2874 62.7867 49.1075 62.7867Z"
				fill="#046289"
			/>
			<path
				d="M38.3026 65.6643C37.7388 65.6643 37.295 65.2106 37.295 64.6613V60.1476C37.295 59.5863 37.7508 59.1445 38.3026 59.1445C38.8663 59.1445 39.3101 59.5983 39.3101 60.1476V64.6613C39.3221 65.2225 38.8663 65.6643 38.3026 65.6643Z"
				fill="#046289"
			/>
			<path
				d="M43.5081 24.8266C43.4241 24.8266 43.3281 24.8146 43.2442 24.7907C42.7044 24.6474 42.3806 24.0982 42.5245 23.5608L42.9203 22.104C43.0642 21.5667 43.628 21.2443 44.1557 21.3876C44.6955 21.5309 45.0193 22.0801 44.8754 22.6175L44.4796 24.0743C44.3716 24.528 43.9519 24.8266 43.5081 24.8266Z"
				fill="#046289"
			/>
			<path
				d="M52.5127 29.9958C52.2488 29.9958 51.9969 29.9002 51.793 29.6972C51.3972 29.3032 51.3972 28.6703 51.793 28.2763L52.8606 27.2135C53.2564 26.8195 53.8921 26.8195 54.2879 27.2135C54.6837 27.6076 54.6837 28.2404 54.2879 28.6345L53.2204 29.6972C53.0405 29.9002 52.7766 29.9958 52.5127 29.9958Z"
				fill="#046289"
			/>
			<path
				d="M57.7207 38.9767C57.2769 38.9767 56.8691 38.6782 56.7491 38.2244C56.6052 37.6871 56.9291 37.1378 57.4688 36.9945L58.9321 36.6004C59.4718 36.4572 60.0236 36.7796 60.1675 37.3169C60.3114 37.8543 59.9876 38.4035 59.4478 38.5468L57.9845 38.9409C57.9006 38.9648 57.8046 38.9767 57.7207 38.9767Z"
				fill="#046289"
			/>
			<path
				d="M59.1842 49.7207C59.1003 49.7207 59.0043 49.7088 58.9203 49.6849L57.457 49.2909C56.9173 49.1476 56.5935 48.5983 56.7374 48.0609C56.8813 47.5236 57.4331 47.2131 57.9728 47.3445L59.4361 47.7385C59.9759 47.8818 60.2997 48.4311 60.1558 48.9685C60.0358 49.4222 59.628 49.7207 59.1842 49.7207Z"
				fill="#046289"
			/>
			<path
				d="M53.5802 59.3727C53.3163 59.3727 53.0645 59.2772 52.8606 59.0742L51.793 58.0114C51.3972 57.6174 51.3972 56.9845 51.793 56.5905C52.1889 56.1964 52.8246 56.1964 53.2204 56.5905L54.2879 57.6532C54.6837 58.0473 54.6837 58.6801 54.2879 59.0742C54.108 59.2652 53.8441 59.3727 53.5802 59.3727Z"
				fill="#046289"
			/>
			<path
				d="M43.9036 64.9359C43.4598 64.9359 43.052 64.6374 42.9321 64.1837L42.5362 62.7269C42.3923 62.1895 42.7162 61.6402 43.2559 61.4969C43.7956 61.3536 44.3474 61.6761 44.4913 62.2134L44.8871 63.6702C45.0311 64.2075 44.7072 64.7568 44.1675 64.9001C44.0715 64.924 43.9876 64.9359 43.9036 64.9359Z"
				fill="#046289"
			/>
			<path
				d="M38.3025 47.9565C35.6398 47.9565 33.4688 45.7952 33.4688 43.1443C33.4688 40.4934 35.6398 38.332 38.3025 38.332C40.9653 38.332 43.1362 40.4934 43.1362 43.1443C43.1482 45.7952 40.9773 47.9565 38.3025 47.9565ZM38.3025 40.3501C36.7553 40.3501 35.4959 41.6039 35.4959 43.1443C35.4959 44.6846 36.7553 45.9384 38.3025 45.9384C39.8498 45.9384 41.1092 44.6846 41.1092 43.1443C41.1212 41.6039 39.8618 40.3501 38.3025 40.3501Z"
				fill="#046289"
			/>
			<path
				d="M38.3026 40.3504C37.7388 40.3504 37.295 39.8967 37.295 39.3474V31.5499C37.295 30.9887 37.7508 30.5469 38.3026 30.5469C38.8663 30.5469 39.3101 31.0006 39.3101 31.5499V39.3474C39.3221 39.8967 38.8663 40.3504 38.3026 40.3504Z"
				fill="#046289"
			/>
			<path
				d="M29.7605 29.4221C29.4127 29.4221 29.0649 29.243 28.8849 28.9206L26.618 25.0159C26.3421 24.5383 26.4981 23.9173 26.9898 23.6427C27.4696 23.3681 28.0933 23.5233 28.3692 24.0129L30.6361 27.9176C30.912 28.3952 30.7441 29.0162 30.2643 29.2908C30.1084 29.3863 29.9404 29.4221 29.7605 29.4221Z"
				fill="#046289"
			/>
			<path
				d="M23.5088 35.6447C23.3409 35.6447 23.161 35.597 23.005 35.5134L19.0829 33.2565C18.6031 32.9819 18.4352 32.361 18.7111 31.8833C18.987 31.4057 19.6107 31.2385 20.0904 31.5131L24.0126 33.77C24.4924 34.0446 24.6603 34.6656 24.3844 35.1432C24.2045 35.4656 23.8567 35.6447 23.5088 35.6447Z"
				fill="#046289"
			/>
			<path
				d="M21.225 44.1467H16.6912C16.1275 44.1467 15.6837 43.693 15.6837 43.1437C15.6837 42.5944 16.1395 42.1406 16.6912 42.1406H21.225C21.7888 42.1406 22.2326 42.5944 22.2326 43.1437C22.2326 43.693 21.7888 44.1467 21.225 44.1467Z"
				fill="#046289"
			/>
			<path
				d="M19.5906 54.9045C19.2428 54.9045 18.8949 54.7254 18.715 54.403C18.4391 53.9253 18.6071 53.3044 19.0868 53.0298L23.0089 50.7729C23.4887 50.4983 24.1125 50.6654 24.3883 51.1431C24.6642 51.6207 24.5083 52.2417 24.0165 52.5163L20.0943 54.7731C19.9384 54.8687 19.7585 54.9045 19.5906 54.9045Z"
				fill="#046289"
			/>
			<path
				d="M27.4949 62.7867C27.327 62.7867 27.147 62.739 26.9911 62.6554C26.5113 62.3807 26.3434 61.7598 26.6193 61.2822L28.8862 57.3775C29.1621 56.8998 29.7858 56.7326 30.2656 57.0073C30.7453 57.2819 30.9133 57.9029 30.6374 58.3805L28.3705 62.2852C28.1905 62.6076 27.8547 62.7867 27.4949 62.7867Z"
				fill="#046289"
			/>
			<path
				d="M33.1087 24.8266C32.6649 24.8266 32.2571 24.528 32.1371 24.0743L31.7413 22.6175C31.5974 22.0801 31.9212 21.5309 32.461 21.3876C33.0007 21.2443 33.5525 21.5667 33.6964 22.104L34.0922 23.5608C34.2362 24.0982 33.9123 24.6474 33.3726 24.7907C33.2766 24.8146 33.1926 24.8266 33.1087 24.8266Z"
				fill="#046289"
			/>
			<path
				d="M24.088 29.9958C23.8242 29.9958 23.5723 29.9002 23.3684 29.6972L22.3009 28.6345C21.905 28.2404 21.905 27.6076 22.3009 27.2135C22.6967 26.8195 23.3324 26.8195 23.7282 27.2135L24.7957 28.2763C25.1915 28.6703 25.1915 29.3032 24.7957 29.6972C24.6038 29.9002 24.3519 29.9958 24.088 29.9958Z"
				fill="#046289"
			/>
			<path
				d="M18.8932 38.9747C18.8093 38.9747 18.7133 38.9627 18.6294 38.9388L17.1661 38.5448C16.6263 38.4015 16.3025 37.8522 16.4464 37.3149C16.5903 36.7775 17.1421 36.467 17.6818 36.5984L19.1451 36.9924C19.6849 37.1357 20.0087 37.685 19.8648 38.2224C19.7448 38.6761 19.337 38.9747 18.8932 38.9747Z"
				fill="#046289"
			/>
			<path
				d="M17.4316 49.7228C16.9878 49.7228 16.58 49.4243 16.4601 48.9705C16.3161 48.4332 16.64 47.8839 17.1797 47.7406L18.643 47.3465C19.1828 47.2033 19.7345 47.5257 19.8785 48.063C20.0224 48.6003 19.6985 49.1496 19.1588 49.2929L17.6955 49.687C17.6115 49.7109 17.5156 49.7228 17.4316 49.7228Z"
				fill="#046289"
			/>
			<path
				d="M23.0205 59.3727C22.7566 59.3727 22.5048 59.2772 22.3009 59.0742C21.905 58.6801 21.905 58.0473 22.3009 57.6532L23.3684 56.5905C23.7642 56.1964 24.3999 56.1964 24.7957 56.5905C25.1915 56.9845 25.1915 57.6174 24.7957 58.0114L23.7282 59.0742C23.5363 59.2652 23.2844 59.3727 23.0205 59.3727Z"
				fill="#046289"
			/>
			<path
				d="M32.7131 64.9359C32.6292 64.9359 32.5332 64.924 32.4493 64.9001C31.9095 64.7568 31.5857 64.2075 31.7296 63.6702L32.1254 62.2134C32.2693 61.6761 32.8331 61.3536 33.3608 61.4969C33.9005 61.6402 34.2244 62.1895 34.0805 62.7269L33.6847 64.1837C33.5647 64.6374 33.1569 64.9359 32.7131 64.9359Z"
				fill="#046289"
			/>
			<path
				d="M38.3036 69.1639C23.8985 69.1639 12.1681 57.4975 12.1681 43.1444C12.1681 28.8033 23.8865 17.125 38.3036 17.125C52.7088 17.125 64.4392 28.7914 64.4392 43.1444C64.4392 57.4856 52.7208 69.1639 38.3036 69.1639ZM38.3036 19.143C25.0139 19.143 14.1951 29.9138 14.1951 43.1444C14.1951 56.375 25.0139 67.1458 38.3036 67.1458C51.5933 67.1458 62.4122 56.375 62.4122 43.1444C62.4122 29.9138 51.6053 19.143 38.3036 19.143Z"
				fill="#046289"
			/>
			<path
				d="M38.3017 73.9028C21.2578 73.9028 7.40439 60.099 7.40439 43.1428C7.40439 26.1747 21.2698 12.3828 38.3017 12.3828C55.3456 12.3828 69.199 26.1866 69.199 43.1428C69.211 60.111 55.3456 73.9028 38.3017 73.9028ZM38.3017 14.4008C22.3733 14.4008 9.41942 27.2971 9.41942 43.1548C9.41942 59.0005 22.3733 71.8967 38.3017 71.8967C54.2301 71.8967 67.184 59.0005 67.184 43.1548C67.184 27.2971 54.2301 14.4008 38.3017 14.4008Z"
				fill="#046289"
			/>
			<path
				d="M38.3026 40.3504C37.7388 40.3504 37.295 39.8967 37.295 39.3474V31.5499C37.295 30.9887 37.7508 30.5469 38.3026 30.5469C38.8663 30.5469 39.3101 31.0006 39.3101 31.5499V39.3474C39.3221 39.8967 38.8663 40.3504 38.3026 40.3504Z"
				fill="#046289"
			/>
			<path
				d="M34.4889 44.1467H29.7752C29.2115 44.1467 28.7677 43.693 28.7677 43.1437C28.7677 42.5944 29.2234 42.1406 29.7752 42.1406H34.4889C35.0527 42.1406 35.4965 42.5944 35.4965 43.1437C35.4965 43.693 35.0407 44.1467 34.4889 44.1467Z"
				fill="#046289"
			/>
			<path
				d="M75.5095 50.6719H71.4916C71.0798 50.6719 70.7383 50.3319 70.7383 49.9219C70.7383 49.5119 71.0798 49.1719 71.4916 49.1719H75.5095C75.9213 49.1719 76.2628 49.5119 76.2628 49.9219C76.2628 50.3319 75.9213 50.6719 75.5095 50.6719Z"
				fill="#046289"
			/>
			<path
				d="M73.5014 52.6719C73.0896 52.6719 72.748 52.3319 72.748 51.9219V47.9219C72.748 47.5119 73.0896 47.1719 73.5014 47.1719C73.9132 47.1719 74.2547 47.5119 74.2547 47.9219V51.9219C74.2547 52.3319 73.9132 52.6719 73.5014 52.6719Z"
				fill="#046289"
			/>
			<path
				d="M73.5014 44.6719C73.9175 44.6719 74.2547 44.3361 74.2547 43.9219C74.2547 43.5077 73.9175 43.1719 73.5014 43.1719C73.0853 43.1719 72.748 43.5077 72.748 43.9219C72.748 44.3361 73.0853 44.6719 73.5014 44.6719Z"
				fill="#046289"
			/>
			<path
				d="M73.5014 41.6719C73.9175 41.6719 74.2547 41.3361 74.2547 40.9219C74.2547 40.5077 73.9175 40.1719 73.5014 40.1719C73.0853 40.1719 72.748 40.5077 72.748 40.9219C72.748 41.3361 73.0853 41.6719 73.5014 41.6719Z"
				fill="#046289"
			/>
			<path
				d="M73.5014 37.6621C73.9175 37.6621 74.2547 37.3263 74.2547 36.9121C74.2547 36.4979 73.9175 36.1621 73.5014 36.1621C73.0853 36.1621 72.748 36.4979 72.748 36.9121C72.748 37.3263 73.0853 37.6621 73.5014 37.6621Z"
				fill="#046289"
			/>
			<path
				d="M73.5014 31.6621C73.9175 31.6621 74.2547 31.3263 74.2547 30.9121C74.2547 30.4979 73.9175 30.1621 73.5014 30.1621C73.0853 30.1621 72.748 30.4979 72.748 30.9121C72.748 31.3263 73.0853 31.6621 73.5014 31.6621Z"
				fill="#046289"
			/>
			<path
				d="M5.19894 36.6719H1.18108C0.769252 36.6719 0.427734 36.3319 0.427734 35.9219C0.427734 35.5119 0.769252 35.1719 1.18108 35.1719H5.19894C5.61077 35.1719 5.95229 35.5119 5.95229 35.9219C5.95229 36.3319 5.61077 36.6719 5.19894 36.6719Z"
				fill="#046289"
			/>
			<path
				d="M3.19085 38.6719C2.77902 38.6719 2.4375 38.3319 2.4375 37.9219V33.9219C2.4375 33.5119 2.77902 33.1719 3.19085 33.1719C3.60268 33.1719 3.9442 33.5119 3.9442 33.9219V37.9219C3.9442 38.3319 3.60268 38.6719 3.19085 38.6719Z"
				fill="#046289"
			/>
			<path
				d="M3.19085 42.6719C3.60691 42.6719 3.9442 42.3361 3.9442 41.9219C3.9442 41.5077 3.60691 41.1719 3.19085 41.1719C2.77479 41.1719 2.4375 41.5077 2.4375 41.9219C2.4375 42.3361 2.77479 42.6719 3.19085 42.6719Z"
				fill="#046289"
			/>
			<path
				d="M3.19085 46.6719C3.60691 46.6719 3.9442 46.3361 3.9442 45.9219C3.9442 45.5077 3.60691 45.1719 3.19085 45.1719C2.77479 45.1719 2.4375 45.5077 2.4375 45.9219C2.4375 46.3361 2.77479 46.6719 3.19085 46.6719Z"
				fill="#046289"
			/>
			<path
				d="M3.19085 51.6719C3.60691 51.6719 3.9442 51.3361 3.9442 50.9219C3.9442 50.5077 3.60691 50.1719 3.19085 50.1719C2.77479 50.1719 2.4375 50.5077 2.4375 50.9219C2.4375 51.3361 2.77479 51.6719 3.19085 51.6719Z"
				fill="#046289"
			/>
			<path
				d="M3.19085 59.6719C3.60691 59.6719 3.9442 59.3361 3.9442 58.9219C3.9442 58.5077 3.60691 58.1719 3.19085 58.1719C2.77479 58.1719 2.4375 58.5077 2.4375 58.9219C2.4375 59.3361 2.77479 59.6719 3.19085 59.6719Z"
				fill="#046289"
			/>
		</svg>
	);
}
