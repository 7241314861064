import { StatusRequest } from "src/@types";

type StatusLabelProps<StatusVariants extends string> = {
	value: number;
	statusObject: { [key: number]: StatusRequest<StatusVariants> };
};

export function StatusLabel<StatusVariants extends string>({
	value,
	statusObject,
}: StatusLabelProps<StatusVariants>) {
	return (
		<div
			className="mx-auto w-[105px] py-1.5 px-2 text-center"
			style={{
				backgroundColor: statusObject[value]?.backgroundColor,
				color: statusObject[value]?.color,
			}}
		>
			<span className="block text-center text-xs font-semibold capitalize leading-[1.1875rem]">
				{statusObject[value]?.label}
			</span>
		</div>
	);
}
