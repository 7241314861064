import { useTranslation } from "react-i18next";
import { useAppDispatch, eventsActions, schedulerActions } from "src/redux";
import { XCloseIcon } from "src/components";

type AddAppointmentHeaderProps = {
	mode: "EDIT" | "ADD";
};

export const AddAppointmentHeader = ({ mode }: AddAppointmentHeaderProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation("scheduler");

	return (
		<div className="flex justify-between px-5 py-3 2xl:py-5 3xl:py-6">
			<h3 className="text-xl font-semibold text-primary 3xl:text-2xl">
				{mode === "ADD"
					? t("Appointments.Add_Appointment_Form.Header")
					: mode === "EDIT" && t("Appointments.Edit_Appointment_Form.Header")}
			</h3>

			{mode === "ADD" && (
				<button
					className="text-gray-600 hover:text-gray-800"
					onClick={() => {
						dispatch(schedulerActions.closeAddAppointmentModal());
						dispatch(eventsActions.cancelAddingFromSlots());
					}}
				>
					<XCloseIcon width="28" height="28" />
				</button>
			)}
		</div>
	);
};
