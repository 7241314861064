import React, { ReactNode, useEffect, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { ChevronDownIcon } from "src/components";
import { Label } from "../Label/Label";

type DropdownProps = {
	title?: string;
	label?: string | undefined;
	value: string | number | undefined;
	position?: "full" | "bottom-left" | "bottom-right";
	color?:
		| "primary"
		| "secondary"
		| "success"
		| "warning"
		| "danger"
		| "filled"
		| "";
	size?: "md" | "sm" | "lg";
	variant?: "filled" | "standard" | "label";
	defaultOption?: boolean;
	designedAsLabel?: boolean;
	requiredStar?: boolean;
	withoutChevron?: boolean;
	children: ReactNode;
	error?: FieldError | string | undefined;
	style?: React.CSSProperties | undefined;
	id?: string | undefined;
	closeAfterSelect?: boolean;
	dropdownClassName?: string;
	component?: (props: any) => JSX.Element;
	onChange: (...event: any[]) => void;
	onBlur?: (...event: any[]) => void;
};

export const Dropdown = ({
	title,
	id,
	children,
	size,
	position,
	color,
	variant,
	designedAsLabel,
	label,
	requiredStar,
	defaultOption,
	style,
	component: Component,
	withoutChevron,
	value,
	error,
	closeAfterSelect = true,
	dropdownClassName,
	onChange,
	onBlur,
}: DropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isBlurred, setIsBlurred] = useState(false);
	const listRef = useRef<HTMLUListElement>(null!);
	const dropdownRef = useRef<HTMLDivElement>(null!);

	useEffect(() => {
		const checkIfClickedOutside = (e: MouseEvent) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (
				isOpen &&
				!listRef.current.contains(e.target as HTMLUListElement) &&
				!dropdownRef.current.contains(e.target as HTMLDivElement)
			) {
				setIsOpen(false);
				setIsBlurred(true);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		if (isBlurred && onBlur) {
			onBlur();
		}

		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isOpen, isBlurred]);

	const onSelect = (value: any) => {
		closeAfterSelect && setIsOpen(false);
		setIsBlurred(true);
		onChange(value);
	};

	const renderListPosition = () => {
		switch (position) {
			case "full":
				return "left-0 right-0 w-full";
			case "bottom-left":
				return "left-0 w-60";
			case "bottom-right":
				return " right-0 w-60";
			default:
				return "left-0 right-0 w-full";
		}
	};

	const renderColor = () => {
		const base = "border-b-2 rounded-sm border-primary";

		switch (color) {
			case "primary":
				return base + " text-darkBlue";
			case "secondary":
				return base + " text-gray-800";
			case "success":
				return base + " text-green2";
			case "warning":
				return base + " text-yellow2";
			case "danger":
				return base + " text-red2";
			case "filled":
				return base + " text-gray-200";
			default:
				return "";
		}
	};

	const renderVariant = () => {
		switch (variant) {
			case "filled":
				return "input--filled";
			case "standard":
				return "input--standard";
			case "label":
				return "";
			default:
				return "input--standard";
		}
	};

	const renderSize = () => {
		switch (size) {
			case "sm":
				return "p-1";
			case "md":
				return "p-2";
			case "lg":
				return "p-3";
			default:
				return "p-1";
		}
	};

	const renderCorrespondingValue = () => {
		if (Array.isArray(children)) {
			const childElement = children.find(
				(child) => child.props.value.toString() === value?.toString()
			);

			if (childElement) {
				return childElement.props.displayedName;
			} else {
				return "";
			}
		}
	};

	return (
		<>
			{label && (
				<Label
					required={requiredStar}
					htmlFor={id}
					className={variant === "standard" ? "" : "mb-2"}
				>
					{label}
				</Label>
			)}

			<div className="relative" ref={dropdownRef} id={id}>
				{Component ? (
					<Component
						setIsOpen={setIsOpen}
						value={value}
						error={error}
						title={title}
					/>
				) : (
					<div
						className={`dropdown ${renderColor()} ${renderVariant()} ${renderSize()} ${
							typeof error === "string" || (error && error.message)
								? "input--error"
								: ""
						}`}
						onClick={() => setIsOpen((prev) => !prev)}
						tabIndex={0}
						style={style}
					>
						{value || (typeof value === "number" && !isNaN(value)) ? (
							<span className="truncate text-sm font-semibold text-current first-letter:capitalize 2xl:text-base">
								{renderCorrespondingValue()}
							</span>
						) : (
							<span className="text-xs text-gray-600">{title}</span>
						)}

						{!withoutChevron && (
							<span
								className={`
									ms-3
									${designedAsLabel ? "text-current" : "text-muted"}
								`}
							>
								<ChevronDownIcon width={18} height={18} />
							</span>
						)}
					</div>
				)}

				<CSSTransition
					in={isOpen}
					timeout={300}
					classNames="transition"
					unmountOnExit
					nodeRef={listRef}
				>
					<ul
						ref={listRef}
						className={`dropMenu scrollBar ${renderListPosition()} ${dropdownClassName}`}
					>
						{defaultOption && (
							<li
								className="cursor-pointer py-1.5 px-1 text-sm font-semibold  text-muted  transition hover:bg-gray-200 "
								onClick={(e) => {
									onChange(undefined);
									setIsOpen(false);
								}}
							>{`${title}...`}</li>
						)}

						{Array.isArray(children)
							? children.map((child, i) => {
									return React.createElement(child.type, {
										...{
											...child.props,
											key: child.key,
											onClick: () => onSelect(child.props.value),
										},
									});
							  })
							: children}
					</ul>
				</CSSTransition>
			</div>
		</>
	);
};
