import { useEffect } from "react";
import { Controller, Control, UseFormTrigger, useWatch } from "react-hook-form";
import { ErrorMsg, Label, TimeInput } from "src/components";

type WorkingHoursProps = {
	control: Control<any, any>;
	trigger: UseFormTrigger<any>;
	errors: any;
	noLabel?: boolean;
};

export function WorkingHours({
	control,
	trigger,
	errors,
	noLabel = false,
}: WorkingHoursProps) {
	const startTime = useWatch({ name: "workStarts", control });
	const endTime = useWatch({ name: "workEnds", control });

	useEffect(() => {
		if (startTime && endTime) {
			trigger(["workStarts", "workEnds"]);
		}
	}, [startTime, endTime]);

	return (
		<section>
			{!noLabel && <Label variant="filled">Working Hours</Label>}

			<div className="flex items-center gap-[1.125rem]">
				<div>
					<Controller
						control={control}
						name="workStarts"
						render={({
							field: { onChange, onBlur, value },
							fieldState: { error },
						}) => (
							<TimeInput
								id="workStarts"
								onChange={onChange}
								value={value}
								error={error}
								onBlur={onBlur}
								trigger={trigger}
								withIcon={true}
								inputSize="md"
								placeholder="From"
								timeIntervals={60}
							/>
						)}
					/>
				</div>

				<div>
					<Controller
						control={control}
						name="workEnds"
						render={({
							field: { onChange, onBlur, value },
							fieldState: { error },
						}) => (
							<TimeInput
								id="workEnds"
								onChange={onChange}
								value={value}
								error={error}
								onBlur={onBlur}
								trigger={trigger}
								withIcon={true}
								inputSize="md"
								placeholder="To"
								timeIntervals={60}
							/>
						)}
					/>
				</div>
			</div>

			{errors.workStarts &&
				(errors.workStarts.type === "min" ||
					errors.workStarts.type === "max") && (
					<ErrorMsg>{errors.workStarts.message}</ErrorMsg>
				)}

			{errors.workEnds &&
				(errors.workEnds.type === "min" || errors.workEnds.type === "max") && (
					<ErrorMsg>{errors.workEnds.message}</ErrorMsg>
				)}
		</section>
	);
}
