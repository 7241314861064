import { useToggle } from "src/hooks";

type SwitchButtonProps = {
	cb: (status: boolean) => void;
	toggled: boolean;
};

export function SwitchButton({ cb, toggled }: SwitchButtonProps) {
	const [toggle, setToggle] = useToggle(toggled || false);

	const handleClick = () => {
		cb(!toggle);
		setToggle();
	};

	return (
		<div
			className={`
				flex h-7 w-14 cursor-pointer items-center rounded-full p-1
				${toggle ? "bg-[rgba(0,120,180,0.26)]" : "bg-[#D0D0D0]"}
			`}
			onClick={handleClick}
		>
			<div
				className={`
					h-5 w-5 transform rounded-full transition-all
					${toggle ? "translate-x-0 bg-nativeWhite" : "translate-x-7 bg-[#2955F3]"}
				`}
			></div>
		</div>
	);
}
