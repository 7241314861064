import moment from "src/config/moment";
import { useEffect } from "react";
import { Control, Controller, UseFormTrigger, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux";
import { DateInput, ErrorMsg, TimeInput } from "src/components";

type DateAndTimeProps = {
	control: Control<any, object>;
	trigger: UseFormTrigger<any>;
	errors?: any;
};

export const DateAndTime = ({ control, errors, trigger }: DateAndTimeProps) => {
	const { t, i18n } = useTranslation("scheduler");
	const timeIntervals = useAppSelector((state) => state.scheduler.step);
	const { workEnds, workStarts } = useAppSelector(
		(state) => state.auth.user.admin
	);
	const startTime = useWatch({ name: "startTime", control });
	const endTime = useWatch({ name: "endTime", control });

	useEffect(() => {
		if (startTime && endTime) {
			trigger(["startTime", "endTime"]);
		}
	}, [startTime, endTime]);

	return (
		<div>
			<div className="flex w-full items-center  ">
				<div className="w-1/2 flex-shrink-0 ">
					<Controller
						control={control}
						name="startDate"
						render={({
							field: { onChange, onBlur, value, name },
							fieldState: { error },
						}) => (
							<DateInput
								onChange={onChange}
								value={value}
								name={name}
								error={error}
								onBlur={onBlur}
								minDate={new Date()}
								placeholder={"اليوم، شهر"}
								dateFormat={
									i18n.language === "ar" ? "EEEE, d LLLL" : "EEE, d LLLL"
								}
								popperPlacement={
									i18n.dir() === "rtl" ? "bottom-end" : "bottom-start"
								}
								textDirection={i18n.dir()}
							/>
						)}
					/>
				</div>

				<div className="flex items-center ms-3">
					<div className="me-1">
						<Controller
							control={control}
							name="startTime"
							render={({
								field: { onChange, onBlur, value },
								fieldState: { error },
							}) => (
								<TimeInput
									id="startTime"
									onChange={onChange}
									value={value}
									error={error}
									onBlur={onBlur}
									trigger={trigger}
									withIcon={true}
									timeIntervals={timeIntervals}
									minTime={
										typeof workStarts === "number"
											? moment()
													.set("hours", workStarts)
													.set("minutes", 0)
													.toDate()
											: undefined
									}
									maxTime={
										typeof workEnds === "number"
											? workEnds === 24
												? moment().endOf("day").toDate()
												: moment().set("hours", workEnds).toDate()
											: undefined
									}
								/>
							)}
						/>
					</div>

					<span>:</span>

					<div>
						<Controller
							control={control}
							name="endTime"
							render={({
								field: { onChange, onBlur, value },
								fieldState: { error },
							}) => (
								<TimeInput
									id="endTime"
									onChange={onChange}
									value={value}
									error={error}
									onBlur={onBlur}
									trigger={trigger}
									timeIntervals={timeIntervals}
									minTime={
										typeof workStarts === "number"
											? moment()
													.set("hours", workStarts)
													.set("minutes", 0)
													.toDate()
											: undefined
									}
									maxTime={
										typeof workEnds === "number"
											? workEnds === 24
												? moment().endOf("day").toDate()
												: moment().set("hours", workEnds).toDate()
											: undefined
									}
								/>
							)}
						/>
					</div>
				</div>
			</div>

			{errors.endTime &&
				(errors.endTime.type === "min" || errors.endTime.type === "max") && (
					<ErrorMsg>{t(errors.endTime.message)}</ErrorMsg>
				)}

			{errors.startTime &&
				(errors.startTime.type === "min" ||
					errors.startTime.type === "max") && (
					<ErrorMsg>{t(errors.startTime.message)}</ErrorMsg>
				)}
		</div>
	);
};
