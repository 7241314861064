import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import NoPrescriptionsImg from "src/assets/svg/no_prescriptions.svg";

export function NoPrescriptions() {
	const { t } = useTranslation("patientFile");

	return (
		<div>
			<NoData
				imgAlt="no patient prescriptions"
				imgSrc={NoPrescriptionsImg}
				notFoundMsg={t("PatientFile.Prescriptions.No_Prescriptions")}
			/>
		</div>
	);
}
