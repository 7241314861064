import moment from "src/config/moment";
import { useTranslation } from "react-i18next";
import { ServiceEnum, AppointmentsStatusEnum } from "src/@types";
import { useAppSelector } from "src/redux";
import { getTime } from "src/features";

export const EditHistory = () => {
	const { t, i18n } = useTranslation("scheduler");
	const event = useAppSelector((state) => state.events.selectedEvent);
	const { users } = useAppSelector((state) => state.auth);
	const { dentists } = useAppSelector((state) => state.dentists);

	const renderUdateType = (type: string) => {
		switch (type) {
			case "starts":
				return t("Appointments.Appointment_details.EditHistory.Appointment");
			case "ends":
				return t("Appointments.Appointment_details.EditHistory.Appointment");
			case "status":
				return t("Appointments.Appointment_details.EditHistory.Status");
			case "type":
				return t("Appointments.Appointment_details.EditHistory.Type");
			case "dentist":
				return t("Appointments.Appointment_details.EditHistory.Dentist");
			case "clinic":
				return t("Appointments.Appointment_details.EditHistory.Clinic");
			case "notes":
				return t("Appointments.Appointment_details.EditHistory.Notes");
			default:
				return "";
		}
	};

	const renderFromTo = (status: string, type: string) => {
		if (type === "status") {
			switch (Number(status)) {
				case AppointmentsStatusEnum.NOT_CONFIRMED:
					return t(
						"Appointments.Appointment_details.Header.Status.Not_Confirmed"
					);
				case AppointmentsStatusEnum.CONFIRMED:
					return t("Appointments.Appointment_details.Header.Status.Confirmed");
				case AppointmentsStatusEnum.ATTENDED:
					return t("Appointments.Appointment_details.Header.Status.Attended");
				case AppointmentsStatusEnum.NOT_ATTENDED:
					return t(
						"Appointments.Appointment_details.Header.Status.Not_Attended"
					);
				case AppointmentsStatusEnum.CANCELED:
					return t("Appointments.Appointment_details.Header.Status.Cancelled");
				default:
					return "";
			}
		} else if (type === "starts" || type === "ends") {
			return moment(status).locale(i18n.language).format("h.mm A");
		} else if (type === "dentist") {
			return `${dentists ? dentists[Number(status)].name : ""}`;
		} else if (type === "clinic") {
			return `${t(
				"Appointments.Appointment_details.EditHistory.Clinic"
			)} ${status}`;
		} else if (type === "type") {
			switch (Number(status)) {
				case ServiceEnum.Emergency:
					return t("Services.Emergency", { ns: "global" });
				case ServiceEnum.Diagnosis:
					return t("Services.Checkup", { ns: "global" });
				case ServiceEnum.CheckUp:
					return t("Services.Follow_Up", { ns: "global" });
				default:
					return "";
			}
		} else return status ? status : "-";
	};

	const renderUser = (id: number) => {
		const user = users ? users[id] : undefined;

		return user ? user.name : "";
	};

	return (
		event && (
			<div className="scrollBar max-h-fit divide-y divide-gray-200 overflow-y-auto px-5">
				{event.resource && event.resource.updates.length > 0 ? (
					event.resource.updates.map((update: any) => (
						<div className="pt-4 pb-2" key={update.id}>
							<p className="mb-2 font-semibold text-dark">
								{t(
									"Appointments.Appointment_details.EditHistory.Update_State",
									{
										updateType: renderUdateType(update.updateEntries[0].field),
										from: renderFromTo(
											update.updateEntries[0].old,
											update.updateEntries[0].field
										),
										to: renderFromTo(
											update.updateEntries[0].new,
											update.updateEntries[0].field
										),
									}
								)}
							</p>
							<p className="mb-2 text-xs text-gray-600">
								{t("Appointments.Appointment_details.EditHistory.Update_By", {
									updateType: renderUdateType(update.updateEntries[0].field),
									updateBy: renderUser(update.updateEntries[0].updatedBy),
									date: getTime(update.updateEntries[0].time),
								})}
							</p>
						</div>
					))
				) : (
					<p className="mt-4 mb-8">لا يوجد اي تعديلات سابقة</p>
				)}
			</div>
		)
	);
};
