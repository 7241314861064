import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const phoneValidation = (message?: string, optional = false) => {
	const baseValidation = yup
		.string()
		.default("")
		.test("isPhone", message || "Invalid phone number", (value: string) => {
			if (value !== "") {
				if (
					value.startsWith("+20") &&
					(value.length !== 13 ||
						!["10", "11", "12", "15"].includes(value.slice(3, 5)))
				) {
					return false;
				}

				return isValidPhoneNumber(value);
			}

			return true;
		});

	if (!optional) {
		return baseValidation.required("Required");
	}

	return baseValidation;
};
