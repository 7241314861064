import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Btn, ChevronLongLeftIcon, Input } from "src/components";
import { updateUser, useAppDispatch, useAppSelector } from "src/redux";
import { useAxios } from "src/hooks";

const schema = yup.object().shape({
	name: yup.string().required(),
	email: yup.string().email(),
});

type FormValues = {
	name: string;
	email: string;
};

type PersonalInfoScreenProps = {
	goToGetStated: () => void;
	goToClinicInfo: () => void;
};

export function PersonalInfoScreen({
	goToClinicInfo,
	goToGetStated,
}: PersonalInfoScreenProps) {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((s) => s.auth);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm<FormValues>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: { name: user?.name, email: user?.email },
	});

	const { makeRequest } = useAxios<any, FormValues>({
		runNow: false,
		configs: { url: "/user/profile", method: "PUT" },
	});

	const onSubmit = (data: FormValues) => {
		if (isDirty) {
			makeRequest({
				configs: { data: { name: data.name, email: data.email } },
				handleSuccess: () => {
					dispatch(updateUser({ name: data.name, email: data.email }));
					goToClinicInfo();
				},
			});
		} else {
			goToClinicInfo();
		}
	};

	return (
		<section className="mt-24">
			<header className="mb-16 text-center">
				<h2 className="mb-2 text-2xl font-semibold leading-[1.875rem] text-dark">
					Let&apos;s setup your profile
				</h2>

				<p className="text-base font-semibold leading-[1.875rem] text-[#828282]">
					Tell us a bit about yourself so we can help you{" "}
				</p>
			</header>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-[1.875rem]">
					<Input
						type="text"
						register={register}
						name="name"
						id="name"
						error={errors.name}
						placeholder="Full name"
						variant="filled"
						inputSize="lg"
					/>
				</div>

				<div className="mb-44">
					<Input
						type="email"
						register={register}
						name="email"
						id="email"
						error={errors.email}
						placeholder="E-mail address"
						variant="filled"
						inputSize="lg"
					/>
				</div>

				<div className="flex items-center justify-between gap-4">
					<button type="button" onClick={goToGetStated}>
						<ChevronLongLeftIcon />
					</button>

					<Btn
						type="submit"
						variant="primary"
						size="md"
						className={`
							!px-10
							${!isValid ? "cursor-not-allowed opacity-50" : ""}
						`}
						disabled={!isValid}
					>
						Next
					</Btn>
				</div>
			</form>
		</section>
	);
}
