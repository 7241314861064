import { Style } from "src/@types";
import { XCloseIcon } from "src/components";

type PopupHeaderProps = {
	title: React.ReactNode;
	subTitle?: string;
	close?: () => void;
	styles?: {
		container?: Style;
		title?: Style;
		subTitle?: Style;
		closeButton?: Style;
	};
};

export const PopupHeader = ({
	title,
	subTitle,
	close,
	styles,
}: PopupHeaderProps) => {
	return (
		<div
			className={`
				flex items-center justify-between py-3 2xl:py-4
				${styles?.container?.className}
			`}
			style={styles?.container?.style}
		>
			<div>
				<h3
					className={`
						text-xl font-semibold capitalize leading-3 text-primary 2xl:text-2xl
						${styles?.title?.className}
					`}
					style={styles?.title?.style}
				>
					{title}
				</h3>

				{subTitle && (
					<p
						className={`
							mt-3 text-xs text-gray-600
							${styles?.subTitle?.className}
						`}
						style={styles?.subTitle?.style}
					>
						{subTitle}
					</p>
				)}
			</div>

			{close && (
				<button
					className={`
						text-gray-600 hover:text-gray-800
						${styles?.closeButton?.className}
					`}
					style={styles?.closeButton?.style}
					onClick={close}
				>
					<XCloseIcon width="28" height="28" />
				</button>
			)}
		</div>
	);
};
