import { EventProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { AppointmentsStatusEnum } from "src/@types";
import { APPOINTMENT_STATUS_OBJECT } from "src/constants";
import { localizer } from "../custom/localizer";

export const EventInWeekView = ({ event }: EventProps) => {
	const { i18n } = useTranslation();

	return (
		<>
			<div className="flex text-sm text-start">
				<div className="mb-1 flex items-center font-semibold">
					<p className="truncate leading-6">
						{event.resource?.patient?.name.split(" ").slice(0, 2).join(" ")}
					</p>
					<span className="ms-1">
						{
							APPOINTMENT_STATUS_OBJECT({ width: "16px", height: "16px" })[
								event.resource?.status as AppointmentsStatusEnum
							].icon
						}
					</span>
				</div>
			</div>

			<div className="flex text-sm text-start">
				<p className="flex-shrink-0">
					{event.start &&
						event.end &&
						localizer.format(event.start, "h:mm", i18n.language) +
							" : " +
							localizer.format(event.end, "h:mm A", i18n.language)}

					{event.resource.notes && (
						<span className="ms-2">
							<ReactTooltip id="note" effect="solid" />

							<span
								className="text-xs"
								data-tip={event.resource.notes}
								data-for="note"
							>
								ℹ️
							</span>
						</span>
					)}
				</p>
			</div>
		</>
	);
};
