import { useTranslation } from "react-i18next";
import { CustomCheckBox, Popup } from "src/components";
import { FilterType } from "../../Calendar";
import { useAppSelector } from "src/redux";

type ClinicsPopupProps = {
	selectedOption: FilterType | null;
	setSelectedOption: React.Dispatch<React.SetStateAction<FilterType | null>>;
	setFilterPopup: (value: React.SetStateAction<boolean>) => void;
	filteredClinics: string[];
	setFilteredClinics: React.Dispatch<React.SetStateAction<string[]>>;
};

export function ClinicsPopup({
	selectedOption,
	filteredClinics,
	setFilterPopup,
	setSelectedOption,
	setFilteredClinics,
}: ClinicsPopupProps) {
	const { t } = useTranslation("scheduler");
	const {
		auth: {
			user: {
				admin: { clinics: clinicsState },
			},
		},
	} = useAppSelector((s) => s);
	const clinicsNumber = clinicsState ? clinicsState : 1;

	const rooms = Array.from(Array(clinicsNumber).keys()).map((i) => ({
		id: i + 1,
		name: `${t("Scheduler.Body.Clinic")} ${i + 1}`,
	}));

	const handleClinicsCheck = (
		e: React.ChangeEvent<HTMLInputElement>,
		clinicId: number
	) => {
		if (filteredClinics.includes(clinicId.toString())) {
			setFilteredClinics((prev) =>
				prev.filter((filterId) => clinicId.toString() !== filterId)
			);
		} else {
			setFilteredClinics((prev) => prev.concat(clinicId.toString()));
		}
	};

	return (
		<Popup
			show={selectedOption === FilterType.Clinic}
			close={() => {
				setSelectedOption(null);
				setFilterPopup(false);
			}}
		>
			<div className="py-4 px-4">
				{rooms.length > 0 ? (
					rooms.map((room) => (
						<div key={room.id} className="mb-2 flex items-center">
							<CustomCheckBox
								className="me-2 "
								id={room.id.toString()}
								checked={filteredClinics.includes(room.id.toString())}
								name="selectedClinics"
								value={room.id.toString()}
								onChange={(e) => handleClinicsCheck(e, room.id)}
							/>
							<label
								htmlFor={room.id.toString()}
								className="text-semibold text-base text-dark"
							>
								{room.name}
							</label>
						</div>
					))
				) : (
					<div className="mt-4 text-center font-semibold text-dark">
						{t("Scheduler.Messages.No_Available_Clinics")}
					</div>
				)}
			</div>
		</Popup>
	);
}
