type ColorCodingProps = {
	color?: string;
};
export const ColorCoding = ({ color }: ColorCodingProps) => {
	return (
		<span
			className={`h-5 w-5 flex-shrink-0 rounded-full shadow bg-${color}-dark`}
		></span>
	);
};
