import { PatientFilePlanWithoutIdType } from "src/@types";
import { patientProfileActions } from "../../patientProfileSlice";
import { updatePlan } from "../plan";

export const deleteProcedure = () => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			const selectedProcedure = state.patientProfile.selectedProcedure;

			if (selectedProcedure) {
				const updatedPlan: PatientFilePlanWithoutIdType = {
					...planToUpdate,
					totalPrice: planToUpdate.totalPrice - selectedProcedure.total,
					updatedAt: new Date().toISOString(),
					procedures: {
						stages: planToUpdate.procedures.stages.map((stage) => {
							if (stage.id === selectedProcedure.stageId) {
								return {
									...stage,
									totalPrice: stage.totalPrice - selectedProcedure.total,
									procedures: stage.procedures.filter(
										(procedure) => procedure.id !== selectedProcedure.id
									),
								};
							}

							return stage;
						}),
					},
				};

				return updatedPlan;
			}

			return planToUpdate;
		},
		handleSuccess: (plan, dispatch) => {
			dispatch(patientProfileActions.deleteProcedureSuccess());
		},
	});
};
