import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import noExpensesImg from "src/assets/svg/no_expenses.svg";

type NoExpensesProps = {
	clickHandler: React.MouseEventHandler<HTMLElement> | undefined;
};

export function NoExpenses({ clickHandler }: NoExpensesProps) {
	const { t } = useTranslation("financial");

	return (
		<NoData
			imgAlt="no expenses added"
			imgSrc={noExpensesImg}
			notFoundMsg={t("Expenses.No_Expenses.MSG")}
			btn={t("Expenses.No_Expenses.CTA")}
			clickHandler={clickHandler}
		/>
	);
}
