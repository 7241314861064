import { useState } from "react";
import { Navbar } from "./Navbar/Navbar";
import { useTranslation } from "react-i18next";
import { ChevronRightBold, CollapsableLogo } from "src/components";
import { NavbarItem, RolesNumberEnum } from "src/@types";
import {
	adminItems,
	assistantNavItems,
	dentistNavItems,
} from "./SidebarRoutes";
import { useAppSelector } from "src/redux";

export function Sidebar() {
	const [isCollapsed, setIsCollapsed] = useState(true);
	const { i18n } = useTranslation();
	const { user } = useAppSelector((s) => s.auth);

	let chevronStyle = {};

	if (
		(i18n.language === "ar" && isCollapsed) ||
		(i18n.language === "en" && !isCollapsed)
	) {
		chevronStyle = {
			transform: "scale(-1, 1)",
		};
	}

	type UserNavItems = {
		[key in RolesNumberEnum]: NavbarItem[];
	};

	const userNavItems: UserNavItems = {
		[RolesNumberEnum.ADMIN]: adminItems({ tasksCount: user?.tasks }),
		[RolesNumberEnum.ASSISTANT]: assistantNavItems({ tasksCount: user?.tasks }),
		[RolesNumberEnum.DENTIST]: dentistNavItems({ tasksCount: user?.tasks }),
	};

	const navItems = userNavItems[user?.role as RolesNumberEnum];

	return navItems ? (
		<div
			className={`transition-padding sticky top-0 duration-300 ${
				isCollapsed ? "" : "ps-2"
			}`}
		>
			<CollapsableLogo isCollapsed={isCollapsed} route={navItems[0].navLink} />

			{/* <div className="relative">
				<button
					className={
						"center absolute top-2 z-50 h-8 w-8 cursor-pointer rounded-full bg-gray-300 text-dark  -end-5 ltr:-right-5 rtl:-left-5 2xl:top-6 2xl:h-10 2xl:w-10 3xl:top-10"
					}
					onClick={() => setIsCollapsed((prev) => !prev)}
					style={chevronStyle}
				>
					<ChevronRightBold width={12} height={8} />
				</button>
			</div> */}

			<Navbar isCollapsed={isCollapsed} navItems={navItems} />
		</div>
	) : null;
}
