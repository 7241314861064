import { useEventListener } from "./useEventListener";

/**
 * Hook to make action when click outside of element
 *
 * useClickOutside(ref, callback);
 */

export function useClickOutside(
	ref: React.RefObject<any>,
	callback: (event: Event) => void
) {
	useEventListener(
		"click",
		(e) => {
			if (
				ref.current === null ||
				ref.current === undefined ||
				ref.current.contains(e.target)
			)
				return;
			callback(e);
		},
		document
	);
}
