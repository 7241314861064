import { useRef } from "react";
import { Row } from "react-table";
import { useReactToPrint } from "react-to-print";

export function useTablePrint(
	rows: Row<any>[],
	pageSize?: number,
	setPageSize?: (pageSize: number) => void
) {
	const printRef = useRef<null | HTMLTableElement>(null);

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		onBeforeGetContent: () => {
			if (setPageSize && pageSize) {
				return new Promise((resolve) => {
					setPageSize(rows.length);

					return resolve("");
				});
			}
		},
		onAfterPrint: () => {
			if (setPageSize && pageSize) setPageSize(pageSize);
		},
	});

	return { handlePrint, printRef };
}
