import smallScreensImage from "src/assets/images/small_screens.png";

export function SmallScreensMessage() {
	return (
		<section className="flex h-[100vh] flex-col items-center justify-center gap-5">
			<p className="mx-6 text-center text-sm text-dark">
				This link is not supported on small screens. Kindly, <b>rotate</b> your
				device
			</p>

			<img
				src={smallScreensImage}
				alt="small screens"
				className="max-w-[70%]"
			/>

			<p className="mx-6 text-center text-xs font-normal text-gray-800">
				For better experience please sign in through your laptop or computer.
				Alternatively, you can rotate your device to <b>landscape</b> mode.
			</p>
		</section>
	);
}
