import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddAppointmentTabs, Tab, Tabs } from "src/components";
import { AddAppointmentHeader } from "./AddAppointmentHeader/AddAppointmentHeader";
import { EventForm } from "./EventFrom/EventForm";
import { NewPatientForm } from "./NewPatient/NewPatient";
import { RegularPatientForm } from "./RegularPatientForm/RegularPatientForm";

type AddAppointmentProps = {
	mode: "EDIT" | "ADD";
	setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddAppointment = ({ mode, setEdit }: AddAppointmentProps) => {
	const { t } = useTranslation("scheduler");
	const addAppointmentRef = useRef<HTMLDivElement>(null!);
	const [tab, setTab] = useState<AddAppointmentTabs>("REGULAR_PATIENT");

	useEffect(() => {
		addAppointmentRef.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}, []);

	return (
		<div
			className="relative z-60 divide-y-2 divide-lightGray rounded-2xl bg-white shadow-xl md:w-[35vw] lg:w-[34vw] xl:w-[25vw]"
			ref={addAppointmentRef}
		>
			<AddAppointmentHeader mode={mode} />

			{mode === "ADD" && (
				<Tabs>
					<Tab tab="REGULAR_PATIENT" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Add_Appointment_Form.Tabs.Regular_Patient")}
					</Tab>
					<Tab tab="NEW_PATIENT" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Add_Appointment_Form.Tabs.New_Patient")}
					</Tab>
					{/* <Tab tab="EVENT" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Add_Appointment_Form.Tabs.Event")}
					</Tab> */}
				</Tabs>
			)}

			{(tab === "REGULAR_PATIENT" || mode === "EDIT") && (
				<RegularPatientForm mode={mode} setEdit={setEdit} />
			)}
			{tab === "NEW_PATIENT" && <NewPatientForm mode="ADD" />}
			{/* {tab === "EVENT" && <EventForm />} */}
		</div>
	);
};
