import { FieldError, UseFormRegister } from "react-hook-form";
import { Input } from "src/components";

type RowInputProps = {
	type?: "text" | "number";
	id: string;
	placeholder: string;
	register: UseFormRegister<any>;
	disabled?: boolean;
	error?: FieldError | undefined;
};

export function RowInput({
	type = "text",
	id,
	placeholder,
	register,
	disabled,
	error,
}: RowInputProps) {
	return (
		<Input
			type={type}
			id={id}
			name={id}
			placeholder={placeholder}
			variant="filled"
			register={register}
			inputSize="sm"
			className="w-full text-base pe-3"
			containerClassName="w-full"
			disabled={disabled}
			error={error}
		/>
	);
}
