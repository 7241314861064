import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Btn, Input } from "src/components";
import { useTranslation } from "react-i18next";
import { NotesHandlerProps } from "../NotesHandler";
import { NoteCreateType } from "src/@types";

const schema = yup.object().shape({
	note: yup.string().required("Required"),
});

type AddNoteProps = {
	addNote: NotesHandlerProps["addNote"];
	toggleAddNote: <T>(newValue?: boolean | T | undefined) => void;
};

export function AddNote({ addNote, toggleAddNote }: AddNoteProps) {
	const { t } = useTranslation("global");

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<NoteCreateType>({
		resolver: yupResolver(schema),
		defaultValues: { note: "" },
	});

	const handleAddNote = (data: NoteCreateType) => {
		addNote(data);
		reset({ note: "" });
		toggleAddNote(false);
	};

	return (
		<form
			className="relative mb-4 flex gap-3 rounded-lg bg-nativeWhite p-4"
			onSubmit={handleSubmit(handleAddNote)}
		>
			<div className="flex-1">
				<Input
					id="note"
					name="note"
					type="text"
					placeholder={t("Notes_Handler.Add_Note")}
					register={register}
					error={errors.note}
					variant="filled"
					inputSize="md"
					autoFocus
				/>
			</div>

			<Btn type="submit" variant="primary" size="sm" className="w-40">
				{t("Notes_Handler.Add")}
			</Btn>
		</form>
	);
}
