import { ReactNode } from "react";
import { LoginHeader } from "../LoginHeader/LoginHeader";

type FormWrapperProps = {
	children: ReactNode;
	className?: string;
};

export const FormWrapper = ({ children, className }: FormWrapperProps) => {
	return (
		<div className="bg-background ">
			<LoginHeader />
			<div className={` center min-h-screen  bg-background ${className} `}>
				<div
					className="m-auto flex w-4/5 max-w-2xl  justify-center rounded-lg bg-white py-8  shadow-md sm:w-3/5 md:w-1/2 "
					style={{ minHeight: "380px" }}
				>
					<div className="px-4" style={{ minWidth: "66%" }}>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};
