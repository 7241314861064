import { useEffect, useState } from "react";

export type SetItemType<DataType> = React.Dispatch<
	React.SetStateAction<DataType[]>
>;
export type AddItemType<DataType> = (item: DataType) => void;
export type FilterItemsType = (callback: any) => void;
export type UpdateItemType<DataType> = (
	index: number,
	newItem: DataType
) => void;
export type UpdateItemByPropType<DataType> = (
	prop: keyof DataType,
	value: DataType[keyof DataType],
	newElement: DataType
) => void;
export type RemoveItemType = (index: number) => void;
export type RemoveItemByPropType<DataType> = (
	prop: keyof DataType,
	value: DataType[keyof DataType]
) => void;

export function useArray<DataType>(
	defaultValue: DataType[] | null | undefined
) {
	const [array, setArray] = useState<DataType[]>(defaultValue || []);

	useEffect(() => {
		if (defaultValue) {
			setArray(defaultValue);
		}
	}, [defaultValue]);

	function unshift(element: DataType) {
		setArray((prev) => [element, ...prev]);
	}

	function push(element: DataType) {
		setArray((prev) => [...prev, element]);
	}

	function filter(callback: any) {
		array.length > 0 && setArray((prev) => prev.filter(callback));
	}

	function update(index: number, newElement: DataType) {
		array.length > 0 &&
			setArray((prev) => [
				...prev.slice(0, index),
				newElement,
				...prev.slice(index + 1, prev.length),
			]);
	}

	function updateByProp(
		prop: keyof DataType,
		value: DataType[keyof DataType],
		newElement: DataType
	) {
		array.length > 0 &&
			setArray((prev) => {
				const index = prev.findIndex((item) => item[prop] === value);

				return [
					...prev.slice(0, index),
					newElement,
					...prev.slice(index + 1, prev.length),
				];
			});
	}

	function remove(index: number) {
		if (array.length > 0) {
			setArray((prev) => [
				...prev.slice(0, index),
				...prev.slice(index + 1, prev.length),
			]);
		}
	}

	function removeByProp(prop: keyof DataType, value: DataType[keyof DataType]) {
		if (array.length > 0) {
			setArray((prev) => {
				const index = prev.findIndex((item) => item[prop] === value);

				return [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)];
			});
		}
	}

	function clear() {
		array.length > 0 && setArray([]);
	}

	return {
		array,
		set: setArray,
		unshift,
		push,
		filter,
		update,
		updateByProp,
		remove,
		removeByProp,
		clear,
	};
}
