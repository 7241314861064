import { SVGProps } from "react";

export const CalendarIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.3333 5.33334H6.66667C5.19391 5.33334 4 6.52725 4 8.00001V26.6667C4 28.1394 5.19391 29.3333 6.66667 29.3333H25.3333C26.8061 29.3333 28 28.1394 28 26.6667V8.00001C28 6.52725 26.8061 5.33334 25.3333 5.33334Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.3335 2.66666V7.99999"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6665 2.66666V7.99999"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 13.3333H28"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CalenderDaysIcon = ({
	width = "12",
	height = "14",
	fill = "#333",
}: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.4914 13.5095H1.7626C1.07391 13.5095 0.515625 12.9356 0.515625 12.2277V3.25502C0.515625 2.5471 1.07391 1.97322 1.7626 1.97322H3.00957V0.691406H4.25654V1.97322H7.99745V0.691406H9.24442V1.97322H10.4914C11.1801 1.97322 11.7384 2.5471 11.7384 3.25502V12.2277C11.7384 12.9356 11.1801 13.5095 10.4914 13.5095ZM1.7626 5.81864V12.2277H10.4914V5.81864H1.7626ZM1.7626 3.25502V4.53683H10.4914V3.25502H1.7626ZM9.24442 10.9459H7.99745V9.66407H9.24442V10.9459ZM6.75048 10.9459H5.50351V9.66407H6.75048V10.9459ZM4.25654 10.9459H3.00957V9.66407H4.25654V10.9459ZM9.24442 8.38226H7.99745V7.10045H9.24442V8.38226ZM6.75048 8.38226H5.50351V7.10045H6.75048V8.38226ZM4.25654 8.38226H3.00957V7.10045H4.25654V8.38226Z"
				fill={fill}
			/>
		</svg>
	);
};
