import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { InfoFilledIcon } from "src/components";

const RADIAN = Math.PI / 180;

type RenderCustomizedLabelType = {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	percent: number;
	index: number;
};

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
}: RenderCustomizedLabelType) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			className="font-inter text-xs"
			style={{ lineHeight: "0.9375rem" }}
			x={x}
			y={y}
			fill="#fff"
			textAnchor="middle"
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const renderLegend = (props: any) => {
	const { payload } = props;

	return (
		<ul className="m-auto grid w-max grid-cols-3 items-center justify-center justify-items-center gap-y-2 gap-x-4">
			{payload.map((entry: any, index: number) => (
				<li key={`item-${index}`} className="flex items-center gap-2">
					<span
						className="rounded-full"
						style={{
							width: "0.5625rem",
							height: "0.5625rem",
							backgroundColor: entry.color,
						}}
					></span>
					<span
						className="font-inter text-xs"
						style={{ lineHeight: "0.9375rem" }}
					>
						{entry.value}
					</span>
				</li>
			))}
		</ul>
	);
};

type CustomPieChartProps = {
	data: any[];
	dataKey: string;
	colors: string[];
	title: string;
	number: string;
	variant?: "primary" | "secondary";
};

export function CustomPieChart({
	data,
	dataKey,
	colors,
	title,
	number,
	variant = "primary",
}: CustomPieChartProps) {
	const renderVariant = () => {
		switch (variant) {
			case "primary":
				return { pieProps: {} };
			case "secondary":
				return { pieProps: { innerRadius: "45%" } };
			default:
				return { pieProps: {} };
		}
	};

	return (
		<section className="w-full bg-nativeWhite p-5">
			<header className="flex items-center justify-between">
				<div>
					<h3 className="mb-3 text-dark" style={{ lineHeight: "0.875rem" }}>
						{title}
					</h3>
					<span
						className="font-bold text-dark"
						style={{ lineHeight: "0.875rem", fontSize: "1.125rem" }}
					>
						{number}
					</span>
				</div>
				<span>
					<InfoFilledIcon />
				</span>
			</header>

			<section className="h-80 w-full">
				<ResponsiveContainer width="100%" height="100%">
					<PieChart>
						<Pie
							data={data}
							cx="50%"
							cy="50%"
							labelLine={false}
							outerRadius={100}
							fill="#8884d8"
							dataKey={dataKey}
							blendStroke
							label={renderCustomizedLabel}
							{...renderVariant().pieProps}
						>
							{data.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={colors[index % colors.length]}
								/>
							))}
						</Pie>

						<Legend content={renderLegend} />
					</PieChart>
				</ResponsiveContainer>
			</section>
		</section>
	);
}
