import { FieldError, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMsg, RadioBtn } from "src/components";

type GenderInputProps = {
	register: UseFormRegister<any>;
	error?: FieldError | undefined;
};

export const GenderInput = ({ register, error }: GenderInputProps) => {
	const { t } = useTranslation("scheduler");

	return (
		<div className="mb-3">
			<div className=" flex ">
				<RadioBtn
					id="male"
					value={0}
					register={register}
					name="gender"
					label={t(
						"Appointments.Add_Appointment_Form.Inputs.Gender.Label.Male"
					)}
				/>
				<RadioBtn
					id="female"
					value={1}
					register={register}
					name="gender"
					label={t(
						"Appointments.Add_Appointment_Form.Inputs.Gender.Label.Female"
					)}
				/>
			</div>
			{error && <ErrorMsg>{error.message}</ErrorMsg>}
		</div>
	);
};
