import { ReactNode } from "react";
import { Style } from "src/@types";

type ContainerProps = Style & {
	children: ReactNode;
};

export const Container = ({ children, className, style }: ContainerProps) => {
	return (
		<div
			className={`bg-background px-10 pt-6 pb-14 2xl:pt-8 3xl:pt-10 ${
				className ? className : ""
			}`}
			style={{ minHeight: "89vh", ...style }}
		>
			{children}
		</div>
	);
};
