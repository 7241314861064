import ReactDOM from "react-dom";
import React, { useEffect, useRef } from "react";

type PortalHandlerProps = {
	id: string;
	children: React.ReactNode;
};

export function Portal({ id, children }: PortalHandlerProps) {
	const isElementCreated = useRef(false);

	useEffect(() => {
		const element = document.createElement("div");

		element.id = id;
		document.body.appendChild(element);
		isElementCreated.current = true;

		return () => {
			document.body.removeChild(element);
		};
	}, []);

	return isElementCreated.current
		? ReactDOM.createPortal(children, document.getElementById(id)!)
		: null;
}
