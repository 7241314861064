import { DropdownIndicatorProps, GroupBase } from "react-select";
import { StatusRequest } from "src/@types";
import { ChevronDownIcon } from "src/components/Svg";

type DropdownIndicatorType<StatusVariants extends string> = {
	dropdownIndicator: React.PropsWithChildren<
		DropdownIndicatorProps<
			StatusRequest<StatusVariants>,
			false,
			GroupBase<StatusRequest<StatusVariants>>
		>
	>;
	statusObject: { [key: number]: StatusRequest<StatusVariants> };
};

export function DropdownIndicator<StatusVariants extends string>({
	dropdownIndicator,
	statusObject,
}: DropdownIndicatorType<StatusVariants>) {
	return (
		<span
			className="relative top-[1px]"
			style={{
				color: statusObject[dropdownIndicator.getValue()[0].value]?.color,
			}}
		>
			<ChevronDownIcon width={18} height={18} />
		</span>
	);
}
