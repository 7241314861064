import { forwardRef, ReactNode } from "react";

type Variant =
	| "primary"
	| "secondary"
	| "danger"
	| "success"
	| "warning"
	| "primary-outline"
	| "text-primary";
type Size = "xs" | "sm" | "lg" | "md";
type Rounded = "sharp" | "rounded-lg" | "rounded-md";

interface BtnProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children?: ReactNode;
	variant?: Variant | undefined;
	size?: Size | undefined;
	rounded?: Rounded | undefined;
}

const renderVariantBtn = (variant: Variant | undefined) => {
	switch (variant) {
		case "primary":
			return "bg-primary text-white border-darkBlue";
		case "danger":
			return "bg-red2 text-white border-red2 hover:bg-red";
		case "primary-outline":
			return "bg-white text-primary  hover:bg-lightBlue border border-primary";
		case "text-primary":
			return " text-primary";
		case "secondary":
			return "bg-lightGray text-gray-800";
		case "success":
			return "bg-success text-white";
		default:
			return "";
	}
};

const renderBtnSize = (size: Size | undefined) => {
	switch (size) {
		case "xs":
			return "py-1.5 px-1.5";
		case "sm":
			return "py-2 px-2";
		case "md":
			return "py-3 px-3";
		case "lg":
			return "py-4 px-3";
		default:
			return "py-3 px-3";
	}
};

const renderBtnRounded = (rounded: Rounded | undefined) => {
	switch (rounded) {
		case "sharp":
			return "rounded";
		case "rounded-lg":
			return "rounded-lg";
		case "rounded-md":
			return "rounded-md";
		default:
			return "rounded-lg";
	}
};

export const Btn = forwardRef<HTMLButtonElement, BtnProps>(
	(
		{
			children,
			variant,
			type,
			rounded,
			className,
			disabled,
			size,
			style,
			onClick,
			...rest
		},
		ref
	) => {
		return (
			<button
				type={type}
				ref={ref}
				className={`font-semibold focus:outline-none ${renderVariantBtn(
					variant
				)} ${renderBtnSize(size)} ${renderBtnRounded(rounded)} ${className}`}
				disabled={disabled}
				onClick={onClick}
				{...rest}
				style={{ minWidth: "65px", ...style }}
			>
				{children}
			</button>
		);
	}
);

type BtnWithIconProps = BtnProps & {
	icon?: ReactNode;
	iconClassName?: string;
};

export const BtnWithIcon = ({
	children,
	variant,
	rounded,
	size,
	icon,
	iconClassName,
	type,
	className,
	disabled,
	onClick,
	style,
}: BtnWithIconProps) => {
	return (
		<button
			type={type}
			className={`align-center flex-shrink-0 font-semibold focus:outline-none
				${renderVariantBtn(variant)}
				${renderBtnSize(size)}
				${renderBtnRounded(rounded)}
				${className}`}
			disabled={disabled}
			onClick={onClick}
			style={style}
		>
			<span className={`text-current me-2 ${iconClassName}`}>{icon}</span>
			<span>{children}</span>
		</button>
	);
};
