import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/constants/index";

type AppGridProps = {
	children: ReactNode;
};

export const AppGrid = ({ children }: AppGridProps) => {
	const { i18n } = useTranslation();
	const location = useLocation();

	return (
		<div
			dir={
				location.pathname.startsWith(ROUTES.ASSISTANT.BASE) ? i18n.dir() : "ltr"
			}
			className={
				!location.pathname.includes("print")
					? "grid w-full grid-cols-app grid-rows-app gap-y-1"
					: ""
			}
		>
			{children}
		</div>
	);
};

export const SidebarCol = ({ children }: AppGridProps) => {
	const location = useLocation();

	return (
		<div
			className={
				!location.pathname.includes("print")
					? "col-span-1 row-span-2 row-start-1 min-h-screen ring-1 ring-background print:hidden"
					: "hidden"
			}
		>
			{children}
		</div>
	);
};

export const HeaderRow = ({ children }: AppGridProps) => {
	const location = useLocation();

	return (
		<div
			className={
				!location.pathname.includes("print")
					? "col-span-10 col-start-2 print:hidden"
					: "hidden"
			}
		>
			{children}
		</div>
	);
};

export const MainCol = ({ children }: AppGridProps) => {
	return <div className="col-span-10 row-start-2 ">{children}</div>;
};

export const SideCol = React.forwardRef<HTMLDivElement, AppGridProps>(
	({ children }, ref) => {
		return (
			<div
				className="ms-2 print:hidden md:w-[35vw] lg:w-[34vw] xl:w-[25vw]"
				ref={ref}
			>
				{children}
			</div>
		);
	}
);
