import { WritableDraft } from "immer/dist/internal";
import { PatientProfileState } from "../patientProfileSlice";

export const screensReducers = {
	setDiagnosisScreen: (state: WritableDraft<PatientProfileState>) => {
		state.currentScreen = "DIAGNOSIS";
	},

	setProceduresScreen: (state: WritableDraft<PatientProfileState>) => {
		state.currentScreen = "PROCEDURES";
	},
};
