import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { THead } from "./THead/THead";
import { TBody } from "./TBody/TBody";
import { TableBodyProvider } from "./TableBodyProvider";
import { TableBodyComponentProps } from "./TableBody.type";

// * Any column that has a "more" id will be hidden in print mode

function TableBodyComponent(
	{
		headerGroups,
		rows,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		tableGroup = false,
		size = "md",
		alignments = "center",
		styles,
		otherProps,
	}: TableBodyComponentProps,
	ref?: React.ForwardedRef<HTMLTableElement | null>
) {
	const { i18n } = useTranslation("patientList");

	const contextValue = {
		styles,
		headerGroups,
		getTableBodyProps,
		rows,
		prepareRow,
		otherProps,
	};

	return (
		<TableBodyProvider value={contextValue}>
			<section
				className={`scrollBar mb-5 overflow-auto ${styles?.container?.className}`}
				style={styles?.container?.style}
			>
				<table
					{...getTableProps()}
					className={`
						custom_table table_${size}
						${tableGroup ? "table_group" : ""}
						${alignments === "start" ? "align_table_start" : ""}
						${styles?.table?.className || ""}
					`}
					style={styles?.table?.style}
					dir={i18n.dir()}
					ref={ref}
				>
					<THead />
					<TBody />
				</table>
			</section>
		</TableBodyProvider>
	);
}

export const TableBody = forwardRef(TableBodyComponent);
