import moment from "src/config/moment";
import { useState } from "react";
import { EyeOffIcon, EyeOnIcon, SMSIcon, WhatsappIcon } from "src/components";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux";
import { toast } from "react-toastify";
import { FetchedProcedure, PatientFileType, Procedure } from "src/@types";
import { useAxios } from "src/hooks";

export const MedicalSurgeon = () => {
	const { t, i18n } = useTranslation("scheduler");
	const event = useAppSelector((state) => state.events.selectedEvent);
	const { dentists } = useAppSelector((state) => state.dentists);
	const [showPatientHistory, setShowPrevSurgeon] = useState(false);
	const [nextProcedures, setNextProcedures] = useState<Procedure[]>([]);
	const [prevProcedures, setPrevProcedures] = useState<Procedure[]>([]);

	const { loading } = useAxios<PatientFileType>({
		configs: { url: `patients/${event?.resource?.patient?.id.toString()}` },
		handleSuccess: (data) => {
			const procedures: FetchedProcedure[] = data.procedures;
			const today = new Date();

			if (event?.resource?.patient?.id) {
				setNextProcedures(
					procedures
						.filter((procedure) => moment(procedure.time).isAfter(today))
						.map((procedure) => ({
							...procedure,
						}))
				);
				setPrevProcedures(
					procedures
						.filter((procedure) => moment(procedure.time).isBefore(today))
						.map((procedure) => ({
							...procedure,
						}))
				);
			}
		},
		handleError: (error) => {
			if (error.response && error.response.data) {
				toast.error(error.response.data);
			}
		},
	});

	const showPreviousPrecedures =
		showPatientHistory && prevProcedures.length > 0;

	return (
		<div className="px-4 py-4 ">
			<h4 className="border-b  border-gray-200 pb-3 text-base  font-semibold ps-8 2xl:text-lg">
				{t("Appointments.Appointment_details.Procedures.Next_Procedures")}
			</h4>
			<div className="my-3  ps-8 ">
				{loading ? (
					<p>...</p>
				) : nextProcedures.length > 0 ? (
					nextProcedures.map((procedure, i) => (
						<p key={i} className="mb-2">
							{procedure.name}
						</p>
					))
				) : (
					<p className="text-sm text-gray-800">
						{t("Appointments.Appointment_details.Procedures.No_Next")}
					</p>
				)}
			</div>
			<div className="mb-3">
				{prevProcedures.length > 0 && (
					<button
						className="align-center text-primary"
						onClick={() => setShowPrevSurgeon((prev) => !prev)}
					>
						<span className="me-1">
							{showPreviousPrecedures ? (
								<EyeOnIcon width="20" height="20" />
							) : (
								<EyeOffIcon width="20" height="20" />
							)}
						</span>
						<span className="text-base">
							{showPreviousPrecedures
								? t("Appointments.Appointment_details.Procedures.Hide_prev")
								: t("Appointments.Appointment_details.Procedures.Show_prev")}
						</span>
					</button>
				)}
			</div>
			{showPreviousPrecedures ? (
				prevProcedures.length > 0 ? (
					<div className="scrollBar  overflow-x-auto">
						<table className="w-full table-fixed divide-y-2 divide-gray-200 text-center text-gray-800">
							<thead>
								<tr>
									<th className="w-1/4 py-4 font-semibold">
										{t(
											"Appointments.Appointment_details.Procedures.Table.Surgery"
										)}
									</th>
									<th className="w-1/4 font-semibold">
										{t(
											"Appointments.Appointment_details.Procedures.Table.Date"
										)}
									</th>
									<th className="w-1/4 font-semibold">
										{t(
											"Appointments.Appointment_details.Procedures.Table.Dentist"
										)}
									</th>
								</tr>
							</thead>
							<tbody className="divide-y-2 divide-gray-200">
								{prevProcedures.map((procedure, i) => (
									<tr key={i}>
										<td className="py-4">{procedure.name}</td>
										<td>
											<p>
												{moment(procedure.time)
													.locale(i18n.language)
													.format("DD MMM YYYY")}
											</p>
											<p className="text-xs text-gray-600">
												<span>
													{moment(procedure.time)
														.locale(i18n.language)
														.format("h:mm:a")}
													:{" "}
													{moment(procedure.time)
														.locale(i18n.language)
														.format("h:mm:a")}
												</span>
											</p>
										</td>
										<td>{dentists && dentists[procedure.dentist]?.name}</td>
										{/* <td className="my-4 flex justify-center">
											<button
												className="center h-7 w-7 rounded-md border-2 border-primary text-primary me-3"
												data-tip={"Via WhatsApp"}
												data-for="whats"
											>
												<WhatsappIcon width="16" height="16" />
											</button>
											<button
												className="center h-7 w-7 rounded-md border-2 border-primary text-primary"
												data-tip={"Via SMS"}
												data-for="sms"
											>
												<SMSIcon width="16" height="16" />
											</button>
										</td> */}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<p className="text-gray-800">
						{t("Appointments.Appointment_details.Procedures.No_Prev")}
					</p>
				)
			) : null}
		</div>
	);
};
