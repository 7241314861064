import { HeaderGroup } from "react-table";
import { ChevronSort } from "src/components/Svg";

type THeadSortButtonProps = {
	column: HeaderGroup<any>;
};

export function THeadSortButton({ column }: THeadSortButtonProps) {
	return (
		<button className="text-muted ms-3 print:hidden">
			{!column.isSorted ? (
				<ChevronSort />
			) : column.isSortedDesc === true ? (
				"🔼"
			) : (
				"🔽"
			)}
		</button>
	);
}
