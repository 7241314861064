import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { PatientFilePlanType } from "src/@types";
import { PedoTeethDictionary } from "src/constants";
import { PatientProfileState } from "../patientProfileSlice";
import {
	convertHoveredTeethToObject,
	isLowerArchSelected,
	isUpperArchSelected,
} from "../utils";

export const teethReducers = {
	toggleSelectTooth: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<number>
	) => {
		if (state.selectedPlanTeethDictionary?.[action.payload].type === "pedo") {
			if (PedoTeethDictionary?.[action.payload] === null) return;
		}

		state.selectedProcedure = null;
		state.selectedTeeth[action.payload] = !state.selectedTeeth[action.payload];

		// * check if any selectedTeeth is true open upper bar
		const selectedTeeth = Object.values(state.selectedTeeth);
		const isAnySelected = selectedTeeth.some((tooth) => tooth);
		const isAllNotSelected = selectedTeeth.every((tooth) => !tooth);

		if (isAnySelected) state.isUpperBarOpen = true;
		if (isAllNotSelected) state.isUpperBarOpen = false;

		state.isUpperArchSelected = isUpperArchSelected(state);
		state.isLowerArchSelected = isLowerArchSelected(state);
	},

	toggleUpperArchTeeth: (state: WritableDraft<PatientProfileState>) => {
		const upperArchTeeth = Array.from({ length: 16 }, (_, i) => i + 1);

		if (!state.isUpperArchSelected) {
			const upperArchTeethObject = upperArchTeeth.reduce(
				(acc, tooth) => ({ ...acc, [tooth]: true }),
				{}
			);

			state.selectedTeeth = {
				...state.selectedTeeth,
				...upperArchTeethObject,
			};
		}

		if (state.isUpperArchSelected) {
			const upperArchTeethObject = upperArchTeeth.reduce(
				(acc, tooth) => ({ ...acc, [tooth]: false }),
				{}
			);

			state.selectedTeeth = {
				...state.selectedTeeth,
				...upperArchTeethObject,
			};
		}

		state.isUpperArchSelected = isUpperArchSelected(state);
		state.selectedProcedure = null;
		state.isUpperBarOpen = true;
	},

	toggleLowerArchTeeth: (state: WritableDraft<PatientProfileState>) => {
		const lowerArchTeeth = Array.from({ length: 16 }, (_, i) => i + 17);

		if (!state.isLowerArchSelected) {
			const lowerArchTeethObject = lowerArchTeeth.reduce(
				(acc, tooth) => ({ ...acc, [tooth]: true }),
				{}
			);

			state.selectedTeeth = {
				...state.selectedTeeth,
				...lowerArchTeethObject,
			};
		}

		if (state.isLowerArchSelected) {
			const lowerArchTeethObject = lowerArchTeeth.reduce(
				(acc, tooth) => ({ ...acc, [tooth]: false }),
				{}
			);

			state.selectedTeeth = {
				...state.selectedTeeth,
				...lowerArchTeethObject,
			};
		}

		state.isLowerArchSelected = isLowerArchSelected(state);
		state.selectedProcedure = null;
		state.isUpperBarOpen = true;
	},

	hoverTeeth: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<PatientFilePlanType["teeth"]>
	) => {
		state.hoveredTeeth = convertHoveredTeethToObject({
			teeth: action.payload,
		});
	},

	unHoverTeeth: (state: WritableDraft<PatientProfileState>) => {
		state.hoveredTeeth = {};
	},
};
