import { Style } from "src/@types";
import { LabStatusCar } from "src/components/Svg";

export function LabStatusTitle({ className, style }: Style) {
	return (
		<h2
			className={`mb-6 flex items-center gap-2 font-semibold capitalize text-darkBlue ${className}`}
			style={{ lineHeight: "1.408125rem", fontSize: "1.17375rem", ...style }}
		>
			<span>Status</span>
			<span>
				<LabStatusCar />
			</span>
		</h2>
	);
}
