import { useTranslation } from "react-i18next";
import { UploadIcon } from "src/components";
type FileInputProps = {
	id?: string | undefined;
};
export const FileInput = ({ id }: FileInputProps) => {
	const { t } = useTranslation("patientList");

	return (
		<>
			<label htmlFor={id} className="cursor-pointer ">
				<div className="flex items-center rounded-lg bg-gray-200 px-2.5 py-2.5 text-gray-600 shadow-sm ">
					<span className="me-3">
						<UploadIcon />
					</span>
					<p className="text-sm">
						{t("Add_Patient.Upload_File.Upload_Images")}
					</p>
				</div>
			</label>
			<input id={id} type="file" className="hidden" />
		</>
	);
};
