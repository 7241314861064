import { ReactNode, useEffect } from "react";
import { Backdrop } from "src/components";

type PopupProps = {
	children: ReactNode;
	show: boolean;
	width?: string;
	close: () => void;
	className?: string;
};

export const Popup = ({
	children,
	show,
	width,
	close,
	className,
}: PopupProps) => {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [show]);

	return show ? (
		<div className="fixed inset-0 z-50 flex items-center justify-center">
			<Backdrop show={show} clickOutside={close} withBackground />

			<div
				className={`scrollBar z-50 my-8 max-h-[90vh] overflow-y-auto bg-white ring-2 ring-gray-200 ${className}`}
				style={{ width: width ?? "35.5rem" }}
			>
				{children}
			</div>
		</div>
	) : null;
};
