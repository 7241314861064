import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Btn, InputCode } from "src/components";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import verificationCodeImg from "src/assets/svg/verification_code.svg";
import { ROUTES } from "src/constants";
import { useAxios, useQuery } from "src/hooks";
import { toast } from "react-toastify";
import { authenticate, useAppDispatch } from "src/redux";

export const VerificationCode = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation<{ newUserPassword: string }>();
	const newUserPassword = location.state?.newUserPassword;

	const query = useQuery();
	const phone = query.get("phone");
	const name = query.get("name");
	const clinicName = query.get("clinicName");
	const queryCode = query.get("code");
	const type = query.get("type") as "forgotPassword" | "activate";

	const isForgotPassword = type === "forgotPassword";
	const isActivate = type === "activate";

	const [code, setCode] = useState(queryCode || "");

	const { makeRequest } = useAxios({
		runNow: false,
		configs: { method: "POST" },
	});

	const authHandler = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (code.length < 5) {
			toast.error("Code must be 5 digits");

			return;
		}

		if (!phone) {
			toast.error("Phone number is required");

			return;
		}

		if (isForgotPassword) {
			history.push(ROUTES.AUTH.RESET_PASSWORD, { phone, code });
		}

		if (isActivate) {
			makeRequest({
				configs: {
					url: "/activate",
					params: { phone: "+" + phone?.replace(/\s/g, ""), code },
				},
				handleSuccess: () => {
					toast.success("Account activated successfully");

					if (newUserPassword) {
						dispatch(
							authenticate({
								username: "+" + phone?.replace(/\s/g, ""),
								password: newUserPassword,
							})
						)
							.unwrap()
							.then(() => {
								history.replace(ROUTES.ADMIN.HOME);
							})
							.catch(() => {
								history.replace(ROUTES.AUTH.LOGIN);
							});
					} else {
						history.replace(ROUTES.AUTH.LOGIN);
					}
				},
				handleError: (error) => {
					toast.error(error.response.data.detail);
				},
			});
		}
	};

	const timeLeftToResendCode = useRef(120); // 2 minutes

	useEffect(() => {
		const timer = setInterval(() => {
			timeLeftToResendCode.current -= 1;
		}, 1000);

		return () => clearInterval(timer);
	}, [timeLeftToResendCode.current]);

	const handleResendCode = () => {
		if (timeLeftToResendCode.current > 0) {
			toast.error(
				`Please wait ${timeLeftToResendCode.current} seconds to resend code again`
			);

			return;
		}

		if (isForgotPassword) {
			makeRequest({
				configs: { url: "/forgot-password", params: { phone } },
				handleSuccess: () => {
					toast.success("Code sent successfully");
				},
			});
		}

		if (isActivate) {
			makeRequest({
				configs: {
					url: "/register",
					method: "POST",
					params: {
						phone: phone,
						password: newUserPassword,
						clinic_name: clinicName,
						full_name: name,
					},
				},
				handleSuccess: () => {
					toast.success("Code sent successfully");
				},
			});
		}
	};

	return (
		<FormWrapper className="mt-20">
			<form onSubmit={authHandler} noValidate autoComplete="off">
				<img
					src={verificationCodeImg}
					alt="img for verification code"
					className="mx-auto"
				/>

				<div className="mb-5">
					<h3 className="mb-2 text-center text-2xl  font-semibold text-dark 2xl:text-3xl ">
						Enter security code
					</h3>

					<p className="text-center text-sm  text-gray-800">
						{`We sent your code to ${phone?.replace(
							phone.substring(3, phone.length - 3),
							"********"
						)}, please check your phone.`}

						<Link
							to={
								isForgotPassword
									? ROUTES.AUTH.FORGOT_PASSWORD
									: `${ROUTES.AUTH.REGISTER}?phone=${phone}&fullName=${name}&clinicName=${clinicName}`
							}
							className="text-primary"
							replace
						>
							Change number
						</Link>
					</p>
				</div>

				<div className="mb-5">
					<InputCode
						defaultCode={code}
						length={5}
						onComplete={(code) => setCode(code)}
					/>
				</div>

				<p className="mb-6 text-center">
					Didn’t get a code?{" "}
					<span
						className="cursor-pointer text-lg font-bold text-primary"
						onClick={handleResendCode}
					>
						Resend
					</span>
				</p>

				<Btn
					variant="primary"
					size="md"
					type="submit"
					rounded="rounded-lg"
					disabled={code.length < 5}
					className={`
						mb-3 w-full text-lg
						${code.length < 5 ? "opacity-50" : "opacity-100"}
					`}
				>
					Continue
				</Btn>
			</form>
		</FormWrapper>
	);
};
