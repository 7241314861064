import React from "react";
import { StarEmptyIcon, StarFilledIcon } from "src/components";

type RatingProps = {
	rating: number;
	starWidth?: number | string;
	starHeight?: number | string;
};

export const Rating = ({ rating, starWidth, starHeight }: RatingProps) => {
	return (
		<div className="flex items-center">
			{[...Array(5)].map((_, index) => {
				return (
					<React.Fragment key={index}>
						{rating > index ? (
							<StarFilledIcon width={starWidth} height={starHeight} />
						) : (
							<StarEmptyIcon width={starWidth} height={starHeight} />
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
};
