import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { Page, SideCol } from "src/layout";
import {
	eventsActions,
	schedulerActions,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import { ActionsMenu, AddAppointment, Backdrop } from "src/components";
import { Scheduler } from "./Scheduler/Calendar";
import { useEffect, useRef, useState } from "react";
import { useCheckRole } from "src/hooks";

const SchedulerPage = () => {
	const { t } = useTranslation("layout");
	const dispatch = useAppDispatch();

	const { isAssistant } = useCheckRole();
	const [edit, setEdit] = useState(false);

	const addAppointmentRef = useRef<HTMLDivElement>(null);
	const editAppointmentRef = useRef<HTMLDivElement>(null);

	const {
		events: { selectedEvent },
		scheduler: { isAddAppointmentOpen },
	} = useAppSelector((state) => state);

	useEffect(() => {
		document.body.classList.add("noscroll");

		return () => document.body.classList.remove("noscroll");
	}, []);

	const handleClose = () => {
		if (edit) setEdit(false);

		if (isAddAppointmentOpen) {
			dispatch(schedulerActions.closeAddAppointmentModal());
			dispatch(eventsActions.cancelAddingFromSlots());
		}
	};

	return (
		<Page title={isAssistant ? t("Header.Main") : "Scheduler Page"}>
			<div className="h-full w-full bg-background py-4 ps-8">
				<div className="flex justify-between print:h-full">
					<div
						className="scrollBar relative flex-grow overflow-y-scroll transition-all pe-4"
						style={{ height: "85vh" }}
					>
						<Scheduler setEdit={setEdit} />
					</div>

					<Backdrop
						show={isAddAppointmentOpen || edit}
						clickOutside={handleClose}
						withBackground
					/>

					<CSSTransition
						in={isAddAppointmentOpen}
						timeout={300}
						classNames="transition"
						unmountOnExit
						nodeRef={addAppointmentRef}
					>
						<SideCol ref={addAppointmentRef}>
							<AddAppointment mode="ADD" />
						</SideCol>
					</CSSTransition>

					{selectedEvent !== null && (
						<CSSTransition
							in={edit}
							timeout={300}
							classNames="transition"
							unmountOnExit
							nodeRef={editAppointmentRef}
						>
							<SideCol ref={editAppointmentRef}>
								<AddAppointment mode="EDIT" setEdit={setEdit} />
							</SideCol>
						</CSSTransition>
					)}
				</div>

				{/* {!isAddAppointmentOpen && <ActionsMenu />} */}
			</div>
		</Page>
	);
};

export default SchedulerPage;
