import { FieldError, UseFormRegister } from "react-hook-form";
import { Label } from "../Label/Label";

interface TextareaProps
	extends React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {
	name: string;
	label?: string;
	icon?: JSX.Element;
	id: string;
	register: UseFormRegister<any>;
	error?: FieldError | undefined;
	requiredStar?: boolean | undefined;
	labelClassName?: string;
	labelStyle?: React.CSSProperties;
	variant?: "outlined" | "filled";
	// inputSize?: Size;
}

export const Textarea = ({
	id,
	register,
	requiredStar,
	name,
	label,
	labelClassName,
	labelStyle,
	error,
	variant = "filled",
	...rest
}: TextareaProps) => {
	return (
		<div>
			<Label
				required={requiredStar}
				htmlFor={id}
				variant="filled"
				error={error}
				className={labelClassName}
				style={labelStyle}
			>
				{label}
			</Label>

			<textarea
				{...register(name)}
				className={`input input--${variant} scrollBar resize-none  px-2 py-3`}
				rows={3}
				{...rest}
			/>
		</div>
	);
};
