import { AppointmentsStatusEnum } from "src/@types";
import {
	CheckCircleIcon,
	CheckIcon,
	EyeOffIcon,
	XCloseIcon,
} from "src/components";

type AppointmentStatusType = {
	label:
		| "Appointments.Appointment_details.Header.Status.Not_Confirmed"
		| "Appointments.Appointment_details.Header.Status.Confirmed"
		| "Appointments.Appointment_details.Header.Status.Attended"
		| "Appointments.Appointment_details.Header.Status.Not_Attended"
		| "Appointments.Appointment_details.Header.Status.Cancelled";
	value: AppointmentsStatusEnum;
	icon?: JSX.Element;
};

type IconSizeType = {
	width: string;
	height: string;
};

const NOT_CONFIRMED = (iconSize: IconSizeType): AppointmentStatusType => ({
	label: "Appointments.Appointment_details.Header.Status.Not_Confirmed",
	value: AppointmentsStatusEnum.NOT_CONFIRMED,
});

const CONFIRMED = (iconSize: IconSizeType): AppointmentStatusType => ({
	label: "Appointments.Appointment_details.Header.Status.Confirmed",
	value: AppointmentsStatusEnum.CONFIRMED,
	icon: <CheckIcon width={iconSize.width} height={iconSize.height} />,
});

const ATTENDED = (iconSize: IconSizeType): AppointmentStatusType => ({
	label: "Appointments.Appointment_details.Header.Status.Attended",
	value: AppointmentsStatusEnum.ATTENDED,
	icon: <CheckCircleIcon width={iconSize.width} height={iconSize.height} />,
});

const NOT_ATTENDED = (iconSize: IconSizeType): AppointmentStatusType => ({
	label: "Appointments.Appointment_details.Header.Status.Not_Attended",
	value: AppointmentsStatusEnum.NOT_ATTENDED,
	icon: <EyeOffIcon width={iconSize.width} height={iconSize.height} />,
});

const CANCELED = (iconSize: IconSizeType): AppointmentStatusType => ({
	label: "Appointments.Appointment_details.Header.Status.Cancelled",
	value: AppointmentsStatusEnum.CANCELED,
	icon: <XCloseIcon width={iconSize.width} height={iconSize.height} />,
});

export const APPOINTMENT_STATUS = (
	iconSize = { width: "20px", height: "20px" }
): AppointmentStatusType[] => [
	NOT_CONFIRMED(iconSize),
	CONFIRMED(iconSize),
	ATTENDED(iconSize),
	NOT_ATTENDED(iconSize),
	CANCELED(iconSize),
];

export const APPOINTMENT_STATUS_OBJECT = (
	iconSize = { width: "20px", height: "20px" }
) => ({
	[AppointmentsStatusEnum.NOT_CONFIRMED]: NOT_CONFIRMED(iconSize),
	[AppointmentsStatusEnum.CONFIRMED]: CONFIRMED(iconSize),
	[AppointmentsStatusEnum.ATTENDED]: ATTENDED(iconSize),
	[AppointmentsStatusEnum.NOT_ATTENDED]: NOT_ATTENDED(iconSize),
	[AppointmentsStatusEnum.CANCELED]: CANCELED(iconSize),
});
