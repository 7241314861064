import { UseFormRegister, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "src/components";

type PatientAgeProps = {
	register: UseFormRegister<any>;
	error: FieldError | undefined;
};

export const PatientAge = ({ register, error }: PatientAgeProps) => {
	const { t } = useTranslation("scheduler");

	return (
		<div className="mb-3">
			<Input
				type="number"
				name="age"
				id="age"
				label={t("Appointments.Add_Appointment_Form.Inputs.Patient_Age.Label")}
				placeholder={t(
					"Appointments.Add_Appointment_Form.Inputs.Patient_Age.Placeholder"
				)}
				register={register}
				error={error}
				min={1}
				requiredStar
				variant="standard"
				inputSize="sm"
			/>
		</div>
	);
};
