import styles from "./Spinner.module.css";

export const Spinner = () => {
	return (
		<div className="center h-full w-full">
			<div className={styles.loader}></div>
		</div>
	);
};

export const AppSpinner = () => {
	return (
		<div className="center h-screen w-full">
			<div className={styles.loader}></div>
		</div>
	);
};
