import { PatientFileDiagnoseType } from "src/@types";
import { updatePlan } from "../plan";

type UpdateDiagnoseParamsType = {
	diagnosisId: PatientFileDiagnoseType["id"];
	notes: PatientFileDiagnoseType["notes"];
};

export const updateDiagnose = ({
	diagnosisId,
	notes,
}: UpdateDiagnoseParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			return {
				...planToUpdate,
				diagnoses: planToUpdate.diagnoses.map((diagnose) => {
					if (diagnose.id === diagnosisId) {
						return { ...diagnose, notes };
					}

					return diagnose;
				}),
			};
		},
	});
};
