import { PatientFileStageType } from "src/@types";
import { generateUniqueIntegerId } from "src/utils";
import { updatePlan } from "../plan";

type CreateStageParamsType = {
	newStageName: PatientFileStageType["name"];
};

export const createStage = ({ newStageName }: CreateStageParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate) => {
			const newStage: PatientFileStageType = {
				id: generateUniqueIntegerId(),
				name: newStageName,
				procedures: [],
				totalPrice: 0,
			};

			return {
				...planToUpdate,
				procedures: { stages: [...planToUpdate.procedures.stages, newStage] },
			};
		},
	});
};
