import moment from "src/config/moment";
import { useMemo, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
	AppointmentsStatusEnum,
	AppointmentFetched,
	Appointment,
} from "src/@types";
import { useAppSelector } from "src/redux";
import { Btn } from "../..";
import { AppSpinner, Spinner } from "src/components";
import logo from "src/assets/images/logo2.png";
import { useAxios, useQuery } from "src/hooks";

export const PrintAppointments = () => {
	const query = useQuery();
	const { dentists } = useAppSelector((state) => state.dentists);
	const { patients } = useAppSelector((state) => state.patients);
	const printDate = useMemo(() => query.get("date"), [query]);

	const [appointments, setAppointments] = useState<Appointment[]>([]);

	const { loading } = useAxios<AppointmentFetched[]>({
		configs: {
			url: "/appointments",
			params: {
				start_date: moment(printDate).startOf("week").format("YYYY-MM-DD"),
				end_date: moment(printDate).endOf("week").format("YYYY-MM-DD"),
			},
		},
		dependencies: [],
		handleSuccess: (data) => {
			setAppointments(
				data.map((event) => {
					return {
						id: event.id,
						patient: patients ? patients[event.patientId] : undefined,
						dentist: dentists ? dentists[event.dentist] : undefined,
						status: event.status,
						starts: event.starts,
						ends: event.ends,
						type: event.type,
						clinic: event.clinic,
						updates: event.updates,
						notes: event.notes,
						attendance: event.attendance,
					};
				})
			);
		},
		handleError: (error) => {
			toast("oops, error ocurred");
		},
	});

	const handlePrint = () => {
		window.print();
	};

	useEffect(() => {
		if (!loading) {
			const timerId = setTimeout(() => {
				window.print();
			}, 1000);

			return () => clearTimeout(timerId);
		}
	}, [loading]);

	const renderStatus = useCallback(
		(status: AppointmentsStatusEnum) => {
			switch (status) {
				case AppointmentsStatusEnum.NOT_CONFIRMED:
					return "Not confirmed";
				case AppointmentsStatusEnum.CONFIRMED:
					return "confirmed";
				case AppointmentsStatusEnum.ATTENDED:
					return "Attended";
				case AppointmentsStatusEnum.NOT_ATTENDED:
					return "not Attended";
				case AppointmentsStatusEnum.CANCELED:
					return "Canceled";
				default:
					return "";
			}
		},
		[appointments.length]
	);

	if (loading) return <AppSpinner />;

	return (
		<div className="page py-4 px-4" dir="ltr" id="divToPrint">
			<div id="pageFooter"></div>
			<div className="flex items-center justify-between me-8">
				<div>
					<div className="mb-2 flex justify-start">
						<img src={logo} alt="logo" className="object-contain" />
					</div>
					<p className="mb-4 text-sm font-bold">
						Schedule Report :{" "}
						{moment(printDate).locale("en").format("ddd , D-MMMM-YYYY")}
					</p>
				</div>
				<div>
					<Btn
						type="button"
						variant="primary-outline"
						size="xs"
						className="print:hidden"
						onClick={handlePrint}
					>
						Print
					</Btn>
				</div>
			</div>
			<div>
				<table className="table_borders table_xl table w-full  table-auto   text-center">
					<thead>
						<tr>
							<th>Appointment</th>
							<th>Patient Name</th>
							<th>Notes</th>
							<th>ID</th>
							<th>Phone</th>
							<th>Dentist</th>
							<th>Duration</th>
							<th>Confirmation</th>
						</tr>
					</thead>
					<tbody>
						{appointments.map((event) => {
							return (
								<tr key={event?.id}>
									<td>{moment(event.starts).locale("en").format("h.mm A")}</td>
									<td>{event?.patient?.name}</td>
									<td>{event?.notes ? event.notes : "-"}</td>
									<td>{event?.patient?.customId ?? "-"}</td>
									<td>{event?.patient?.phone}</td>
									<td>{event?.dentist?.name}</td>
									<td>
										{moment(event.ends).diff(event.starts, "minutes")} MIN
									</td>
									<td>{renderStatus(event?.status)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};
