import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { toast } from "react-toastify";
import { PatientFilePlanType, PatientFileType } from "src/@types";
import { RootState } from "src/redux/store/store";

export const deletePatientPlan = createAsyncThunk<
	{ planId: PatientFilePlanType["id"] },
	{
		patientId: PatientFileType["id"];
		planId: PatientFilePlanType["id"];
	},
	{ rejectValue: string; state: RootState }
>(
	"patientProfile/deletePatientPlan",
	async ({ patientId, planId }, thunkAPI) => {
		try {
			await axios.delete(`/patients/${patientId}/plans/${planId}`);

			return { planId };
		} catch (error) {
			toast.error("Error deleting plan");

			return thunkAPI.rejectWithValue("Error deleting plan");
		}
	}
);
