import { PedoTeethDictionary } from "src/constants";
import { PatientProfileState } from "../patientProfileSlice";

export function isUpperArchSelected(state: PatientProfileState): boolean {
	let numberOfTeethSelected = 0;

	const hiddenTeeth =
		state.selectedPlan?.teeth.filter((tooth) =>
			tooth.type === "pedo" && tooth.number <= 16
				? PedoTeethDictionary?.[tooth.number] === null
					? true
					: false
				: false
		).length || 0;

	for (const tooth in state.selectedTeeth) {
		if (state.selectedTeeth[tooth] && +tooth <= 16) {
			numberOfTeethSelected++;
		}
	}

	console.log(hiddenTeeth);

	return (
		numberOfTeethSelected === 16 || numberOfTeethSelected === 16 - hiddenTeeth
	);
}

export function isLowerArchSelected(state: PatientProfileState): boolean {
	let numberOfTeethSelected = 0;

	const hiddenTeeth =
		state.selectedPlan?.teeth.filter((tooth) =>
			tooth.type === "pedo" && tooth.number > 16
				? PedoTeethDictionary?.[tooth.number] === null
					? true
					: false
				: false
		).length || 0;

	for (const tooth in state.selectedTeeth) {
		if (state.selectedTeeth[tooth] && +tooth > 16) {
			numberOfTeethSelected++;
		}
	}

	return (
		numberOfTeethSelected === 16 || numberOfTeethSelected === 16 - hiddenTeeth
	);
}
