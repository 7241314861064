import { useState } from "react";
import { ClinicInfoScreen } from "./ClinicInfoScreen/ClinicInfoScreen";
import { GetStartedScreen } from "./GetStartedScreen/GetStartedScreen";
import { PersonalInfoScreen } from "./PersonalInfoScreen/PersonalInfoScreen";
import { Progress } from "./Progress/Progress";

export enum Screens {
	GET_STARTED = 1,
	PERSONAL_INFO = 2,
	CLINIC_INFO = 3,
}

type InfoProcessProps = {
	closeOnBoarding: () => void;
};

export function InfoProcess({ closeOnBoarding }: InfoProcessProps) {
	const [currentScreen, setCurrentScreen] = useState<Screens>(
		Screens.GET_STARTED
	);

	const goToGetStated = () => setCurrentScreen(Screens.GET_STARTED);
	const goToPersonalInfo = () => setCurrentScreen(Screens.PERSONAL_INFO);
	const goToClinicInfo = () => setCurrentScreen(Screens.CLINIC_INFO);
	const finishOnBoarding = () => closeOnBoarding();

	return (
		<section className="scrollBar h-full flex-1 overflow-auto px-[3.75rem] py-16">
			<Progress currentScreen={currentScreen} />

			{currentScreen === Screens.GET_STARTED && (
				<GetStartedScreen goToPersonalInfo={goToPersonalInfo} />
			)}

			{currentScreen === Screens.PERSONAL_INFO && (
				<PersonalInfoScreen
					goToGetStated={goToGetStated}
					goToClinicInfo={goToClinicInfo}
				/>
			)}

			{currentScreen === Screens.CLINIC_INFO && (
				<ClinicInfoScreen
					goToPersonalInfo={goToPersonalInfo}
					finishOnBoarding={finishOnBoarding}
				/>
			)}
		</section>
	);
}
