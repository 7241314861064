import { Control, Controller } from "react-hook-form";
import { StarIcon } from "src/components";

type CreateRatingProps = {
	control: Control | any;
	name: string;
};

export function CreateRating({ control, name }: CreateRatingProps) {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => (
				<div className="flex gap-3">
					{Array.from(Array(5).keys()).map((number) => {
						const currentNumber = number + 1;
						const isActive = currentNumber <= +value;

						const handleClick = () => {
							if (currentNumber === +value) onChange(0);
							if (currentNumber !== +value) onChange(currentNumber);
						};

						return (
							<span
								key={number}
								className={`cursor-pointer ${isActive ? "text-primary" : ""}`}
								style={{ color: isActive ? "#0077B4" : "#C9C9C9" }}
								onClick={handleClick}
							>
								<StarIcon width={34} />
							</span>
						);
					})}
				</div>
			)}
		/>
	);
}
