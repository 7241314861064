import { Style } from "src/@types";
import { BtnWithIcon, PlusIcon } from "src/components";

type TableSimpleHeaderProps = {
	title?: React.ReactNode;
	count?: number;
	button?: { text: string; onClick: () => void };
	styles?: {
		container?: Style;
		title?: Style;
	};
};

export function TableSimpleHeader({
	title,
	count,
	button,
	styles,
}: TableSimpleHeaderProps) {
	return (
		<header
			className={`
				flex items-center justify-between
				${button ? "mb-2" : "mb-4"}
				${styles?.container?.className}
			`}
			style={styles?.container?.style}
		>
			<h4
				className={`
					text-base font-semibold text-dark
					${styles?.title?.className}
				`}
				style={styles?.title?.style}
			>
				{title} {count !== 0 && `(${count})`}
			</h4>

			{button && (
				<BtnWithIcon
					icon={<PlusIcon />}
					variant="text-primary"
					size="md"
					onClick={button?.onClick}
				>
					{button?.text}
				</BtnWithIcon>
			)}
		</header>
	);
}
