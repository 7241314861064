import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { NavbarItem } from "src/@types";
import { DotCircle } from "src/components";
import style from "./Navbar.module.css";

type NavbarProps = {
	isCollapsed: boolean;
	navItems: NavbarItem[];
};

export const Navbar = ({ isCollapsed, navItems }: NavbarProps) => {
	const { t } = useTranslation("layout");

	return (
		<nav className="scrollBar mt-12 max-h-[70vh] overflow-auto pt-2 pb-7 2xl:mt-20 3xl:mt-28">
			<ul>
				{navItems.map((navItem, i) => (
					<li
						className={isCollapsed ? "mb-4 " : "mb-6 2xl:mb-7 3xl:mb-9"}
						key={i}
					>
						<NavLink
							to={navItem.navLink}
							className={`align-center flex-row text-gray-600 transition-all duration-300 pe-1 hover:text-gray-700 ${
								isCollapsed
									? "flex-col "
									: "flex-row hover:ps-1 hover:border-e-4"
							}`}
							activeClassName={style.activeNavItem}
							exact={navItem.exact}
						>
							<i className={`relative px-3 ${isCollapsed ? "mb-2" : "mb-0"}`}>
								{navItem.navIcon}
								{navItem.count && <DotCircle>{navItem.count}</DotCircle>}
							</i>
							<span
								className={`font-cairo font-bold text-current ${
									isCollapsed
										? "text-xs 2xl:text-sm 3xl:text-lg"
										: "text-sm 2xl:text-base 3xl:text-xl"
								}`}
							>
								{t(navItem.navItem)}
							</span>
						</NavLink>
					</li>
				))}
			</ul>
		</nav>
	);
};
