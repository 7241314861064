import { Link } from "react-router-dom";

type StatisticCardProps = {
	title: string;
	value: React.ReactNode;
	valueLabel?: string;
	icon: React.ReactNode;
	button?: { text: string; to: string };
};

export function StatisticCard({
	title,
	value,
	valueLabel,
	icon,
	button,
}: StatisticCardProps) {
	return (
		<div className="flex h-[166px] w-full flex-col items-center rounded-lg bg-nativeWhite px-2 pb-2 pt-7">
			<div className="mb-3 flex items-end font-poppins font-semibold text-primary">
				<div className="text-[2.375rem]">{value}</div>

				{valueLabel && (
					<span className="relative bottom-2 text-base">{valueLabel}</span>
				)}
			</div>

			<div className="mb-4 flex items-center justify-center gap-2">
				{icon}

				<h3 className="text-xl font-semibold capitalize leading-6 text-[#141414]">
					{title}
				</h3>
			</div>

			{button && (
				<Link
					to={button.to}
					className="ml-auto rounded-[3.06118px] py-[0.3125rem] px-[0.6875rem] font-poppins text-[0.625rem] font-bold capitalize leading-[0.9375rem] text-darkBlue"
					style={{ backgroundColor: "rgba(4, 98, 137, 0.1)" }}
				>
					{button.text}
				</Link>
			)}
		</div>
	);
}
