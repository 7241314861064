import { useTranslation } from "react-i18next";
import { NoData } from "./NoData";
import NoOrdersImg from "src/assets/svg/no_lab_orders.svg";

type NoLabOrdersProps = {
	clickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export function NoLabOrders({ clickHandler }: NoLabOrdersProps) {
	const { t } = useTranslation("lab");

	return (
		<NoData
			imgAlt="No Orders"
			imgSrc={NoOrdersImg}
			notFoundMsg={t("Orders.Messages.No_Orders_Found")}
			desc={t("Orders.Messages.No_Orders_Found_Desc")}
			btn={t("Orders.Add_New_Order.Add_Order")}
			clickHandler={clickHandler}
		/>
	);
}
