import { PatientBasicInfo, Patient, Detail } from "src/@types";
import axios from "src/config/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPatients = createAsyncThunk<
	PatientBasicInfo[],
	void,
	{ rejectValue: string }
>("patients/fetchPatients", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get("store/patients", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

export const addNewPatient = createAsyncThunk<
	PatientBasicInfo,
	Patient,
	{ rejectValue: Detail }
>("patients/addNewPatient", async (patient, thunkAPI) => {
	try {
		const { data } = await axios.post(
			"patients",
			{
				...patient,
			},
			{ signal: thunkAPI.signal }
		);

		const newBasicPatient: PatientBasicInfo = {
			id: data.id,
			name: data.name,
			phone: data.phone,
			customId: data.customId,
			createdAt: new Date().toString(),
			balance: 0,
		};

		return newBasicPatient;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

export const updatePatient = createAsyncThunk<
	PatientBasicInfo,
	{ patientId: number; patient: Patient },
	{ rejectValue: Detail }
>("patients/editPatient", async ({ patientId, patient }, thunkAPI) => {
	try {
		const { data } = await axios.put(
			`/patients/${patientId}`,
			{ ...patient },
			{ signal: thunkAPI.signal }
		);

		const newBasicPatient: PatientBasicInfo = {
			id: data.id,
			name: data.name,
			phone: data.phone,
			customId: data.customId,
			createdAt: new Date().toString(),
			balance: 0,
		};

		return newBasicPatient;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

export const deletePatient = createAsyncThunk<
	number,
	number,
	{ rejectValue: Detail }
>("patients/deletePatient", async (patientId, thunkAPI) => {
	try {
		await axios.delete(`/patients/${patientId}`, {
			signal: thunkAPI.signal,
		});

		return patientId;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});
