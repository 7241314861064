import moment from "src/config/moment";
import { useEffect, useState } from "react";
import { Dropdown, ListItem } from "src/components";

type RangeDropdownProps = {
	dataProp: any[];
	setData: React.Dispatch<React.SetStateAction<any[]>>;
};

const endDate = new Date("Apr 27, 2022");

const options = [
	{
		text: "Last 7 days",
		start: moment(endDate).subtract(7, "days").toDate(),
	},
	{
		text: "Last month",
		start: moment(endDate).subtract(1, "month").toDate(),
	},
	{
		text: "Last 3 months",
		start: moment(endDate).subtract(3, "months").toDate(),
	},
	{
		text: "Last 6 months",
		start: moment(endDate).subtract(6, "months").toDate(),
	},
	{
		text: "Last year",
		start: moment(endDate).subtract(1, "year").toDate(),
	},
	{
		text: "All time",
		start: new Date(0),
	},
];

export function RangeDropdown({ dataProp, setData }: RangeDropdownProps) {
	const [startDate, setStartDate] = useState(options[0]);

	const filterDataByDate = (start: Date) => {
		const newData = dataProp.filter((v) => {
			return moment(v.date).isBetween(start, endDate);
		});

		setData(newData);
	};

	useEffect(() => filterDataByDate(startDate.start), []);

	return (
		<div className="w-32">
			<Dropdown
				variant="filled"
				size="md"
				title="Date"
				value={startDate.text}
				onChange={(text) => {
					setStartDate(options.find((v) => v.text === text) || options[0]);
					filterDataByDate(
						options.find((v) => v.text === text)?.start || options[0].start
					);
				}}
			>
				{options.map((option, index) => {
					return (
						<ListItem
							value={option.text}
							displayedName={option.text}
							key={index}
						>
							<div className="py-1 text-base text-dark 3xl:text-xl">
								{option.text}
							</div>
						</ListItem>
					);
				})}
			</Dropdown>
		</div>
	);
}
