import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Page } from "src/layout";
import { PatientListTabs } from "./PatientList/PatientListTabs/PatientListTabs";
import { PatientGroupsSkeleton, PatientListSkeleton } from "src/components";
import { ROUTES } from "../../../constants";
import { useForAllRoutes } from "src/hooks";

const PatientListTable = React.lazy(
	() => import("./PatientList/PatientListTable/PatientListTable")
);

const PatientGroups = React.lazy(() => import("./PatientGroups/PatientGroups"));

const PatientsList = () => {
	const { t } = useTranslation("layout");
	const route = useForAllRoutes();

	return (
		<Page title={t("Header.Patient_List")}>
			<Container>
				<PatientListTabs />

				<Switch>
					<Route path={ROUTES[route].PATIENTS_GROUPS + "/"}>
						<Suspense fallback={<PatientGroupsSkeleton />}>
							<PatientGroups />
						</Suspense>
					</Route>
					<Route path={ROUTES[route].PATIENTS + "/"}>
						<Suspense fallback={<PatientListSkeleton />}>
							<PatientListTable />
						</Suspense>
					</Route>
				</Switch>
			</Container>
		</Page>
	);
};

export default PatientsList;
