export const range = (start: number, end: number) => {
	const length = end - start + 1;

	return Array.from({ length }, (_, idx) => idx + start);
};

export const getRandomFromArray = (arr: any[], n: number) => {
	const result = new Array(n);
	let len = arr.length;
	const taken = new Array(len);

	if (n > len) {
		throw new RangeError(
			"getRandomFromArray: more elements taken than available"
		);
	}

	while (n--) {
		const x = Math.floor(Math.random() * len);

		result[n] = arr[x in taken ? taken[x] : x];
		taken[x] = --len in taken ? taken[len] : len;
	}

	return result;
};
