import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { PatientFilePlanType, PatientFileType } from "src/@types";
import { PatientProfileState } from "../patientProfileSlice";
import { convertSelectedPlanTeethToDictionary } from "../utils";

export const plansReducers = {
	setPlans: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<{
			plans: PatientFilePlanType[];
			patientId: PatientFileType["id"];
		}>
	) => {
		state.plans = action.payload.plans;
		state.selectedPlan = action.payload.plans[0];
		state.selectedPlanTeethDictionary = convertSelectedPlanTeethToDictionary({
			plan: action.payload.plans[0],
		});
	},

	selectPlan: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<PatientFilePlanType["id"]>
	) => {
		if (state.selectedProcedure) state.selectedProcedure = null;
		if (state.isUpperBarOpen) state.isUpperBarOpen = false;
		if (state.selectedTeeth) state.selectedTeeth = {};

		const selectedPlan = state.plans?.find(
			(plan) => plan.id === action.payload
		);

		if (selectedPlan) {
			state.selectedPlan = selectedPlan;

			state.selectedPlanTeethDictionary = convertSelectedPlanTeethToDictionary({
				plan: selectedPlan,
			});
		}
	},
};
