import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { PatientFilePlanType, PatientFileProcedureType } from "src/@types";
import { PatientProfileState } from "../patientProfileSlice";

export const proceduresReducers = {
	toggleSelectProcedure: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<PatientFileProcedureType>
	) => {
		state.selectedTeeth = {};

		if (state.selectedProcedure?.id === action.payload.id) {
			state.selectedProcedure = null;
			state.isUpperBarOpen = false;
		} else {
			state.selectedProcedure = action.payload;
			state.isUpperBarOpen = true;
		}
	},

	createProcedureSuccess: (state: WritableDraft<PatientProfileState>) => {
		state.currentScreen = "PROCEDURES";
		state.selectedTeeth = {};
		state.isUpperBarOpen = false;
		state.isUpperArchSelected = false;
		state.isLowerArchSelected = false;
	},

	updateProcedureSuccess: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<{ plan: PatientFilePlanType }>
	) => {
		action.payload.plan.procedures.stages.forEach((stage) => {
			if (stage.id === state.selectedProcedure?.stageId) {
				stage.procedures.forEach((procedure) => {
					if (procedure.id === state.selectedProcedure?.id) {
						state.selectedProcedure = procedure;
					}
				});
			}
		});
	},

	deleteProcedureSuccess: (state: WritableDraft<PatientProfileState>) => {
		state.selectedProcedure = null;
		state.isUpperBarOpen = false;
	},
};
