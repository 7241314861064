import { useEffect, useState } from "react";
import { useBlockChanges } from "src/hooks";
import { useAppSelector } from "src/redux";
import { Popup } from "src/components";
import { Advertisement } from "./Advertisement/Advertisement";
import { InfoProcess } from "./InfoProcess/InfoProcess";

export function OnBoarding() {
	const { admin } = useAppSelector((s) => s.auth.user);

	const [IsOnBoardingOpen, setIsOnBoardingOpen] = useState(
		admin?.onboarded === false || false
	);

	const closeOnBoarding = () => setIsOnBoardingOpen(false);

	useEffect(() => {
		if (admin?.onboarded === false) setIsOnBoardingOpen(!admin?.onboarded);
	}, [admin?.onboarded]);

	useBlockChanges({ condition: IsOnBoardingOpen });

	return IsOnBoardingOpen ? (
		<Popup
			show={true}
			close={() => {}}
			width="56.25rem"
			className="flex h-[50rem] rounded-lg bg-white shadow-[0px_4px_4px_rgba(94,99,102,0.2)]"
		>
			<InfoProcess closeOnBoarding={closeOnBoarding} />
			<Advertisement />
		</Popup>
	) : null;
}
