import { TFunction } from "react-i18next";
import { phoneValidation } from "src/utils";
import * as yup from "yup";

export interface NewPatientForm {
	name: string;
	phone: string;
	gender: string;
	birthDate: Date;
	age?: number;
	email?: string;
	picture?: string;
	emergencyName?: string;
	emergencyPhone?: string;
	address?: string;
	customId?: number;
	insurance?: number;
	employment?: string;
	nationality?: string;
}

export const schema = (translation: TFunction<"scheduler", undefined>) => {
	return yup.object().shape({
		name: yup.string().required("Required"),
		phone: phoneValidation(
			translation(
				"Appointments.Add_Appointment_Form.Inputs.Patient_Phone.Errors.Invalid_Phone"
			)
		),
		birthDate: yup.date().required("required").default(null).nullable(),
		age: yup
			.number()
			.required()
			.min(
				1,
				translation(
					"Appointments.Add_Appointment_Form.Inputs.Patient_Age.Errors.Min"
				)
			)
			.max(
				125,
				translation(
					"Appointments.Add_Appointment_Form.Inputs.Patient_Age.Errors.Max"
				)
			),
		email: yup.string().email(),
		insurance: yup.string(),
		emergencyPhone: phoneValidation(
			translation(
				"Appointments.Add_Appointment_Form.Inputs.Patient_Phone.Errors.Invalid_Phone"
			),
			true
		),
	});
};
