import { createSlice } from "@reduxjs/toolkit";
import { PatientBasicInfo } from "src/@types";
import {
	addNewPatient,
	fetchPatients,
	updatePatient,
	deletePatient,
} from "./patientsAsyncActions";
import { RootState } from "../../store/store";

interface InitialState {
	loading: boolean;
	addingLoading: boolean;
	updatingLoading: boolean;
	deleteLoading: boolean;
	patients: { [id: number]: PatientBasicInfo } | null;
	errorMsg: string;
}

const initialState: InitialState = {
	loading: true,
	addingLoading: false,
	updatingLoading: false,
	deleteLoading: false,
	patients: null,
	errorMsg: "",
};

const patientSlice = createSlice({
	name: "patients",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPatients.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchPatients.rejected, (state, action) => {
			state.errorMsg = action.payload as string;
			state.loading = false;
		});

		builder.addCase(fetchPatients.fulfilled, (state, action) => {
			state.patients = action.payload.reduce(
				(obj, patient) => ({
					...obj,
					[patient.id]: patient,
				}),
				{}
			);
			state.loading = false;
		});

		builder.addCase(addNewPatient.pending, (state) => {
			state.addingLoading = true;
		});

		builder.addCase(addNewPatient.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.addingLoading = false;
		});

		builder.addCase(addNewPatient.fulfilled, (state, action) => {
			if (state.patients) {
				state.patients = Object.assign(state.patients, {
					[action.payload.id]: action.payload,
				});
				state.addingLoading = false;
			}
		});

		builder.addCase(updatePatient.pending, (state) => {
			state.updatingLoading = true;
		});

		builder.addCase(updatePatient.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.updatingLoading = false;
		});

		builder.addCase(updatePatient.fulfilled, (state, action) => {
			if (state.patients) {
				state.patients = Object.assign(state.patients, {
					[action.payload.id]: action.payload,
				});
				state.updatingLoading = false;
			}
		});

		builder.addCase(deletePatient.pending, (state) => {
			state.deleteLoading = true;
		});

		builder.addCase(deletePatient.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.deleteLoading = false;
		});

		builder.addCase(deletePatient.fulfilled, (state, action) => {
			if (state.patients) {
				delete state.patients[action.payload];
				state.deleteLoading = false;
			}
		});
	},
});

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const patientsSelector = (state: RootState) => state.patients;

export const patientsReducer = patientSlice.reducer;
