import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	fetchEvents,
	addEvent,
	updateEventStatus,
	updateEvent,
	updateEventResizing,
	updateEventMoving,
	deleteEvent,
} from "./eventsAsyncActions";
import { AppointmentFetched, Event } from "src/@types";

interface InitialState {
	loading: boolean;
	editingLoading: boolean;
	deleteLoading: boolean;
	addingLoading: boolean;
	events: Event[];
	selectedEvent: Event | null;
	errorMsg: string;
	start: string | null;
	end: string | null;
}

const initialState: InitialState = {
	loading: false,
	addingLoading: false,
	editingLoading: false,
	deleteLoading: false,
	events: [],
	selectedEvent: null,
	errorMsg: "",
	start: null,
	end: null,
};

const eventsSlice = createSlice({
	name: "events",
	initialState,
	reducers: {
		addAppointmentFromSlots: (
			state: InitialState,
			action: PayloadAction<{ start: string; end: string }>
		) => {
			state.start = action.payload.start;
			state.end = action.payload.end;
		},

		addSelectableEvent: (state: InitialState, action: PayloadAction<any>) => {
			const event = action.payload;

			event.title = "NEW_EVENT_ADDED_FROM_SLOTS";

			state.events.push(action.payload);
		},

		cancelAddingFromSlots: (state: InitialState) => {
			const newEventIndex = state.events.findIndex(
				(event) => event.title === "NEW_EVENT_ADDED_FROM_SLOTS"
			);

			if (newEventIndex >= 0) {
				state.events.splice(newEventIndex, 1);
			}
		},

		selectedEvent: (state: InitialState, action: PayloadAction<Event>) => {
			state.selectedEvent = action.payload;
		},

		removeSelectedEvent: (state: InitialState) => {
			state.selectedEvent = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchEvents.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchEvents.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.loading = false;
		});

		builder.addCase(fetchEvents.fulfilled, (state, action) => {
			const { data, patients, dentists } = action.payload;
			let isFetchedBefore = false;
			const mappedEvents: Event[] = data.map((event: AppointmentFetched) => {
				state.events.forEach((old) => {
					if (old.resource?.id === event.id) {
						isFetchedBefore = true;

						return;
					}
				});
				const allDay = false;

				return {
					title: event.attendance,
					start: event.starts,
					end: event.ends,
					allDay: allDay,
					resource: {
						id: event.id,
						patient: patients ? patients[event.patientId] : undefined,
						dentist: dentists ? dentists[event.dentist] : undefined,
						status: event.status,
						type: event.type,
						clinic: event.clinic,
						updates: event.updates,
						notes: event.notes,
						attendance: event.attendance,
						token: event.token,
					},
				};
			});

			if (!isFetchedBefore) {
				state.events = state.events.concat(mappedEvents);
			}
			state.loading = false;
		});

		builder.addCase(addEvent.pending, (state, action) => {
			state.addingLoading = true;
		});

		builder.addCase(addEvent.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.addingLoading = false;
		});

		builder.addCase(addEvent.fulfilled, (state, action) => {
			const { patients, dentists } = action.payload;
			const {
				attendance,
				starts,
				ends,
				id,
				patientId,
				dentist,
				status,
				type,
				clinic,
				updates,
				notes,
				token,
			} = action.payload.data;
			const newEvent: Event = {
				title: attendance,
				start: starts,
				end: ends,
				resource: {
					attendance,
					starts,
					ends,
					id,
					patient: patients ? patients[patientId] : undefined,
					dentist: dentists ? dentists[dentist] : undefined,
					status,
					type,
					clinic,
					updates,
					notes,
					token,
				},
			};
			/* if added from selectable slots */
			const newEventFromSlots = state.events.find(
				(event) => event.title === "NEW_EVENT_ADDED_FROM_SLOTS"
			);

			if (newEventFromSlots) {
				newEventFromSlots.title = attendance;
				newEventFromSlots.start = starts;
				newEventFromSlots.end = ends;
				newEventFromSlots.resource = {
					attendance,
					starts,
					ends,
					id,
					patient: patients ? patients[patientId] : undefined,
					dentist: dentists ? dentists[dentist] : undefined,
					updates,
					status,
					type,
					clinic,
					notes,
					token,
				};
			} else {
				state.events.push(newEvent);
			}
			state.addingLoading = false;
		});

		builder.addCase(updateEventStatus.pending, (state, action) => {
			state.loading = false;
		});

		builder.addCase(updateEventStatus.rejected, (state, action) => {
			state.errorMsg = action.payload?.msg || "";
			state.loading = false;
		});

		builder.addCase(updateEventStatus.fulfilled, (state, action) => {
			state.events.forEach((existingEvent) => {
				if (existingEvent.resource?.id === action.payload.eventId) {
					existingEvent.resource.status = action.payload.status;
					// existingEvent.resource.updates.splice(0, existingEvent.resource.updates.length)
					// existingEvent.resource.updates.concat(action.payload.updates)
				}
			});
			if (state.selectedEvent && state.selectedEvent.resource) {
				state.selectedEvent.resource.status = action.payload.status;
				// state.selectedEvent.resource.updates.splice(0, state.selectedEvent.resource.updates.length)
				// state.selectedEvent.resource.updates.concat(action.payload.updates)
			}
		});

		builder.addCase(updateEvent.pending, (state, action) => {
			state.editingLoading = true;
		});

		builder.addCase(updateEvent.rejected, (state, action) => {
			state.editingLoading = false;
			state.errorMsg = action.payload?.msg || "";
		});

		builder.addCase(updateEvent.fulfilled, (state, action) => {
			state.editingLoading = false;
			const {
				attendance,
				starts,
				ends,
				dentist,
				id,
				patientId,
				updates,
				type,
				status,
				clinic,
				notes,
				token,
			} = action.payload.data;
			const { patients, dentists } = action.payload;

			state.events.forEach((event) => {
				if (event.resource?.id === action.payload.eventId) {
					event.title = attendance;
					event.start = starts;
					event.end = ends;
					event.resource = {
						attendance,
						starts,
						ends,
						id,
						patient: patients ? patients[patientId] : undefined,
						dentist: dentists ? dentists[dentist] : undefined,
						updates,
						type,
						status,
						clinic,
						notes,
						token,
					};
				}
			});
		});

		builder.addCase(updateEventResizing.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(updateEventResizing.rejected, (state, action) => {
			state.errorMsg = action.payload?.error?.msg || "";
			state.events = action.payload?.oldEvents as Event[];
			state.loading = false;
		});

		builder.addCase(updateEventResizing.fulfilled, (state, action) => {
			state.loading = false;
			state.events.forEach((existingEvent) => {
				if (existingEvent.resource?.id === action.payload.event?.resource?.id) {
					existingEvent.start = action.payload.start as any;
					existingEvent.end = action.payload.end as any;
				}
			});
		});

		builder.addCase(updateEventMoving.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(updateEventMoving.rejected, (state, action) => {
			state.errorMsg = action.payload?.error?.msg || "";
			state.events = action.payload?.oldEvents as Event[];
			state.loading = false;
		});

		builder.addCase(updateEventMoving.fulfilled, (state, action) => {
			state.loading = false;
			state.events.forEach((existingEvent) => {
				if (
					existingEvent.resource?.id === action.payload?.event?.resource?.id
				) {
					existingEvent.start = action.payload.start as any;
					existingEvent.end = action.payload.end as any;
					existingEvent.allDay = false;
				}
			});
		});

		builder.addCase(deleteEvent.pending, (state, action) => {
			state.deleteLoading = true;
		});

		builder.addCase(deleteEvent.rejected, (state, action) => {
			state.deleteLoading = false;
			state.errorMsg = action.payload?.msg || "";
		});

		builder.addCase(deleteEvent.fulfilled, (state, action) => {
			state.deleteLoading = false;
			const deletedEventIndex = state.events.findIndex(
				(event) => event.resource?.id === action.payload
			);

			if (deletedEventIndex >= 0) {
				state.events.splice(deletedEventIndex, 1);
			}
		});
	},
});

export const eventsActions = eventsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo

export const eventsReducer = eventsSlice.reducer;
