import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpenseOtherCategory } from "../../../@types/Expenses.type";

export interface ExpensesOtherCategoriesState {
	other_categories: ExpenseOtherCategory[];
}

const initialState: ExpensesOtherCategoriesState = {
	other_categories: [],
};

const expenseOtherCategorySlice = createSlice({
	name: "expenses-other-categories",
	initialState,
	reducers: {
		setExpensesCategories(
			state,
			{ payload }: PayloadAction<ExpenseOtherCategory[]>
		) {
			state.other_categories = payload;
		},
	},
});

export const { setExpensesCategories } = expenseOtherCategorySlice.actions;

export const expensesOtherCategoriesReducer = expenseOtherCategorySlice.reducer;
