import { WritableDraft } from "immer/dist/internal";
import { PatientProfileState } from "../patientProfileSlice";

export const diagnosesReducers = {
	createDiagnoseSuccess: (state: WritableDraft<PatientProfileState>) => {
		state.currentScreen = "DIAGNOSIS";
		state.selectedTeeth = {};
		state.isUpperBarOpen = false;
		state.isUpperArchSelected = false;
		state.isLowerArchSelected = false;
	},
};
