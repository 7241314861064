import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { FieldError, UseFormTrigger } from "react-hook-form";
import ar from "date-fns/locale/ar-SA";
import en from "date-fns/locale/en-US";
import { ClockIcon } from "src/components";

type TimeInputProps = {
	id: string;
	value: Date | null | undefined;
	onChange: (...event: any[]) => void;
	onBlur?: (...event: any[]) => void;
	trigger?: UseFormTrigger<any>;
	error?: FieldError | undefined;
	withIcon?: boolean | undefined;
	minTime?: Date | undefined;
	maxTime?: Date | undefined;
	readOnly?: boolean | undefined;
	inputSize?: "sm" | "md";
	variant?: "standard" | "filled";
	placeholder?: string;
	timeIntervals?: number;
};

export const TimeInput = ({
	id,
	value,
	onChange,
	error,
	onBlur,
	trigger,
	withIcon,
	minTime,
	maxTime,
	readOnly,
	inputSize,
	variant,
	placeholder,
	timeIntervals,
}: TimeInputProps) => {
	const { i18n } = useTranslation();

	const renderSize = () => {
		switch (inputSize) {
			case "sm":
				return "py-2";
			case "md":
				return "py-3";
			default:
				return "py-2";
		}
	};

	const renderVariant = () => {
		switch (variant) {
			case "standard":
				return "input--standard";
			case "filled":
				return "input--filled";
			default:
				return "input--standard";
		}
	};

	return (
		<div className="relative">
			{withIcon && (
				<span
					className={`
						pointer-events-none absolute top-1/2 z-10 -translate-y-1/2 text-muted start-0
						${error && error.message ? "text-red2" : "text-muted"}
					`}
				>
					<ClockIcon width="18" height="18" />
				</span>
			)}

			<ReactDatePicker
				selected={value}
				onChange={(date) => {
					onChange(date);
					if (trigger) trigger(id);
				}}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={timeIntervals}
				timeCaption="Time"
				dateFormat="h:mm aa"
				locale={i18n.language === "ar" ? ar : en}
				className={`
					input font-cairo
					${withIcon ? "ps-6" : "text-center"}  
					${renderVariant()} ${renderSize()}
					${error && error.message ? "input--error" : ""}
					${readOnly ? "border-none focus:border-0" : " cursor-pointer"}
				`}
				onClickOutside={() => onBlur && onBlur()}
				popperPlacement="bottom-end"
				tabIndex={1}
				placeholderText={placeholder || "00:00"}
				onBlur={() => {
					if (onBlur && trigger) {
						trigger(id);
						onBlur();
					}
				}}
				minTime={minTime}
				maxTime={maxTime}
				readOnly={readOnly}
				autoComplete="nope"
			/>
		</div>
	);
};
