import { Event, EventPropGetter } from "react-big-calendar";
import { getformates } from "src/constants/index";

export const eventPropGetter: EventPropGetter<Event> | undefined = (
	e,
	start,
	end,
	isSelected
) => {
	const classes = `shadow-md border-none ${
		getformates(e.resource?.dentist?.formates).bg
	} ${getformates(e.resource?.dentist?.formates).text}`;
	const style = {
		color: isSelected ? "#fff" : "",
		border: "none",
		boxShadow:
			"0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
	};

	return { className: classes, style: style };
};
