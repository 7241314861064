import { base, getSharedRoutes, getAssistantAndDentist } from "./global";
import { getPatientRoutes } from "./patientRoutes";
import { getSettingsRoutes } from "./settingsRoutes";

const dentistBase = base + "/dentist";

export const DENTIST = {
	BASE: dentistBase,
	HOME: dentistBase + "/home",

	...getPatientRoutes(dentistBase),
	...getSharedRoutes(dentistBase),
	...getAssistantAndDentist(dentistBase),
	...getSettingsRoutes(dentistBase),
};
