import { Style } from "src/@types";

type TitleProps = {
	children: React.ReactNode;
	subtitle?: string;
	styles?: { container?: Style; title?: Style; subtitle?: Style };
};

export function Title({ children, subtitle, styles }: TitleProps) {
	return (
		<div
			className={styles?.container?.className}
			style={styles?.container?.style}
		>
			<h3
				className={`
					mb-1.5 text-base font-semibold leading-5 text-[#000000] 2xl:text-lg 3xl:text-xl
					${styles?.title?.className}
				`}
				style={styles?.title?.style}
			>
				{children}
			</h3>
			<p
				className={`
					font-poppins text-xs leading-[0.875rem] text-[#919699]
					${styles?.subtitle?.className}
				`}
				style={styles?.subtitle?.style}
			>
				{subtitle}
			</p>
		</div>
	);
}
