import { useState } from "react";
import { ToolbarProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, schedulerActions } from "src/redux";
import ReactTooltip from "react-tooltip";
import {
	DotCircle,
	BtnWithIcon,
	CalendarIcon,
	ChevronLeftIcon,
	FilterIcon,
	PlusIcon,
	PrinterIcon,
	ChevronRightIcon,
	Popup,
	PrintPopup,
} from "src/components";

type CustomToolbarProps = ToolbarProps & {
	openFilterPopup: () => void;
	filtersCount: number;
};

export const Toolbar = (props: CustomToolbarProps) => {
	const { t, i18n } = useTranslation("scheduler");
	const [dayToPrintPopup, setDayToPrintPopup] = useState(false);
	const dispatch = useAppDispatch();
	const { isAddAppointmentOpen } = useAppSelector((state) => state.scheduler);

	const handlePrint = () => {
		setDayToPrintPopup(true);
	};

	return (
		<>
			<div className="absolute top-0 left-0 z-10 flex w-full items-center justify-between bg-white px-3 py-2 print:justify-center">
				{!isAddAppointmentOpen && (
					<BtnWithIcon
						variant="primary"
						icon={<PlusIcon />}
						rounded="rounded-lg"
						size="md"
						className="flex-shrink-0 px-4 text-sm"
						onClick={() =>
							dispatch(schedulerActions.openAddAppointmentModal({}))
						}
					>
						{t("Scheduler.Toolbar.Add_Appointment")}
					</BtnWithIcon>
				)}

				<div className="align-center ">
					<ReactTooltip id="prev" effect="solid" eventOff="click" />

					<button
						className="cursor-pointer p-3 text-darkBlue me-1"
						onClick={() => props.onNavigate("PREV")}
						data-tip={t("Scheduler.Toolbar.Previous")}
						data-for="prev"
					>
						{i18n.dir() === "rtl" ? (
							<ChevronRightIcon width="24" height="24" />
						) : (
							<ChevronLeftIcon width="24" height="24" />
						)}
					</button>

					<div className="align-center center w-auto">
						<ReactTooltip id="today" effect="solid" eventOff="click" />

						<span
							className="cursor-pointer text-darkBlue"
							data-tip={`Navigate to current ${
								props.view === "day" ? "day" : "week"
							}`}
							data-for="today"
							onClick={() => props.onNavigate("TODAY")}
						>
							<CalendarIcon width="28" height="28" />
						</span>

						<h3 className="min-w-[250px] text-center text-xl font-semibold text-dark">
							{props.date.toLocaleDateString(i18n.language, {
								weekday: "short",
								year: "numeric",
								month: "long",
								day: "numeric",
							})}
						</h3>
					</div>

					<ReactTooltip id="next" effect="solid" eventOff="click" />

					<button
						className="cursor-pointer p-3 text-darkBlue ms-1"
						onClick={() => props.onNavigate("NEXT")}
						data-tip={t("Scheduler.Toolbar.Next")}
						data-for="next"
					>
						{i18n.dir() === "rtl" ? (
							<ChevronLeftIcon width="24" height="24" />
						) : (
							<ChevronRightIcon width="24" height="24" />
						)}
					</button>
				</div>

				<div className="flex items-center">
					<div className="align-center me-12">
						<button
							type="button"
							className="cursor-pointer text-muted me-4"
							onClick={handlePrint}
							data-tip={t("Scheduler.Toolbar.Print")}
							data-for="print"
						>
							<PrinterIcon width={24} height={24} />
						</button>

						<ReactTooltip id="print" effect="solid" eventOff="click" />

						<button
							className="relative cursor-pointer text-muted"
							onClick={props.openFilterPopup}
							data-tip={t("Scheduler.Toolbar.Filter")}
							data-for="filter"
						>
							<FilterIcon width={24} height={24} />
							{props.filtersCount > 0 && (
								<DotCircle style={{ top: "-8px", right: "-4px" }}>
									{props.filtersCount}
								</DotCircle>
							)}
						</button>

						<ReactTooltip id="filter" effect="solid" />
					</div>

					<div className="flex-shrink-0 overflow-hidden rounded-lg bg-background">
						<button
							className={`3xl:text-md cursor-pointer py-2 px-3 text-sm font-semibold ${
								props.view === "day"
									? "bg-lightBlue text-darkBlue"
									: "bg-transparent text-gray-600"
							}`}
							onClick={() => props.onView("day")}
						>
							{t("Scheduler.Toolbar.Day_View")}
						</button>

						<button
							className={`3xl:text-md cursor-pointer py-2 px-3 text-sm font-semibold ${
								props.view === "week"
									? "bg-lightBlue text-darkBlue"
									: "bg-transparent text-gray-600"
							}`}
							onClick={() => props.onView("week")}
						>
							{t("Scheduler.Toolbar.Week_View")}
						</button>
					</div>
				</div>
			</div>

			<Popup show={dayToPrintPopup} close={() => setDayToPrintPopup(false)}>
				<PrintPopup close={() => setDayToPrintPopup(false)} />
			</Popup>
		</>
	);
};
