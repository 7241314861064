import { useTranslation } from "react-i18next";
import { Dentist } from "src/@types";
import { CustomCheckBox, DentistNameColor, Popup } from "src/components";
import { useAppSelector } from "src/redux";
import { FilterType } from "../../Calendar";

type DentistsPopupProps = {
	selectedOption: FilterType | null;
	setSelectedOption: React.Dispatch<React.SetStateAction<FilterType | null>>;
	setFilterPopup: (value: React.SetStateAction<boolean>) => void;
	filteredDentists: string[];
	setFilteredDentists: React.Dispatch<React.SetStateAction<string[]>>;
};

export function DentistsPopup({
	selectedOption,
	setFilterPopup,
	setSelectedOption,
	filteredDentists,
	setFilteredDentists,
}: DentistsPopupProps) {
	const { t } = useTranslation("scheduler");
	const { dentists } = useAppSelector((s) => s.dentists);

	const handleDentistsCheck = (
		e: React.ChangeEvent<HTMLInputElement>,
		dentistId: number
	) => {
		if (filteredDentists.includes(dentistId.toString())) {
			setFilteredDentists((prev) =>
				prev.filter((filterId) => dentistId.toString() !== filterId)
			);
		} else {
			setFilteredDentists((prev) => prev.concat(dentistId.toString()));
		}
	};

	return (
		<Popup
			show={selectedOption === FilterType.Dentist}
			close={() => {
				setSelectedOption(null);
				setFilterPopup(false);
			}}
		>
			<div className="py-4 px-4 " style={{ minHeight: "50vh" }}>
				{dentists ? (
					Object.values(dentists).map((dentist: Dentist) => (
						<div key={dentist.id} className="mb-2 flex items-center">
							<CustomCheckBox
								className="me-2 "
								id={dentist.id.toString()}
								checked={filteredDentists.includes(dentist.id.toString())}
								name="selectedDentist"
								value={dentist.id.toString()}
								onChange={(e) => handleDentistsCheck(e, dentist.id)}
							/>
							<DentistNameColor dentist={dentist} />
						</div>
					))
				) : (
					<div className="mt-4 text-center font-semibold text-dark">
						{t("Scheduler.Messages.No_Available_Dentists")}
					</div>
				)}
			</div>
		</Popup>
	);
}
