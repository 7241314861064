import { useEffect } from "react";
import { useAppDispatch } from "src/redux";

export function useReduxAsync(asyncAction: any) {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const promise = dispatch(asyncAction);

		return () => promise.abort();
	}, [dispatch]);
}
