import { PatientFileDiagnoseType } from "src/@types";
import { updatePlan } from "../plan";

type DeleteDiagnoseParamsType = {
	diagnosisId: PatientFileDiagnoseType["id"];
};

export const deleteDiagnose = ({ diagnosisId }: DeleteDiagnoseParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			return {
				...planToUpdate,
				diagnoses: planToUpdate.diagnoses.filter(
					(diagnose) => diagnose.id !== diagnosisId
				),
			};
		},
	});
};
