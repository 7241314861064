export const BellIcon = () => {
	return (
		<svg
			width="32"
			height="31"
			viewBox="0 0 32 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.7185 10.2988C23.7185 8.25027 22.9048 6.28561 21.4562 4.83705C20.0076 3.3885 18.043 2.57471 15.9944 2.57471C13.9458 2.57471 11.9812 3.3885 10.5326 4.83705C9.08406 6.28561 8.27027 8.25027 8.27027 10.2988C8.27027 19.3103 4.4082 21.885 4.4082 21.885H27.5806C27.5806 21.885 23.7185 19.3103 23.7185 10.2988Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.2218 27.0345C17.9955 27.4247 17.6706 27.7485 17.2798 27.9736C16.8889 28.1988 16.4458 28.3173 15.9947 28.3173C15.5436 28.3173 15.1005 28.1988 14.7096 27.9736C14.3188 27.7485 13.9939 27.4247 13.7676 27.0345"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const BigCheckMark = () => {
	return (
		<svg
			width="207"
			height="201"
			viewBox="0 0 207 201"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_758_1402)">
				<path
					d="M179.951 18.1782L188.755 26.9825L81.0807 134.657L48.124 101.7L56.9283 92.8957L72.2765 108.244L81.0807 117.048L89.8849 108.244L179.951 18.1782ZM179.951 0.569824L81.0807 99.4397L56.9283 75.2873L30.5156 101.7L81.0807 152.265L206.363 26.9825L179.951 0.569824Z"
					fill="#1FAD66"
				/>
				<path
					d="M99.76 200.57C44.8302 200.57 0.136719 155.876 0.136719 100.947C0.136719 46.0168 44.8302 1.32324 99.76 1.32324C108.539 1.32324 117.481 2.56231 126.328 5.0093L123.01 17.0139C115.239 14.8658 107.419 13.7762 99.76 13.7762C51.6918 13.7762 12.5896 52.8783 12.5896 100.947C12.5896 149.015 51.6918 188.117 99.76 188.117C147.828 188.117 186.93 149.015 186.93 100.947C186.93 93.288 185.841 85.4676 183.693 77.7032L195.697 74.3845C198.144 83.2261 199.383 92.1672 199.383 100.947C199.383 155.876 154.69 200.57 99.76 200.57Z"
					fill="#1FAD66"
				/>
			</g>
			<defs>
				<clipPath id="clip0_758_1402">
					<rect
						width="206.226"
						height="200"
						fill="white"
						transform="translate(0.136719 0.569824)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
