import { Fragment } from "react";
import { CheckIcon } from "src/components/Svg";
import { Screens } from "../InfoProcess";
import style from "./Progress.module.css";

type ProgressProps = {
	currentScreen: Screens;
};

export function Progress({ currentScreen }: ProgressProps) {
	const steps = [
		{ name: "Get started", screen: Screens.GET_STARTED },
		{ name: "Personal info", screen: Screens.PERSONAL_INFO },
		{ name: "Clinic info", screen: Screens.CLINIC_INFO },
	];

	const stepsList = steps.map((step, index) => {
		const isCurrent = step.screen === currentScreen;
		const isDone = step.screen < currentScreen;

		return (
			<Fragment key={index}>
				<div className={style.step}>
					<span
						className={`
							${style.number}
							${isCurrent ? style.number_current : ""}
							${isDone ? style.number_done : ""}
						`}
					>
						<CheckIcon width={20} height={20} />
					</span>
					<h3
						className={`
							${style.name}
							${isCurrent || isDone ? style.name_current : ""}
						`}
					>
						{step.name}
					</h3>
				</div>

				{index !== steps.length - 1 && (
					<div className={style.line_container}>
						<div
							className={`${style.line} ${isDone ? style.line_active : ""}`}
						/>
					</div>
				)}
			</Fragment>
		);
	});

	return <section className={style.container}>{stepsList}</section>;
}
