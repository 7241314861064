import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
	Btn,
	ChevronLongLeftIcon,
	Input,
	CustomPhoneInput,
	WorkingHours,
} from "src/components";
import { phoneValidation } from "src/utils";
import { Note } from "./Note/Note";
import { useAxios } from "src/hooks";
import {
	updateClinicSettings,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import { convertHourToFullDate } from "src/features";
import { ClinicSettingsUpdateType } from "src/@types";
import moment from "moment";
import { toast } from "react-toastify";

const schema = yup.object().shape({
	clinicName: yup.string().required(),
	clinicSize: yup.number().required(),
	clinicPhone: phoneValidation("Invalid phone number"),
	clinicAddress: yup.string().required(),
	workStarts: yup.date().default(null).required("Required").nullable(),
	workEnds: yup
		.date()
		.default(null)
		.required("Required")
		.nullable()
		.min(yup.ref("workStarts"), "End time can't be before Start Time"),
});

type FormValues = {
	clinicName: string;
	clinicSize: number;
	clinicPhone: string;
	clinicAddress: string;
	workStarts: Date;
	workEnds: Date;
};

type ClinicInfoScreenProps = {
	goToPersonalInfo: () => void;
	finishOnBoarding: () => void;
};

export function ClinicInfoScreen({
	goToPersonalInfo,
	finishOnBoarding,
}: ClinicInfoScreenProps) {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((s) => s.auth);

	const { makeRequest } = useAxios<any, ClinicSettingsUpdateType>({
		runNow: false,
		configs: { method: "PUT" },
	});

	const {
		register,
		control,
		trigger,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<FormValues>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: {
			clinicName: user?.admin?.clinicName,
			clinicPhone: "",
			clinicAddress: "",
			clinicSize: 0,
			workStarts: moment().hour(12).minute(0).toDate(),
			workEnds: moment().hour(22).minute(0).toDate(),
		},
	});

	const onSubmit = (data: FormValues) => {
		const body: ClinicSettingsUpdateType = {
			address: data.clinicAddress,
			clinics: data.clinicSize,
			name: data.clinicName,
			phone: data.clinicPhone,
			workEnds: moment(data.workEnds).hour(),
			workStarts: moment(data.workStarts).hour(),
		};

		makeRequest({
			configs: { url: "/clinic/settings", data: body },
			handleSuccess: () => {
				dispatch(
					updateClinicSettings({
						clinics: data.clinicSize,
						clinicName: data.clinicName,
						clinicAddress: data.clinicAddress,
						phone: data.clinicPhone,
						workEnds: moment(data.workEnds).hour(),
						workStarts: moment(data.workStarts).hour(),
					})
				);

				makeRequest({
					configs: { url: "/complete-onboarding", method: "POST" },
					handleSuccess: () => {
						finishOnBoarding();
						toast.success(
							"Onboarding completed successfully, Welcome to BeClarified"
						);
					},
				});
			},
		});
	};

	return (
		<section className="mt-24">
			<header className="mb-16 text-center">
				<h2 className="mb-2 text-2xl font-semibold leading-[1.875rem] text-dark">
					Let&apos;s setup<span className="text-primary "> Dentallap </span>
					settings
				</h2>

				<p className="text-base font-semibold leading-[1.875rem] text-[#828282]">
					You can edit this information anytime.
				</p>
			</header>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-6">
					<Input
						type="text"
						register={register}
						name="clinicName"
						id="clinicName"
						error={errors.clinicName}
						placeholder="Clinic name"
						variant="filled"
						inputSize="lg"
					/>
				</div>

				<div className="mb-6">
					<Input
						type="number"
						register={register}
						name="clinicSize"
						id="clinicSize"
						error={errors.clinicSize}
						placeholder="Clinic size"
						variant="filled"
						inputSize="lg"
					/>
				</div>

				{/* Logo */}

				<div className="mb-6">
					<Controller
						control={control}
						name="clinicPhone"
						render={({
							field: { onChange, onBlur, value },
							fieldState: { error },
						}) => (
							<CustomPhoneInput
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								error={error}
								variant="filled"
								label="Phone"
								requiredStar
							/>
						)}
					/>
				</div>

				<div className="mb-6">
					<Input
						type="text"
						register={register}
						name="clinicAddress"
						id="clinicAddress"
						error={errors.clinicAddress}
						placeholder="Clinic address"
						variant="filled"
						inputSize="lg"
					/>

					<Note note="It'll be used in prescriptions, invoices or any printed	documents." />
				</div>

				<div className="mb-10">
					<WorkingHours
						control={control}
						trigger={trigger}
						errors={errors}
						noLabel={true}
					/>

					<Note note="It'll be used in visits timetable." />
				</div>

				<div className="flex items-center justify-between gap-4">
					<button type="button" onClick={goToPersonalInfo}>
						<ChevronLongLeftIcon />
					</button>

					<Btn
						type="submit"
						variant="primary"
						size="md"
						className={`
							!px-10
							${!isValid ? "cursor-not-allowed opacity-50" : ""}
						`}
						disabled={!isValid}
					>
						Save
					</Btn>
				</div>
			</form>
		</section>
	);
}
