import { PatientFilePlanWithoutIdType, PatientFileStageType } from "src/@types";
import { patientProfileActions } from "../../patientProfileSlice";
import { updatePlan } from "../plan";

type DeleteStageParamsType = {
	stageId?: PatientFileStageType["id"];
};

export const deleteStage = ({ stageId }: DeleteStageParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate) => {
			const updatedPlan: PatientFilePlanWithoutIdType = {
				...planToUpdate,
				procedures: {
					stages: planToUpdate.procedures.stages.filter(
						(stage) => stage.id !== stageId
					),
				},
			};

			return updatedPlan;
		},
		handleSuccess(plan, dispatch) {
			dispatch(patientProfileActions.deleteStageSuccess());
		},
	});
};
