import moment from "src/config/moment";
import { useTranslation } from "react-i18next";
import {
	DeletePopup,
	NoNotes,
	PlusIcon,
	TrashIcon,
	useDeletePopup,
} from "src/components";
import { useAppSelector } from "src/redux";
import { useToggle } from "src/hooks";
import { AddNote } from "./AddNote/AddNote";
import { NoteCreateType, NoteType } from "src/@types";
import { getTime } from "src/features";

export type NotesHandlerProps = {
	notes: NoteType[];
	addNote: (element: NoteCreateType) => void;
	deleteNote: (noteId: NoteType["id"]) => void;
};

type DeleteNoteDataType = {
	noteId: NoteType["id"];
};

export const NotesHandler = ({
	notes,
	addNote,
	deleteNote,
}: NotesHandlerProps) => {
	const { t } = useTranslation("global");
	const { users } = useAppSelector((s) => s.auth);
	const { deletePopup, closeDeletePopup, openDeletePopup } =
		useDeletePopup<DeleteNoteDataType>();
	const [isAddNoteOpen, toggleAddNote] = useToggle(false);

	const handleDeleteNote = () => {
		if (deletePopup.deleteData) {
			deleteNote(deletePopup.deleteData?.noteId);
			closeDeletePopup();
		}
	};

	return (
		<>
			<div>
				{notes.length !== 0 && (
					<div
						className="mb-4 w-full rounded-lg bg-nativeWhite py-4 px-4"
						onClick={toggleAddNote}
					>
						<button className="center w-full rounded-lg bg-gray-200 py-4 text-primary shadow">
							<span className="me-3">
								<PlusIcon />
							</span>
							<span className="font-semibold">
								{t("Notes_Handler.Add_Note")}
							</span>
						</button>
					</div>
				)}

				{isAddNoteOpen && (
					<AddNote addNote={addNote} toggleAddNote={toggleAddNote} />
				)}

				{notes.map((note, i) => (
					<div
						key={note.id}
						className="relative mb-4 flex h-24  flex-col justify-between rounded-lg bg-nativeWhite p-4  "
					>
						<button
							className="absolute top-3 text-muted end-3 hover:text-red3 hover:opacity-80"
							onClick={() => openDeletePopup({ noteId: note.id })}
						>
							<TrashIcon width={20} height={20} />
						</button>

						<p className="truncate">{note.note}</p>

						<p
							className="text-sm font-semibold text-muted"
							title={`${moment(new Date(note.createdAt))}`}
						>
							{t("Notes_Handler.Add_By", {
								name: users?.[note.createdBy]?.name,
								date: getTime(new Date(note.createdAt)),
							})}
						</p>
					</div>
				))}

				<DeletePopup
					isOpen={deletePopup.isOpen}
					closeDeletePopup={closeDeletePopup}
					onDelete={() => handleDeleteNote()}
					desc={t("Notes_Handler.Delete_Note_Confirm")}
				/>
			</div>

			{notes.length === 0 && (
				<div className="py-7">
					<NoNotes clickHandler={() => toggleAddNote(true)} />
				</div>
			)}
		</>
	);
};
