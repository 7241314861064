import { NoData } from "./NoData";
import noReportImage from "src/assets/svg/no-report.svg";

export function NoReports() {
	return (
		<NoData
			notFoundMsg="Find a Report"
			desc="Select a period to generate a detailed report for this period"
			imgAlt="No Report"
			imgSrc={noReportImage}
			styles={{
				imgContainer: { className: "mb-2.5" },
				desc: { className: "capitalize w-[300px] text-center text-muted" },
			}}
		/>
	);
}
