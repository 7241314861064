export enum LabOrderStatusEnum {
	ASSIGNED = 0,
	WAITING = 1,
	RECEIVED = 2,
	RE_ASSIGNED = 3,
	DELIVERED = 4,
}

export const getLabOrderStatusName = (status: LabOrderStatusEnum) => {
	switch (status) {
		case LabOrderStatusEnum.ASSIGNED:
			return "Assigned";

		case LabOrderStatusEnum.DELIVERED:
			return "Delivered";

		case LabOrderStatusEnum.RE_ASSIGNED:
			return "Reassigned";

		case LabOrderStatusEnum.WAITING:
			return "Waiting";

		case LabOrderStatusEnum.RECEIVED:
			return "Received";

		default:
			return "-";
	}
};

export const getLabOrderStatusColor = (status: LabOrderStatusEnum) => {
	switch (status) {
		case LabOrderStatusEnum.ASSIGNED:
			return "#046289";

		case LabOrderStatusEnum.DELIVERED:
			return "#21913E";

		case LabOrderStatusEnum.RE_ASSIGNED:
			return "#C23A47";

		case LabOrderStatusEnum.WAITING:
			return "#F3A72E";

		case LabOrderStatusEnum.RECEIVED:
			return "#0F897B";

		default:
			return "#000";
	}
};
