import { RootState } from "../../store/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dentist, DentistDictionary } from "src/@types";
import { colorPlates } from "src/constants/index";
import axios from "src/config/axios";

export const fetchDentists = createAsyncThunk<
	Dentist[],
	void,
	{
		rejectValue: string;
	}
>("dentists/fetchDentists", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get("dentists", { signal: thunkAPI.signal });

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

interface InitialState {
	loading: boolean;
	dentists: DentistDictionary | null;
	dentistsArray: Dentist[] | null;
	errorMsg: string;
}

const initialState: InitialState = {
	loading: true,
	dentists: null,
	dentistsArray: null,
	errorMsg: "",
};

const dentistSlice = createSlice({
	name: "dentists",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDentists.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchDentists.rejected, (state, action) => {
			state.errorMsg = action.payload as string;
			state.loading = false;
		});

		builder.addCase(fetchDentists.fulfilled, (state, action) => {
			const totalColorPlates = colorPlates.length;
			const dentists = action.payload.sort((a, b) => a.id - b.id);

			state.dentists = dentists.reduce((acc, dentist, index) => {
				const color = colorPlates[index % totalColorPlates];

				acc[dentist.id] = {
					...dentist,
					formates: color,
				};

				return acc;
			}, {} as DentistDictionary);

			state.dentistsArray = dentists;
			state.loading = false;
		});
	},
});

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const dentistsSelector = (state: RootState) => state.dentists;

export const dentistsReducer = dentistSlice.reducer;
