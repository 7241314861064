import { useState } from "react";
import moment from "src/config/moment";
import ReactDatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-SA";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { CalenderDaysIcon, ChevronDownIcon } from "src/components";
import { getLastMonth } from "src/features";
import styles from "./DateRange.module.css";

type DateRangeProps = {
	dateStates: {
		startDate: Date;
		setStartDate: React.Dispatch<React.SetStateAction<Date>>;
		endDate: Date;
		setEndDate: React.Dispatch<React.SetStateAction<Date>>;
	};
	hasMaxDate?: boolean;
	filterData?: (start: Date, end: Date) => void;
	variant?: "standard" | "primary";
};

export function DateRange({
	dateStates: { startDate, setStartDate, endDate, setEndDate },
	hasMaxDate = true,
	filterData,
	variant,
}: DateRangeProps) {
	const { i18n } = useTranslation();

	const onChange = (dates: any) => {
		const [start, end] = dates;

		setStartDate(start);
		setEndDate(end);
		filterData && filterData(start, end);
	};

	const renderVariant = () => {
		switch (variant) {
			case "primary":
				return {
					icon: styles.date_icon_primary,
					datePicker: styles.date_picker_primary,
					fillColor: "#0077B4",
				};
			default:
				return {
					icon: styles.date_icon_standard,
					datePicker: styles.date_picker_standard,
					fillColor: "#333333",
				};
		}
	};

	const geMaxDate = () => {
		const tomorrow = moment().add(1, "days").locale(i18n.language).toDate();

		if (endDate === null) {
			const after180Days = moment(startDate)
				.add(180, "days")
				.locale(i18n.language)
				.toDate();

			if (moment(after180Days).isAfter(tomorrow)) return tomorrow;
			else return after180Days;
		} else {
			return tomorrow;
		}
	};

	return (
		<div className={styles.container}>
			<span className={`${styles.date_icon} start-3 ${renderVariant().icon}`}>
				<CalenderDaysIcon fill={renderVariant().fillColor} />
			</span>

			<ReactDatePicker
				selected={startDate}
				onChange={onChange}
				startDate={startDate}
				endDate={endDate}
				maxDate={hasMaxDate ? geMaxDate() : undefined}
				dateFormat="dd.MM.yyyy"
				dropdownMode="select"
				calendarClassName={`
					bg-white text-dark border-none shadow-xl py-2 z-80
					${i18n.language === "ar" ? "w-[300px]" : ""}
				`}
				popperClassName="z-80"
				className={`input bg-nativeWhite
					${styles.date_picker}
					${renderVariant().datePicker}
				`}
				weekDayClassName={(date) => {
					return "text-xs font-semibold text-dark font-cairo";
				}}
				onKeyDown={(e) => e.preventDefault()}
				selectsRange
				allowSameDay
				locale={i18n.language === "ar" ? ar : en}
				calendarStartDay={6}
			/>

			<span className={`${styles.date_icon} end-3 ${renderVariant().icon}`}>
				<ChevronDownIcon width={20} height={20} />
			</span>
		</div>
	);
}

export function useDateRange(start?: Date, end?: Date) {
	const { i18n } = useTranslation();
	const { startDate: lastMonthStart, endDate: lastMonthEnd } = getLastMonth(
		i18n.language
	);

	const [startDate, setStartDate] = useState<Date>(
		start || new Date(lastMonthStart)
	);
	const [endDate, setEndDate] = useState<Date>(end || new Date(lastMonthEnd));

	return { dateStates: { startDate, setStartDate, endDate, setEndDate } };
}
