import { NoData } from "./NoData";
import NoNotesImg from "src/assets/svg/no_notes.svg";
import { useTranslation } from "react-i18next";

type NoNotesProps = {
	clickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export function NoNotes({ clickHandler }: NoNotesProps) {
	const { t } = useTranslation("global");

	return (
		<NoData
			imgAlt="No Notes"
			imgSrc={NoNotesImg}
			notFoundMsg={t("Notes_Handler.No_Notes_Found")}
			btn={t("Notes_Handler.Add_Note")}
			clickHandler={clickHandler}
			styles={{ notFoundMsg: { className: "py-4" } }}
		/>
	);
}
