import { Style } from "src/@types";
import { PlusIcon, BtnWithIcon } from "src/components";

type NoDataWithButton = {
	btn: React.ReactNode;
	clickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

type NoDataWithoutButton = {
	btn?: never;
	clickHandler?: never;
};

type NoDataButton = NoDataWithButton | NoDataWithoutButton;

type NoDataProps = NoDataButton & {
	imgSrc: string;
	imgAlt: string;
	notFoundMsg: React.ReactNode;
	desc?: string;
	icon?: React.ReactNode;
	styles?: {
		container?: Style;
		imgContainer?: Style;
		notFoundMsg?: Style;
		desc?: Style;
	};
};

export const NoData = ({
	imgSrc,
	imgAlt,
	notFoundMsg,
	btn,
	desc,
	icon,
	clickHandler,
	styles,
}: NoDataProps) => {
	return (
		<div
			className={`center mt-4 h-full w-full ${styles?.container?.className}`}
			style={styles?.container?.style}
		>
			<div className="flex flex-col items-center">
				<div
					className={styles?.imgContainer?.className}
					style={styles?.imgContainer?.style}
				>
					<img src={imgSrc} alt={imgAlt} />
				</div>

				<h3
					className={`mb-2 font-semibold text-dark ${styles?.notFoundMsg?.className}`}
					style={styles?.notFoundMsg?.style}
				>
					{notFoundMsg}
				</h3>

				{desc && (
					<h4
						className={`mb-4 font-semibold text-gray-800 ${styles?.desc?.className}`}
						style={styles?.desc?.style}
					>
						{desc}
					</h4>
				)}

				{btn && clickHandler && (
					<BtnWithIcon
						variant="primary"
						icon={icon ? icon : <PlusIcon />}
						rounded="rounded-lg"
						size="md"
						className=" text-sm 2xl:text-base 3xl:text-lg"
						onClick={clickHandler}
					>
						{btn}
					</BtnWithIcon>
				)}
			</div>
		</div>
	);
};
