export const OrdersBigIcon = () => {
	return (
		<svg
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.5371 38.7812H42.3505"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.5371 24.9844H42.3505"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.5371 11.1914H42.3505"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.68652 11.1913L8.66786 13.162L14.6119 7.25"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.68652 24.9843L8.66786 26.9549L14.6119 21.043"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.68652 38.7812L8.66786 40.7518L14.6119 34.8398"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CostBigIcon = () => {
	return (
		<svg
			width="52"
			height="51"
			viewBox="0 0 52 51"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.0371 30.3764C19.0371 33.0843 21.1261 35.2675 23.7216 35.2675H29.0181C31.2759 35.2675 33.1117 33.3572 33.1117 31.0061C33.1117 28.4451 31.9934 27.5425 30.3263 26.9547L21.8225 24.0159C20.1555 23.4281 19.0371 22.5255 19.0371 19.9645C19.0371 17.6134 20.8729 15.7031 23.1308 15.7031H28.4272C31.0227 15.7031 33.1117 17.8863 33.1117 20.5942"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.0596 12.8906V38.0808"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.3908 46.4797H19.7299C9.17926 46.4797 4.95898 42.2813 4.95898 31.7854V19.1903C4.95898 8.69445 9.17926 4.49609 19.7299 4.49609H32.3908C42.9415 4.49609 47.1617 8.69445 47.1617 19.1903V31.7854C47.1617 42.2813 42.9415 46.4797 32.3908 46.4797Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const StarBigIcon = () => {
	return (
		<svg
			width="36"
			height="34"
			viewBox="0 0 36 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 1.65874L21.5645 12.9339L21.6749 13.2832H22.0413H33.6138L24.2397 20.283L23.955 20.4956L24.0621 20.8343L27.6355 32.1374L18.2992 25.1657L18 24.9424L17.7008 25.1657L8.36454 32.1374L11.9379 20.8343L12.045 20.4956L11.7603 20.283L2.38625 13.2832H13.9587H14.3251L14.4355 12.9339L18 1.65874Z"
				stroke="#141414"
			/>
		</svg>
	);
};
