import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
	Control,
	FieldError,
	UseFormRegister,
	UseFormSetValue,
	useWatch,
} from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { Size, Variant } from "src/@types";
import { Spinner } from "src/components";
import { Label } from "../Label/Label";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string;
	icon?: JSX.Element;
	requiredStar?: boolean;
	id: string;
	register: UseFormRegister<any>;
	control: Control<any, object>;
	listItems?: ReactNode;
	queryFunc: (q: string) => void;
	queryLoading?: boolean;
	queryErrorMsg?: string;
	setValue: UseFormSetValue<any>;
	correspondingValue: any | undefined;
	setCorrespondingValue: React.Dispatch<React.SetStateAction<any>>;
	error?: FieldError | undefined;
	variant?: Variant;
	inputSize?: Size;
}
const renderVariant = (variant: Variant) => {
	switch (variant) {
		case "standard":
			return "input--standard";
		case "filled":
			return "input--filled";
		case "basic":
			return "input--basic";
		default:
			return "input--standard";
	}
};
const renderSize = (inputSize: Size) => {
	switch (inputSize) {
		case "sm":
			return "py-2";
		case "md":
			return "py-3";
		default:
			return "py-2";
	}
};

export const SearchInput = ({
	type,
	id,
	name,
	label,
	requiredStar,
	icon,
	placeholder,
	correspondingValue,
	setCorrespondingValue,
	control,
	register,
	listItems,
	queryFunc,
	queryLoading,
	queryErrorMsg,
	setValue,
	error,
	variant,
	inputSize,
	...rest
}: InputProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isTyping, setIsTyping] = useState(false);

	const value = useWatch({ name: id, control });
	const listRef = useRef<HTMLUListElement>(null);

	const onSelect = (value: any, displayedName: string) => {
		setIsOpen(false);
		setIsTyping(false);
		setValue(id, displayedName);
		setCorrespondingValue(value);
	};

	useEffect(() => {
		if (value && isTyping) {
			setIsOpen(true);
			queryFunc(value);
		} else {
			setIsOpen(false);
		}
	}, [value]);

	useEffect(() => {
		if (typeof correspondingValue !== "undefined") {
			if (Array.isArray(listItems)) {
				const itemElement = listItems.find(
					(item) => item.props.value === correspondingValue
				);

				if (itemElement) {
					setValue(id, itemElement.props.displayedName);
				}
			}
		}
	}, [correspondingValue]);

	return (
		<>
			{label && (
				<Label htmlFor={id} required={requiredStar} variant={variant}>
					{label}
				</Label>
			)}
			<div className="relative ">
				<span
					className={`absolute top-1/2 -translate-y-1/2 start-2   ${
						error && error.message ? "text-red2 opacity-80" : "text-muted"
					} `}
				>
					{icon}
				</span>
				<input
					type={type}
					id={id}
					className={`input  ${renderVariant(variant)} ${renderSize(
						inputSize
					)} ${icon ? "ps-8" : ""}  ${
						error && error.message ? "input--error" : ""
					}`}
					placeholder={placeholder}
					{...register(name, {
						onChange: () => {
							setIsTyping(true);
							setCorrespondingValue(undefined);
						},
						onBlur: (e) => {
							setIsOpen(false);
						},
					})}
					autoComplete="off"
					{...rest}
				/>
				{/* <ErrorMsg>{error && error.message}</ErrorMsg> */}

				<CSSTransition
					in={isOpen}
					timeout={300}
					classNames="transition"
					unmountOnExit
					nodeRef={listRef}
				>
					<ul className="dropMenu scrollBar left-0 right-0" ref={listRef}>
						{queryLoading ? (
							<Spinner />
						) : queryErrorMsg ? (
							<li className="center h-20 text-center text-lg text-gray-600">
								{queryErrorMsg}
							</li>
						) : Array.isArray(listItems) ? (
							listItems.map((item, i) => {
								return React.createElement(item.type, {
									...{
										...item.props,
										key: item.key,
										onClick: () =>
											onSelect(item.props.value, item.props.displayedName),
									},
								});
							})
						) : (
							listItems
						)}
					</ul>
				</CSSTransition>
			</div>
		</>
	);
};
