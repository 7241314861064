import { useMemo } from "react";
import { range } from "src/utils";
const DOTS = "DOTS";

export const useRangePagination = ({
	pageCount,
	pageSize,
	siblingCount = 1,
	currentPage,
}: useRangePaginationProps) => {
	const paginationRange = useMemo(() => {
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		/*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..pageCount]
        */
		if (totalPageNumbers >= pageCount) {
			return range(1, pageCount);
		}

		/*
            Calculate left and right sibling index and make sure they are within range 1 and pageCount
        */
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, pageCount);

		/*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and pageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < pageCount - 2
        */
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = pageCount;

		/*
            Case 2: No left dots to show, but rights dots to be shown
        */
		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, pageCount];
		}

		/*
            Case 3: No right dots to show, but left dots to be shown
        */
		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(pageCount - rightItemCount + 1, pageCount);

			return [firstPageIndex, DOTS, ...rightRange];
		}

		/*
            Case 4: Both left and right dots to be shown
        */
		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);

			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [pageCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};
type useRangePaginationProps = {
	pageCount: number;
	pageSize: number;
	siblingCount?: number;
	currentPage: number;
};
