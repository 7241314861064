import { PatientFileDiagnoseType } from "src/@types";
import { generateUniqueIntegerId } from "src/utils";
import { patientProfileActions } from "../../patientProfileSlice";
import { convertSelectedTeethToArray } from "../../utils";
import { updatePlan } from "../plan";

type CreateDiagnoseParamsType = {
	diagnosisType: PatientFileDiagnoseType["diagnosisType"];
};

export const createDiagnose = ({ diagnosisType }: CreateDiagnoseParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			const newDiagnose: PatientFileDiagnoseType = {
				id: generateUniqueIntegerId(),
				notes: "",
				diagnosisType,
				createdBy: state.auth.user.id,
				updatedBy: state.auth.user.id,
				updatedAt: new Date().toISOString(),
				teeth: convertSelectedTeethToArray({
					state: state.patientProfile,
					key: "diagnoses",
					newItemType: diagnosisType,
				}),
			};

			return {
				...planToUpdate,
				diagnoses: [...planToUpdate.diagnoses, newDiagnose],
			};
		},
		handleSuccess(plan, dispatch) {
			dispatch(patientProfileActions.createDiagnoseSuccess());
		},
	});
};
