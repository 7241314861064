import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { services } from "src/constants/index";
import { Dropdown, ListItem } from "src/components";

type ClinicDropdownProps = {
	control: Control<any, object>;
};

export const ServicesDropdown = ({ control }: ClinicDropdownProps) => {
	const { t } = useTranslation("scheduler");
	const { t: tGlobal } = useTranslation("global");

	return (
		<div className=" mb-4">
			<Controller
				control={control}
				name="type"
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { invalid, isTouched, isDirty, error },
				}) => (
					<Dropdown
						title={t(
							"Appointments.Add_Appointment_Form.Inputs.Service_Input.Placeholder"
						)}
						label={t(
							"Appointments.Add_Appointment_Form.Inputs.Service_Input.Label"
						)}
						value={value}
						onChange={onChange}
						error={error}
						onBlur={onBlur}
						requiredStar
					>
						{services(tGlobal).map((type, i) => (
							<ListItem value={type.id} displayedName={type.name} key={i}>
								<div className="py-1 text-base text-dark 3xl:text-lg">
									{type.name}
								</div>
							</ListItem>
						))}
					</Dropdown>
				)}
			/>
		</div>
	);
};
