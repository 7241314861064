import i18n from "src/config/i18n";
import { localizer } from "./localizer";

export const formats = {
	// eventTimeRangeFormat: (range: Range) =>
	// 	`${localizer.format(range.start, "h:MM A", "Ar")} – ${localizer.format(
	// 		range.end,
	// 		"h:MM A",
	// 		"Ar"
	// 	)}`,
	eventTimeRangeFormat: (range: any) => "",
	timeGutterFormat: (date: any) => {
		return localizer.format(date, "h A", i18n.language);
	},
	dayFormat: (date: any) =>
		localizer.format(date, "dddd", i18n.language) +
		" " +
		localizer.format(date, "D", i18n.language),

	// dayRangeHeaderFormat:({ start, end }:Range) =>
	// localizer.format(start,"dddd", "ar") + ' — ' +
	// localizer.format(end, "dddd", "ar")
};
