export enum RolesEnum {
	ASSISTANT = "assistant",
	DENTIST = "dentist",
	ADMIN = "admin",
}

export enum RolesNumberEnum {
	ADMIN = 1,
	DENTIST = 2,
	ASSISTANT = 3,
}
