import { useState, useRef, ChangeEvent, KeyboardEvent } from "react";

type InputCodeProps = {
	length: number;
	onComplete: (code: string) => void;
	defaultCode?: string;
};

export const InputCode = ({
	length,
	onComplete,
	defaultCode,
}: InputCodeProps) => {
	const [code, setCode] = useState(
		defaultCode ? defaultCode.split("") : [...Array(length)].map(() => "")
	);
	const inputs = useRef<(HTMLInputElement | null)[]>([]);

	const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
		const num = e.target.value;

		if (/[^0-9]/.test(num)) return;

		const newCode = [...code];

		newCode[slot] = num;
		setCode(newCode);

		if (slot !== length - 1) {
			inputs.current[slot + 1]?.focus();
		}

		if (newCode.every((num) => num !== "")) {
			onComplete(newCode.join(""));
		}
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, slot: number) => {
		if (
			(e.key === "Backspace" || e.key === "Delete") &&
			!code[slot] &&
			slot !== 0
		) {
			const newCode = [...code];

			newCode[slot - 1] = "";
			setCode(newCode);
			inputs.current[slot - 1]?.focus();
		}
	};

	return (
		<div className="code-input">
			<div className="flex items-center justify-center space-x-2">
				{code.map((num, idx) => {
					return (
						<input
							key={idx}
							type="text"
							className="h-12 w-12 bg-background text-center text-xl font-semibold focus:border focus:border-primary focus:outline-none "
							inputMode="numeric"
							maxLength={1}
							value={num}
							autoFocus={!code[0].length && idx === 0}
							onChange={(e) => processInput(e, idx)}
							onKeyUp={(e) => onKeyUp(e, idx)}
							ref={(ref) => inputs.current.push(ref)}
						/>
					);
				})}
			</div>
		</div>
	);
};
