import { WritableDraft } from "immer/dist/internal";
import { PatientProfileState } from "../patientProfileSlice";

export const clearReducers = {
	clearSelected: (state: WritableDraft<PatientProfileState>) => {
		state.selectedProcedure = null;
		state.selectedTeeth = {};
		state.isUpperBarOpen = false;
		state.isUpperArchSelected = false;
		state.isLowerArchSelected = false;
	},

	clearAll: (state: WritableDraft<PatientProfileState>) => {
		state.isUpperBarOpen = false;

		state.plans = [];
		state.selectedPlan = null;
		state.selectedPlanTeethDictionary = null;
		state.selectedProcedure = null;

		state.selectedTeeth = {};
		state.hoveredTeeth = {};

		state.isLowerArchSelected = false;
		state.isUpperArchSelected = false;
	},
};
