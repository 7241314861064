import { useCallback, useEffect, useRef } from "react";

export function useInterval(
	callback: () => void,
	delay: number,
	runNow?: boolean
) {
	const callbackRef = useRef(callback);
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const set = useCallback(() => {
		intervalRef.current = setInterval(() => callbackRef.current(), delay);
	}, [delay]);

	const clear = useCallback(() => {
		intervalRef.current && clearInterval(intervalRef.current);
	}, []);

	useEffect(() => {
		if (runNow) set();

		return clear;
	}, [delay, set, clear]);

	const reset = useCallback(() => {
		clear();
		set();
	}, [clear, set]);

	return { reset, clear, set };
}
