import { useMemo, useState } from "react";

type CategoryType = { label: string; value: string | number };

type UseTableCategoryFilterProps<DataType> = {
	data: DataType[];
	labelKey: keyof DataType;
	valueKey: keyof DataType;
	allLabel?: string;
};

export function useTableCategoryFilter<DataType>({
	data,
	labelKey,
	valueKey,
	allLabel,
}: UseTableCategoryFilterProps<DataType>) {
	const ALL_VALUE = "all";

	const categories = useMemo(() => {
		return [
			{ label: allLabel || "All", value: ALL_VALUE },
			...data.map((item) => ({
				label: item[labelKey],
				value: item[valueKey],
			})),
		];
	}, [data]) as CategoryType[];

	const [selectedCategory, setSelectedCategory] = useState(categories[0]);

	const isAll = (value: string | number) => value === ALL_VALUE;

	return { isAll, categories, selectedCategory, setSelectedCategory };
}
