type BackdropProps = {
	show: boolean;
	clickOutside: () => void;
	withBackground?: boolean;
};

export const Backdrop = ({
	show,
	clickOutside,
	withBackground,
}: BackdropProps) => {
	return show ? (
		<div
			className={`fixed inset-0 z-50 h-full w-full ${
				withBackground ? "Blured bg-gray-400 opacity-40 backdrop-blur" : ""
			}`}
			onClick={clickOutside}
		></div>
	) : null;
};
