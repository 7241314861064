import { ReactNode } from "react";

type DotCircleProps = {
	children: ReactNode;
	style?: React.CSSProperties | undefined;
};
export const DotCircle = ({ children, style }: DotCircleProps) => {
	return (
		<span
			className="absolute -top-1 right-0 h-4 w-4 rounded-full bg-red text-center text-xs font-semibold text-white"
			style={style}
		>
			{children}
		</span>
	);
};
