export function ClinicBigIcon() {
	return (
		<svg
			width="78"
			height="75"
			viewBox="0 0 78 75"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30.5215 74.6328C38.2535 74.6328 44.5215 68.3648 44.5215 60.6328C44.5215 52.9008 38.2535 46.6328 30.5215 46.6328C22.7895 46.6328 16.5215 52.9008 16.5215 60.6328C16.5215 68.3648 22.7895 74.6328 30.5215 74.6328Z"
				fill="#DFEDF0"
			/>
			<path
				d="M65.0332 40.1328C71.6606 40.1328 77.0332 34.7602 77.0332 28.1328C77.0332 21.5054 71.6606 16.1328 65.0332 16.1328C58.4058 16.1328 53.0332 21.5054 53.0332 28.1328C53.0332 34.7602 58.4058 40.1328 65.0332 40.1328Z"
				fill="#DFEDF0"
			/>
			<path
				d="M20.0215 40.3926C31.0672 40.3926 40.0215 31.4383 40.0215 20.3926C40.0215 9.34688 31.0672 0.392578 20.0215 0.392578C8.97579 0.392578 0.0214844 9.34688 0.0214844 20.3926C0.0214844 31.4383 8.97579 40.3926 20.0215 40.3926Z"
				fill="#DFEDF0"
			/>
			<path
				d="M67.7832 22.8828H7.7832V66.8828H67.7832V22.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M64.7832 22.8828H7.7832V62.8828H62.7832C63.8832 62.8828 64.7832 61.9928 64.7832 60.8828V22.8828Z"
				fill="white"
			/>
			<path
				d="M67.7832 67.6328H7.7832C7.3732 67.6328 7.0332 67.2928 7.0332 66.8828V22.8828C7.0332 22.4728 7.3732 22.1328 7.7832 22.1328H67.7832C68.1932 22.1328 68.5332 22.4728 68.5332 22.8828V66.8828C68.5332 67.2928 68.1932 67.6328 67.7832 67.6328ZM8.5332 66.1328H67.0332V23.6328H8.5332V66.1328Z"
				fill="#046289"
			/>
			<path
				d="M69.7832 67.6328H5.7832C5.3732 67.6328 5.0332 67.2928 5.0332 66.8828C5.0332 66.4728 5.3732 66.1328 5.7832 66.1328H69.7832C70.1932 66.1328 70.5332 66.4728 70.5332 66.8828C70.5332 67.2928 70.1932 67.6328 69.7832 67.6328Z"
				fill="#046289"
			/>
			<path
				d="M18.7715 28.8828H12.7715V40.8828H18.7715V28.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M16.7715 28.8828H12.7715V38.8828H15.7715C16.3215 38.8828 16.7715 38.4328 16.7715 37.8828V28.8828Z"
				fill="white"
			/>
			<path
				d="M18.7715 41.6328H12.7715C12.3615 41.6328 12.0215 41.2928 12.0215 40.8828V28.8828C12.0215 28.4728 12.3615 28.1328 12.7715 28.1328H18.7715C19.1815 28.1328 19.5215 28.4728 19.5215 28.8828V40.8828C19.5215 41.2928 19.1915 41.6328 18.7715 41.6328ZM13.5215 40.1328H18.0215V29.6328H13.5215V40.1328Z"
				fill="#046289"
			/>
			<path
				d="M19.2715 41.6328H12.2715C11.8615 41.6328 11.5215 41.2928 11.5215 40.8828C11.5215 40.4728 11.8615 40.1328 12.2715 40.1328H19.2715C19.6815 40.1328 20.0215 40.4728 20.0215 40.8828C20.0215 41.2928 19.6915 41.6328 19.2715 41.6328Z"
				fill="#046289"
			/>
			<path
				d="M18.7715 48.8828H12.7715V60.8828H18.7715V48.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M16.7715 48.8828H12.7715V58.8828H15.7715C16.3215 58.8828 16.7715 58.4328 16.7715 57.8828V48.8828Z"
				fill="white"
			/>
			<path
				d="M18.7715 61.6328H12.7715C12.3615 61.6328 12.0215 61.2928 12.0215 60.8828V48.8828C12.0215 48.4728 12.3615 48.1328 12.7715 48.1328H18.7715C19.1815 48.1328 19.5215 48.4728 19.5215 48.8828V60.8828C19.5215 61.2928 19.1915 61.6328 18.7715 61.6328ZM13.5215 60.1328H18.0215V49.6328H13.5215V60.1328Z"
				fill="#046289"
			/>
			<path
				d="M19.2715 61.6328H12.2715C11.8615 61.6328 11.5215 61.2928 11.5215 60.8828C11.5215 60.4728 11.8615 60.1328 12.2715 60.1328H19.2715C19.6815 60.1328 20.0215 60.4728 20.0215 60.8828C20.0215 61.2928 19.6915 61.6328 19.2715 61.6328Z"
				fill="#046289"
			/>
			<path
				d="M29.7832 48.8828H23.7832V60.8828H29.7832V48.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M27.7832 48.8828H23.7832V58.8828H26.7832C27.3332 58.8828 27.7832 58.4328 27.7832 57.8828V48.8828Z"
				fill="white"
			/>
			<path
				d="M29.7715 61.6328H23.7715C23.3615 61.6328 23.0215 61.2928 23.0215 60.8828V48.8828C23.0215 48.4728 23.3615 48.1328 23.7715 48.1328H29.7715C30.1815 48.1328 30.5215 48.4728 30.5215 48.8828V60.8828C30.5215 61.2928 30.1915 61.6328 29.7715 61.6328ZM24.5215 60.1328H29.0215V49.6328H24.5215V60.1328Z"
				fill="#046289"
			/>
			<path
				d="M30.2715 61.6328H23.2715C22.8615 61.6328 22.5215 61.2928 22.5215 60.8828C22.5215 60.4728 22.8615 60.1328 23.2715 60.1328H30.2715C30.6815 60.1328 31.0215 60.4728 31.0215 60.8828C31.0215 61.2928 30.6915 61.6328 30.2715 61.6328Z"
				fill="#046289"
			/>
			<path
				d="M40.7832 48.8828H34.7832V60.8828H40.7832V48.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M38.7832 48.8828H34.7832V58.8828H37.7832C38.3332 58.8828 38.7832 58.4328 38.7832 57.8828V48.8828Z"
				fill="white"
			/>
			<path
				d="M40.7832 61.6328H34.7832C34.3732 61.6328 34.0332 61.2928 34.0332 60.8828V48.8828C34.0332 48.4728 34.3732 48.1328 34.7832 48.1328H40.7832C41.1932 48.1328 41.5332 48.4728 41.5332 48.8828V60.8828C41.5332 61.2928 41.1932 61.6328 40.7832 61.6328ZM35.5332 60.1328H40.0332V49.6328H35.5332V60.1328Z"
				fill="#046289"
			/>
			<path
				d="M41.2832 61.6328H34.2832C33.8732 61.6328 33.5332 61.2928 33.5332 60.8828C33.5332 60.4728 33.8732 60.1328 34.2832 60.1328H41.2832C41.6932 60.1328 42.0332 60.4728 42.0332 60.8828C42.0332 61.2928 41.6932 61.6328 41.2832 61.6328Z"
				fill="#046289"
			/>
			<path
				d="M29.7832 28.8828H23.7832V40.8828H29.7832V28.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M27.7832 28.8828H23.7832V38.8828H26.7832C27.3332 38.8828 27.7832 38.4328 27.7832 37.8828V28.8828Z"
				fill="white"
			/>
			<path
				d="M29.7715 41.6328H23.7715C23.3615 41.6328 23.0215 41.2928 23.0215 40.8828V28.8828C23.0215 28.4728 23.3615 28.1328 23.7715 28.1328H29.7715C30.1815 28.1328 30.5215 28.4728 30.5215 28.8828V40.8828C30.5215 41.2928 30.1915 41.6328 29.7715 41.6328ZM24.5215 40.1328H29.0215V29.6328H24.5215V40.1328Z"
				fill="#046289"
			/>
			<path
				d="M30.2715 41.6328H23.2715C22.8615 41.6328 22.5215 41.2928 22.5215 40.8828C22.5215 40.4728 22.8615 40.1328 23.2715 40.1328H30.2715C30.6815 40.1328 31.0215 40.4728 31.0215 40.8828C31.0215 41.2928 30.6915 41.6328 30.2715 41.6328Z"
				fill="#046289"
			/>
			<path
				d="M40.7832 28.8828H34.7832V40.8828H40.7832V28.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M38.7832 28.8828H34.7832V38.8828H37.7832C38.3332 38.8828 38.7832 38.4328 38.7832 37.8828V28.8828Z"
				fill="white"
			/>
			<path
				d="M40.7832 41.6328H34.7832C34.3732 41.6328 34.0332 41.2928 34.0332 40.8828V28.8828C34.0332 28.4728 34.3732 28.1328 34.7832 28.1328H40.7832C41.1932 28.1328 41.5332 28.4728 41.5332 28.8828V40.8828C41.5332 41.2928 41.1932 41.6328 40.7832 41.6328ZM35.5332 40.1328H40.0332V29.6328H35.5332V40.1328Z"
				fill="#046289"
			/>
			<path
				d="M41.2832 41.6328H34.2832C33.8732 41.6328 33.5332 41.2928 33.5332 40.8828C33.5332 40.4728 33.8732 40.1328 34.2832 40.1328H41.2832C41.6932 40.1328 42.0332 40.4728 42.0332 40.8828C42.0332 41.2928 41.6932 41.6328 41.2832 41.6328Z"
				fill="#046289"
			/>
			<path
				d="M51.7832 28.8828H45.7832V40.8828H51.7832V28.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M49.7832 28.8828H45.7832V38.8828H48.7832C49.3332 38.8828 49.7832 38.4328 49.7832 37.8828V28.8828Z"
				fill="white"
			/>
			<path
				d="M51.7832 41.6328H45.7832C45.3732 41.6328 45.0332 41.2928 45.0332 40.8828V28.8828C45.0332 28.4728 45.3732 28.1328 45.7832 28.1328H51.7832C52.1932 28.1328 52.5332 28.4728 52.5332 28.8828V40.8828C52.5332 41.2928 52.1932 41.6328 51.7832 41.6328ZM46.5332 40.1328H51.0332V29.6328H46.5332V40.1328Z"
				fill="#046289"
			/>
			<path
				d="M52.2832 41.6328H45.2832C44.8732 41.6328 44.5332 41.2928 44.5332 40.8828C44.5332 40.4728 44.8732 40.1328 45.2832 40.1328H52.2832C52.6932 40.1328 53.0332 40.4728 53.0332 40.8828C53.0332 41.2928 52.6932 41.6328 52.2832 41.6328Z"
				fill="#046289"
			/>
			<path
				d="M62.7832 28.8828H56.7832V40.8828H62.7832V28.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M60.7832 28.8828H56.7832V38.8828H59.7832C60.3332 38.8828 60.7832 38.4328 60.7832 37.8828V28.8828Z"
				fill="white"
			/>
			<path
				d="M62.7832 41.6328H56.7832C56.3732 41.6328 56.0332 41.2928 56.0332 40.8828V28.8828C56.0332 28.4728 56.3732 28.1328 56.7832 28.1328H62.7832C63.1932 28.1328 63.5332 28.4728 63.5332 28.8828V40.8828C63.5332 41.2928 63.1932 41.6328 62.7832 41.6328ZM57.5332 40.1328H62.0332V29.6328H57.5332V40.1328Z"
				fill="#046289"
			/>
			<path
				d="M63.2832 41.6328H56.2832C55.8732 41.6328 55.5332 41.2928 55.5332 40.8828C55.5332 40.4728 55.8732 40.1328 56.2832 40.1328H63.2832C63.6932 40.1328 64.0332 40.4728 64.0332 40.8828C64.0332 41.2928 63.6932 41.6328 63.2832 41.6328Z"
				fill="#046289"
			/>
			<path
				d="M59.7832 6.88281H15.7832L5.7832 22.8828H69.7832L59.7832 6.88281Z"
				fill="#E6F2FF"
			/>
			<path
				d="M55.6543 6.88281H15.7843L7.6543 19.8828H61.9743C62.7643 19.8828 63.2343 19.0128 62.8243 18.3528L55.6543 6.88281Z"
				fill="white"
			/>
			<path
				d="M69.7809 23.6328H5.78085C5.51085 23.6328 5.26085 23.4828 5.12085 23.2428C4.99085 23.0028 5.00087 22.7128 5.14087 22.4828L15.1409 6.48281C15.2809 6.26281 15.5209 6.13281 15.7809 6.13281H59.7809C60.0409 6.13281 60.2809 6.26281 60.4209 6.48281L70.4209 22.4828C70.5609 22.7128 70.5709 23.0028 70.4409 23.2428C70.3009 23.4828 70.0508 23.6328 69.7809 23.6328ZM7.13086 22.1328H68.4209L59.3609 7.63281H16.1909L7.13086 22.1328Z"
				fill="#046289"
			/>
			<path
				d="M41.7832 16.8828H33.7832C32.1232 16.8828 30.7832 15.5428 30.7832 13.8828V5.88281C30.7832 4.22281 32.1232 2.88281 33.7832 2.88281H41.7832C43.4432 2.88281 44.7832 4.22281 44.7832 5.88281V13.8828C44.7832 15.5328 43.4332 16.8828 41.7832 16.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M41.7832 14.8828H32.7832C31.6832 14.8828 30.7832 13.9828 30.7832 12.8828V5.88281C30.7832 4.22281 32.1232 2.88281 33.7832 2.88281H40.7832C41.8832 2.88281 42.7832 3.77281 42.7832 4.88281V13.8828C42.7832 14.4328 42.3332 14.8828 41.7832 14.8828Z"
				fill="white"
			/>
			<path
				d="M41.7832 17.6328H33.7832C31.7132 17.6328 30.0332 15.9528 30.0332 13.8828V5.88281C30.0332 3.81281 31.7132 2.13281 33.7832 2.13281H41.7832C43.8532 2.13281 45.5332 3.81281 45.5332 5.88281V13.8828C45.5332 15.9428 43.8432 17.6328 41.7832 17.6328ZM33.7832 3.63281C32.5432 3.63281 31.5332 4.64281 31.5332 5.88281V13.8828C31.5332 15.1228 32.5432 16.1328 33.7832 16.1328H41.7832C43.0232 16.1328 44.0332 15.1228 44.0332 13.8828V5.88281C44.0332 4.64281 43.0232 3.63281 41.7832 3.63281H33.7832Z"
				fill="#046289"
			/>
			<path
				d="M40.7832 10.6328H34.7832C34.3732 10.6328 34.0332 10.2928 34.0332 9.88281C34.0332 9.47281 34.3732 9.13281 34.7832 9.13281H40.7832C41.1932 9.13281 41.5332 9.47281 41.5332 9.88281C41.5332 10.2928 41.1932 10.6328 40.7832 10.6328Z"
				fill="#046289"
			/>
			<path
				d="M37.7832 13.6328C37.3732 13.6328 37.0332 13.2928 37.0332 12.8828V6.88281C37.0332 6.47281 37.3732 6.13281 37.7832 6.13281C38.1932 6.13281 38.5332 6.47281 38.5332 6.88281V12.8828C38.5332 13.2928 38.1932 13.6328 37.7832 13.6328Z"
				fill="#046289"
			/>
			<path
				d="M62.2832 48.8828H54.2832V66.8828H62.2832V48.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M58.2832 63.8828H54.2832V48.8828H59.2832V62.8828C59.2832 63.4328 58.8332 63.8828 58.2832 63.8828Z"
				fill="white"
			/>
			<path
				d="M62.2832 67.6328H54.2832C53.8732 67.6328 53.5332 67.2928 53.5332 66.8828V48.8828C53.5332 48.4728 53.8732 48.1328 54.2832 48.1328H62.2832C62.6932 48.1328 63.0332 48.4728 63.0332 48.8828V66.8828C63.0332 67.2928 62.6932 67.6328 62.2832 67.6328ZM55.0332 66.1328H61.5332V49.6328H55.0332V66.1328Z"
				fill="#046289"
			/>
			<path
				d="M54.2832 48.8828H46.2832V66.8828H54.2832V48.8828Z"
				fill="#E6F2FF"
			/>
			<path
				d="M50.2832 63.8828H46.2832V48.8828H51.2832V62.8828C51.2832 63.4328 50.8332 63.8828 50.2832 63.8828Z"
				fill="white"
			/>
			<path
				d="M54.2832 67.6328H46.2832C45.8732 67.6328 45.5332 67.2928 45.5332 66.8828V48.8828C45.5332 48.4728 45.8732 48.1328 46.2832 48.1328H54.2832C54.6932 48.1328 55.0332 48.4728 55.0332 48.8828V66.8828C55.0332 67.2928 54.6932 67.6328 54.2832 67.6328ZM47.0332 66.1328H53.5332V49.6328H47.0332V66.1328Z"
				fill="#046289"
			/>
			<path
				d="M63.2832 49.6328H45.2832C44.8732 49.6328 44.5332 49.2928 44.5332 48.8828C44.5332 48.4728 44.8732 48.1328 45.2832 48.1328H63.2832C63.6932 48.1328 64.0332 48.4728 64.0332 48.8828C64.0332 49.2928 63.6932 49.6328 63.2832 49.6328Z"
				fill="#046289"
			/>
			<path
				d="M51.7832 59.8828C52.1974 59.8828 52.5332 59.547 52.5332 59.1328C52.5332 58.7186 52.1974 58.3828 51.7832 58.3828C51.369 58.3828 51.0332 58.7186 51.0332 59.1328C51.0332 59.547 51.369 59.8828 51.7832 59.8828Z"
				fill="#046289"
			/>
			<path
				d="M56.7832 59.8828C57.1974 59.8828 57.5332 59.547 57.5332 59.1328C57.5332 58.7186 57.1974 58.3828 56.7832 58.3828C56.369 58.3828 56.0332 58.7186 56.0332 59.1328C56.0332 59.547 56.369 59.8828 56.7832 59.8828Z"
				fill="#046289"
			/>
			<path
				d="M74.7832 52.6328H70.7832C70.3732 52.6328 70.0332 52.2928 70.0332 51.8828C70.0332 51.4728 70.3732 51.1328 70.7832 51.1328H74.7832C75.1932 51.1328 75.5332 51.4728 75.5332 51.8828C75.5332 52.2928 75.1932 52.6328 74.7832 52.6328Z"
				fill="#046289"
			/>
			<path
				d="M72.7832 54.6328C72.3732 54.6328 72.0332 54.2928 72.0332 53.8828V49.8828C72.0332 49.4728 72.3732 49.1328 72.7832 49.1328C73.1932 49.1328 73.5332 49.4728 73.5332 49.8828V53.8828C73.5332 54.2928 73.1932 54.6328 72.7832 54.6328Z"
				fill="#046289"
			/>
			<path
				d="M72.7832 46.6328C73.1974 46.6328 73.5332 46.297 73.5332 45.8828C73.5332 45.4686 73.1974 45.1328 72.7832 45.1328C72.369 45.1328 72.0332 45.4686 72.0332 45.8828C72.0332 46.297 72.369 46.6328 72.7832 46.6328Z"
				fill="#046289"
			/>
			<path
				d="M72.7832 43.6328C73.1974 43.6328 73.5332 43.297 73.5332 42.8828C73.5332 42.4686 73.1974 42.1328 72.7832 42.1328C72.369 42.1328 72.0332 42.4686 72.0332 42.8828C72.0332 43.297 72.369 43.6328 72.7832 43.6328Z"
				fill="#046289"
			/>
			<path
				d="M72.7832 39.623C73.1974 39.623 73.5332 39.2873 73.5332 38.873C73.5332 38.4588 73.1974 38.123 72.7832 38.123C72.369 38.123 72.0332 38.4588 72.0332 38.873C72.0332 39.2873 72.369 39.623 72.7832 39.623Z"
				fill="#046289"
			/>
			<path
				d="M72.7832 33.623C73.1974 33.623 73.5332 33.2873 73.5332 32.873C73.5332 32.4588 73.1974 32.123 72.7832 32.123C72.369 32.123 72.0332 32.4588 72.0332 32.873C72.0332 33.2873 72.369 33.623 72.7832 33.623Z"
				fill="#046289"
			/>
			<path
				d="M4.7832 38.6328H0.783203C0.373203 38.6328 0.0332031 38.2928 0.0332031 37.8828C0.0332031 37.4728 0.373203 37.1328 0.783203 37.1328H4.7832C5.1932 37.1328 5.5332 37.4728 5.5332 37.8828C5.5332 38.2928 5.1932 38.6328 4.7832 38.6328Z"
				fill="#046289"
			/>
			<path
				d="M2.7832 40.6328C2.3732 40.6328 2.0332 40.2928 2.0332 39.8828V35.8828C2.0332 35.4728 2.3732 35.1328 2.7832 35.1328C3.1932 35.1328 3.5332 35.4728 3.5332 35.8828V39.8828C3.5332 40.2928 3.1932 40.6328 2.7832 40.6328Z"
				fill="#046289"
			/>
			<path
				d="M2.7832 44.6328C3.19742 44.6328 3.5332 44.297 3.5332 43.8828C3.5332 43.4686 3.19742 43.1328 2.7832 43.1328C2.36899 43.1328 2.0332 43.4686 2.0332 43.8828C2.0332 44.297 2.36899 44.6328 2.7832 44.6328Z"
				fill="#046289"
			/>
			<path
				d="M2.7832 48.6328C3.19742 48.6328 3.5332 48.297 3.5332 47.8828C3.5332 47.4686 3.19742 47.1328 2.7832 47.1328C2.36899 47.1328 2.0332 47.4686 2.0332 47.8828C2.0332 48.297 2.36899 48.6328 2.7832 48.6328Z"
				fill="#046289"
			/>
			<path
				d="M2.7832 53.6328C3.19742 53.6328 3.5332 53.297 3.5332 52.8828C3.5332 52.4686 3.19742 52.1328 2.7832 52.1328C2.36899 52.1328 2.0332 52.4686 2.0332 52.8828C2.0332 53.297 2.36899 53.6328 2.7832 53.6328Z"
				fill="#046289"
			/>
			<path
				d="M2.7832 61.6328C3.19742 61.6328 3.5332 61.297 3.5332 60.8828C3.5332 60.4686 3.19742 60.1328 2.7832 60.1328C2.36899 60.1328 2.0332 60.4686 2.0332 60.8828C2.0332 61.297 2.36899 61.6328 2.7832 61.6328Z"
				fill="#046289"
			/>
		</svg>
	);
}
