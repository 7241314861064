import { useEffect, useRef } from "react";

/**
 * Use it to handle event listeners
 *
 * useEventListener(eventType, callback, element);
 *
 * 	- eventType: [ click, mouseover, mouseout, etc. ]
 * 	- callback: [ function ]
 * 	- element: [ document, window, ref, etc. ]
 */

export function useEventListener(
	eventType: keyof WindowEventMap,
	callback: (event: Event) => void,
	element: Document | Window = window
) {
	const callbackRef = useRef(callback);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		if (element === null) return;
		const handler = (e: Event) => callbackRef.current(e);

		element.addEventListener(eventType, handler);

		return () => element.removeEventListener(eventType, handler);
	}, [eventType, element]);
}
