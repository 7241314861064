import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface patientListState {
	addPatientGroupModal: boolean;
	mode: "ADD" | "EDIT" | "NEITHER";
}

const initialState: patientListState = {
	addPatientGroupModal: false,
	mode: "NEITHER",
};

const patientListSlice = createSlice({
	name: "patientList",
	initialState,
	reducers: {
		openAddPatientGroupModal: (
			state: patientListState,
			action: PayloadAction<{ mode: "ADD" | "EDIT" }>
		) => {
			state.addPatientGroupModal = true;
			state.mode = action.payload.mode;
		},
		closeAddPatientGroupModal: (state: patientListState) => {
			state.addPatientGroupModal = false;
			state.mode = "NEITHER";
		},
	},
});

export const patientListActions = patientListSlice.actions;

export const patientListReducer = patientListSlice.reducer;
