export const PedoTeethDictionary: {
	[key: number]: string | null;
} = {
	1: null,
	2: null,
	3: null,
	4: "E",
	5: "D",
	6: "C",
	7: "B",
	8: "A",
	9: "A",
	10: "B",
	11: "C",
	12: "D",
	13: "E",
	14: null,
	15: null,
	16: null,
	17: null,
	18: null,
	19: null,
	20: "E",
	21: "D",
	22: "C",
	23: "B",
	24: "A",
	25: "A",
	26: "B",
	27: "C",
	28: "D",
	29: "E",
	30: null,
	31: null,
	32: null,
};
