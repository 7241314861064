import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Btn, PasswordInput } from "src/components";
import { FormWrapper } from "./../FormWrapper/FormWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { useAxios } from "src/hooks";
import { ROUTES } from "src/constants";
import { toast } from "react-toastify";

type ResetPasswordFormType = {
	password: string;
	confirmPassword: string;
};

const schema = yup.object().shape({
	password: yup
		.string()
		.required("Required field")
		.min(8, "Your password should be at least 8 characters."),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password")], "Passwords must match")
		.required("Required field"),
});

export const RestPassword = () => {
	const history = useHistory();
	const location = useLocation<{ phone: string; code: string }>();
	const phone = location.state?.phone;
	const code = location.state?.code;

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<ResetPasswordFormType>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: { password: "", confirmPassword: "" },
	});

	const { makeRequest } = useAxios({
		runNow: false,
		configs: { method: "POST" },
	});

	const authHandler = (data: ResetPasswordFormType) => {
		makeRequest({
			configs: {
				url: "/reset-password",
				params: {
					phone: "+" + phone.replace(/\s/g, ""),
					code,
					password: data.password,
				},
			},
			handleSuccess: () => {
				history.push(ROUTES.AUTH.LOGIN_SUCCESSFULLY, {
					phone,
					password: data.password,
				});
			},
			handleError: (error) => {
				toast.error(error.response.data.detail);
			},
		});
	};

	return (
		<FormWrapper>
			<form onSubmit={handleSubmit(authHandler)} noValidate autoComplete="off">
				<div className="mb-12">
					<h3 className="mb-8 text-center text-2xl  font-semibold text-dark 2xl:text-3xl">
						Reset your password
					</h3>
				</div>

				<div className="mb-8">
					<PasswordInput
						name="password"
						id="password"
						placeholder="Create new password"
						register={register}
						error={errors.password}
						variant="basic"
						inputSize="md"
						label="Create new password"
					/>
				</div>

				<div className="mb-8">
					<PasswordInput
						name="confirmPassword"
						id="confirmPassword"
						placeholder="Confirm new password"
						register={register}
						error={errors.confirmPassword}
						variant="basic"
						inputSize="md"
						label="Confirm new password"
					/>
				</div>

				<Btn
					variant="primary"
					size="md"
					type="submit"
					rounded="rounded-lg"
					className={`
						mb-3 w-full text-lg
						${isValid ? "opacity-100" : "cursor-not-allowed opacity-60"}
					`}
					disabled={!isValid}
				>
					Continue
				</Btn>
			</form>
		</FormWrapper>
	);
};

export default RestPassword;
