import { useAppDispatch, useAppSelector, fetchUser, logout } from "src/redux";
import {
	ProfileImg,
	ChevronDownIcon,
	UserIcon,
	GlobeIcon,
	Backdrop,
	GearIcon,
} from "src/components";
import {
	useCheckRole,
	useForAllRoutes,
	useReduxAsync,
	useToggle,
} from "src/hooks";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/constants/index";
import { useRef } from "react";
import { renderRole } from "src/utils";
import Cookies from "js-cookie";

export const LoggedInUser = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { t, i18n } = useTranslation("layout");
	const { t: tGlobal } = useTranslation("global");
	const { user } = useAppSelector((state) => state.auth);
	const { isAssistant } = useCheckRole();
	const route = useForAllRoutes();
	const [isActionsMenuOpen, toggleActionMenu] = useToggle(false);

	const handleLogout = () => {
		dispatch(logout());
		toggleActionMenu(false);

		history.push(ROUTES.AUTH.LOGIN);
	};

	const handleChangeLanguage = () => {
		if (i18n.language === "en") i18n.changeLanguage("ar");
		else if (i18n.language === "ar") i18n.changeLanguage("en");

		toggleActionMenu(false);
	};

	const handleGoToSettings = () => {
		history.push(ROUTES[route].SETTINGS.BASE);
		toggleActionMenu(false);
	};

	useReduxAsync(fetchUser());

	const listRef = useRef<HTMLUListElement>(null!);

	const build_cloudfront_signed_query = (
		file_url: string
	): string | undefined => {
		if (!file_url || file_url === "") {
			return;
		}

		//TODO REMOVE WHEN APP/BACK ON SAME DOMAIN
		const key_pair_id = Cookies.get("CloudFront-Key-Pair-Id");
		const policy = Cookies.get("CloudFront-Policy");
		const signature = Cookies.get("CloudFront-Signature");

		return `${file_url}?Key-Pair-Id=${key_pair_id}&Policy=${policy}&Signature=${signature}`;
	};

	return (
		<div className="relative flex">
			<div className="align-center cursor-pointer" onClick={handleGoToSettings}>
				<ProfileImg
					username={user.name}
					bgColor="bg-darkBlue text-white"
					imgsrc={build_cloudfront_signed_query(user.picture)}
				/>

				<div className="ms-4">
					<p className="text-sm font-semibold capitalize 2xl:text-base 3xl:text-lg">
						{user.name}
					</p>
					<p className="text-xs font-semibold capitalize text-muted text-start 2xl:text-sm 3xl:text-base">
						{tGlobal(renderRole(user.role))}
					</p>
				</div>
			</div>

			<span
				className="cursor-pointer text-gray-600 ms-6"
				onClick={toggleActionMenu}
			>
				<ChevronDownIcon width={24} height={24} />
			</span>

			<Backdrop
				show={isActionsMenuOpen}
				clickOutside={() => toggleActionMenu(false)}
			/>

			<CSSTransition
				in={isActionsMenuOpen}
				timeout={0}
				unmountOnExit
				onEnter={() => toggleActionMenu(true)}
				onExited={() => toggleActionMenu(false)}
				nodeRef={listRef}
			>
				<ul
					ref={listRef}
					className={
						"absolute -bottom-2 left-0 z-70 w-full translate-y-full divide-y divide-lightGray  bg-white shadow-md end-16"
					}
				>
					{isAssistant && (
						<li
							className="align-center cursor-pointer py-3 px-4 text-primary hover:bg-gray-200"
							onClick={handleChangeLanguage}
						>
							<span className="text-primary me-3">
								<GlobeIcon width={20} height={20} />
							</span>
							<span className="text-sm font-semibold">
								{t("Header.Menu.ChangeLanguage")}
							</span>
						</li>
					)}

					<li
						className="align-center cursor-pointer py-3 px-4 text-primary hover:bg-gray-200"
						onClick={handleGoToSettings}
					>
						<span className="text-primary me-3">
							<GearIcon width={20} height={20} />
						</span>
						<span className="text-sm font-semibold">Settings</span>
					</li>

					<li
						className="align-center cursor-pointer py-3 px-4 text-primary hover:bg-gray-200"
						onClick={handleLogout}
					>
						<span className="text-primary me-3">
							<UserIcon width={20} height={20} />
						</span>
						<span className="text-sm font-semibold">
							{t("Header.Menu.Logout")}
						</span>
					</li>
				</ul>
			</CSSTransition>
		</div>
	);
};
