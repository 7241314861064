import * as yup from "yup";

export type AddNewOrder = {
	order: string;
	price: number;
	quantity: number;
	total: number;
	patientId: string;
	dentist: number;
	labId: number;
	notes: string;
	teeth: { [key: number]: boolean };
};

const teeth = Array.from({ length: 32 }, (_, i) => i + 1).reduce(
	(obj, number) => ({ ...obj, [number]: false }),
	{}
);

export const labOrderSchema = yup.object().shape({
	order: yup.string().required("Required field"),
	price: yup.number().required("Required field"),
	quantity: yup.number().required("Required field"),
	total: yup.number().required("Required field"),
	patientId: yup.string().required("Required field"),
	dentist: yup.number().required("Required field"),
	labId: yup.number().required("Required field"),
	notes: yup.string(),
	teeth: yup.object().shape(teeth),
});

export const defaultValues = {
	teeth: teeth,
	quantity: 1,
};
