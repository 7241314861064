import { useTranslation } from "react-i18next";
import { Row } from "react-table";
import { Style } from "src/@types";
import { Pagination } from "src/components";

type TableFooterProps = {
	rows: Row<any>[];
	pageSize: number;
	pageIndex: number;
	pageOptions: number[];
	canNextPage: boolean;
	canPreviousPage: boolean;
	gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
	previousPage: () => void;
	nextPage: () => void;
	setPageSize: (pageSize: number) => void;
	styles?: { container?: Style };
};

export function TableFooter({
	rows,
	pageSize,
	pageIndex,
	pageOptions,
	canNextPage,
	canPreviousPage,
	gotoPage,
	previousPage,
	nextPage,
	setPageSize,
	styles,
}: TableFooterProps) {
	const { t } = useTranslation("layout");

	return rows.length > 5 ? (
		<footer
			className={`grid grid-cols-3 items-center rounded-[0.625rem] bg-nativeWhite p-4 ${styles?.container?.className}`}
			style={styles?.container?.style}
		>
			<div className="flex items-center">
				<span>{t("Show")}</span>

				<select
					value={pageSize}
					onChange={(e) => setPageSize(Number(e.target.value))}
					className="w-16 rounded-lg px-2 text-muted ms-4 focus:outline-none "
				>
					<option value={5}>5</option>
					<option value={10}>10</option>
					<option value={20}>20</option>
				</select>
			</div>

			<div className="justify-self-center">
				{pageOptions.length > 1 && (
					<Pagination
						pageIndex={pageIndex}
						pageCount={pageOptions.length}
						pageSize={pageSize}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						canPreviousPage={canPreviousPage}
					/>
				)}
			</div>
		</footer>
	) : null;
}
