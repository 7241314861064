import { useMemo } from "react";
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
	Hooks,
	Column,
	useRowSelect,
} from "react-table";
import { TableHeader } from "../TableHeader/TableHeader";
import { Spinner } from "src/components";
import { TableBody, TableFooter, useTablePrint } from "src/features";
import { Style } from "src/@types";

type FullTableProps<DataType> = {
	data: ({ date: Date } & (DataType | any))[];
	columns: any[];
	tableHooks?: (hooks: Hooks<any>) => void;
	hiddenColumns?: string[];
	loading?: boolean;

	headerProps?: Pick<
		React.ComponentProps<typeof TableHeader>,
		"title" | "button" | "dateStates" | "categoryFilter"
	> & { hasPrint?: boolean; searchProps?: { placeholder: string } };

	bodyProps?: Pick<
		React.ComponentProps<typeof TableBody>,
		"alignments" | "size" | "styles" | "otherProps"
	>;

	noData?: React.ReactElement;
	hasPagination?: boolean;
	componentAfterHeader?: React.ElementType;
	componentAfterBody?: React.ElementType;
	styles?: { container?: Style; headerAndBodyContainer?: Style };
};

export function FullTable<DataType>({
	data: DATA,
	columns,
	tableHooks,
	hiddenColumns,
	loading,
	headerProps,
	bodyProps,
	hasPagination = true,
	noData,
	componentAfterHeader: ComponentAfterHeader,
	componentAfterBody: ComponentAfterBody,
	styles,
}: FullTableProps<DataType>) {
	const data = useMemo(() => DATA, [DATA]);

	const defaultTableHooks = (hooks: Hooks<DataType[]>) => {
		hooks.visibleColumns.push((cols: Column<DataType[]>[]) => [...cols]);
	};

	const {
		headerGroups,
		canNextPage,
		canPreviousPage,
		pageOptions,
		page,
		rows,
		selectedFlatRows,
		state: { globalFilter, pageIndex, pageSize },
		getTableProps,
		getTableBodyProps,
		prepareRow,
		setGlobalFilter,
		nextPage,
		previousPage,
		gotoPage,
		setPageSize,
	} = useTable(
		{
			columns,
			data,
			initialState: { hiddenColumns: hiddenColumns || [] },
			autoResetGlobalFilter: false,
		},
		tableHooks || defaultTableHooks,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect
	);

	const { handlePrint, printRef } = useTablePrint(rows, pageSize, setPageSize);

	return (
		<section>
			{DATA.length !== 0 || !noData ? (
				<div
					className={styles?.container?.className}
					style={styles?.container?.style}
				>
					<div
						className={`
							bg-nativeWhite
							${headerProps ? "rounded-[0.625rem] px-5 pt-8" : ""}
							${styles?.headerAndBodyContainer?.className}
						`}
						style={styles?.headerAndBodyContainer?.style}
					>
						{headerProps && (
							<TableHeader
								{...headerProps}
								count={rows.length}
								selectedFlatRows={selectedFlatRows}
								handlePrint={headerProps.hasPrint ? handlePrint : undefined}
								searchProps={{
									placeholder: headerProps.searchProps?.placeholder,
									globalFilterState: { globalFilter, setGlobalFilter },
								}}
							/>
						)}

						{ComponentAfterHeader && <ComponentAfterHeader />}

						{!loading ? (
							<TableBody
								{...bodyProps}
								getTableProps={getTableProps}
								getTableBodyProps={getTableBodyProps}
								headerGroups={headerGroups}
								rows={hasPagination ? page : rows}
								prepareRow={prepareRow}
								ref={printRef}
							/>
						) : (
							<Spinner />
						)}
					</div>

					{ComponentAfterBody && <ComponentAfterBody />}

					{hasPagination && (
						<>
							{!loading && (
								<TableFooter
									rows={rows}
									pageSize={pageSize}
									pageIndex={pageIndex}
									pageOptions={pageOptions}
									canNextPage={canNextPage}
									canPreviousPage={canPreviousPage}
									gotoPage={gotoPage}
									previousPage={previousPage}
									nextPage={nextPage}
									setPageSize={setPageSize}
								/>
							)}
						</>
					)}
				</div>
			) : (
				<div className="my-24">{noData}</div>
			)}
		</section>
	);
}
