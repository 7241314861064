import { SVGProps } from "react";

export const UserPlusIcon = ({ width, height }: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.3334 17.5V15.8333C13.3334 14.9493 12.9822 14.1014 12.3571 13.4763C11.7319 12.8512 10.8841 12.5 10 12.5H4.16671C3.28265 12.5 2.43481 12.8512 1.80968 13.4763C1.18456 14.1014 0.833374 14.9493 0.833374 15.8333V17.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428 10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333 2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428 5.24238 9.16667 7.08333 9.16667Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.6666 6.66699V11.667"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.1666 9.16699H14.1666"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const UsersIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.8332 25.4897V23.1564C19.8332 21.9187 19.3415 20.7318 18.4663 19.8566C17.5912 18.9814 16.4042 18.4897 15.1665 18.4897H5.83317C4.59549 18.4897 3.40851 18.9814 2.53334 19.8566C1.65817 20.7318 1.1665 21.9187 1.1665 23.1564V25.4897"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5002 13.8231C13.0775 13.8231 15.1668 11.7337 15.1668 9.15641C15.1668 6.57908 13.0775 4.48975 10.5002 4.48975C7.92283 4.48975 5.8335 6.57908 5.8335 9.15641C5.8335 11.7337 7.92283 13.8231 10.5002 13.8231Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.8335 25.4897V23.1564C26.8327 22.1224 26.4886 21.1179 25.8551 20.3007C25.2216 19.4835 24.3346 18.8999 23.3335 18.6414"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6665 4.64136C19.6703 4.89838 20.56 5.48218 21.1954 6.30072C21.8308 7.11926 22.1757 8.12599 22.1757 9.16219C22.1757 10.1984 21.8308 11.2051 21.1954 12.0237C20.56 12.8422 19.6703 13.426 18.6665 13.683"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const UserIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30 31.5V28.5C30 26.9087 29.3679 25.3826 28.2426 24.2574C27.1174 23.1321 25.5913 22.5 24 22.5H12C10.4087 22.5 8.88258 23.1321 7.75736 24.2574C6.63214 25.3826 6 26.9087 6 28.5V31.5"
				stroke="currentColor"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18 16.5C21.3137 16.5 24 13.8137 24 10.5C24 7.18629 21.3137 4.5 18 4.5C14.6863 4.5 12 7.18629 12 10.5C12 13.8137 14.6863 16.5 18 16.5Z"
				stroke="currentColor"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
