import { Style } from "src/@types";
import { ChevronDownIcon } from "src/components";
import { useToggle } from "src/hooks";

export type DropdownMenuHandlerProps = {
	title: string;
	children: React.ReactNode;
	defaultState?: boolean;
	styles?: {
		container?: Style;
		header?: Style & { title?: Style; arrow?: Style };
		body?: Style;
	};
};

export function DropdownMenuHandler({
	title,
	children,
	defaultState,
	styles,
}: DropdownMenuHandlerProps) {
	const [isDropdownOpen, toggleDropdown] = useToggle(defaultState || false);

	return (
		<div
			className={styles?.container?.className}
			style={styles?.container?.style}
		>
			<header
				className={`
					flex cursor-pointer items-center justify-between gap-4 py-3 px-4
					${styles?.header?.className}
				`}
				style={styles?.header?.style}
				onClick={toggleDropdown}
			>
				<span
					className={`text-sm font-bold leading-5 text-dark first-letter:capitalize ${
						styles?.header?.title?.className || ""
					}`}
					style={styles?.header?.title?.style}
				>
					{title}
				</span>

				<span
					className={`
						block text-gray-800
						${isDropdownOpen && "rotate-180"}
						${styles?.header?.arrow?.className || ""}
					`}
					style={styles?.header?.arrow?.style}
				>
					<ChevronDownIcon width="14px" height="14px" />
				</span>
			</header>

			{isDropdownOpen && (
				<div
					className={`py-3 ${styles?.body?.className}`}
					style={styles?.body?.style}
				>
					{children}
				</div>
			)}
		</div>
	);
}
