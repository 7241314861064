import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { Backdrop } from "../Backdrop/Backdrop";

type PopupProps = {
	children: ReactNode;
	show: boolean;
	width?: string;
	close: () => void;
	withoutBackground?: boolean;
};

export const MenuPopup = ({
	children,
	show,
	width,
	withoutBackground,
	close,
}: PopupProps) => {
	return show ? (
		<>
			<Backdrop
				show={show}
				clickOutside={close}
				withBackground={!withoutBackground}
			/>

			<div
				className="scrollBar fixed left-1/2 top-1/2 z-50 max-h-screen -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto bg-white shadow-2xl ring-2 ring-gray-200"
				style={{ width: width ?? "40%", zIndex: 999 }}
			>
				{children}
			</div>
		</>
	) : null;
};

type MenuProps = {
	show: boolean;
	close: () => void;
	children: ReactNode;
	className?: string;
	position?: "absolute" | "fixed";
};

export const Menu = ({
	show,
	close,
	children,
	className,
	position = "absolute",
}: MenuProps) => {
	const menuRef = useRef<HTMLUListElement | null>(null);
	const [menuPosition, setMenuPosition] = useState("");

	useLayoutEffect(() => {
		if (menuRef.current && position === "absolute") {
			const { top, left, width } = menuRef.current.getBoundingClientRect();
			const { innerHeight, innerWidth } = window;

			if (top + 200 > innerHeight) {
				setMenuPosition("bottom-6 -right-10");
			} else {
				setMenuPosition("top-6 -right-10");
			}

			if (left + width > innerWidth) {
				setMenuPosition((prev) => prev.replace("-right-10", "-left-10"));
			}
		}
	}, [show]);

	const handleClose = () => {
		close();
	};

	return show ? (
		<>
			<Backdrop show={show} clickOutside={handleClose} withBackground />

			<ul
				ref={menuRef}
				className={`
					z-50 w-max divide-y divide-lightGray rounded-lg bg-white py-1 shadow-lg pe-1
					${position === "absolute" ? "absolute" : "fixed"}
					${menuPosition}
					${className}
				`}
			>
				{children}
			</ul>
		</>
	) : null;
};
