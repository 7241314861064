import { useState } from "react";
import {
	Control,
	FieldError,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Size, Variant, PatientBasicInfo } from "src/@types";
import { useAppSelector } from "src/redux";
import {
	ListItem,
	SearchInput,
	PatientNamePhone,
	SearchIcon,
} from "src/components";

type SearchPatientProps = {
	register: UseFormRegister<any>;
	control: Control<any, object>;
	setValue: UseFormSetValue<any>;
	error?: FieldError | undefined;
	setPatientId: React.Dispatch<React.SetStateAction<any>>;
	patientId: number | undefined;
	variant?: Variant;
	inputSize?: Size;
};

export const SearchPatient = ({
	register,
	control,
	setValue,
	error,
	patientId,
	setPatientId,
	variant,
	inputSize,
}: SearchPatientProps) => {
	const patients = useAppSelector((state) => state.patients.patients);
	const [results, setResults] = useState<PatientBasicInfo[]>(
		patients ? Object.values(patients) : []
	);

	const [errorMsg, setErrorMSg] = useState<any>("");
	const { t, i18n } = useTranslation("layout");

	const fetchData = (querySearch: string) => {
		const results = patients
			? Object.values(patients).filter(
					(patient) =>
						patient.name.toLowerCase().includes(querySearch.toLowerCase()) ||
						patient.phone.includes(querySearch)
			  )
			: [];

		setResults(results);

		if (results.length === 0) {
			setErrorMSg(
				i18n.language === "en" ? "No patients found" : " لا توجد نتائج بحث"
			);
		} else {
			setErrorMSg("");
		}
	};

	return (
		<SearchInput
			type="text"
			name="patientId"
			id="patientId"
			variant={variant}
			placeholder={t("Forms.Patient_Name_Input.Placeholder")}
			label={t("Forms.Patient_Name_Input.Label")}
			icon={<SearchIcon />}
			register={register}
			control={control}
			setValue={setValue}
			correspondingValue={patientId}
			setCorrespondingValue={setPatientId}
			error={error}
			requiredStar
			listItems={results.map((result, i) => (
				<ListItem value={result.id} key={i} displayedName={result.name}>
					<PatientNamePhone user={result} />
				</ListItem>
			))}
			queryFunc={fetchData}
			queryErrorMsg={errorMsg}
			inputSize={inputSize}
		/>
	);
};
