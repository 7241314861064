import { Cell, Column, Hooks } from "react-table";
import { ActionButtons, Btn } from "src/components";

type UseEditableTableHooksProps = {
	shouldRenderInput: (id: number) => boolean;
	cancelProcess: () => void;
	enableEditItem: (data: any) => void;
	openDeletePopup: (deleteData?: { id: number } | undefined) => void;
};

export function useEditableTableHooks({
	shouldRenderInput,
	cancelProcess,
	enableEditItem,
	openDeletePopup,
}: UseEditableTableHooksProps) {
	const tableHooks = (hooks: Hooks<any>) => {
		hooks.visibleColumns.push((cols: Column<any>[]) => [
			...cols,
			{
				id: "more",
				Header: "actions",
				Cell: ({ row }: Cell<any>) => {
					return shouldRenderInput(row.original.id) ? (
						<div className="flex items-center gap-x-3">
							<Btn
								type="submit"
								variant="success"
								size="xs"
								className="!text-sm"
							>
								Save
							</Btn>
							<Btn
								type="button"
								variant="secondary"
								size="xs"
								className="!text-sm"
								onClick={cancelProcess}
							>
								Cancel
							</Btn>
						</div>
					) : (
						<ActionButtons
							editButtonClickHandler={() => enableEditItem(row.original)}
							deleteButtonClickHandler={() =>
								openDeletePopup({ id: row.original.id })
							}
							iconSize={{ width: 24, height: 24 }}
						/>
					);
				},
				disableSortBy: true,
			},
		]);
	};

	return { tableHooks };
}
