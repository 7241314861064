import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CustomPhoneInput } from "src/components";

type PhoneInputProps = {
	control: Control<any, object>;
};

export const PhoneNumberInput = ({ control }: PhoneInputProps) => {
	const { t } = useTranslation("scheduler");

	return (
		<div className="mb-3">
			<Controller
				control={control}
				name="phone"
				render={({
					field: { onChange, onBlur, value },
					fieldState: { error },
				}) => (
					<>
						<CustomPhoneInput
							label={t(
								"Appointments.Add_Appointment_Form.Inputs.Patient_Phone.Label"
							)}
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							error={error}
							variant="standard"
							requiredStar
						/>
					</>
				)}
			/>
		</div>
	);
};
