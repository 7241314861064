import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

interface ModalState {
	headerTitle: string;
}

const initialState: ModalState = {
	headerTitle: "",
};

const headerTitleSlice = createSlice({
	name: "headerTitle",
	initialState,
	reducers: {
		changeHeader: (
			state: ModalState,
			action: PayloadAction<"Header.Main" | "Header.Patient_List" | string>
		) => {
			state.headerTitle = action.payload;
		},
	},
});

export const headerTitleActions = headerTitleSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const headerTitle = (state: RootState) => state.headerTitle.headerTitle;

export const headerTitleReducer = headerTitleSlice.reducer;
