import { Style } from "src/@types";
import { Title } from "src/components";
import { StatusStatisticCard } from "./StatusStatisticCard/StatusStatisticCard";

type StatusStatisticCard = React.ComponentProps<
	typeof StatusStatisticCard
>["card"];

type StatusStatisticCardsProps = {
	cards: StatusStatisticCard[];
	styles?: { container?: Style; cardsContainer?: Style };
	titleProps?: React.ComponentProps<typeof Title>;
	cardStyles?: React.ComponentProps<typeof StatusStatisticCard>["styles"];
};

export function StatusStatisticCards({
	cards,
	titleProps,
	styles,
	cardStyles,
}: StatusStatisticCardsProps) {
	const cardsList = cards.map((card, index) => (
		<StatusStatisticCard key={index} card={card} styles={cardStyles} />
	));

	return (
		<section
			className={`mb-16 ${styles?.container?.className}`}
			style={styles?.container?.style}
		>
			{titleProps && (
				<Title styles={{ container: { className: "mb-4" } }} {...titleProps} />
			)}

			<div
				className={`
					flex items-center gap-2.5
					${styles?.cardsContainer?.className}
				`}
				style={styles?.cardsContainer?.style}
			>
				{cardsList}
			</div>
		</section>
	);
}
