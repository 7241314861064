import { AUTH } from "./auth";
import { ASSISTANT } from "./assistant";
import { DENTIST } from "./dentist";
import { ADMIN } from "./admin";

export const ROUTES = {
	LANDING: "https://beclarified.com",
	ON_BOARDING: {
		BASE: "/onboarding",
	},
	AUTH,
	ASSISTANT,
	DENTIST,
	ADMIN,
};
