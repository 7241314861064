import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BtnWithIcon, UploadIcon, Loader } from "src/components";
import { useAxios } from "src/hooks";

type FormDataType = {
	files: FileList;
};

type UploadFileButtonProps<ResponseType> = {
	url: string;
	handleSuccess?: (file: ResponseType) => void;
};

export function UploadFileButton<ResponseType>({
	url,
	handleSuccess,
}: UploadFileButtonProps<ResponseType>) {
	const { t } = useTranslation("global");

	const { makeRequest, loading } = useAxios<ResponseType>({
		runNow: false,
		configs: { method: "POST" },
		handleSuccess: (data) => {
			handleSuccess?.(data);
			toast.success(t("Messages.Upload_File.Success"));
		},
		handleError: () => {
			toast.error(t("Messages.Upload_File.Error"));
		},
	});

	const onSubmit = (data: FormDataType) => {
		const formData = new FormData();

		for (let i = 0; i < data.files.length; i++) {
			formData.append("files", data.files[i]);
		}

		makeRequest({ configs: { url, data: formData } });
	};

	return (
		<form>
			<label
				htmlFor="file"
				className={`
					cursor-pointer
					${loading ? "pointer-events-none cursor-not-allowed" : ""}
				`}
			>
				<BtnWithIcon
					icon={<UploadIcon />}
					variant={loading ? "secondary" : "primary"}
					size="md"
					className={`
						pointer-events-none
						${loading ? "cursor-not-allowed" : ""}
					`}
					disabled={loading}
				>
					{!loading ? (
						t("Buttons.Upload_File")
					) : (
						<Loader className="!text-primary" />
					)}
				</BtnWithIcon>
			</label>

			<input
				type="file"
				id="file"
				name="file"
				className="hidden"
				multiple
				onChange={(e) => {
					if (e.target.files) {
						onSubmit({ files: e.target.files });
					}
				}}
			/>
		</form>
	);
}
