import { FormWrapper } from "../../LoginPage/FormWrapper/FormWrapper";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, PasswordInput, CustomPhoneInput, Btn } from "src/components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "src/constants/index";
import { phoneValidation } from "src/utils";
import { useAxios, useQuery } from "src/hooks";
import { authenticate, useAppDispatch } from "src/redux";
import { RolesEnum } from "src/@types";
import { toast } from "react-toastify";

type FormDataType = {
	clinicName: string;
	name: string;
	phone: string;
	password: string;
};

const schema = yup.object().shape({
	clinicName: yup.string().required("Required field"),
	name: yup.string().required("Required field"),
	phone: phoneValidation(),
	password: yup.string().required("Required field"),
});

export const Register = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const isOnboarding =
		location.pathname.toLowerCase() === ROUTES.ON_BOARDING.BASE.toLowerCase();

	const query = useQuery();
	const clinicName = query.get("clinicName");
	const fullName = query.get("fullName");
	const phone = query.get("phone");
	const code = query.get("code");

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm<FormDataType>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: {
			clinicName: clinicName || "",
			name: fullName || "",
			phone: phone ? `+${phone}` : "",
			password: "",
		},
	});

	const { makeRequest } = useAxios<string>({ runNow: false });

	const authHandler = (data: FormDataType) => {
		if (isOnboarding) {
			makeRequest({
				configs: {
					url: "/onboard",
					method: "POST",
					params: {
						phone: data.phone.replace(/\s/g, ""),
						code: code,
						password: data.password,
					},
				},
				handleSuccess: () => {
					dispatch(
						authenticate({
							username: data.phone.replace(/\s/g, ""),
							password: data.password,
						})
					)
						.unwrap()
						.then(({ userRole: role }) => {
							if (role === RolesEnum.DENTIST) {
								window.location.href = ROUTES.DENTIST.HOME;
							}

							if (role === RolesEnum.ASSISTANT) {
								window.location.href = ROUTES.ASSISTANT.SCHEDULER;
							}

							if (role === RolesEnum.ADMIN) {
								window.location.href = ROUTES.ADMIN.HOME;
							}
						})
						.catch((error) => {
							toast.error("Incorrect username or password");
						});
				},
			});
		}

		if (!isOnboarding) {
			makeRequest({
				configs: {
					url: "/register",
					method: "POST",
					params: {
						phone: data.phone.replace(/\s/g, ""),
						password: data.password,
						clinic_name: data.clinicName,
						full_name: data.name,
					},
				},
				handleSuccess: () => {
					history.push(
						`${ROUTES.AUTH.VERIFICATION_CODE}?phone=${data.phone}&name=${data.name}&clinicName=${data.clinicName}&type=activate`,
						{ newUserPassword: data.password }
					);
				},
				handleError: (error) => {
					toast.error(error.response.data.detail);
				},
			});
		}
	};

	return (
		<FormWrapper className="pt-20">
			<div className="text-center">
				<h2 className="mb-8 text-2xl  text-dark">Get started for free</h2>

				<form
					onSubmit={handleSubmit(authHandler)}
					noValidate
					autoComplete="off"
				>
					<div className="mb-5">
						<Input
							type="text"
							name="clinicName"
							id="clinicName"
							label="Clinic Name"
							placeholder={"Clinic Name"}
							register={register}
							error={errors.clinicName}
							variant="basic"
							inputSize="md"
							autoFocus={!isOnboarding}
							hasValue={!!clinicName}
							disabled={isOnboarding}
							readOnly={isOnboarding}
						/>
					</div>
					<div className="mb-5">
						<Input
							type="text"
							name="name"
							id="name"
							placeholder={"Your Name"}
							register={register}
							error={errors.name}
							label="Your Name"
							variant="basic"
							inputSize="md"
							hasValue={!!fullName}
							readOnly={isOnboarding}
							disabled={isOnboarding}
						/>
					</div>
					<div className="mb-5">
						<Controller
							control={control}
							name="phone"
							render={({
								field: { onChange, onBlur, value },
								fieldState: { error },
							}) => (
								<CustomPhoneInput
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									variant="basic"
									size="md"
									label="Phone Number"
									disabled={isOnboarding}
									hasValue={!!phone}
								/>
							)}
						/>
					</div>
					<div className="mb-8">
						<PasswordInput
							name="password"
							id="password"
							placeholder="Password"
							register={register}
							error={errors.password}
							variant="basic"
							inputSize="md"
							label="Create password"
							autoComplete="off"
						/>
					</div>
					<Btn
						variant="primary"
						size="md"
						type="submit"
						rounded="rounded-lg"
						className={` mb-4 w-full text-lg ${
							isValid ? "opacity-100" : "cursor-not-allowed opacity-60"
						}`}
						disabled={!isValid}
					>
						Create account
					</Btn>
					<p className="mb-8 text-dark">
						Already have an account?{" "}
						<Link to={ROUTES.AUTH.LOGIN} className="text-primary ">
							Log In
						</Link>
					</p>
					<p className="text-sm text-gray-800">
						By clicking “Create account”, I agree to BeClarified’s{" "}
						<span className="font-semibold">Terms & Conditions</span>.
					</p>
				</form>
			</div>
		</FormWrapper>
	);
};
