import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "ar",
		debug: false,
		detection: {
			order: ["localStorage", "cookie", "queryString"],
			caches: ["cookie", "localStorage"],
		},
		ns: [
			"layout",
			"financial",
			"lab",
			"patientList",
			"patientFile",
			"stock",
			"tasks",
			"global",
			"scheduler",
			"settings",
			"patientGroups",
		],
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
