export function TotalPhoneBigIcon() {
	return (
		<svg
			width="46"
			height="46"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31.7611 35.3549C22.222 30.9849 14.5334 23.2962 10.1633 13.764C8.34697 9.78992 7.11107 5.49494 6.55115 0.988281C5.54057 0.988281 4.48901 0.988281 3.56037 0.988281C1.9967 0.988281 0.733473 2.29931 0.835897 3.85615C2.24935 25.7681 19.7433 43.2689 41.6552 44.6824C43.2189 44.7848 44.53 43.5147 44.53 41.9442V38.9603C40.0233 38.4072 35.7283 37.1713 31.7611 35.3549Z"
				fill="white"
			/>
			<path
				d="M31.7632 35.3549C22.2241 30.9848 14.5355 23.2962 10.1654 13.764C8.3491 9.78992 7.1132 5.49494 6.55328 0.988281C5.5427 0.988281 4.49114 0.988281 3.5625 0.988281C3.56933 0.988281 3.57616 0.988281 3.58298 0.988281C4.18387 5.84318 5.51538 10.4796 7.48192 14.7609C12.1934 25.0443 20.4829 33.3338 30.7663 38.0384C35.0476 39.9982 39.6772 41.3297 44.5321 41.9306V38.9603C40.0254 38.4072 35.7305 37.1713 31.7632 35.3549Z"
				fill="#BDC3D8"
			/>
			<path
				d="M41.8337 45.2017C41.7654 45.2017 41.6903 45.2017 41.622 45.1949C19.5053 43.7678 1.75179 26.0074 0.324681 3.8907C0.270055 3.00985 0.570485 2.16314 1.18503 1.50763C1.79957 0.852113 2.66677 0.476562 3.56127 0.476562H6.55206C6.81153 0.476562 7.03003 0.667756 7.05734 0.927231C7.6036 5.3178 8.80538 9.56498 10.6285 13.5459C14.9508 22.9825 22.5302 30.5619 31.9737 34.8842C35.9546 36.7073 40.2017 37.9091 44.5923 38.4554C44.8518 38.4895 45.043 38.708 45.043 38.9607V41.9446C45.043 42.8459 44.6674 43.7131 44.0119 44.3277C43.4042 44.8944 42.6394 45.2017 41.8337 45.2017ZM3.56127 1.5008C2.94673 1.5008 2.35266 1.76027 1.92931 2.21094C1.51278 2.65477 1.30795 3.22836 1.34209 3.82924C2.73505 25.4339 20.0789 42.7777 41.6835 44.1775C42.2844 44.2116 42.858 44.0068 43.3018 43.5902C43.7525 43.1669 44.0119 42.5728 44.0119 41.9515V39.4182C39.676 38.8378 35.4834 37.6292 31.5435 35.8197C21.8815 31.3949 14.1246 23.638 9.69304 13.976C7.89038 10.0361 6.67496 5.84358 6.09455 1.5008H3.56127Z"
				fill="#21365F"
			/>
			<path
				d="M12.6795 13.7571C14.0383 13.7571 14.9055 12.33 14.3046 11.1078C13.0482 8.54035 12.0377 5.83635 11.3002 3.01628C10.9861 1.82133 9.90724 0.988281 8.6645 0.988281C8.02947 0.988281 7.30567 0.988281 6.55457 0.988281C7.11448 5.49494 8.35038 9.78992 10.1667 13.764C10.952 13.7571 11.785 13.7571 12.6795 13.7571Z"
				fill="white"
			/>
			<path
				d="M14.3011 11.1078C13.0447 8.54035 12.0341 5.83635 11.2966 3.01628C10.9825 1.82133 9.90368 0.988281 8.66094 0.988281C8.59948 0.988281 8.53119 0.988281 8.47656 0.988281C8.5107 1.07022 8.54485 1.15216 8.56533 1.24092C9.43935 4.57312 10.6957 7.75509 12.2867 10.7254C12.5872 11.2921 12.1911 11.9818 11.5493 11.9818H9.40522C9.64421 12.5827 9.89685 13.1767 10.17 13.7571C10.9552 13.7571 11.7883 13.7571 12.6828 13.7571C14.0348 13.7571 14.902 12.33 14.3011 11.1078Z"
				fill="#BDC3D8"
			/>
			<path
				d="M12.6788 14.2697H10.166C9.96803 14.2697 9.78367 14.1536 9.70173 13.9692C7.83079 9.89274 6.60171 5.54313 6.04179 1.05013C6.02131 0.906741 6.0691 0.756521 6.16469 0.647269C6.26029 0.538017 6.40368 0.476562 6.54708 0.476562H8.65701C10.1251 0.476562 11.4156 1.46666 11.7844 2.88694C12.5082 5.63873 13.5051 8.32907 14.7547 10.8828C15.1166 11.6203 15.0756 12.4807 14.6386 13.1771C14.2289 13.86 13.4846 14.2697 12.6788 14.2697ZM10.5006 13.2454H12.6788C13.1363 13.2454 13.5392 13.0201 13.785 12.6377C14.0308 12.2417 14.0513 11.7569 13.8465 11.3403C12.5628 8.72511 11.5385 5.96649 10.8011 3.15324C10.5484 2.18363 9.6676 1.50763 8.66384 1.50763H7.14113C7.70105 5.57727 8.82772 9.52401 10.5006 13.2454Z"
				fill="#21365F"
			/>
			<path
				d="M34.0443 36.3044C37.3629 37.6017 40.8794 38.5031 44.5325 38.9606V36.8575C44.5325 35.6147 43.6927 34.529 42.4909 34.2149C39.664 33.4706 36.9532 32.4532 34.3789 31.1968C33.1703 30.6028 31.7637 31.4973 31.7637 32.8424V35.3484C35.7377 37.1647 40.0259 38.4006 44.5325 38.9606C40.8726 38.5099 37.356 37.6017 34.0443 36.3044Z"
				fill="white"
			/>
			<path
				d="M42.4822 34.2156C39.6553 33.4713 36.9444 32.4539 34.3702 31.1975C33.2981 30.6718 32.0759 31.3204 31.8164 32.4061C31.9871 32.4335 32.1578 32.4813 32.3217 32.5632C35.1554 33.9562 38.1667 35.0487 41.3145 35.8066C41.9973 35.9705 42.4822 36.5646 42.4822 37.261V38.654C42.687 38.6881 42.8987 38.7291 43.1035 38.7633C43.3015 38.7974 43.5064 38.8179 43.7044 38.852C43.9775 38.893 44.2507 38.934 44.5306 38.9681V36.865C44.5306 35.6154 43.6908 34.5366 42.4822 34.2156Z"
				fill="#BDC3D8"
			/>
			<path
				d="M44.5329 39.4783C44.5125 39.4783 44.4919 39.4783 44.4715 39.4714C39.9785 38.9115 35.6289 37.6824 31.5524 35.8183C31.368 35.7364 31.252 35.552 31.252 35.354V32.848C31.252 32.0354 31.6616 31.2912 32.3581 30.861C33.041 30.4376 33.8809 30.3898 34.6047 30.7449C37.1653 32.0013 39.8624 33.0051 42.621 33.7289C44.0481 34.1044 45.0451 35.3949 45.0451 36.863V38.9593C45.0451 38.9593 45.0451 38.9593 45.0451 38.9661C45.0451 39.1095 44.9836 39.2529 44.8743 39.3485C44.7788 39.4305 44.6558 39.4783 44.5329 39.4783ZM34.1198 35.7978C34.154 35.8046 34.1881 35.8115 34.2223 35.8251C37.3701 37.0542 40.6613 37.9146 44.014 38.3789V36.8562C44.014 35.8525 43.3312 34.9648 42.3547 34.7121C39.5346 33.9747 36.776 32.9436 34.1471 31.6599C33.7374 31.4619 33.28 31.4824 32.8908 31.7282C32.5015 31.974 32.2694 32.3905 32.2694 32.848V35.0262C32.8839 35.2925 33.4985 35.552 34.1198 35.7978Z"
				fill="#21365F"
			/>
			<path
				d="M24.0458 24.2044H36.3366L44.5306 29.667V3.71959C44.5306 2.21054 43.3083 0.988281 41.7992 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V21.4731C21.3145 22.9821 22.5367 24.2044 24.0458 24.2044Z"
				fill="#BDC3D8"
			/>
			<path
				d="M23.3629 21.4731H36.3639C36.7941 21.4731 37.2038 21.6096 37.552 21.8555L41.2598 24.5048C41.4852 24.6687 41.7992 24.5048 41.7992 24.2249V3.03676C41.7992 1.90327 40.8843 0.988281 39.7508 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V19.4246C21.3145 20.5513 22.2294 21.4731 23.3629 21.4731Z"
				fill="white"
			/>
			<path
				d="M44.531 30.1785C44.4286 30.1785 44.333 30.1512 44.2442 30.0898L36.1801 24.7091H24.0462C22.2572 24.7091 20.8028 23.2547 20.8028 21.4657V3.71218C20.8028 1.92317 22.2572 0.46875 24.0462 0.46875H41.7997C43.5887 0.46875 45.0431 1.92317 45.0431 3.71218V29.6596C45.0431 29.8508 44.9407 30.0215 44.77 30.1103C44.6949 30.158 44.613 30.1785 44.531 30.1785ZM24.0462 1.49981C22.824 1.49981 21.827 2.49674 21.827 3.719V21.4725C21.827 22.6947 22.824 23.6917 24.0462 23.6917H36.3371C36.4395 23.6917 36.5351 23.719 36.6239 23.7804L44.0189 28.7104V3.719C44.0189 2.49674 43.022 1.49981 41.7997 1.49981H24.0462Z"
				fill="#21365F"
			/>
			<path
				d="M39.8061 10.092H35.4223V5.70828C35.4223 5.36004 35.1424 5.08008 34.7941 5.08008H31.0386C30.6903 5.08008 30.4104 5.36004 30.4104 5.70828V10.092H26.0267C25.6784 10.092 25.3984 10.372 25.3984 10.7202V14.4758C25.3984 14.824 25.6784 15.104 26.0267 15.104H30.4104V19.4877C30.4104 19.836 30.6903 20.1159 31.0386 20.1159H34.7941C35.1424 20.1159 35.4223 19.836 35.4223 19.4877V15.104H39.8061C40.1543 15.104 40.4343 14.824 40.4343 14.4758V10.7202C40.4343 10.372 40.1543 10.092 39.8061 10.092Z"
				fill="#BDC3D8"
			/>
			<path
				d="M38.5575 10.092H35.4301V5.70828C35.4301 5.36004 35.1502 5.08008 34.8019 5.08008H31.0464C30.6982 5.08008 30.4182 5.36004 30.4182 5.70828V10.092H26.0344C25.6862 10.092 25.4062 10.372 25.4062 10.7202V14.4758C25.4062 14.824 25.6862 15.104 26.0344 15.104H30.4182V18.2313H32.9242C33.2724 18.2313 33.5524 17.9514 33.5524 17.6031V14.4758C33.5524 13.7861 34.1123 13.2262 34.8019 13.2262H37.9293C38.2775 13.2262 38.5575 12.9462 38.5575 12.598V10.092Z"
				fill="white"
			/>
			<path
				d="M34.8025 20.6206H31.047C30.4188 20.6206 29.9067 20.1085 29.9067 19.4803V15.6087H26.035C25.4068 15.6087 24.8947 15.0966 24.8947 14.4684V10.7128C24.8947 10.0846 25.4068 9.5725 26.035 9.5725H29.9067V5.70087C29.9067 5.07267 30.4188 4.56055 31.047 4.56055H34.8025C35.4307 4.56055 35.9428 5.07267 35.9428 5.70087V9.5725H39.8145C40.4427 9.5725 40.9548 10.0846 40.9548 10.7128V14.4684C40.9548 15.0966 40.4427 15.6087 39.8145 15.6087H35.9428V19.4803C35.936 20.1085 35.4307 20.6206 34.8025 20.6206ZM26.035 10.6036C25.9736 10.6036 25.919 10.6514 25.919 10.7196V14.4752C25.919 14.5366 25.9667 14.5913 26.035 14.5913H30.4188C30.6988 14.5913 30.9309 14.8234 30.9309 15.1034V19.4871C30.9309 19.5486 30.9787 19.6032 31.047 19.6032H34.8025C34.864 19.6032 34.9186 19.5554 34.9186 19.4871V15.1034C34.9186 14.8234 35.1508 14.5913 35.4307 14.5913H39.8145C39.8759 14.5913 39.9306 14.5366 39.9306 14.4752V10.7196C39.9306 10.6582 39.8827 10.6036 39.8145 10.6036H35.4307C35.1508 10.6036 34.9186 10.3714 34.9186 10.0914V5.7077C34.9186 5.64625 34.8708 5.59162 34.8025 5.59162H31.047C30.9855 5.59162 30.9309 5.64625 30.9309 5.7077V10.0914C30.9309 10.3714 30.6988 10.6036 30.4188 10.6036H26.035Z"
				fill="#21365F"
			/>
			<path
				d="M12.6986 43.1531H11.7563C11.4763 43.1531 11.2441 42.921 11.2441 42.641C11.2441 42.3611 11.4763 42.1289 11.7563 42.1289H12.6986C12.9785 42.1289 13.2107 42.3611 13.2107 42.641C13.2107 42.921 12.9854 43.1531 12.6986 43.1531Z"
				fill="#21365F"
			/>
			<path
				d="M15.8509 43.1531H14.9086C14.6286 43.1531 14.3965 42.921 14.3965 42.641C14.3965 42.3611 14.6286 42.1289 14.9086 42.1289H15.8509C16.1309 42.1289 16.363 42.3611 16.363 42.641C16.363 42.921 16.1309 43.1531 15.8509 43.1531Z"
				fill="#21365F"
			/>
			<path
				d="M13.8031 45.2019C13.5232 45.2019 13.291 44.9697 13.291 44.6898V43.7406C13.291 43.4607 13.5232 43.2285 13.8031 43.2285C14.0831 43.2285 14.3153 43.4607 14.3153 43.7406V44.6829C14.3153 44.9697 14.0831 45.2019 13.8031 45.2019Z"
				fill="#21365F"
			/>
			<path
				d="M13.8031 42.0466C13.5232 42.0466 13.291 41.8144 13.291 41.5345V40.5922C13.291 40.3122 13.5232 40.0801 13.8031 40.0801C14.0831 40.0801 14.3153 40.3122 14.3153 40.5922V41.5345C14.3153 41.8213 14.0831 42.0466 13.8031 42.0466Z"
				fill="#21365F"
			/>
			<path
				d="M3.82161 29.4988H2.87931C2.59935 29.4988 2.36719 29.2667 2.36719 28.9867C2.36719 28.7068 2.59935 28.4746 2.87931 28.4746H3.82161C4.10157 28.4746 4.33373 28.7068 4.33373 28.9867C4.33373 29.2667 4.1084 29.4988 3.82161 29.4988Z"
				fill="#21365F"
			/>
			<path
				d="M6.97396 29.4988H6.03165C5.75169 29.4988 5.51953 29.2667 5.51953 28.9867C5.51953 28.7068 5.75169 28.4746 6.03165 28.4746H6.97396C7.25391 28.4746 7.48607 28.7068 7.48607 28.9867C7.48607 29.2667 7.25391 29.4988 6.97396 29.4988Z"
				fill="#21365F"
			/>
			<path
				d="M4.92814 31.5456C4.64818 31.5456 4.41602 31.3135 4.41602 31.0335V30.0844C4.41602 29.8044 4.64818 29.5723 4.92814 29.5723C5.20809 29.5723 5.44026 29.8044 5.44026 30.0844V31.0267C5.44026 31.3135 5.20809 31.5456 4.92814 31.5456Z"
				fill="#21365F"
			/>
			<path
				d="M4.92814 28.3904C4.64818 28.3904 4.41602 28.1582 4.41602 27.8782V26.9359C4.41602 26.656 4.64818 26.4238 4.92814 26.4238C5.20809 26.4238 5.44026 26.656 5.44026 26.9359V27.8782C5.44026 28.1582 5.20809 28.3904 4.92814 28.3904Z"
				fill="#21365F"
			/>
			<path
				d="M1.77278 37.6903H0.830479C0.55052 37.6903 0.318359 37.4581 0.318359 37.1781C0.318359 36.8982 0.55052 36.666 0.830479 36.666H1.77278C2.05274 36.666 2.2849 36.8982 2.2849 37.1781C2.2849 37.4581 2.05957 37.6903 1.77278 37.6903Z"
				fill="#21365F"
			/>
			<path
				d="M4.92513 37.6903H3.98282C3.70286 37.6903 3.4707 37.4581 3.4707 37.1781C3.4707 36.8982 3.70286 36.666 3.98282 36.666H4.92513C5.20509 36.666 5.43725 36.8982 5.43725 37.1781C5.43725 37.4581 5.20509 37.6903 4.92513 37.6903Z"
				fill="#21365F"
			/>
			<path
				d="M2.87931 39.739C2.59935 39.739 2.36719 39.5068 2.36719 39.2269V38.2777C2.36719 37.9978 2.59935 37.7656 2.87931 37.7656C3.15927 37.7656 3.39143 37.9978 3.39143 38.2777V39.22C3.39143 39.5068 3.15927 39.739 2.87931 39.739Z"
				fill="#21365F"
			/>
			<path
				d="M2.87931 36.5857C2.59935 36.5857 2.36719 36.3535 2.36719 36.0735V35.1313C2.36719 34.8513 2.59935 34.6191 2.87931 34.6191C3.15927 34.6191 3.39143 34.8513 3.39143 35.1313V36.0735C3.39143 36.3603 3.15927 36.5857 2.87931 36.5857Z"
				fill="#21365F"
			/>
		</svg>
	);
}

export function ConfirmedPhoneBigIcon() {
	return (
		<svg
			width="55"
			height="56"
			viewBox="0 0 55 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.61035 48.6894C14.89 48.6894 19.1699 44.4095 19.1699 39.1299C19.1699 33.8503 14.89 29.5703 9.61035 29.5703C4.33075 29.5703 0.0507812 33.8503 0.0507812 39.1299C0.0507812 44.4095 4.33075 48.6894 9.61035 48.6894Z"
				fill="#DCF7E3"
			/>
			<path
				d="M28.0474 17.1085C32.5728 17.1085 36.2414 13.44 36.2414 8.91462C36.2414 4.38925 32.5728 0.720703 28.0474 0.720703C23.5221 0.720703 19.8535 4.38925 19.8535 8.91462C19.8535 13.44 23.5221 17.1085 28.0474 17.1085Z"
				fill="#DCF7E3"
			/>
			<path
				d="M40.5081 55.8111C48.0504 55.8111 54.1646 49.6969 54.1646 42.1546C54.1646 34.6123 48.0504 28.498 40.5081 28.498C32.9658 28.498 26.8516 34.6123 26.8516 42.1546C26.8516 49.6969 32.9658 55.8111 40.5081 55.8111Z"
				fill="#DCF7E3"
			/>
			<path
				d="M35.7611 41.3549C26.222 36.9849 18.5334 29.2962 14.1633 19.764C12.347 15.7899 11.1111 11.4949 10.5512 6.98828C9.54057 6.98828 8.48901 6.98828 7.56037 6.98828C5.9967 6.98828 4.73347 8.29931 4.8359 9.85615C6.24935 31.7681 23.7433 49.2689 45.6552 50.6824C47.2189 50.7848 48.53 49.5147 48.53 47.9442V44.9603C44.0233 44.4072 39.7283 43.1713 35.7611 41.3549Z"
				fill="white"
			/>
			<path
				d="M35.7632 41.3549C26.2241 36.9848 18.5355 29.2962 14.1654 19.764C12.3491 15.7899 11.1132 11.4949 10.5533 6.98828C9.5427 6.98828 8.49114 6.98828 7.5625 6.98828C7.56933 6.98828 7.57616 6.98828 7.58298 6.98828C8.18387 11.8432 9.51538 16.4796 11.4819 20.7609C16.1934 31.0443 24.4829 39.3338 34.7663 44.0384C39.0476 45.9982 43.6772 47.3297 48.5321 47.9306V44.9603C44.0254 44.4072 39.7305 43.1713 35.7632 41.3549Z"
				fill="#BDC3D8"
			/>
			<path
				d="M45.8337 51.2017C45.7654 51.2017 45.6903 51.2017 45.622 51.1949C23.5053 49.7678 5.75179 32.0074 4.32468 9.8907C4.27006 9.00985 4.57048 8.16314 5.18503 7.50763C5.79957 6.85211 6.66677 6.47656 7.56127 6.47656H10.5521C10.8115 6.47656 11.03 6.66776 11.0573 6.92723C11.6036 11.3178 12.8054 15.565 14.6285 19.5459C18.9508 28.9825 26.5302 36.5619 35.9737 40.8842C39.9546 42.7073 44.2017 43.9091 48.5923 44.4554C48.8518 44.4895 49.043 44.708 49.043 44.9607V47.9446C49.043 48.8459 48.6674 49.7131 48.0119 50.3277C47.4042 50.8944 46.6394 51.2017 45.8337 51.2017ZM7.56127 7.5008C6.94673 7.5008 6.35266 7.76027 5.92931 8.21094C5.51278 8.65477 5.30795 9.22836 5.34209 9.82924C6.73505 31.4339 24.0789 48.7777 45.6835 50.1775C46.2844 50.2116 46.858 50.0068 47.3018 49.5902C47.7525 49.1669 48.0119 48.5728 48.0119 47.9515V45.4182C43.676 44.8378 39.4834 43.6292 35.5435 41.8197C25.8815 37.3949 18.1246 29.638 13.693 19.976C11.8904 16.0361 10.675 11.8436 10.0946 7.5008H7.56127Z"
				fill="#2BBB50"
			/>
			<path
				d="M16.6795 19.7571C18.0383 19.7571 18.9055 18.33 18.3046 17.1078C17.0482 14.5403 16.0377 11.8363 15.3002 9.01628C14.9861 7.82133 13.9072 6.98828 12.6645 6.98828C12.0295 6.98828 11.3057 6.98828 10.5546 6.98828C11.1145 11.4949 12.3504 15.7899 14.1667 19.764C14.952 19.7571 15.785 19.7571 16.6795 19.7571Z"
				fill="white"
			/>
			<path
				d="M18.3011 17.1078C17.0447 14.5403 16.0341 11.8363 15.2966 9.01628C14.9825 7.82133 13.9037 6.98828 12.6609 6.98828C12.5995 6.98828 12.5312 6.98828 12.4766 6.98828C12.5107 7.07022 12.5448 7.15216 12.5653 7.24092C13.4394 10.5731 14.6957 13.7551 16.2867 16.7254C16.5872 17.2921 16.1911 17.9818 15.5493 17.9818H13.4052C13.6442 18.5827 13.8968 19.1767 14.17 19.7571C14.9552 19.7571 15.7883 19.7571 16.6828 19.7571C18.0348 19.7571 18.902 18.33 18.3011 17.1078Z"
				fill="#BDC3D8"
			/>
			<path
				d="M16.6788 20.2697H14.166C13.968 20.2697 13.7837 20.1536 13.7017 19.9692C11.8308 15.8927 10.6017 11.5431 10.0418 7.05013C10.0213 6.90674 10.0691 6.75652 10.1647 6.64727C10.2603 6.53802 10.4037 6.47656 10.5471 6.47656H12.657C14.1251 6.47656 15.4156 7.46666 15.7844 8.88694C16.5082 11.6387 17.5051 14.3291 18.7547 16.8828C19.1166 17.6203 19.0756 18.4807 18.6386 19.1771C18.2289 19.86 17.4846 20.2697 16.6788 20.2697ZM14.5006 19.2454H16.6788C17.1363 19.2454 17.5392 19.0201 17.785 18.6377C18.0308 18.2417 18.0513 17.7569 17.8465 17.3403C16.5628 14.7251 15.5385 11.9665 14.8011 9.15324C14.5484 8.18363 13.6676 7.50763 12.6638 7.50763H11.1411C11.701 11.5773 12.8277 15.524 14.5006 19.2454Z"
				fill="#2BBB50"
			/>
			<path
				d="M38.0443 42.3044C41.3629 43.6017 44.8794 44.5031 48.5325 44.9606V42.8575C48.5325 41.6147 47.6927 40.529 46.4909 40.2149C43.664 39.4706 40.9532 38.4532 38.3789 37.1968C37.1703 36.6028 35.7637 37.4973 35.7637 38.8424V41.3484C39.7377 43.1647 44.0259 44.4006 48.5325 44.9606C44.8726 44.5099 41.356 43.6017 38.0443 42.3044Z"
				fill="white"
			/>
			<path
				d="M46.4822 40.2156C43.6553 39.4713 40.9444 38.4539 38.3702 37.1975C37.2981 36.6718 36.0759 37.3204 35.8164 38.4061C35.9871 38.4335 36.1578 38.4813 36.3217 38.5632C39.1554 39.9562 42.1667 41.0487 45.3145 41.8066C45.9973 41.9705 46.4822 42.5646 46.4822 43.261V44.654C46.687 44.6881 46.8987 44.7291 47.1035 44.7633C47.3015 44.7974 47.5064 44.8179 47.7044 44.852C47.9775 44.893 48.2507 44.934 48.5306 44.9681V42.865C48.5306 41.6154 47.6908 40.5366 46.4822 40.2156Z"
				fill="#BDC3D8"
			/>
			<path
				d="M48.5329 45.4783C48.5125 45.4783 48.4919 45.4783 48.4715 45.4714C43.9785 44.9115 39.6289 43.6824 35.5524 41.8183C35.368 41.7364 35.252 41.552 35.252 41.354V38.848C35.252 38.0354 35.6616 37.2912 36.3581 36.861C37.041 36.4376 37.8809 36.3898 38.6047 36.7449C41.1653 38.0013 43.8624 39.0051 46.621 39.7289C48.0481 40.1044 49.0451 41.3949 49.0451 42.863V44.9593C49.0451 44.9593 49.0451 44.9593 49.0451 44.9661C49.0451 45.1095 48.9836 45.2529 48.8743 45.3485C48.7788 45.4305 48.6558 45.4783 48.5329 45.4783ZM38.1198 41.7978C38.154 41.8046 38.1881 41.8115 38.2223 41.8251C41.3701 43.0542 44.6613 43.9146 48.014 44.3789V42.8562C48.014 41.8525 47.3312 40.9648 46.3547 40.7121C43.5346 39.9747 40.776 38.9436 38.1471 37.6599C37.7374 37.4619 37.28 37.4824 36.8908 37.7282C36.5015 37.974 36.2694 38.3905 36.2694 38.848V41.0262C36.8839 41.2925 37.4985 41.552 38.1198 41.7978Z"
				fill="#2BBB50"
			/>
			<path
				d="M28.0458 30.2044H40.3366L48.5306 35.667V9.71959C48.5306 8.21054 47.3083 6.98828 45.7992 6.98828H28.0458C26.5367 6.98828 25.3145 8.21054 25.3145 9.71959V27.4731C25.3145 28.9821 26.5367 30.2044 28.0458 30.2044Z"
				fill="#BDC3D8"
			/>
			<path
				d="M27.3629 27.4731H40.3639C40.7941 27.4731 41.2038 27.6096 41.552 27.8555L45.2598 30.5048C45.4852 30.6687 45.7992 30.5048 45.7992 30.2249V9.03676C45.7992 7.90327 44.8843 6.98828 43.7508 6.98828H28.0458C26.5367 6.98828 25.3145 8.21054 25.3145 9.71959V25.4246C25.3145 26.5513 26.2294 27.4731 27.3629 27.4731Z"
				fill="white"
			/>
			<path
				d="M48.5311 36.1785C48.4287 36.1785 48.3331 36.1512 48.2443 36.0898L40.1801 30.7091H28.0463C26.2573 30.7091 24.8029 29.2547 24.8029 27.4657V9.71218C24.8029 7.92317 26.2573 6.46875 28.0463 6.46875H45.7998C47.5888 6.46875 49.0432 7.92317 49.0432 9.71218V35.6596C49.0432 35.8508 48.9408 36.0215 48.77 36.1103C48.6949 36.158 48.613 36.1785 48.5311 36.1785ZM28.0463 7.49981C26.824 7.49981 25.8271 8.49674 25.8271 9.719V27.4725C25.8271 28.6947 26.824 29.6917 28.0463 29.6917H40.3372C40.4396 29.6917 40.5352 29.719 40.6239 29.7804L48.019 34.7104V9.719C48.019 8.49674 47.022 7.49981 45.7998 7.49981H28.0463Z"
				fill="#2BBB50"
			/>
			<path
				d="M43.8061 16.092H39.4223V11.7083C39.4223 11.36 39.1424 11.0801 38.7941 11.0801H35.0386C34.6903 11.0801 34.4104 11.36 34.4104 11.7083V16.092H30.0267C29.6784 16.092 29.3984 16.372 29.3984 16.7202V20.4758C29.3984 20.824 29.6784 21.104 30.0267 21.104H34.4104V25.4877C34.4104 25.836 34.6903 26.1159 35.0386 26.1159H38.7941C39.1424 26.1159 39.4223 25.836 39.4223 25.4877V21.104H43.8061C44.1543 21.104 44.4343 20.824 44.4343 20.4758V16.7202C44.4343 16.372 44.1543 16.092 43.8061 16.092Z"
				fill="#BDC3D8"
			/>
			<path
				d="M42.5575 16.092H39.4301V11.7083C39.4301 11.36 39.1502 11.0801 38.8019 11.0801H35.0464C34.6982 11.0801 34.4182 11.36 34.4182 11.7083V16.092H30.0344C29.6862 16.092 29.4062 16.372 29.4062 16.7202V20.4758C29.4062 20.824 29.6862 21.104 30.0344 21.104H34.4182V24.2313H36.9242C37.2724 24.2313 37.5524 23.9514 37.5524 23.6031V20.4758C37.5524 19.7861 38.1123 19.2262 38.8019 19.2262H41.9293C42.2775 19.2262 42.5575 18.9462 42.5575 18.598V16.092Z"
				fill="white"
			/>
			<path
				d="M38.8026 26.6206H35.047C34.4188 26.6206 33.9067 26.1085 33.9067 25.4803V21.6087H30.0351C29.4069 21.6087 28.8948 21.0966 28.8948 20.4684V16.7128C28.8948 16.0846 29.4069 15.5725 30.0351 15.5725H33.9067V11.7009C33.9067 11.0727 34.4188 10.5605 35.047 10.5605H38.8026C39.4308 10.5605 39.9429 11.0727 39.9429 11.7009V15.5725H43.8145C44.4427 15.5725 44.9549 16.0846 44.9549 16.7128V20.4684C44.9549 21.0966 44.4427 21.6087 43.8145 21.6087H39.9429V25.4803C39.9361 26.1085 39.4308 26.6206 38.8026 26.6206ZM30.0351 16.6036C29.9736 16.6036 29.919 16.6514 29.919 16.7196V20.4752C29.919 20.5366 29.9668 20.5913 30.0351 20.5913H34.4189C34.6988 20.5913 34.931 20.8234 34.931 21.1034V25.4871C34.931 25.5486 34.9788 25.6032 35.047 25.6032H38.8026C38.864 25.6032 38.9187 25.5554 38.9187 25.4871V21.1034C38.9187 20.8234 39.1508 20.5913 39.4308 20.5913H43.8145C43.876 20.5913 43.9306 20.5366 43.9306 20.4752V16.7196C43.9306 16.6582 43.8828 16.6036 43.8145 16.6036H39.4308C39.1508 16.6036 38.9187 16.3714 38.9187 16.0914V11.7077C38.9187 11.6462 38.8709 11.5916 38.8026 11.5916H35.047C34.9856 11.5916 34.931 11.6462 34.931 11.7077V16.0914C34.931 16.3714 34.6988 16.6036 34.4189 16.6036H30.0351Z"
				fill="#2BBB50"
			/>
			<path
				d="M16.6986 49.1531H15.7563C15.4763 49.1531 15.2441 48.921 15.2441 48.641C15.2441 48.3611 15.4763 48.1289 15.7563 48.1289H16.6986C16.9785 48.1289 17.2107 48.3611 17.2107 48.641C17.2107 48.921 16.9854 49.1531 16.6986 49.1531Z"
				fill="#2BBB50"
			/>
			<path
				d="M19.8509 49.1531H18.9086C18.6286 49.1531 18.3965 48.921 18.3965 48.641C18.3965 48.3611 18.6286 48.1289 18.9086 48.1289H19.8509C20.1309 48.1289 20.363 48.3611 20.363 48.641C20.363 48.921 20.1309 49.1531 19.8509 49.1531Z"
				fill="#2BBB50"
			/>
			<path
				d="M17.8031 51.2019C17.5232 51.2019 17.291 50.9697 17.291 50.6898V49.7406C17.291 49.4607 17.5232 49.2285 17.8031 49.2285C18.0831 49.2285 18.3153 49.4607 18.3153 49.7406V50.6829C18.3153 50.9697 18.0831 51.2019 17.8031 51.2019Z"
				fill="#2BBB50"
			/>
			<path
				d="M17.8031 48.0466C17.5232 48.0466 17.291 47.8144 17.291 47.5345V46.5922C17.291 46.3122 17.5232 46.0801 17.8031 46.0801C18.0831 46.0801 18.3153 46.3122 18.3153 46.5922V47.5345C18.3153 47.8213 18.0831 48.0466 17.8031 48.0466Z"
				fill="#2BBB50"
			/>
			<path
				d="M7.82161 35.4988H6.87931C6.59935 35.4988 6.36719 35.2667 6.36719 34.9867C6.36719 34.7068 6.59935 34.4746 6.87931 34.4746H7.82161C8.10157 34.4746 8.33373 34.7068 8.33373 34.9867C8.33373 35.2667 8.1084 35.4988 7.82161 35.4988Z"
				fill="#2BBB50"
			/>
			<path
				d="M10.974 35.4988H10.0317C9.75169 35.4988 9.51953 35.2667 9.51953 34.9867C9.51953 34.7068 9.75169 34.4746 10.0317 34.4746H10.974C11.2539 34.4746 11.4861 34.7068 11.4861 34.9867C11.4861 35.2667 11.2539 35.4988 10.974 35.4988Z"
				fill="#2BBB50"
			/>
			<path
				d="M8.92814 37.5456C8.64818 37.5456 8.41602 37.3135 8.41602 37.0335V36.0844C8.41602 35.8044 8.64818 35.5723 8.92814 35.5723C9.20809 35.5723 9.44026 35.8044 9.44026 36.0844V37.0267C9.44026 37.3135 9.20809 37.5456 8.92814 37.5456Z"
				fill="#2BBB50"
			/>
			<path
				d="M8.92814 34.3904C8.64818 34.3904 8.41602 34.1582 8.41602 33.8782V32.9359C8.41602 32.656 8.64818 32.4238 8.92814 32.4238C9.20809 32.4238 9.44026 32.656 9.44026 32.9359V33.8782C9.44026 34.1582 9.20809 34.3904 8.92814 34.3904Z"
				fill="#2BBB50"
			/>
			<path
				d="M5.77278 43.6903H4.83048C4.55052 43.6903 4.31836 43.4581 4.31836 43.1781C4.31836 42.8982 4.55052 42.666 4.83048 42.666H5.77278C6.05274 42.666 6.2849 42.8982 6.2849 43.1781C6.2849 43.4581 6.05957 43.6903 5.77278 43.6903Z"
				fill="#2BBB50"
			/>
			<path
				d="M8.92513 43.6903H7.98282C7.70286 43.6903 7.4707 43.4581 7.4707 43.1781C7.4707 42.8982 7.70286 42.666 7.98282 42.666H8.92513C9.20509 42.666 9.43725 42.8982 9.43725 43.1781C9.43725 43.4581 9.20509 43.6903 8.92513 43.6903Z"
				fill="#2BBB50"
			/>
			<path
				d="M6.87931 45.739C6.59935 45.739 6.36719 45.5068 6.36719 45.2269V44.2777C6.36719 43.9978 6.59935 43.7656 6.87931 43.7656C7.15927 43.7656 7.39143 43.9978 7.39143 44.2777V45.22C7.39143 45.5068 7.15927 45.739 6.87931 45.739Z"
				fill="#2BBB50"
			/>
			<path
				d="M6.87931 42.5857C6.59935 42.5857 6.36719 42.3535 6.36719 42.0735V41.1313C6.36719 40.8513 6.59935 40.6191 6.87931 40.6191C7.15927 40.6191 7.39143 40.8513 7.39143 41.1313V42.0735C7.39143 42.3603 7.15927 42.5857 6.87931 42.5857Z"
				fill="#2BBB50"
			/>
		</svg>
	);
}

export function MissedPhoneBigIcon() {
	return (
		<svg
			width="46"
			height="46"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31.7611 35.3549C22.222 30.9849 14.5334 23.2962 10.1633 13.764C8.34697 9.78992 7.11107 5.49494 6.55115 0.988281C5.54057 0.988281 4.48901 0.988281 3.56037 0.988281C1.9967 0.988281 0.733473 2.29931 0.835897 3.85615C2.24935 25.7681 19.7433 43.2689 41.6552 44.6824C43.2189 44.7848 44.53 43.5147 44.53 41.9442V38.9603C40.0233 38.4072 35.7283 37.1713 31.7611 35.3549Z"
				fill="white"
			/>
			<path
				d="M31.7632 35.3549C22.2241 30.9848 14.5355 23.2962 10.1654 13.764C8.3491 9.78992 7.1132 5.49494 6.55328 0.988281C5.5427 0.988281 4.49114 0.988281 3.5625 0.988281C3.56933 0.988281 3.57616 0.988281 3.58298 0.988281C4.18387 5.84318 5.51538 10.4796 7.48192 14.7609C12.1934 25.0443 20.4829 33.3338 30.7663 38.0384C35.0476 39.9982 39.6772 41.3297 44.5321 41.9306V38.9603C40.0254 38.4072 35.7305 37.1713 31.7632 35.3549Z"
				fill="#F3A72E"
			/>
			<path
				d="M41.8337 45.2017C41.7654 45.2017 41.6903 45.2017 41.622 45.1949C19.5053 43.7678 1.75179 26.0074 0.324681 3.8907C0.270055 3.00985 0.570485 2.16314 1.18503 1.50763C1.79957 0.852113 2.66677 0.476562 3.56127 0.476562H6.55206C6.81153 0.476562 7.03003 0.667756 7.05734 0.927231C7.6036 5.3178 8.80538 9.56498 10.6285 13.5459C14.9508 22.9825 22.5302 30.5619 31.9737 34.8842C35.9546 36.7073 40.2017 37.9091 44.5923 38.4554C44.8518 38.4895 45.043 38.708 45.043 38.9607V41.9446C45.043 42.8459 44.6674 43.7131 44.0119 44.3277C43.4042 44.8944 42.6394 45.2017 41.8337 45.2017ZM3.56127 1.5008C2.94673 1.5008 2.35266 1.76027 1.92931 2.21094C1.51278 2.65477 1.30795 3.22836 1.34209 3.82924C2.73505 25.4339 20.0789 42.7777 41.6835 44.1775C42.2844 44.2116 42.858 44.0068 43.3018 43.5902C43.7525 43.1669 44.0119 42.5728 44.0119 41.9515V39.4182C39.676 38.8378 35.4834 37.6292 31.5435 35.8197C21.8815 31.3949 14.1246 23.638 9.69304 13.976C7.89038 10.0361 6.67496 5.84358 6.09455 1.5008H3.56127Z"
				fill="#CF912D"
			/>
			<path
				d="M12.6795 13.7571C14.0383 13.7571 14.9055 12.33 14.3046 11.1078C13.0482 8.54035 12.0377 5.83635 11.3002 3.01628C10.9861 1.82133 9.90724 0.988281 8.6645 0.988281C8.02947 0.988281 7.30567 0.988281 6.55457 0.988281C7.11448 5.49494 8.35038 9.78992 10.1667 13.764C10.952 13.7571 11.785 13.7571 12.6795 13.7571Z"
				fill="white"
			/>
			<path
				d="M14.3011 11.1078C13.0447 8.54035 12.0341 5.83635 11.2966 3.01628C10.9825 1.82133 9.90368 0.988281 8.66094 0.988281C8.59948 0.988281 8.53119 0.988281 8.47656 0.988281C8.5107 1.07022 8.54485 1.15216 8.56533 1.24092C9.43935 4.57312 10.6957 7.75509 12.2867 10.7254C12.5872 11.2921 12.1911 11.9818 11.5493 11.9818H9.40522C9.64421 12.5827 9.89685 13.1767 10.17 13.7571C10.9552 13.7571 11.7883 13.7571 12.6828 13.7571C14.0348 13.7571 14.902 12.33 14.3011 11.1078Z"
				fill="#F3A72E"
			/>
			<path
				d="M12.6788 14.2697H10.166C9.96803 14.2697 9.78367 14.1536 9.70173 13.9692C7.83079 9.89274 6.60171 5.54313 6.04179 1.05013C6.02131 0.906741 6.0691 0.756521 6.16469 0.647269C6.26029 0.538017 6.40368 0.476562 6.54708 0.476562H8.65701C10.1251 0.476562 11.4156 1.46666 11.7844 2.88694C12.5082 5.63873 13.5051 8.32907 14.7547 10.8828C15.1166 11.6203 15.0756 12.4807 14.6386 13.1771C14.2289 13.86 13.4846 14.2697 12.6788 14.2697ZM10.5006 13.2454H12.6788C13.1363 13.2454 13.5392 13.0201 13.785 12.6377C14.0308 12.2417 14.0513 11.7569 13.8465 11.3403C12.5628 8.72511 11.5385 5.96649 10.8011 3.15324C10.5484 2.18363 9.6676 1.50763 8.66384 1.50763H7.14113C7.70105 5.57727 8.82772 9.52401 10.5006 13.2454Z"
				fill="#CF912D"
			/>
			<path
				d="M34.0443 36.3044C37.3629 37.6017 40.8794 38.5031 44.5325 38.9606V36.8575C44.5325 35.6147 43.6927 34.529 42.4909 34.2149C39.664 33.4706 36.9532 32.4532 34.3789 31.1968C33.1703 30.6028 31.7637 31.4973 31.7637 32.8424V35.3484C35.7377 37.1647 40.0259 38.4006 44.5325 38.9606C40.8726 38.5099 37.356 37.6017 34.0443 36.3044Z"
				fill="white"
			/>
			<path
				d="M42.4822 34.2156C39.6553 33.4713 36.9444 32.4539 34.3702 31.1975C33.2981 30.6718 32.0759 31.3204 31.8164 32.4061C31.9871 32.4335 32.1578 32.4813 32.3217 32.5632C35.1554 33.9562 38.1667 35.0487 41.3145 35.8066C41.9973 35.9705 42.4822 36.5646 42.4822 37.261V38.654C42.687 38.6881 42.8987 38.7291 43.1035 38.7633C43.3015 38.7974 43.5064 38.8179 43.7044 38.852C43.9775 38.893 44.2507 38.934 44.5306 38.9681V36.865C44.5306 35.6154 43.6908 34.5366 42.4822 34.2156Z"
				fill="#F3A72E"
			/>
			<path
				d="M44.5329 39.4783C44.5125 39.4783 44.4919 39.4783 44.4715 39.4714C39.9785 38.9115 35.6289 37.6824 31.5524 35.8183C31.368 35.7364 31.252 35.552 31.252 35.354V32.848C31.252 32.0354 31.6616 31.2912 32.3581 30.861C33.041 30.4376 33.8809 30.3898 34.6047 30.7449C37.1653 32.0013 39.8624 33.0051 42.621 33.7289C44.0481 34.1044 45.0451 35.3949 45.0451 36.863V38.9593C45.0451 38.9593 45.0451 38.9593 45.0451 38.9661C45.0451 39.1095 44.9836 39.2529 44.8743 39.3485C44.7788 39.4305 44.6558 39.4783 44.5329 39.4783ZM34.1198 35.7978C34.154 35.8046 34.1881 35.8115 34.2223 35.8251C37.3701 37.0542 40.6613 37.9146 44.014 38.3789V36.8562C44.014 35.8525 43.3312 34.9648 42.3547 34.7121C39.5346 33.9747 36.776 32.9436 34.1471 31.6599C33.7374 31.4619 33.28 31.4824 32.8908 31.7282C32.5015 31.974 32.2694 32.3905 32.2694 32.848V35.0262C32.8839 35.2925 33.4985 35.552 34.1198 35.7978Z"
				fill="#CF912D"
			/>
			<path
				d="M24.0458 24.2044H36.3366L44.5306 29.667V3.71959C44.5306 2.21054 43.3083 0.988281 41.7992 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V21.4731C21.3145 22.9821 22.5367 24.2044 24.0458 24.2044Z"
				fill="#F3A72E"
			/>
			<path
				d="M23.3629 21.4731H36.3639C36.7941 21.4731 37.2038 21.6096 37.552 21.8555L41.2598 24.5048C41.4852 24.6687 41.7992 24.5048 41.7992 24.2249V3.03676C41.7992 1.90327 40.8843 0.988281 39.7508 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V19.4246C21.3145 20.5513 22.2294 21.4731 23.3629 21.4731Z"
				fill="white"
			/>
			<path
				d="M31.176 13.981L30.945 4.783H34.83L34.599 13.981H31.176ZM31.029 19V15.451H34.746V19H31.029Z"
				fill="#F3A72E"
			/>
			<path
				d="M44.5311 30.1785C44.4287 30.1785 44.3331 30.1512 44.2443 30.0898L36.1801 24.7091H24.0463C22.2573 24.7091 20.8029 23.2547 20.8029 21.4657V3.71218C20.8029 1.92317 22.2573 0.46875 24.0463 0.46875H41.7998C43.5888 0.46875 45.0432 1.92317 45.0432 3.71218V29.6596C45.0432 29.8508 44.9408 30.0215 44.77 30.1103C44.6949 30.158 44.613 30.1785 44.5311 30.1785ZM24.0463 1.49981C22.824 1.49981 21.8271 2.49674 21.8271 3.719V21.4725C21.8271 22.6947 22.824 23.6917 24.0463 23.6917H36.3372C36.4396 23.6917 36.5352 23.719 36.6239 23.7804L44.019 28.7104V3.719C44.019 2.49674 43.022 1.49981 41.7998 1.49981H24.0463Z"
				fill="#CF912D"
			/>
			<path
				d="M12.6986 43.1531H11.7563C11.4763 43.1531 11.2441 42.921 11.2441 42.641C11.2441 42.3611 11.4763 42.1289 11.7563 42.1289H12.6986C12.9785 42.1289 13.2107 42.3611 13.2107 42.641C13.2107 42.921 12.9854 43.1531 12.6986 43.1531Z"
				fill="#CF912D"
			/>
			<path
				d="M15.8509 43.1531H14.9086C14.6286 43.1531 14.3965 42.921 14.3965 42.641C14.3965 42.3611 14.6286 42.1289 14.9086 42.1289H15.8509C16.1309 42.1289 16.363 42.3611 16.363 42.641C16.363 42.921 16.1309 43.1531 15.8509 43.1531Z"
				fill="#CF912D"
			/>
			<path
				d="M13.8031 45.2019C13.5232 45.2019 13.291 44.9697 13.291 44.6898V43.7406C13.291 43.4607 13.5232 43.2285 13.8031 43.2285C14.0831 43.2285 14.3153 43.4607 14.3153 43.7406V44.6829C14.3153 44.9697 14.0831 45.2019 13.8031 45.2019Z"
				fill="#CF912D"
			/>
			<path
				d="M13.8031 42.0466C13.5232 42.0466 13.291 41.8144 13.291 41.5345V40.5922C13.291 40.3122 13.5232 40.0801 13.8031 40.0801C14.0831 40.0801 14.3153 40.3122 14.3153 40.5922V41.5345C14.3153 41.8213 14.0831 42.0466 13.8031 42.0466Z"
				fill="#CF912D"
			/>
			<path
				d="M3.82161 29.4988H2.87931C2.59935 29.4988 2.36719 29.2667 2.36719 28.9867C2.36719 28.7068 2.59935 28.4746 2.87931 28.4746H3.82161C4.10157 28.4746 4.33373 28.7068 4.33373 28.9867C4.33373 29.2667 4.1084 29.4988 3.82161 29.4988Z"
				fill="#CF912D"
			/>
			<path
				d="M6.97396 29.4988H6.03165C5.75169 29.4988 5.51953 29.2667 5.51953 28.9867C5.51953 28.7068 5.75169 28.4746 6.03165 28.4746H6.97396C7.25391 28.4746 7.48607 28.7068 7.48607 28.9867C7.48607 29.2667 7.25391 29.4988 6.97396 29.4988Z"
				fill="#CF912D"
			/>
			<path
				d="M4.92814 31.5456C4.64818 31.5456 4.41602 31.3135 4.41602 31.0335V30.0844C4.41602 29.8044 4.64818 29.5723 4.92814 29.5723C5.20809 29.5723 5.44026 29.8044 5.44026 30.0844V31.0267C5.44026 31.3135 5.20809 31.5456 4.92814 31.5456Z"
				fill="#CF912D"
			/>
			<path
				d="M4.92814 28.3904C4.64818 28.3904 4.41602 28.1582 4.41602 27.8782V26.9359C4.41602 26.656 4.64818 26.4238 4.92814 26.4238C5.20809 26.4238 5.44026 26.656 5.44026 26.9359V27.8782C5.44026 28.1582 5.20809 28.3904 4.92814 28.3904Z"
				fill="#CF912D"
			/>
			<path
				d="M1.77278 37.6903H0.830479C0.55052 37.6903 0.318359 37.4581 0.318359 37.1781C0.318359 36.8982 0.55052 36.666 0.830479 36.666H1.77278C2.05274 36.666 2.2849 36.8982 2.2849 37.1781C2.2849 37.4581 2.05957 37.6903 1.77278 37.6903Z"
				fill="#CF912D"
			/>
			<path
				d="M4.92513 37.6903H3.98282C3.70286 37.6903 3.4707 37.4581 3.4707 37.1781C3.4707 36.8982 3.70286 36.666 3.98282 36.666H4.92513C5.20509 36.666 5.43725 36.8982 5.43725 37.1781C5.43725 37.4581 5.20509 37.6903 4.92513 37.6903Z"
				fill="#CF912D"
			/>
			<path
				d="M2.87931 39.739C2.59935 39.739 2.36719 39.5068 2.36719 39.2269V38.2777C2.36719 37.9978 2.59935 37.7656 2.87931 37.7656C3.15927 37.7656 3.39143 37.9978 3.39143 38.2777V39.22C3.39143 39.5068 3.15927 39.739 2.87931 39.739Z"
				fill="#CF912D"
			/>
			<path
				d="M2.87931 36.5857C2.59935 36.5857 2.36719 36.3535 2.36719 36.0735V35.1313C2.36719 34.8513 2.59935 34.6191 2.87931 34.6191C3.15927 34.6191 3.39143 34.8513 3.39143 35.1313V36.0735C3.39143 36.3603 3.15927 36.5857 2.87931 36.5857Z"
				fill="#CF912D"
			/>
		</svg>
	);
}

export function CanceledPhoneBigIcon() {
	return (
		<svg
			width="46"
			height="46"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M31.7611 35.3549C22.222 30.9849 14.5334 23.2962 10.1633 13.764C8.34697 9.78992 7.11107 5.49494 6.55115 0.988281C5.54057 0.988281 4.48901 0.988281 3.56037 0.988281C1.9967 0.988281 0.733473 2.29931 0.835897 3.85615C2.24935 25.7681 19.7433 43.2689 41.6552 44.6824C43.2189 44.7848 44.53 43.5147 44.53 41.9442V38.9603C40.0233 38.4072 35.7283 37.1713 31.7611 35.3549Z"
				fill="white"
			/>
			<path
				d="M31.7632 35.3549C22.2241 30.9848 14.5355 23.2962 10.1654 13.764C8.3491 9.78992 7.1132 5.49494 6.55328 0.988281C5.5427 0.988281 4.49114 0.988281 3.5625 0.988281C3.56933 0.988281 3.57616 0.988281 3.58298 0.988281C4.18387 5.84318 5.51538 10.4796 7.48192 14.7609C12.1934 25.0443 20.4829 33.3338 30.7663 38.0384C35.0476 39.9982 39.6772 41.3297 44.5321 41.9306V38.9603C40.0254 38.4072 35.7305 37.1713 31.7632 35.3549Z"
				fill="#DE3E32"
			/>
			<path
				d="M41.8337 45.2017C41.7654 45.2017 41.6903 45.2017 41.622 45.1949C19.5053 43.7678 1.75179 26.0074 0.324681 3.8907C0.270055 3.00985 0.570485 2.16314 1.18503 1.50763C1.79957 0.852113 2.66677 0.476562 3.56127 0.476562H6.55206C6.81153 0.476562 7.03003 0.667756 7.05734 0.927231C7.6036 5.3178 8.80538 9.56498 10.6285 13.5459C14.9508 22.9825 22.5302 30.5619 31.9737 34.8842C35.9546 36.7073 40.2017 37.9091 44.5923 38.4554C44.8518 38.4895 45.043 38.708 45.043 38.9607V41.9446C45.043 42.8459 44.6674 43.7131 44.0119 44.3277C43.4042 44.8944 42.6394 45.2017 41.8337 45.2017ZM3.56127 1.5008C2.94673 1.5008 2.35266 1.76027 1.92931 2.21094C1.51278 2.65477 1.30795 3.22836 1.34209 3.82924C2.73505 25.4339 20.0789 42.7777 41.6835 44.1775C42.2844 44.2116 42.858 44.0068 43.3018 43.5902C43.7525 43.1669 44.0119 42.5728 44.0119 41.9515V39.4182C39.676 38.8378 35.4834 37.6292 31.5435 35.8197C21.8815 31.3949 14.1246 23.638 9.69304 13.976C7.89038 10.0361 6.67496 5.84358 6.09455 1.5008H3.56127Z"
				fill="#C23A47"
			/>
			<path
				d="M12.6795 13.7571C14.0383 13.7571 14.9055 12.33 14.3046 11.1078C13.0482 8.54035 12.0377 5.83635 11.3002 3.01628C10.9861 1.82133 9.90724 0.988281 8.6645 0.988281C8.02947 0.988281 7.30567 0.988281 6.55457 0.988281C7.11448 5.49494 8.35038 9.78992 10.1667 13.764C10.952 13.7571 11.785 13.7571 12.6795 13.7571Z"
				fill="white"
			/>
			<path
				d="M14.3011 11.1078C13.0447 8.54035 12.0341 5.83635 11.2966 3.01628C10.9825 1.82133 9.90368 0.988281 8.66094 0.988281C8.59948 0.988281 8.53119 0.988281 8.47656 0.988281C8.5107 1.07022 8.54485 1.15216 8.56533 1.24092C9.43935 4.57312 10.6957 7.75509 12.2867 10.7254C12.5872 11.2921 12.1911 11.9818 11.5493 11.9818H9.40522C9.64421 12.5827 9.89685 13.1767 10.17 13.7571C10.9552 13.7571 11.7883 13.7571 12.6828 13.7571C14.0348 13.7571 14.902 12.33 14.3011 11.1078Z"
				fill="#DE3E32"
			/>
			<path
				d="M12.6788 14.2697H10.166C9.96803 14.2697 9.78367 14.1536 9.70173 13.9692C7.83079 9.89274 6.60171 5.54313 6.04179 1.05013C6.02131 0.906741 6.0691 0.756521 6.16469 0.647269C6.26029 0.538017 6.40368 0.476562 6.54708 0.476562H8.65701C10.1251 0.476562 11.4156 1.46666 11.7844 2.88694C12.5082 5.63873 13.5051 8.32907 14.7547 10.8828C15.1166 11.6203 15.0756 12.4807 14.6386 13.1771C14.2289 13.86 13.4846 14.2697 12.6788 14.2697ZM10.5006 13.2454H12.6788C13.1363 13.2454 13.5392 13.0201 13.785 12.6377C14.0308 12.2417 14.0513 11.7569 13.8465 11.3403C12.5628 8.72511 11.5385 5.96649 10.8011 3.15324C10.5484 2.18363 9.6676 1.50763 8.66384 1.50763H7.14113C7.70105 5.57727 8.82772 9.52401 10.5006 13.2454Z"
				fill="#C23A47"
			/>
			<path
				d="M34.0443 36.3044C37.3629 37.6017 40.8794 38.5031 44.5325 38.9606V36.8575C44.5325 35.6147 43.6927 34.529 42.4909 34.2149C39.664 33.4706 36.9532 32.4532 34.3789 31.1968C33.1703 30.6028 31.7637 31.4973 31.7637 32.8424V35.3484C35.7377 37.1647 40.0259 38.4006 44.5325 38.9606C40.8726 38.5099 37.356 37.6017 34.0443 36.3044Z"
				fill="white"
			/>
			<path
				d="M42.4822 34.2156C39.6553 33.4713 36.9444 32.4539 34.3702 31.1975C33.2981 30.6718 32.0759 31.3204 31.8164 32.4061C31.9871 32.4335 32.1578 32.4813 32.3217 32.5632C35.1554 33.9562 38.1667 35.0487 41.3145 35.8066C41.9973 35.9705 42.4822 36.5646 42.4822 37.261V38.654C42.687 38.6881 42.8987 38.7291 43.1035 38.7633C43.3015 38.7974 43.5064 38.8179 43.7044 38.852C43.9775 38.893 44.2507 38.934 44.5306 38.9681V36.865C44.5306 35.6154 43.6908 34.5366 42.4822 34.2156Z"
				fill="#DE3E32"
			/>
			<path
				d="M44.5329 39.4783C44.5125 39.4783 44.4919 39.4783 44.4715 39.4714C39.9785 38.9115 35.6289 37.6824 31.5524 35.8183C31.368 35.7364 31.252 35.552 31.252 35.354V32.848C31.252 32.0354 31.6616 31.2912 32.3581 30.861C33.041 30.4376 33.8809 30.3898 34.6047 30.7449C37.1653 32.0013 39.8624 33.0051 42.621 33.7289C44.0481 34.1044 45.0451 35.3949 45.0451 36.863V38.9593C45.0451 38.9593 45.0451 38.9593 45.0451 38.9661C45.0451 39.1095 44.9836 39.2529 44.8743 39.3485C44.7788 39.4305 44.6558 39.4783 44.5329 39.4783ZM34.1198 35.7978C34.154 35.8046 34.1881 35.8115 34.2223 35.8251C37.3701 37.0542 40.6613 37.9146 44.014 38.3789V36.8562C44.014 35.8525 43.3312 34.9648 42.3547 34.7121C39.5346 33.9747 36.776 32.9436 34.1471 31.6599C33.7374 31.4619 33.28 31.4824 32.8908 31.7282C32.5015 31.974 32.2694 32.3905 32.2694 32.848V35.0262C32.8839 35.2925 33.4985 35.552 34.1198 35.7978Z"
				fill="#C23A47"
			/>
			<path
				d="M24.0458 24.2044H36.3366L44.5306 29.667V3.71959C44.5306 2.21054 43.3083 0.988281 41.7992 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V21.4731C21.3145 22.9821 22.5367 24.2044 24.0458 24.2044Z"
				fill="#DE3E32"
			/>
			<path
				d="M23.3629 21.4731H36.3639C36.7941 21.4731 37.2038 21.6096 37.552 21.8555L41.2598 24.5048C41.4852 24.6687 41.7992 24.5048 41.7992 24.2249V3.03676C41.7992 1.90327 40.8843 0.988281 39.7508 0.988281H24.0458C22.5367 0.988281 21.3145 2.21054 21.3145 3.71959V19.4246C21.3145 20.5513 22.2294 21.4731 23.3629 21.4731Z"
				fill="white"
			/>
			<path
				d="M44.5311 30.1785C44.4287 30.1785 44.3331 30.1512 44.2443 30.0898L36.1801 24.7091H24.0463C22.2573 24.7091 20.8029 23.2547 20.8029 21.4657V3.71218C20.8029 1.92317 22.2573 0.46875 24.0463 0.46875H41.7998C43.5888 0.46875 45.0432 1.92317 45.0432 3.71218V29.6596C45.0432 29.8508 44.9408 30.0215 44.77 30.1103C44.6949 30.158 44.613 30.1785 44.5311 30.1785ZM24.0463 1.49981C22.824 1.49981 21.8271 2.49674 21.8271 3.719V21.4725C21.8271 22.6947 22.824 23.6917 24.0463 23.6917H36.3372C36.4396 23.6917 36.5352 23.719 36.6239 23.7804L44.019 28.7104V3.719C44.019 2.49674 43.022 1.49981 41.7998 1.49981H24.0463Z"
				fill="#C23A47"
			/>
			<path
				d="M38.5575 10.092H35.4301V5.70828C35.4301 5.36004 35.1502 5.08008 34.8019 5.08008H31.0464C30.6982 5.08008 30.4182 5.36004 30.4182 5.70828V10.092H26.0344C25.6862 10.092 25.4062 10.372 25.4062 10.7202V14.4758C25.4062 14.824 25.6862 15.104 26.0344 15.104H30.4182V18.2313H32.9242C33.2724 18.2313 33.5524 17.9514 33.5524 17.6031V14.4758C33.5524 13.7861 34.1123 13.2262 34.8019 13.2262H37.9293C38.2775 13.2262 38.5575 12.9462 38.5575 12.598V10.092Z"
				fill="white"
			/>
			<path
				d="M28.656 19.6439L25.9709 17.0182C25.5218 16.5789 25.5137 15.8548 25.9529 15.4056L28.6598 12.6376L25.8917 9.93063C25.4426 9.49141 25.4345 8.76722 25.8737 8.31808L28.4995 5.63301C28.9387 5.18387 29.6629 5.17578 30.112 5.61499L32.8801 8.32191L35.587 5.55384C36.0262 5.10471 36.7504 5.09661 37.1995 5.53582L39.8846 8.16157C40.3337 8.60078 40.3418 9.32498 39.9026 9.77411L37.1957 12.5422L39.9638 15.2491C40.4129 15.6883 40.421 16.4125 39.9818 16.8617L37.3561 19.5467C36.9168 19.9959 36.1926 20.0039 35.7435 19.5647L32.9754 16.8578L30.2685 19.6259C29.8244 20.0702 29.1051 20.0831 28.656 19.6439ZM29.3911 6.35216C29.3472 6.30919 29.2747 6.30518 29.227 6.354L26.6012 9.03907C26.5583 9.08301 26.5542 9.15547 26.6031 9.20322L29.7373 12.2682C29.9374 12.4639 29.9411 12.7922 29.7454 12.9924L26.6804 16.1266C26.6375 16.1705 26.6334 16.243 26.6822 16.2907L29.3673 18.9165C29.4113 18.9595 29.4837 18.9635 29.5315 18.9146L32.5964 15.7804C32.7922 15.5803 33.1205 15.5766 33.3206 15.7724L36.4548 18.8373C36.4988 18.8803 36.576 18.8794 36.619 18.8355L39.2447 16.1504C39.2877 16.1065 39.2917 16.034 39.2429 15.9862L36.1087 12.9213C35.9085 12.7256 35.9049 12.3973 36.1006 12.1971L39.1655 9.06289C39.2085 9.01895 39.2125 8.94649 39.1637 8.89875L36.4786 6.273C36.4347 6.23004 36.3575 6.23091 36.3145 6.27484L33.2495 9.40905C33.0538 9.60921 32.7255 9.61288 32.5253 9.41714L29.3911 6.35216Z"
				fill="#C23A47"
			/>
			<path
				d="M12.6986 43.1531H11.7563C11.4763 43.1531 11.2441 42.921 11.2441 42.641C11.2441 42.3611 11.4763 42.1289 11.7563 42.1289H12.6986C12.9785 42.1289 13.2107 42.3611 13.2107 42.641C13.2107 42.921 12.9854 43.1531 12.6986 43.1531Z"
				fill="#C23A47"
			/>
			<path
				d="M15.8509 43.1531H14.9086C14.6286 43.1531 14.3965 42.921 14.3965 42.641C14.3965 42.3611 14.6286 42.1289 14.9086 42.1289H15.8509C16.1309 42.1289 16.363 42.3611 16.363 42.641C16.363 42.921 16.1309 43.1531 15.8509 43.1531Z"
				fill="#C23A47"
			/>
			<path
				d="M13.8031 45.2019C13.5232 45.2019 13.291 44.9697 13.291 44.6898V43.7406C13.291 43.4607 13.5232 43.2285 13.8031 43.2285C14.0831 43.2285 14.3153 43.4607 14.3153 43.7406V44.6829C14.3153 44.9697 14.0831 45.2019 13.8031 45.2019Z"
				fill="#C23A47"
			/>
			<path
				d="M13.8031 42.0466C13.5232 42.0466 13.291 41.8144 13.291 41.5345V40.5922C13.291 40.3122 13.5232 40.0801 13.8031 40.0801C14.0831 40.0801 14.3153 40.3122 14.3153 40.5922V41.5345C14.3153 41.8213 14.0831 42.0466 13.8031 42.0466Z"
				fill="#C23A47"
			/>
			<path
				d="M3.82161 29.4988H2.87931C2.59935 29.4988 2.36719 29.2667 2.36719 28.9867C2.36719 28.7068 2.59935 28.4746 2.87931 28.4746H3.82161C4.10157 28.4746 4.33373 28.7068 4.33373 28.9867C4.33373 29.2667 4.1084 29.4988 3.82161 29.4988Z"
				fill="#C23A47"
			/>
			<path
				d="M6.97396 29.4988H6.03165C5.75169 29.4988 5.51953 29.2667 5.51953 28.9867C5.51953 28.7068 5.75169 28.4746 6.03165 28.4746H6.97396C7.25391 28.4746 7.48607 28.7068 7.48607 28.9867C7.48607 29.2667 7.25391 29.4988 6.97396 29.4988Z"
				fill="#C23A47"
			/>
			<path
				d="M4.92814 31.5456C4.64818 31.5456 4.41602 31.3135 4.41602 31.0335V30.0844C4.41602 29.8044 4.64818 29.5723 4.92814 29.5723C5.20809 29.5723 5.44026 29.8044 5.44026 30.0844V31.0267C5.44026 31.3135 5.20809 31.5456 4.92814 31.5456Z"
				fill="#C23A47"
			/>
			<path
				d="M4.92814 28.3904C4.64818 28.3904 4.41602 28.1582 4.41602 27.8782V26.9359C4.41602 26.656 4.64818 26.4238 4.92814 26.4238C5.20809 26.4238 5.44026 26.656 5.44026 26.9359V27.8782C5.44026 28.1582 5.20809 28.3904 4.92814 28.3904Z"
				fill="#C23A47"
			/>
			<path
				d="M1.77278 37.6903H0.830479C0.55052 37.6903 0.318359 37.4581 0.318359 37.1781C0.318359 36.8982 0.55052 36.666 0.830479 36.666H1.77278C2.05274 36.666 2.2849 36.8982 2.2849 37.1781C2.2849 37.4581 2.05957 37.6903 1.77278 37.6903Z"
				fill="#C23A47"
			/>
			<path
				d="M4.92513 37.6903H3.98282C3.70286 37.6903 3.4707 37.4581 3.4707 37.1781C3.4707 36.8982 3.70286 36.666 3.98282 36.666H4.92513C5.20509 36.666 5.43725 36.8982 5.43725 37.1781C5.43725 37.4581 5.20509 37.6903 4.92513 37.6903Z"
				fill="#C23A47"
			/>
			<path
				d="M2.87931 39.739C2.59935 39.739 2.36719 39.5068 2.36719 39.2269V38.2777C2.36719 37.9978 2.59935 37.7656 2.87931 37.7656C3.15927 37.7656 3.39143 37.9978 3.39143 38.2777V39.22C3.39143 39.5068 3.15927 39.739 2.87931 39.739Z"
				fill="#C23A47"
			/>
			<path
				d="M2.87931 36.5857C2.59935 36.5857 2.36719 36.3535 2.36719 36.0735V35.1313C2.36719 34.8513 2.59935 34.6191 2.87931 34.6191C3.15927 34.6191 3.39143 34.8513 3.39143 35.1313V36.0735C3.39143 36.3603 3.15927 36.5857 2.87931 36.5857Z"
				fill="#C23A47"
			/>
		</svg>
	);
}
