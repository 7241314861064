import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
	PatientFilePlanWithoutIdType,
	PatientFileProcedureType,
} from "src/@types";
import { RootState } from "src/redux/store/store";
import { createService } from "src/redux";

type HandleUpdatePriceParamsType = {
	planToUpdate: PatientFilePlanWithoutIdType;
	basePrice: PatientFileProcedureType["basePrice"];
	discount: PatientFileProcedureType["discount"];
	total: PatientFileProcedureType["total"];
	state: RootState;
	dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
};

export function handleUpdatePrice({
	planToUpdate,
	basePrice,
	discount,
	total,
	state,
	dispatch,
}: HandleUpdatePriceParamsType): PatientFilePlanWithoutIdType {
	const selectedProcedure = state?.patientProfile?.selectedProcedure;

	const serviceDictionaryByName = state?.services?.serviceDictionaryByName;
	const isProcedureInServices = selectedProcedure
		? serviceDictionaryByName?.[selectedProcedure.procedureType]
			? true
			: false
		: false;

	if (isProcedureInServices === false && selectedProcedure) {
		dispatch(
			createService({
				name: selectedProcedure?.procedureType,
				price: basePrice,
			})
		);
	}

	return {
		...planToUpdate,
		totalPrice: selectedProcedure
			? planToUpdate.totalPrice - selectedProcedure.total + total
			: planToUpdate.totalPrice,
		updatedAt: new Date().toISOString(),
		procedures: {
			stages: planToUpdate.procedures.stages.map((stage) => {
				if (stage.id === selectedProcedure?.stageId) {
					return {
						...stage,
						totalPrice: stage.totalPrice - selectedProcedure.total + total,
						procedures: stage.procedures.map((procedure) => {
							if (procedure.id === selectedProcedure.id) {
								return {
									...procedure,
									basePrice,
									discount,
									total,
									updatedAt: new Date().toISOString(),
									updatedBy: state.auth.user?.id,
								};
							}

							return procedure;
						}),
					};
				}

				return stage;
			}),
		},
	};
}
