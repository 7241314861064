import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useAppDispatch, useAppSelector, schedulerActions } from "src/redux";
import { ListItem, Menu, ClockIcon } from "src/components";

export const TimeGutterHeader = () => {
	const { t } = useTranslation("scheduler");
	const { slotModal } = useAppSelector((state) => state.scheduler);
	const dispatch = useAppDispatch();
	const onClickHandler = () => {
		dispatch(schedulerActions.toggleSlotModal(true));
	};

	const timeMenu = [
		{
			text: t("Scheduler.TimeGutter.Slots.Every_5"),
			onClickHandler: () => dispatch(schedulerActions.changeSlotsTo5Mins()),
		},
		{
			text: t("Scheduler.TimeGutter.Slots.Every_15"),
			onClickHandler: () => dispatch(schedulerActions.changeSlotsTo15Mins()),
		},
		{
			text: t("Scheduler.TimeGutter.Slots.Every_30"),
			onClickHandler: () => dispatch(schedulerActions.changeSlotsTo30Mins()),
		},
		{
			text: t("Scheduler.TimeGutter.Slots.Every_60"),
			onClickHandler: () => dispatch(schedulerActions.changeSlotsTo60Mins()),
		},
	];

	return (
		<>
			<button
				className="center h-full w-full bg-white text-muted transition-colors hover:text-gray-800"
				onClick={onClickHandler}
				data-for="changeTimeSlots"
				data-tip={t("Scheduler.TimeGutter.Change_Time_Slots")}
			>
				<ClockIcon width="25" height="25" />
			</button>

			{!slotModal && (
				<ReactTooltip id="changeTimeSlots" effect="solid" eventOff="click" />
			)}

			<div className="relative">
				<Menu
					show={slotModal}
					close={() => dispatch(schedulerActions.toggleSlotModal(false))}
					className="w-48"
					position="fixed"
				>
					{timeMenu.map((item, index) => (
						<ListItem
							key={index}
							className="capitalize text-gray-800"
							onClick={item.onClickHandler}
						>
							{item.text}
						</ListItem>
					))}
				</Menu>
			</div>
		</>
	);
};
