import { useState } from "react";
import { xAxisProps, yAxisProps } from "../../Components";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

type ChartProps = {
	data: any[];
	color: string;
	dataKey: string;
};

export function Chart({ data, color, dataKey }: ChartProps) {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	return (
		<section className="h-80 w-full">
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={data}
					margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
					barCategoryGap={14}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" {...xAxisProps} />
					<YAxis {...yAxisProps} />

					<Tooltip
						cursor={{ fill: "transparent" }}
						formatter={(value: string) => [value, ""]}
						separator=""
						contentStyle={{
							paddingLeft: 20,
							paddingRight: 20,
							background: color,
							borderRadius: "5px",
							border: 0,
						}}
						itemStyle={{
							fontWeight: "bold",
							fontSize: "18px",
							lineHeight: "25px",
							color: "#FFFFFF",
							backgroundColor: color,
						}}
						labelStyle={{ display: "none" }}
					/>

					<Bar
						dataKey={dataKey}
						fill={color}
						radius={[15, 15, 0, 0]}
						barSize={40}
						onMouseEnter={(data, index) => setActiveIndex(index)}
						onMouseLeave={() => setActiveIndex(null)}
					>
						{data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={
									index === activeIndex || activeIndex === null
										? color
										: "#E0EAFF"
								}
							/>
						))}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</section>
	);
}
