import { useTranslation } from "react-i18next";
import { useRangePagination } from "src/hooks";
import { ChevronLeftIcon, ChevronRightIcon } from "src/components";

type PaginationProps = {
	pageIndex: number;
	pageCount: number;
	pageSize: number;
	previousPage: () => void;
	nextPage: () => void;
	gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
	canPreviousPage?: boolean;
	canNextPage?: boolean;
};

export const Pagination = ({
	pageIndex,
	pageCount,
	pageSize,
	canPreviousPage,
	canNextPage,
	previousPage,
	gotoPage,
	nextPage,
}: PaginationProps) => {
	const { i18n } = useTranslation();
	const paginationRange = useRangePagination({
		pageCount: pageCount,
		currentPage: pageIndex + 1,
		pageSize: pageSize,
	});

	return (
		<ul
			className={`flex items-center space-x-3 ${
				i18n.dir() === "rtl" ? "space-x-reverse" : ""
			}`}
		>
			<li>
				<button
					className={`center ${
						canPreviousPage ? "text-primary" : "text-muted"
					}`}
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					{i18n.dir() === "rtl" ? (
						<ChevronRightIcon width="24" height="24" />
					) : (
						<ChevronLeftIcon width="24" height="20" />
					)}
				</button>
			</li>

			{paginationRange &&
				paginationRange.map((page, i) =>
					page === "DOTS" ? (
						<li
							key={i}
							className={
								"  h-8 w-8  rounded-md text-center leading-8 text-primary "
							}
						>
							<button className="block h-full w-full">...</button>
						</li>
					) : (
						<li
							key={i}
							className={`  h-8 w-8  rounded-md text-center leading-8 text-primary ${
								page === pageIndex + 1 ? "bg-lightBlue" : "bg-white"
							}`}
						>
							<button
								className="block h-full w-full"
								onClick={() =>
									typeof page === "number" ? gotoPage(page - 1) : undefined
								}
							>
								{page !== "DOTS" && Number(page)}
							</button>
						</li>
					)
				)}

			<li>
				<button
					className={` center ${canNextPage ? "text-primary" : "text-muted"}`}
					onClick={() => nextPage()}
					disabled={!canNextPage}
				>
					{i18n.dir() === "rtl" ? (
						<ChevronLeftIcon width="24" height="20" />
					) : (
						<ChevronRightIcon width="24" height="24" />
					)}
				</button>
			</li>
		</ul>
	);
};
