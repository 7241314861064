import { useEffect } from "react";
import { headerTitleActions, useAppDispatch } from "src/redux";

type PageProps = {
	title: string;
	headerTitle?: string | null;
	children: React.ReactNode;
};

export function Page({ title, headerTitle = null, children }: PageProps) {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			headerTitleActions.changeHeader(
				headerTitle !== null ? headerTitle : title
			)
		);
	}, []);

	useEffect(() => {
		document.title = `${title} | BeClarified`;
		window.scrollTo(0, 0);
	}, [title]);

	return <div className="h-full">{children}</div>;
}
