import { useHistory, useLocation } from "react-router-dom";
import { RolesEnum } from "src/@types";
import { Btn, BigCheckMark } from "src/components";
import { ROUTES } from "src/constants";
import { authenticate, useAppDispatch } from "src/redux";
import { FormWrapper } from "../FormWrapper/FormWrapper";

export const LoginSuccessfully = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation<{ phone: string; password: string }>();
	const phone = location.state?.phone;
	const password = location.state?.password;

	const handleGoToDashboard = () => {
		if (phone && password) {
			dispatch(
				authenticate({
					username: "+" + phone?.replace(/\s/g, ""),
					password: password,
				})
			)
				.unwrap()
				.then(({ userRole }) => {
					if (userRole === RolesEnum.DENTIST) {
						history.replace(ROUTES.DENTIST.HOME);
					}

					if (userRole === RolesEnum.ASSISTANT) {
						history.replace(ROUTES.ASSISTANT.SCHEDULER);
					}

					if (userRole === RolesEnum.ADMIN) {
						history.replace(ROUTES.ADMIN.HOME);
					}
				})
				.catch(() => {
					history.replace(ROUTES.AUTH.LOGIN);
				});
		}
	};

	return (
		<FormWrapper>
			<div>
				<h2 className="mb-12 text-center text-2xl font-semibold text-dark">
					Your password has been changed successfully!
				</h2>

				<div className="center mb-12">
					<BigCheckMark />
				</div>

				<Btn
					variant="primary"
					size="md"
					type="button"
					rounded="rounded-lg"
					className={`
						mb-3 w-full text-lg
						${phone && password ? "opacity-100" : "cursor-not-allowed opacity-60"}
					`}
					onClick={handleGoToDashboard}
					disabled={!phone || !password}
				>
					Go to Dashboard
				</Btn>
			</div>
		</FormWrapper>
	);
};
