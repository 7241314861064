import moment from "src/config/moment";

export function convertHourToFullDate(hour: number): Date {
	return moment(
		new Date(
			moment().format("YYYY MM DD") +
				" " +
				moment().hour(hour).minute(0).format("LT")
		)
	).toDate();
}
