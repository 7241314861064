import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { PatientProfileState } from "../patientProfileSlice";
import { PatientFileOrthodonticType } from "../../../../@types/PatientFile.type";

export const orthodonticsReducers = {
	setOrthodontics: (
		state: WritableDraft<PatientProfileState>,
		action: PayloadAction<{
			orthodontics: PatientFileOrthodonticType;
		}>
	) => {
		state.orthodontics = action.payload.orthodontics;
	},
};
