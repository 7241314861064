import { useEffect, useState } from "react";
import { DateRange, useDateRange } from "../DateRange/DateRange";
import Select, { SingleValue } from "react-select";
import moment from "src/config/moment";
import { useTranslation } from "react-i18next";

type DropdownOption = { label: string; value: number };

type WithTimeOption = {
	timeOptions: DropdownOption[];
	defaultTimeOption: DropdownOption;
};

type WithoutTimeOption = {
	timeOptions?: never;
	defaultTimeOption?: never;
};

type DateFilterProps = (WithTimeOption | WithoutTimeOption) & {
	dateStates: React.ComponentProps<typeof DateRange>["dateStates"];
	hasMaxDate?: boolean;
	defaultTimeOption?: DropdownOption;
	timeOptions?: DropdownOption[];
};

export function DateFilter({
	dateStates,
	hasMaxDate = true,
	timeOptions,
	defaultTimeOption,
}: DateFilterProps) {
	const { i18n, t } = useTranslation("global");

	const dropdownOptions: DropdownOption[] = [
		{ label: t("Date_Filter.Date_Options.Last_Day"), value: 1 },
		{ label: t("Date_Filter.Date_Options.Last_Week"), value: 7 },
		{ label: t("Date_Filter.Date_Options.Last_Month"), value: 30 },
		{ label: t("Date_Filter.Date_Options.Last_3_Month"), value: 90 },
		{ label: t("Date_Filter.Date_Options.Last_Year"), value: 365 },
	];

	const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
		defaultTimeOption || dropdownOptions[2]
	);

	const onSelect = (newValue: SingleValue<DropdownOption>) => {
		if (newValue?.value && newValue?.value >= 0) {
			dateStates.setStartDate(
				moment()
					.locale(i18n.language)
					.subtract(newValue?.value, "days")
					.toDate()
			);
			dateStates.setEndDate(moment().locale(i18n.language).toDate());
		} else {
			dateStates.setEndDate(
				moment()
					.locale(i18n.language)
					.subtract(newValue?.value, "days")
					.toDate()
			);
			dateStates.setStartDate(moment().locale(i18n.language).toDate());
		}
	};

	useEffect(() => {
		(timeOptions || dropdownOptions).forEach((option) => {
			if (
				moment(dateStates.startDate).isSame(
					moment()
						.locale(i18n.language)
						.subtract(option.value, "days")
						.toDate(),
					"dates"
				) &&
				moment().isSame(dateStates.endDate, "dates")
			) {
				setSelectedOption(option);
			}

			if (
				!moment(dateStates.startDate).isSame(
					moment()
						.locale(i18n.language)
						.subtract(option.value, "days")
						.toDate(),
					"dates"
				) &&
				!moment().isSame(dateStates.endDate, "dates")
			) {
				setSelectedOption(null);
			}
		});
	}, [dateStates.startDate, dateStates.endDate]);

	return (
		<div className="flex gap-2.5">
			<div>
				<DateRange
					dateStates={dateStates}
					variant="standard"
					hasMaxDate={hasMaxDate}
				/>
			</div>

			<div className="w-40">
				<Select
					defaultValue={defaultTimeOption || dropdownOptions[2]}
					options={timeOptions || dropdownOptions}
					menuPortalTarget={document.body}
					isSearchable={false}
					onChange={onSelect}
					value={selectedOption}
					styles={{
						indicatorSeparator: () => ({
							display: "none",
						}),
						dropdownIndicator: (provided) => ({
							...provided,
							color: "#333",
						}),
						control: (provided) => ({
							...provided,
							color: "#333333",
							fontSize: "0.875rem",
							border: "1px solid #E5E5E5",
							boxShadow: "none",
							borderRadius: "0.1875rem",
							cursor: "pointer",
							padding: "1.5px",

							"&:hover": {
								border: "1px solid #E5E5E5",
							},

							"&:focus": {
								border: "1px solid #0077B4",
							},

							"&:active": {
								border: "1px solid #0077B4",
							},

							"&:focus-within": {
								border: "1px solid #0077B4",
							},
						}),
						option: (provided, state) => ({
							...provided,
							fontSize: "0.875rem",
							backgroundColor: state.isSelected ? "#0077B4" : "#fff",
							cursor: "pointer",
							direction: i18n.dir(),
							transition: "all cubic-bezier(0.4, 0, 0.2, 1) 150ms",

							"&:hover": {
								backgroundColor: state.isSelected ? "#0077B4" : "#F5F5F5",
							},
						}),
						menu: (provided) => ({
							...provided,
							borderRadius: "0.75rem",
							overflow: "hidden",
							backgroundColor: "#fff",
							boxShadow:
								"0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
						}),
					}}
				/>
			</div>
		</div>
	);
}

export function useDateFilter(start?: Date, end?: Date) {
	const { dateStates } = useDateRange(start, end);

	return { dateStates };
}
