import { useState } from "react";
import { LabOrderType } from "src/@types";
import { Popup } from "src/components";
import { AddOrderForm } from "./AddOrderForm/AddOrderForm";

type LabRequestPopupProps = {
	show: boolean;
	close: () => void;
	addOrder: (element: LabOrderType) => void;
	patientId?: number;
};

export function LabRequestPopup({
	show,
	close,
	addOrder,
	patientId,
}: LabRequestPopupProps) {
	return (
		<Popup show={show} close={close} width="750px">
			<AddOrderForm
				closePopup={close}
				addOrder={addOrder}
				patientId={patientId}
			/>
		</Popup>
	);
}

export function useLabRequestPopup() {
	const [show, setShow] = useState(false);

	const openLabRequestPopup = () => setShow(true);
	const closeLabRequestPopup = () => setShow(false);

	return {
		isLabRequestPopupOpen: show,
		openLabRequestPopup,
		closeLabRequestPopup,
	};
}
