import moment from "moment";
import "moment/locale/ar-ly";
import i18n from "./i18n";

new Promise((resolve) => {
	i18n.on("languageChanged", (lng) => {
		moment.locale(lng);
		resolve(lng);
	});

	i18n.on("initialized", (options) => {
		moment.locale(options.lng);
		resolve(options.lng);
	});
});

export default moment;
