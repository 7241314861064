import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Dropdown, ListItem } from "src/components";
import { useAppSelector } from "src/redux";

type ClinicDropdownProps = {
	control: Control<any, object>;
};

export const ClinicDropdown = ({ control }: ClinicDropdownProps) => {
	const { t } = useTranslation("scheduler");
	const {
		auth: {
			user: {
				admin: { clinics: clinicsState },
			},
		},
	} = useAppSelector((s) => s);
	const clinicsNumber = clinicsState ? clinicsState : 1;

	const rooms = Array.from(Array(clinicsNumber).keys()).map((i) => ({
		id: i + 1,
		name: `${t("Scheduler.Body.Clinic")} ${i + 1}`,
	}));

	return (
		<div className="mb-4">
			<Controller
				control={control}
				name="clinic"
				render={({
					field: { onChange, onBlur, value },
					fieldState: { error },
				}) => (
					<Dropdown
						title={t(
							"Appointments.Add_Appointment_Form.Inputs.Clinic_Input.Placeholder"
						)}
						label={t(
							"Appointments.Add_Appointment_Form.Inputs.Clinic_Input.Label"
						)}
						value={value}
						onChange={onChange}
						error={error}
						onBlur={onBlur}
						requiredStar
					>
						{rooms.map((room, i) => (
							<ListItem value={room.id} displayedName={room.name} key={i}>
								<div className="py-1 text-base text-dark 3xl:text-lg">
									{room.name}
								</div>
							</ListItem>
						))}
					</Dropdown>
				)}
			/>
		</div>
	);
};
