import { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import { Style } from "src/@types";

type LabelProps = Style & {
	required?: boolean;
	htmlFor?: string | undefined;
	children: ReactNode;
	variant?: "standard" | "filled" | "basic" | undefined;
	error?: FieldError | undefined;
};

export const Label = ({
	required,
	className,
	htmlFor,
	variant,
	children,
	error,
	style,
}: LabelProps) => {
	const renderVariant = () => {
		switch (variant) {
			case "standard":
				return "label";
			case "filled":
				return "label mb-2";
			case "basic":
				return "label-basic";
			default:
				return "label";
		}
	};

	return (
		<label
			htmlFor={htmlFor}
			className={`${renderVariant()} cursor-text text-lg ${className} ${
				error ? "text-red2" : ""
			} `}
			style={style}
		>
			{children} {required && <span className="text-red">*</span>}
		</label>
	);
};
