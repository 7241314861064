import { SVGProps } from "react";

export function MorningIcon({
	width = 22,
	height = 22,
}: SVGProps<SVGAElement>) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.55 18.09L3.96 19.5L5.76 17.71L4.34 16.29L2.55 18.09ZM11 5C7.69 5 5 7.69 5 11C5 14.31 7.69 17 11 17C14.31 17 17 14.31 17 11C17 7.68 14.31 5 11 5ZM19 12H22V10H19V12ZM16.24 17.71L18.04 19.5L19.45 18.09L17.66 16.29L16.24 17.71ZM19.45 4L18.04 2.6L16.24 4.39L17.66 5.81L19.45 4ZM12 0H10V3H12V0ZM5.76 4.39L3.96 2.6L2.55 4L4.34 5.81L5.76 4.39ZM0 12H3V10H0V12ZM12 19H10V22H12"
				fill="#046289"
			/>
		</svg>
	);
}

export function AfternoonIcon({
	width = 22,
	height = 22,
}: SVGProps<SVGAElement>) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 14C4.34339 14 3.69321 13.8707 3.08658 13.6194C2.47995 13.3681 1.92876 12.9998 1.46447 12.5355C0.526784 11.5979 0 10.3261 0 9C0 7.67392 0.526784 6.40215 1.46447 5.46447C2.40215 4.52678 3.67392 4 5 4C6 1.65 8.3 0 11 0C14.43 0 17.24 2.66 17.5 6.03L18 6C19.0609 6 20.0783 6.42143 20.8284 7.17157C21.5786 7.92172 22 8.93913 22 10C22 11.0609 21.5786 12.0783 20.8284 12.8284C20.0783 13.5786 19.0609 14 18 14H5ZM18 8H16V7C16 5.67392 15.4732 4.40215 14.5355 3.46447C13.5979 2.52678 12.3261 2 11 2C8.5 2 6.45 3.82 6.06 6.19C5.73 6.07 5.37 6 5 6C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9C2 9.79565 2.31607 10.5587 2.87868 11.1213C3.44129 11.6839 4.20435 12 5 12H18C18.5304 12 19.0391 11.7893 19.4142 11.4142C19.7893 11.0391 20 10.5304 20 10C20 9.46957 19.7893 8.96086 19.4142 8.58579C19.0391 8.21071 18.5304 8 18 8Z"
				fill="#046289"
			/>
		</svg>
	);
}

export function NightIcon({ width = 22, height = 22 }: SVGProps<SVGAElement>) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 9.28C19.74 9.3 19.5 9.31 19.26 9.31C17.32 9.31 15.39 8.57 13.91 7.09C12.25 5.44 11.5 3.19 11.72 1C11.77 0.53 11.22 0 10.71 0C10.57 0 10.44 0.04 10.32 0.12C10 0.36 9.66 0.64 9.36 0.94C7.05 3.24 6.55 6.66 7.84 9.46C6.31 10.11 5.13 11.43 4.69 13.06L4 13C1.79 13 0 14.79 0 17C0 19.21 1.79 21 4 21H17C18.66 21 20 19.66 20 18C20 16.34 18.66 15 17 15C16.42 15 15.88 15.16 15.42 15.45L15.5 14.5C15.5 14.28 15.5 14.05 15.46 13.83C17.14 13.67 18.77 12.94 20.06 11.64C20.38 11.34 20.64 11 20.88 10.68C21.27 10.13 20.65 9.28 20.04 9.28H20ZM17 17C17.55 17 18 17.45 18 18C18 18.55 17.55 19 17 19H4C2.9 19 2 18.1 2 17C2 15.9 2.9 15 4 15H6.5V14.5C6.5 12.94 7.53 11.64 8.94 11.18C9.1 11.13 9.26 11.09 9.43 11.06C9.61 11.03 9.8 11 10 11C10.23 11 10.45 11.03 10.66 11.07C10.73 11.08 10.8 11.1 10.87 11.13C11 11.16 11.15 11.2 11.28 11.25C11.36 11.28 11.44 11.32 11.5 11.36C11.63 11.41 11.74 11.47 11.84 11.54C11.92 11.59 12 11.64 12.07 11.7C12.17 11.77 12.25 11.84 12.34 11.92C12.41 12 12.5 12.05 12.55 12.12C12.63 12.2 12.69 12.29 12.76 12.37C12.82 12.45 12.89 12.53 12.94 12.62C13 12.71 13.04 12.8 13.09 12.9C13.14 13 13.2 13.08 13.24 13.18C13.41 13.59 13.5 14.03 13.5 14.5V17H17ZM14.83 11.86C13.9 10.16 12.08 9 10 9H9.87C9.41 8.19 9.14 7.26 9.14 6.29C9.14 5.31 9.39 4.37 9.86 3.55C10.21 5.41 11.12 7.14 12.5 8.5C13.86 9.88 15.58 10.79 17.45 11.14C16.66 11.6 15.76 11.84 14.83 11.86Z"
				fill="#046289"
			/>
		</svg>
	);
}

export function TimerIcon({ width = 11, height = 17 }: SVGProps<SVGAElement>) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 11 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.5 1.16699C0.5 0.614707 0.947715 0.166992 1.5 0.166992H9.5C10.0523 0.166992 10.5 0.614708 10.5 1.16699V4.75278C10.5 5.018 10.3946 5.27235 10.2071 5.45989L7.87377 7.79322C7.48325 8.18374 7.48325 8.81691 7.87377 9.20743L10.2071 11.5408C10.3946 11.7283 10.5 11.9827 10.5 12.2479V15.8337C10.5 16.3859 10.0523 16.8337 9.5 16.8337H1.5C0.947715 16.8337 0.5 16.3859 0.5 15.8337V12.2479C0.5 11.9827 0.605357 11.7283 0.792893 11.5408L3.12623 9.20743C3.51675 8.81691 3.51675 8.18374 3.12623 7.79322L0.792893 5.45989C0.605357 5.27235 0.5 5.018 0.5 4.75278V1.16699ZM8.83333 12.6645C8.83333 12.3993 8.72798 12.145 8.54044 11.9574L6.20711 9.6241C5.81658 9.23357 5.18342 9.23357 4.79289 9.6241L2.45956 11.9574C2.27202 12.145 2.16667 12.3993 2.16667 12.6645V14.167C2.16667 14.7193 2.61438 15.167 3.16667 15.167H7.83333C8.38562 15.167 8.83333 14.7193 8.83333 14.167V12.6645ZM4.79289 7.37655C5.18342 7.76708 5.81658 7.76708 6.20711 7.37655L8.54044 5.04322C8.72798 4.85568 8.83333 4.60133 8.83333 4.33611V2.83366C8.83333 2.28138 8.38562 1.83366 7.83333 1.83366H3.16667C2.61438 1.83366 2.16667 2.28137 2.16667 2.83366V4.33611C2.16667 4.60133 2.27202 4.85568 2.45956 5.04322L4.79289 7.37655ZM3.83333 3.94227C3.83333 3.69819 4.0312 3.50033 4.27527 3.50033H6.72473C6.9688 3.50033 7.16667 3.69819 7.16667 3.94227C7.16667 4.05948 7.1201 4.17189 7.03722 4.25477L6.20711 5.08489C5.81658 5.47541 5.18342 5.47541 4.79289 5.08489L3.96277 4.25477C3.87989 4.17189 3.83333 4.05948 3.83333 3.94227Z"
				fill="currentColor"
			/>
		</svg>
	);
}
