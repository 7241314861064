import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AppoitmentDetailsTabs, XCloseIcon, Tab, Tabs } from "src/components";
import { AllDetails } from "./AllDetails/AllDetails";
import { EditHistory } from "./EditHistory/EditHistory";
import { DetailsHeader } from "./DetailsHeader/DetailsHeader";
import { MedicalSurgeon } from "./MedicalSurgeon/MedicalSurgeon";

type AppointmentDetailsProps = {
	setPopup: React.Dispatch<React.SetStateAction<boolean>>;
	openDeletePopup: (deleteData?: any) => void;
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppointmentDetails = ({
	setPopup,
	openDeletePopup,
	setEdit,
}: AppointmentDetailsProps) => {
	const { t } = useTranslation("scheduler");
	const [tab, setTab] = useState<AppoitmentDetailsTabs>("APPOINTMENT_DETAILS");

	return (
		<div style={{ minHeight: "60vh" }}>
			<div className="flex justify-end px-3 pt-3">
				<button
					className="cursor-pointer text-gray-800 hover:text-dark"
					onClick={() => setPopup(false)}
				>
					<XCloseIcon width="28" height="28" />
				</button>
			</div>

			<div className=" divide-y divide-gray-200 ">
				<DetailsHeader
					setPopup={setPopup}
					openDeletePopup={openDeletePopup}
					setEdit={setEdit}
				/>

				<Tabs className="px-8">
					<Tab tab="APPOINTMENT_DETAILS" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Appointment_details.Tabs.Appoitment_Details")}
					</Tab>
					<Tab tab="Procedures" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Appointment_details.Tabs.Procedures")}
					</Tab>
					<Tab tab="EDIT_HISTORY" selectedTab={tab} onChangeTab={setTab}>
						{t("Appointments.Appointment_details.Tabs.Edit_History")}
					</Tab>
				</Tabs>
				{tab === "APPOINTMENT_DETAILS" && <AllDetails />}
				{tab === "Procedures" && <MedicalSurgeon />}
				{tab === "EDIT_HISTORY" && <EditHistory />}
			</div>
		</div>
	);
};
