import { Style } from "src/@types";

type LabTasksTitleProps = Style & {
	title?: string;
};

export function LabTasksTitle({
	title = "Tasks list and progress",
	className,
	style,
}: LabTasksTitleProps) {
	return (
		<h2
			className={`mb-9 font-poppins font-medium capitalize leading-[1.0625rem] text-dark ${className}`}
			style={{ ...style }}
		>
			{title}
		</h2>
	);
}
