// ! Don't touch this values
// ! Don't touch this values
// ! Don't touch this values
// ! Don't touch this values

type Enumerate<
	N extends number,
	Acc extends number[] = []
> = Acc["length"] extends N
	? Acc[number]
	: Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<
	Enumerate<T>,
	Enumerate<F>
>;

// * Label [ From 1 to 8 ]
// * Value [ From 1 to 32]
export type Quarter = {
	label: IntRange<1, 9>;
	value: IntRange<1, 33>;
};

// ! Don't touch this values
// * [ Upper Arch ] [ Right Quarter ] [ From 1 to 8 ]
const UPPER_ARCH_RIGHT_QUARTER: Quarter[] = [
	{ label: 8, value: 1 },
	{ label: 7, value: 2 },
	{ label: 6, value: 3 },
	{ label: 5, value: 4 },
	{ label: 4, value: 5 },
	{ label: 3, value: 6 },
	{ label: 2, value: 7 },
	{ label: 1, value: 8 },
];

// ! Don't touch this values
// * [ Upper Arch ] [ Left Quarter ] [ From 9 to 16 ]
const UPPER_ARCH_LEFT_QUARTER: Quarter[] = [
	{ label: 1, value: 9 },
	{ label: 2, value: 10 },
	{ label: 3, value: 11 },
	{ label: 4, value: 12 },
	{ label: 5, value: 13 },
	{ label: 6, value: 14 },
	{ label: 7, value: 15 },
	{ label: 8, value: 16 },
];

// ! Don't touch this values
// * [ Lower Arch ] [ Left Quarter ] [ From 24 to 17 ]
const LOWER_ARCH_LEFT_QUARTER: Quarter[] = [
	{ label: 1, value: 24 },
	{ label: 2, value: 23 },
	{ label: 3, value: 22 },
	{ label: 4, value: 21 },
	{ label: 5, value: 20 },
	{ label: 6, value: 19 },
	{ label: 7, value: 18 },
	{ label: 8, value: 17 },
];

// ! Don't touch this values
// * [ Lower Arch ] [ Right Quarter ] [ From 25 tp 32 ]
const LOWER_ARCH_RIGHT_QUARTER: Quarter[] = [
	{ label: 8, value: 32 },
	{ label: 7, value: 31 },
	{ label: 6, value: 30 },
	{ label: 5, value: 29 },
	{ label: 4, value: 28 },
	{ label: 3, value: 27 },
	{ label: 2, value: 26 },
	{ label: 1, value: 25 },
];

// ! Don't touch this values
export const TEETH = [
	UPPER_ARCH_RIGHT_QUARTER,
	UPPER_ARCH_LEFT_QUARTER,
	LOWER_ARCH_RIGHT_QUARTER,
	LOWER_ARCH_LEFT_QUARTER,
];
