import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type TabsProps = {
	children: ReactNode;
	className?: string | undefined;
};

export const Tabs = ({ children, className }: TabsProps) => {
	return (
		<div className={`flex w-full justify-between px-5 ${className}`}>
			{children}
		</div>
	);
};

export const NavTabs = ({ children, className }: TabsProps) => {
	const { i18n } = useTranslation();

	return (
		<div
			className={`flex space-x-10 ${
				i18n.dir() === "rtl" ? "space-x-reverse " : ""
			}${className}`}
		>
			{children}
		</div>
	);
};
