import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SearchPatient } from "../CustomInput/SearchPatient/SearchPatient";
import { DateAndTime } from "../CustomInput/DateAndtime/DateAndTime";
import { DoctorName } from "../CustomInput/DoctorName/DoctorName";
import { ClinicDropdown } from "../CustomInput/ClinicDropdown/ClinicDropdown";
import { ServicesDropdown } from "../CustomInput/ServicesDropdown/ServicesDropdown";
import { NotesInput } from "../CustomInput/NotesInput/NotesInput";
import { SubmitBtn } from "../CustomInput/SubmitBtn/SubmitBtn";
import { getDifferenceTwoObjects } from "src/utils";
import { getStartAndEndDate } from "src/features";
import {
	useAppDispatch,
	useAppSelector,
	addEvent,
	updateEvent,
	eventsActions,
	dentistsSelector,
	schedulerActions,
} from "src/redux";
import { toast } from "react-toastify";
import moment from "src/config/moment";
import { useQuery } from "src/hooks";

/* add form type */
type AddRegularPatient = {
	startDate: Date | string | null;
	startTime: Date | string | null;
	endTime: Date | string | null;
	dentist: string;
	patientId: string;
	clinic: string;
	type: string;
	notes: string;
};

type RegularPatientFormProps = {
	mode: "EDIT" | "ADD";
	setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RegularPatientForm = ({
	mode,
	setEdit,
}: RegularPatientFormProps) => {
	const [patientId, setPatientId] = useState<undefined | number>();
	const query = useQuery();
	const dispatch = useAppDispatch();

	const {
		scheduler: { selectedClinic },
		events: {
			addingLoading,
			editingLoading,
			errorMsg,
			start,
			end,
			selectedEvent,
		},
		dentists: { dentists },
		// auth: {
		// 	user: {
		// 		admin: { workStarts, workEnds },
		// 	},
		// },
	} = useAppSelector((state) => state);

	const schema = yup.object().shape({
		startDate: yup.date().default(new Date()).required("Required"),
		startTime: yup.date().default(null).required("Required").nullable(),
		// .min(moment().set("hours", workStarts ?? 0 ).set("minutes",0).toDate())
		// .max(workEnds === 24 ? moment().endOf("day").toDate() :moment().set("hours",workEnds??0).toDate())
		endTime: yup
			.date()
			.nullable()
			.default(null)
			.required("Required")
			.min(
				yup.ref("startTime"),
				"Appointments.Add_Appointment_Form.Inputs.End_Time.Errors"
			),
		// .max(new Date(new Date().setHours(22, 0)))
		dentist: yup.string().required("Required"),
		patientId: yup.string().required("Required"),
		clinic: yup.string().required("Required"),
		type: yup.string().required("Required"),
		notes: yup.string(),
	});

	const {
		register,
		handleSubmit,
		setValue,
		control,
		trigger,
		reset,
		formState: { errors, isDirty, isValid, isSubmitSuccessful },
	} = useForm<AddRegularPatient>({
		mode: "onSubmit",
		resolver: yupResolver(schema),
		defaultValues: {
			startDate: new Date(),
			startTime: null,
			endTime: null,
			dentist: dentists ? Object.values(dentists)[0].id.toString() : "",
			clinic: "1",
			type: "1",
			patientId: "",
			notes: "",
		},
	});

	const addAppointment = ({
		startDate,
		endTime,
		startTime,
		dentist,
		clinic,
		notes,
		type,
	}: any) => {
		const { starts, ends } = getStartAndEndDate(startDate, endTime, startTime);

		if (mode === "ADD") {
			const formData = {
				starts,
				ends,
				dentist,
				clinic,
				type,
				notes,
				patientId,
				status: 0,
			};

			dispatch(addEvent(formData))
				.then(() => {
					reset();
					setPatientId(undefined);
					dispatch(schedulerActions.closeAddAppointmentModal());
					toast.success("تم إضافة الموعد");
				})
				.catch(() => {
					toast.error("خطأ في إضافة الموعد ");
					dispatch(eventsActions.cancelAddingFromSlots());
					dispatch(schedulerActions.closeAddAppointmentModal());
				});
		} else if (mode === "EDIT") {
			const oldForm: any = {};

			if (
				selectedEvent?.start &&
				selectedEvent?.end &&
				selectedEvent?.resource
			) {
				oldForm.starts = moment(selectedEvent.start)
					.locale("en")
					.format("YYYY-MM-DDTHH:mm:00.00");
				oldForm.ends = moment(selectedEvent.end)
					.locale("en")
					.format("YYYY-MM-DDTHH:mm:00.00");
				oldForm.dentist = selectedEvent.resource.dentist?.id.toString();
				oldForm.clinic = selectedEvent.resource.clinic.toString();
				oldForm.type = selectedEvent.resource.type.toString();
				oldForm.notes = selectedEvent.resource.notes;
				oldForm.patientId = selectedEvent.resource.patient?.id;
			}

			const newForm = {
				starts: starts.toString(),
				ends: ends.toString(),
				dentist,
				clinic,
				type,
				notes,
				patientId,
			};
			const diff: object = getDifferenceTwoObjects(oldForm, newForm);

			if (Object.keys(diff).length !== 0 && selectedEvent?.resource?.id) {
				dispatch(
					updateEvent({ eventId: selectedEvent.resource.id, updated: diff })
				)
					.then(() => {
						toast.success("تم تعديل الموعد بنجاح");
						setEdit && setEdit(false);
					})
					.catch(() => {
						toast.error("خطأ في تعديل الموعد ");
					});
			}
		}
	};

	useEffect(() => {
		if (start && end) {
			setValue("startDate", new Date(start));
			setValue("startTime", new Date(start));
			setValue("endTime", new Date(end));
		}
	}, [start, end]);

	useEffect(() => {
		if (query.get("patient")) {
			setPatientId(Number(query.get("patient")));
		}
	}, [query]);

	useEffect(() => {
		if (mode === "ADD" && selectedClinic) {
			setValue("clinic", `${selectedClinic}`);
		}

		if (mode === "EDIT" && selectedEvent) {
			if (selectedEvent.resource && selectedEvent.start && selectedEvent.end) {
				setValue("startDate", new Date(selectedEvent.start));
				setValue("startTime", new Date(selectedEvent.start));
				setValue("endTime", new Date(selectedEvent.end));

				setValue("clinic", selectedEvent.resource.clinic.toString());
				setValue("type", selectedEvent.resource.type.toString());
				setValue("notes", selectedEvent.resource.notes);

				if (selectedEvent.resource.dentist && selectedEvent.resource.patient) {
					setValue("dentist", selectedEvent.resource.dentist.id.toString());
					setPatientId(selectedEvent.resource.patient.id);
				}
			}
		}
	}, [mode, selectedEvent, selectedClinic]);

	return (
		<form className="px-4 py-2" onSubmit={handleSubmit(addAppointment)}>
			<DateAndTime control={control} errors={errors} trigger={trigger} />

			<div className="mt-4">
				<div className="mb-4">
					<SearchPatient
						patientId={patientId}
						setPatientId={setPatientId}
						control={control}
						register={register}
						setValue={setValue}
						error={errors.patientId}
					/>
				</div>
				<DoctorName control={control} />
				<ClinicDropdown control={control} />
				<ServicesDropdown control={control} />
				<NotesInput register={register} error={errors.notes} />
				<SubmitBtn
					mode={mode}
					errorMsg={errorMsg}
					loading={addingLoading || editingLoading}
					isDirty={isDirty}
					isValid={typeof patientId !== "undefined" && isValid}
					isSubmitSuccessful={isSubmitSuccessful}
				/>
			</div>
		</form>
	);
};
