import { ReactNode } from "react";

type Size = "xs" | "sm" | "md" | "lg";
type Variant = "primary" | "secondary" | "info" | "primary-sm";

type BadgeProps = {
	size?: Size;
	variant?: Variant;
	children: ReactNode;
};

export const Badge = ({
	children,
	size = "sm",
	variant = "primary",
}: BadgeProps) => {
	const renderSize = (size: Size) => {
		switch (size) {
			case "xs":
				return "w-4 h-4";
			case "sm":
				return "w-6 h-6";
			default:
				return "w-7 h-7";
		}
	};

	const renderVariant = (variant: Variant) => {
		switch (variant) {
			case "primary":
				return {
					classes: "bg-lightBlue text-primary",
				};
			case "primary-sm":
				return {
					classes: "bg-lightBlue text-primary text-sm",
				};
			case "secondary":
				return {
					classes: "font-bold text-xs text-nativeWhite",
					style: {
						backgroundColor: "#E32424",
					},
				};
			case "info":
				return {
					classes: "font-medium text-xs bg-primary text-nativeWhite",
				};
			default:
				return {
					classes: "bg-lightBlue text-primary",
				};
		}
	};

	return (
		<span
			className={`center rounded-full ${renderSize(size)} ${
				renderVariant(variant).classes
			} `}
			style={renderVariant(variant)?.style}
		>
			{children}
		</span>
	);
};
