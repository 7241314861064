import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Dentist } from "src/@types";
import { useAppSelector, dentistsSelector } from "src/redux";
import { DentistNameColor, Dropdown, ListItem } from "src/components";

type DoctorNameProps = {
	control: Control<any, object>;
};

export const DoctorName = ({ control }: DoctorNameProps) => {
	const { t } = useTranslation("layout");
	const {
		dentists: { dentists },
		auth: { users },
	} = useAppSelector((s) => s);

	return (
		<div className="mb-4">
			<Controller
				control={control}
				name="dentist"
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { invalid, isTouched, isDirty, error },
					formState,
				}) => (
					<Dropdown
						title={t("Forms.Doctor_Name_Input.Placeholder")}
						label={t("Forms.Doctor_Name_Input.Label")}
						value={value}
						onChange={onChange}
						error={error}
						onBlur={onBlur}
						requiredStar
					>
						{dentists &&
							Object.values(dentists)
								.filter(
									(dentist: Dentist) => users?.[dentist.id].blocked === false
								)
								.map((dentist: Dentist, i) => (
									<ListItem
										value={dentist.id}
										displayedName={dentist.name}
										key={i}
									>
										<DentistNameColor dentist={dentist} />
									</ListItem>
								))}
					</Dropdown>
				)}
			/>
		</div>
	);
};
