type NoteProps = {
	note: string;
};

export function Note({ note }: NoteProps) {
	return (
		<p className="mt-1 text-sm leading-[1.1875rem] text-gray-800 ms-3">
			{note}
		</p>
	);
}
