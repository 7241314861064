import {
	PatientFilePlanWithoutIdType,
	PatientFileProcedureType,
} from "src/@types";
import { RootState } from "src/redux/store/store";

type HandleUpdateStatusParamsType = {
	planToUpdate: PatientFilePlanWithoutIdType;
	state: RootState;
	status: PatientFileProcedureType["status"];
	stageId: PatientFileProcedureType["stageId"];
	procedureId: PatientFileProcedureType["id"];
};

export function handleUpdateStatus({
	planToUpdate,
	state,
	status,
	stageId,
	procedureId,
}: HandleUpdateStatusParamsType): PatientFilePlanWithoutIdType {
	return {
		...planToUpdate,
		updatedAt: new Date().toISOString(),
		procedures: {
			stages: planToUpdate.procedures.stages.map((stage) => {
				if (stage.id === stageId) {
					return {
						...stage,
						procedures: stage.procedures.map((procedure) => {
							if (procedure.id === procedureId) {
								return {
									...procedure,
									status,
									updatedAt: new Date().toISOString(),
									updatedBy: state.auth.user?.id,
								};
							}

							return procedure;
						}),
					};
				}

				return stage;
			}),
		},
	};
}
