import React from "react";
import { Switch } from "react-router-dom";
import { PrintAppointments, Spinner } from "src/components";
import { ROUTES } from "src/constants";
import { useForAllRoutes } from "src/hooks";
import { PrivateRouter } from "./PrivateRouter";
import SchedulerPage from "src/pages/Shared/SchedulerPage/SchedulerPage";
import PatientListPage from "src/pages/Shared/PatientsListPage/PatientsListPage";
import AddNewPatient from "src/pages/Shared/PatientsListPage/AddNewPatient/AddNewPatient";

const LazyLabPage = React.lazy(
	() => import("src/pages/Shared/LabPage/LabPage")
);

const LazyPatientFilePage = React.lazy(
	() => import("src/pages/Shared/PatientFilePage/PatientFilePage")
);

const LazyCreateInvoice = React.lazy(
	() => import("src/pages/Shared/FinancialPage/CreateInvoice/CreateInvoice")
);

const LazyInvoicePage = React.lazy(
	() => import("src/pages/Shared/FinancialPage/InvoicePage/InvoicePage")
);

const LazyTasksPage = React.lazy(
	() => import("src/pages/Shared/TasksPage/TasksPage")
);

const LazySettingsPage = React.lazy(
	() => import("src/pages/Shared/SettingsPage/SettingsPage")
);

type SharedRoutesProps = {
	isAuth: boolean;
};

export function SharedRoutes({ isAuth }: SharedRoutesProps) {
	const route = useForAllRoutes();

	return (
		<Switch>
			<PrivateRouter path={ROUTES[route].SCHEDULER} isAuth={isAuth}>
				<SchedulerPage />
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].PRINT_APPOINTMENTS} isAuth={isAuth}>
				<PrintAppointments />
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].PATIENT.ROUTE} isAuth={isAuth}>
				<React.Suspense fallback={<Spinner />}>
					<LazyPatientFilePage />
				</React.Suspense>
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].ADD_NEW_PATIENT} isAuth={isAuth}>
				<AddNewPatient />
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].PATIENTS} isAuth={isAuth}>
				<PatientListPage />
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].CREATE_INVOICES} exact isAuth={isAuth}>
				<React.Suspense fallback={<Spinner />}>
					<LazyCreateInvoice />
				</React.Suspense>
			</PrivateRouter>

			<PrivateRouter
				path={ROUTES[route].INVOICES + "/:invoiceId"}
				isAuth={isAuth}
				exact
			>
				<React.Suspense fallback={<Spinner />}>
					<LazyInvoicePage />
				</React.Suspense>
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].LAB.BASE} isAuth={isAuth}>
				<React.Suspense fallback={<Spinner />}>
					<LazyLabPage />
				</React.Suspense>
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].TASKS.BASE} isAuth={isAuth}>
				<React.Suspense fallback={<Spinner />}>
					<LazyTasksPage />
				</React.Suspense>
			</PrivateRouter>

			<PrivateRouter path={ROUTES[route].SETTINGS.BASE} isAuth={isAuth}>
				<React.Suspense fallback={<Spinner />}>
					<LazySettingsPage />
				</React.Suspense>
			</PrivateRouter>
		</Switch>
	);
}
