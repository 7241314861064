import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomRadioBtn, Popup, XCloseIcon } from "src/components";
import { useAppSelector } from "src/redux";
import { FilterType } from "../Calendar";
import { ClinicsPopup } from "./ClinicsPopup/ClinicsPopup";
import { DentistsPopup } from "./DentistsPopup/DentistsPopup";

const filterOptions = [
	{ id: "dentist", label: "Scheduler.Filter.Dentist" },
	{ id: "clinic", label: "Scheduler.Filter.Clinic" },
];

type FilterPopupProps = {
	filterPopup: boolean;
	setFilterPopup: React.Dispatch<React.SetStateAction<boolean>>;
	filteredDentists: string[];
	filteredClinics: string[];
	setFilteredClinics: React.Dispatch<React.SetStateAction<string[]>>;
	setFilteredDentists: React.Dispatch<React.SetStateAction<string[]>>;
};

export function FilterPopup({
	filterPopup,
	setFilterPopup,
	filteredDentists,
	filteredClinics,
	setFilteredDentists,
	setFilteredClinics,
}: FilterPopupProps) {
	const { t } = useTranslation("scheduler");
	const { dentists } = useAppSelector((s) => s.dentists);
	const [selectedOption, setSelectedOption] = useState<null | FilterType>(null);
	const {
		auth: {
			user: {
				admin: { clinics: clinicsState },
			},
		},
	} = useAppSelector((s) => s);
	const clinicsNumber = clinicsState ? clinicsState : 1;

	const rooms = Array.from(Array(clinicsNumber).keys()).map((i) => ({
		id: i + 1,
		text: `${t("Scheduler.Body.Clinic")} ${i + 1}`,
	}));
	const handleRemoveDentist = (dentistId: string) => {
		setFilteredDentists((prev) =>
			prev.filter((filterId) => dentistId !== filterId)
		);
	};

	const handleRemoveClinics = (clinicId: string) => {
		if (filteredClinics.length === 1) {
			return;
		}
		setFilteredClinics((prev) =>
			prev.filter((filterId) => clinicId !== filterId)
		);
	};

	return (
		<>
			<Popup show={filterPopup} close={() => setFilterPopup(false)}>
				<div className="py-9 px-9">
					{filterOptions.map((filter) => (
						<div
							key={filter.id}
							className="mb-3 flex flex-shrink-0 items-center"
						>
							<CustomRadioBtn
								className="me-2"
								id={filter.id}
								label={t(filter.label)}
								name="filterName"
								value={filter.id}
								onChange={(e) => {
									setSelectedOption(e.target.value as any);
								}}
							/>

							<div className="flex flex-wrap">
								{filter.id === "dentist" &&
									filteredDentists.map((den, index) => (
										<div
											className="m-1 flex min-w-[40px] items-center justify-center rounded-md bg-gray-200 p-1 text-xs text-dark"
											key={index}
										>
											<div className="me-2">
												{dentists ? dentists[Number(den)]?.name : null}
											</div>
											<button
												className="center h-3 w-3 rounded-full border bg-transparent text-black transition-colors hover:text-red3"
												onClick={() => handleRemoveDentist(den)}
											>
												<XCloseIcon width={12} height={12} />
											</button>
										</div>
									))}
							</div>

							<div className="flex flex-wrap">
								{filter.id === "clinic" &&
									filteredClinics.map((clinicId, index) => (
										<div
											className="m-1 flex min-w-[40px] items-center justify-center rounded-md bg-gray-200 p-1 text-xs text-dark"
											key={index}
										>
											<div className=" me-2">
												{
													rooms.find((room) => room.id.toString() === clinicId)
														?.text
												}
											</div>
											<button
												className="center h-3 w-3 rounded-full border bg-transparent text-black transition-colors hover:text-red3"
												onClick={() => handleRemoveClinics(clinicId)}
											>
												<XCloseIcon width={12} height={12} />
											</button>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</Popup>

			<DentistsPopup
				filteredDentists={filteredDentists}
				setFilteredDentists={setFilteredDentists}
				selectedOption={selectedOption}
				setFilterPopup={setFilterPopup}
				setSelectedOption={setSelectedOption}
			/>

			<ClinicsPopup
				filteredClinics={filteredClinics}
				setFilteredClinics={setFilteredClinics}
				selectedOption={selectedOption}
				setFilterPopup={setFilterPopup}
				setSelectedOption={setSelectedOption}
			/>
		</>
	);
}
