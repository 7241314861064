import "react-image-crop/dist/ReactCrop.css";
import {
	DeletePopup,
	ImageCropperPopup,
	ListItem,
	Menu,
	TrashIcon,
	UploadIcon,
	useDeletePopup,
	useImageCropperPopup,
} from "src/components";
import logo from "src/assets/images/logo_icon.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "src/hooks";
import { toast } from "react-toastify";

type UploadImageProps = {
	image?: string;
	url: string;
	deleteUrl: string;
	formDataKey: string;
	handleSuccess?: (image: any) => void;
};

export function UploadImage({
	image,
	url,
	deleteUrl,
	formDataKey,
	handleSuccess,
}: UploadImageProps) {
	const { t } = useTranslation("settings");
	const hasImage = image !== "";

	const [menu, setMenu] = useState(false);
	const openMenu = () => setMenu(true);
	const closeMenu = () => setMenu(false);

	const { deletePopup, openDeletePopup, closeDeletePopup } = useDeletePopup();

	const { imageCropperPopupProps, onSelectFile } = useImageCropperPopup({
		formDataKey,
		url,
		handleSuccess,
	});

	const { makeRequest } = useAxios({ runNow: false });

	const handleDeleteImage = () => {
		makeRequest({
			configs: { url: deleteUrl, method: "DELETE" },
			handleSuccess: () => {
				handleSuccess?.("");
				closeDeletePopup();
				closeMenu();
				toast.success(t("Messages.Upload_Image.Delete", { ns: "global" }));
			},
		});
	};

	return (
		<div className="relative">
			<label
				htmlFor={hasImage === false ? "uploadImage" : ""}
				className="center cursor-pointer rounded-full border border-darkPrimary"
				style={{
					width: "6.25rem",
					height: "6.25rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
				}}
				onClick={hasImage ? openMenu : undefined}
			>
				<img src={image || logo} alt="upload image" className="rounded-full" />
			</label>

			<input
				type="file"
				accept="image/*"
				className="sr-only"
				id="uploadImage"
				name="uploadImage"
				onChange={(e) => {
					onSelectFile(e);
					closeMenu();
				}}
			/>

			<Menu
				show={menu}
				close={closeMenu}
				className="top-full mt-4 w-40"
				position="absolute"
			>
				<ListItem className="!p-0">
					<label
						htmlFor="uploadImage"
						className="flex items-center !py-3 !px-2 text-sm text-gray-800"
					>
						<span className="me-2">
							<UploadIcon width={20} height={20} />
						</span>
						<span className="font-semibold">
							{t("Settings.Profile_Header.Upload_Image")}
						</span>
					</label>
				</ListItem>

				<ListItem
					className="flex items-center !py-3 !px-2 text-sm text-gray-800"
					onClick={openDeletePopup}
				>
					<span className="me-2">
						<TrashIcon width={20} height={20} />
					</span>
					<span className="font-semibold">
						{t("Settings.Profile_Header.Delete_Image")}
					</span>
				</ListItem>

				<DeletePopup
					isOpen={deletePopup.isOpen}
					closeDeletePopup={closeDeletePopup}
					onDelete={handleDeleteImage}
					desc={t("Settings.Profile_Header.Messages.Delete_Confirmation")}
				/>
			</Menu>

			<ImageCropperPopup {...imageCropperPopupProps} />
		</div>
	);
}
