import axiosPackage, { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "src/config/axios";
import { handleAPIMessages } from "src/utils";

type Handlers<DataType> = {
	handleSuccess?: (data: DataType) => void;
	handleError?: (error: any) => void;
};

type Request<ResponseDataType, RequestDataType> = Handlers<ResponseDataType> & {
	configs?: AxiosRequestConfig<RequestDataType>;
};

type UseAxiosProps<ResponseDataType, RequestDataType> = Request<
	ResponseDataType,
	RequestDataType
> & {
	dependencies?: any[];
	runNow?: boolean;
};

export function useAxios<ResponseDataType = any, RequestDataType = any>({
	configs = {},
	dependencies = [],
	runNow = true,
	handleSuccess,
	handleError,
}: UseAxiosProps<ResponseDataType, RequestDataType>) {
	const { t } = useTranslation("global");

	const [data, setData] = useState<ResponseDataType | null>(null);
	const [initialLoading, setInitialLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const abortCount = useRef(new AbortController());
	const abortCountCurrent = abortCount.current;

	useEffect(() => {
		return () => abortCountCurrent.abort();
	}, [abortCountCurrent]);

	const initialRequest = useRef(true);

	const makeLoading = (state: boolean) => {
		if (initialRequest.current) {
			setInitialLoading(state);
			setLoading(state);
		} else {
			setLoading(state);
		}
	};

	const makeRequest = useCallback(
		(request?: Request<ResponseDataType, RequestDataType>) => {
			makeLoading(true);
			setError(null);

			const DEFAULT_CONFIGS = {
				signal: abortCountCurrent.signal,
			};

			axios({
				...DEFAULT_CONFIGS,
				...configs,
				...request?.configs,
			})
				.then(({ data }) => {
					setData(data);
					request?.handleSuccess && request.handleSuccess(data);
					handleSuccess && handleSuccess(data);
				})
				.catch((err) => {
					if (err.name !== "AbortError" && !axiosPackage.isCancel(err)) {
						setError("Error " + err.message);

						request?.handleError && request?.handleError(err);
						handleError && handleError(err);

						if (!handleError && !request?.handleError) {
							const method =
								request?.configs?.method || configs?.method || "GET";

							handleAPIMessages("error", t, method, err);
						}
					}
				})
				.finally(() => {
					makeLoading(false);
					initialRequest.current = false;
				});
		},
		[...dependencies]
	);

	useEffect(() => {
		if (runNow) makeRequest();
	}, [makeRequest, runNow, ...dependencies]);

	return { data, initialLoading, loading, error, makeRequest };
}
