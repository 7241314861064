import { useEffect, useState } from "react";
import {
	Control,
	FieldError,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	Size,
	Variant,
	PatientBasicInfo,
	LabType,
	LabServiceType,
	LabOrderType,
} from "src/@types";
import { useAppSelector } from "src/redux";
import {
	ListItem,
	SearchInput,
	PatientNamePhone,
	SearchIcon,
} from "src/components";
import { useAxios } from "src/hooks";
import { AddNewOrder } from "../formData";

type SelectOrderProps = {
	register: UseFormRegister<any>;
	control: Control<any, object>;
	setValue: UseFormSetValue<AddNewOrder>;
	error?: FieldError | undefined;
	labId: number | undefined;
	selectedOrder: LabOrderType | undefined;
	setSelectedOrder: React.Dispatch<
		React.SetStateAction<LabOrderType | undefined>
	>;
	variant?: Variant;
	inputSize?: Size;
};

export const SelectOrder = ({
	register,
	control,
	setValue,
	error,
	labId,
	selectedOrder,
	setSelectedOrder,
	variant,
	inputSize,
}: SelectOrderProps) => {
	const { data: labServices, makeRequest } = useAxios({ runNow: false });

	useEffect(() => {
		if (labId) {
			makeRequest({ configs: { url: `/labs/${labId}/services` } });
		}
	}, [labId]);

	const [results, setResults] = useState<LabServiceType[]>(labServices || []);

	const [errorMsg, setErrorMSg] = useState<any>("");
	const { t, i18n } = useTranslation("lab");

	const fetchData = (querySearch: string) => {
		const results = labServices
			? labServices.filter((service: LabServiceType) =>
					service.name.toLowerCase().includes(querySearch.toLowerCase())
			  )
			: [];

		setResults(results);

		if (results.length === 0) {
			setErrorMSg(
				i18n.language === "en" ? "There is no result" : " لا توجد نتائج بحث"
			);
		} else {
			setErrorMSg("");
		}
	};

	return (
		<SearchInput
			type="text"
			name="order"
			id="order"
			variant={variant}
			placeholder={t("Orders.Add_New_Order.Order_Name.Placeholder")}
			label={t("Orders.Add_New_Order.Order_Name.Label")}
			icon={<SearchIcon />}
			register={register}
			control={control}
			setValue={setValue}
			correspondingValue={selectedOrder}
			setCorrespondingValue={(v) => {
				setSelectedOrder(v);
				typeof v !== "undefined" && setValue("price", JSON.parse(v).price);
			}}
			error={error}
			requiredStar
			listItems={results.map((result, i) => (
				<ListItem
					value={JSON.stringify(result)}
					key={i}
					displayedName={result.name}
				>
					{result.name}
				</ListItem>
			))}
			queryFunc={fetchData}
			queryErrorMsg={errorMsg}
			inputSize={inputSize}
			disabled={labId === undefined}
		/>
	);
};
