import { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";
import { Style } from "src/@types";
import style from "./RadioBtn.module.css";
import { bool } from "yup";

interface RadioBtnProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label: ReactNode;
	name: string;
	register: UseFormRegister<any>;
	with_min_width?: boolean;
}

export const RadioBtn = ({
	id,
	name,
	label,
	register,
	value,
	with_min_width,
	...rest
}: RadioBtnProps) => {
	return (
		<div className={style.container}>
			<input
				id={id}
				type="radio"
				className="sr-only"
				{...register(name)}
				value={value}
				{...rest}
			/>

			<label
				htmlFor={id}
				className={style.label + (with_min_width ? " w-28" : "")}
			>
				<span className={style.ball} tabIndex={0}></span>

				{label}
			</label>
		</div>
	);
};

interface CustomRadioBtnProps
	extends React.DetailedHTMLProps<
			React.InputHTMLAttributes<HTMLInputElement>,
			HTMLInputElement
		>,
		Omit<Style, "style"> {
	label?: ReactNode;
	styles?: {
		container?: Style;
	};
}

export const CustomRadioBtn = ({
	id,
	label,
	className,
	styles,
	...rest
}: CustomRadioBtnProps) => {
	return (
		<div
			className={`${style.container} ${styles?.container?.className}`}
			style={styles?.container?.style}
		>
			<input id={id} type="radio" className="sr-only" {...rest} />

			<label htmlFor={id} className={`${style.label} ${className}`}>
				<span className={style.ball} tabIndex={0}></span>

				{label}
			</label>
		</div>
	);
};
