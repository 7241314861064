import moment from "moment";
import { useAppSelector } from "src/redux";

type DateWithUserCellProps = {
	date: string | Date;
	user: number;
	dateFormat?: string;
};

export function DateWithUserCell({
	date,
	user,
	dateFormat = "YYYY/MM/DD",
}: DateWithUserCellProps) {
	const { users } = useAppSelector((s) => s.auth);

	return (
		<div className="flex flex-col gap-0.5 leading-[1.1875rem]">
			<span className="text-base text-dark">
				{moment(new Date(date)).format(dateFormat)}
			</span>
			<span className="text-base text-primary ">{users?.[user]?.name}</span>
		</div>
	);
}
