import { PatientFilePlanType } from "src/@types";
import { updatePlan } from "./updatePlan";

export const updatePatientPlan = ({
	planId,
	newPlanName,
}: {
	planId: PatientFilePlanType["id"];
	newPlanName: PatientFilePlanType["name"];
}) => {
	return updatePlan({
		planId,
		updatedPlan: (planToUpdate) => {
			return { ...planToUpdate, name: newPlanName };
		},
	});
};
