export const TrendingUpIcon = () => {
	return (
		<svg
			width="15"
			height="19"
			viewBox="0 0 15 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1_798)">
				<path
					d="M13.4548 4.68317L7.91313 11.7847L4.99646 8.04705L0.62146 13.6535"
					stroke="#44C160"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.95471 4.68317H13.4547V9.16834"
					stroke="#44C160"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_798">
					<rect
						width="14"
						height="17.9407"
						fill="white"
						transform="translate(0.0380859 0.197998)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const TrendingDownIcon = () => {
	return (
		<svg
			width="15"
			height="11"
			viewBox="0 0 15 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.1389 1.73005C14.3537 1.51527 14.3537 1.16703 14.1389 0.952237C13.9241 0.737449 13.5759 0.737449 13.3611 0.952237L14.1389 1.73005ZM8.98333 6.10781L8.67825 6.56544C8.89639 6.71087 9.18686 6.68211 9.37224 6.49672L8.98333 6.10781ZM6.38333 4.37448L6.68842 3.91685C6.47028 3.77142 6.17981 3.80019 5.99442 3.98557L6.38333 4.37448ZM0.2 10.0078C0.2 10.3116 0.446243 10.5578 0.75 10.5578L5.7 10.5578C6.00376 10.5578 6.25 10.3116 6.25 10.0078C6.25 9.70406 6.00376 9.45781 5.7 9.45781L1.3 9.45781L1.3 5.05781C1.3 4.75406 1.05376 4.50781 0.749999 4.50781C0.446243 4.50781 0.199999 4.75406 0.199999 5.05781L0.2 10.0078ZM13.3611 0.952237L8.59442 5.7189L9.37224 6.49672L14.1389 1.73005L13.3611 0.952237ZM9.28842 5.65018L6.68842 3.91685L6.07825 4.83211L8.67825 6.56544L9.28842 5.65018ZM5.99442 3.98557L0.361092 9.6189L1.13891 10.3967L6.77224 4.76339L5.99442 3.98557Z"
				fill="#E24949"
			/>
		</svg>
	);
};
