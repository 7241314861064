export const getClassName = (
	className?: string | ((arg: any) => string),
	funcArg?: any
) => {
	if (typeof className === "function") return className(funcArg);

	return className || "";
};

export const getStyle = (
	style?: React.CSSProperties | ((arg: any) => React.CSSProperties),
	funcArg?: any
) => {
	if (typeof style === "function") return style(funcArg);

	return style || {};
};
