import { PatientFilePlanType, SelectedTeethDictionaryType } from "src/@types";

type ConvertHoveredTeethToObjectParams = {
	teeth: PatientFilePlanType["teeth"];
};

export function convertHoveredTeethToObject({
	teeth,
}: ConvertHoveredTeethToObjectParams): SelectedTeethDictionaryType {
	const teethObject: SelectedTeethDictionaryType = {};

	teeth.forEach((tooth) => (teethObject[+tooth] = true));

	return teethObject;
}
