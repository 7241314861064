import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RolesEnum } from "src/@types";
import {
	fetchAllUsers,
	fetchDentists,
	fetchInsurances,
	fetchLabs,
	fetchPatients,
	fetchServices,
	fetchStockProducts,
	useAppSelector,
} from "src/redux";
import { useAxios, useReduxAsync } from "../async";
import { fetchReports } from "../../redux/slices/reportsSlice/reportsAsyncActions";
import moment from "../../config/moment";

type UseAppProps = {
	userRole: RolesEnum;
	englishOnly?: boolean;
};

export function useApp({
	userRole: userRoleProp,
	englishOnly = false,
}: UseAppProps) {
	const { i18n } = useTranslation();

	useEffect(() => {
		if (englishOnly) i18n.changeLanguage("en");
	}, []);

	useAxios({ configs: { url: "/files-cookie" } });

	const {
		patients: { loading: patientLoading },
		auth: {
			fetchingLoading: usersLoading,
			login: { isLoggedIn, userRole },
		},
		dentists: { loading: dentistLoading },
		insurances: { loading: insuranceLoading },
		labs: { loading: labsLoading },
		services: { loading: servicesLoading },
		stock: { loading: stockLoading },
	} = useAppSelector((state) => state);

	useReduxAsync(fetchPatients());
	useReduxAsync(fetchDentists());
	useReduxAsync(fetchAllUsers());
	useReduxAsync(fetchInsurances());
	useReduxAsync(fetchLabs());
	useReduxAsync(fetchServices());
	useReduxAsync(fetchStockProducts());
	useReduxAsync(
		fetchReports({
			from_date: moment().subtract(30, "days").format("DD/MM/YYYY"),
			to_date: moment().format("DD/MM/YYYY"),
		})
	);

	const isAppLoading =
		patientLoading ||
		dentistLoading ||
		usersLoading ||
		insuranceLoading ||
		labsLoading ||
		servicesLoading ||
		stockLoading;

	const isAuth = isLoggedIn && userRole === userRoleProp;

	return { isAppLoading, isAuth };
}
