import { useTranslation } from "react-i18next";
import { FieldError } from "react-hook-form";
import { CalendarIcon } from "src/components";
import ReactDatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-SA";
import en from "date-fns/locale/en-US";
import { ReactNode } from "react";
import { Label } from "../Label/Label";
import * as Popper from "@popperjs/core";

type DateInputProps = {
	value: Date | null | undefined;
	onChange: (...event: any[]) => void;
	dateFormat?: string | string[] | undefined;
	onBlur?: (...event: any[]) => void;
	error?: FieldError | undefined;
	minDate?: Date | null | undefined;
	maxDate?: Date | null | undefined;
	readOnly?: boolean | undefined;
	requiredStar?: boolean;
	name?: string | undefined;
	label?: ReactNode;
	id?: string | undefined;
	inputSize?: "sm" | "md";
	variant?: "standard" | "filled";
	placeholder?: string | undefined;
	textDirection?: "ltr" | "rtl" | undefined;
	year?: boolean;
	popperPlacement?: Popper.Placement | undefined;
	withPortal?: boolean;
};

export const DateInput = ({
	value,
	id,
	dateFormat,
	minDate,
	maxDate,
	onChange,
	error,
	onBlur,
	requiredStar,
	name,
	readOnly,
	inputSize,
	variant,
	label,
	placeholder,
	year,
	textDirection = "rtl",
	popperPlacement = "auto",
	withPortal,
}: DateInputProps) => {
	const { i18n } = useTranslation();

	const renderSize = () => {
		switch (inputSize) {
			case "sm":
				return "py-2";
			case "md":
				return "py-3";
			default:
				return "py-2";
		}
	};

	const renderIconClasses = () => {
		switch (inputSize) {
			case "sm":
				return "top-2";
			case "md":
				return "top-3";
			default:
				return "top-3";
		}
	};

	const renderVariant = () => {
		switch (variant) {
			case "standard":
				return "input--standard";
			case "filled":
				return "input--filled";
			default:
				return "input--standard";
		}
	};

	return (
		<>
			{label && (
				<Label htmlFor={id} required={requiredStar} variant={variant}>
					{label}
				</Label>
			)}

			<div className="relative">
				<span
					className={`
						pointer-events-none absolute z-10 cursor-pointer
						${renderIconClasses()}
						${textDirection === "rtl" ? "right-1" : "left-1"}
						${error && error.message ? "text-red" : "text-muted"}
					`}
				>
					<CalendarIcon width="20" height="20" />
				</span>

				<ReactDatePicker
					name={name}
					id={id}
					selected={value}
					onChange={(date) => onChange(date)}
					dateFormat={dateFormat}
					minDate={minDate}
					maxDate={maxDate}
					calendarStartDay={6}
					locale={i18n.language === "ar" ? ar : en}
					popperClassName=""
					onBlur={onBlur}
					onClickOutside={() => onBlur && onBlur()}
					formatWeekDay={
						i18n.language === "ar"
							? (nameOfDay) => `${nameOfDay}`
							: (nameOfDay) => `${nameOfDay.slice(0, 3)}`
					}
					calendarClassName={`bg-white text-dark border-none shadow-xl py-2
						${!withPortal ? "w-80 2xl:w-80 3xl:w-[26rem]" : "w-auto"}
					`}
					className={`input !leading-3
						${textDirection === "rtl" ? "pr-8 text-right" : "pl-8 text-left"}
						${renderVariant()} ${renderSize()}
						${error && error.message ? "input--error" : ""}
						${readOnly ? "border-none focus:border-0" : "cursor-pointer"}
					`}
					weekDayClassName={(date) => {
						return "text-xs font-semibold text-dark font-cairo 3xl:text-base";
					}}
					popperPlacement={popperPlacement}
					placeholderText={placeholder}
					tabIndex={1}
					readOnly={readOnly}
					autoComplete="nope"
					peekNextMonth
					showMonthDropdown={year}
					showYearDropdown={year}
					dropdownMode={year ? "select" : undefined}
					withPortal={withPortal}
					portalId={withPortal ? "root-portal" : undefined}
				/>
			</div>
		</>
	);
};
