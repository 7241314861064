import { HeaderGroup } from "react-table";
import { useTableBodyContext } from "../TableBodyProvider";
import { getClassName, getStyle } from "../utils";
import { THeadSortButton } from "./THeadSortButton";

type THeadCeilProps = {
	headerGroup: HeaderGroup<any>;
};

export function THeadCeil({ headerGroup }: THeadCeilProps) {
	const { styles } = useTableBodyContext();

	return (
		<>
			{headerGroup.headers.map((column) => {
				const { key, style, ...restHeaderProps } = column?.canSort
					? column.getHeaderProps(column.getSortByToggleProps())
					: column.getHeaderProps();

				return (
					<th
						{...restHeaderProps}
						key={key}
						className={`
							${getClassName(styles?.thead?.tr?.th?.className, column)}
							${column.id === "more" ? "print:hidden" : ""}
						`}
						style={{
							...getStyle(styles?.thead?.tr?.th?.style, column),
							...style,
						}}
					>
						{!column.canSort && column.render("Header")}

						{column.canSort && (
							<div>
								<span>{column.render("Header")}</span>

								<THeadSortButton column={column} />
							</div>
						)}
					</th>
				);
			})}
		</>
	);
}
