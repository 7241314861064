export function getDifferenceTwoObjects<T extends object>(
	oldObj: T,
	newObj: T
) {
	const difference: any = {};

	for (const key in newObj) {
		if (newObj[key] !== oldObj[key]) {
			difference[key] = newObj[key];
		}
	}

	return difference;
}
export function cleanObjects(obj: any) {
	for (const propName in obj) {
		if (
			obj[propName] === null ||
			obj[propName] === undefined ||
			obj[propName] === "" ||
			obj[propName] === " " ||
			(typeof obj[propName] === "object" && obj[propName].length === 0)
		) {
			delete obj[propName];
		}
	}

	return obj;
}
