import { SVGProps } from "react";

export const WhatsappIcon = ({ width, height }: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2402_7463)">
				<path
					d="M17.5071 14.307L17.4981 14.382C15.2991 13.286 15.0691 13.14 14.7851 13.566C14.5881 13.861 14.0141 14.53 13.8411 14.728C13.6661 14.923 13.4921 14.938 13.1951 14.803C12.8951 14.653 11.9321 14.338 10.7921 13.318C9.90414 12.523 9.30814 11.548 9.13214 11.248C8.83914 10.742 9.45214 10.67 10.0101 9.61402C10.1101 9.40402 10.0591 9.23902 9.98514 9.09002C9.91014 8.94002 9.31314 7.47002 9.06314 6.88402C8.82314 6.30002 8.57614 6.37402 8.39114 6.37402C7.81514 6.32402 7.39414 6.33202 7.02314 6.71802C5.40914 8.49202 5.81614 10.322 7.19714 12.268C9.91114 15.82 11.3571 16.474 14.0011 17.382C14.7151 17.609 15.3661 17.577 15.8811 17.503C16.4551 17.412 17.6481 16.782 17.8971 16.077C18.1521 15.372 18.1521 14.787 18.0771 14.652C18.0031 14.517 17.8071 14.442 17.5071 14.307Z"
					fill="currentColor"
				/>
				<path
					d="M20.52 3.44879C12.831 -3.98421 0.106 1.40679 0.101 11.8928C0.101 13.9888 0.65 16.0328 1.696 17.8378L0 23.9998L6.335 22.3478C14.24 26.6178 23.996 20.9478 24 11.8988C24 8.72279 22.76 5.73379 20.505 3.48779L20.52 3.44879ZM22.002 11.8658C21.996 19.4988 13.617 24.2658 6.99 20.3698L6.63 20.1558L2.88 21.1308L3.885 17.4858L3.646 17.1108C-0.478 10.5458 4.26 1.96579 12.072 1.96579C14.726 1.96579 17.217 3.00079 19.093 4.87579C20.968 6.73479 22.002 9.22579 22.002 11.8658Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2402_7463">
					<rect width="24" height="24" fill="currentColor" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const FacebookIcon = () => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 0.66626C4.5 0.66626 0 5.15626 0 10.6863C0 15.6863 3.66 19.8363 8.44 20.5863V13.5863H5.9V10.6863H8.44V8.47626C8.44 5.96626 9.93 4.58626 12.22 4.58626C13.31 4.58626 14.45 4.77626 14.45 4.77626V7.24626H13.19C11.95 7.24626 11.56 8.01626 11.56 8.80626V10.6863H14.34L13.89 13.5863H11.56V20.5863C13.9164 20.2141 16.0622 19.0118 17.6099 17.1963C19.1576 15.3809 20.0054 13.0719 20 10.6863C20 5.15626 15.5 0.66626 10 0.66626Z"
				fill="#414D55"
			/>
		</svg>
	);
};
