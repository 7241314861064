import { RolesEnum } from "src/@types";
import { useAppSelector } from "src/redux";

export function useCheckRole() {
	const { userRole } = useAppSelector((state) => state.auth.login);

	const isAssistant = userRole === RolesEnum.ASSISTANT;
	const isDentist = userRole === RolesEnum.DENTIST;
	const isAdmin = userRole === RolesEnum.ADMIN;
	const notAuthorized = !isAssistant && !isDentist && !isAdmin;

	return { isAssistant, isDentist, isAdmin, notAuthorized };
}
