import { invoices, lab, patient, procedures } from "./global";

export function getPatientRoutes(baseRoute: string) {
	const patientBase = baseRoute + patient;
	const dynamicId = patientBase + "/:id";

	const notes = "/notes";
	const prescription = "/prescription";
	const overview = "/overview";
	const treatment = "/treatment";
	const storage = "/storage";
	const orthodontics = "/orthodontic";
	const visits = "/visits";
	const visitHistory = "/visit-history";

	return {
		PATIENT: {
			ROUTE: dynamicId,
			LINK: (id: string) => `${patientBase}/${id}`,

			OVERVIEW: {
				TREATMENT: {
					ROUTE: dynamicId + overview + treatment,
					LINK: (id: string) => `${patientBase}/${id}${overview}` + treatment,
				},
			},

			VISITS: {
				ROUTE: dynamicId + visits,
				LINK: (id: string) => `${patientBase}/${id}${visits}`,
			},

			INVOICES: {
				ROUTE: dynamicId + invoices,
				LINK: (id: string) => `${patientBase}/${id}${invoices}`,
			},

			LAB: {
				ROUTE: dynamicId + lab,
				LINK: (id: string) => `${patientBase}/${id}${lab}`,
			},

			NOTES: {
				ROUTE: dynamicId + notes,
				LINK: (id: string) => `${patientBase}/${id}${notes}`,
			},

			PRESCRIPTION: {
				ROUTE: dynamicId + prescription,
				LINK: (id: string) => `${patientBase}/${id}${prescription}`,
			},

			PROCEDURES: {
				ROUTE: dynamicId + procedures,
				LINK: (id: string) => `${patientBase}/${id}${procedures}`,
			},

			STORAGE: {
				ROUTE: dynamicId + storage,
				LINK: (id: string) => `${patientBase}/${id}${storage}`,
			},

			ORTHODONTICS: {
				ROUTE: dynamicId + orthodontics,
				LINK: (id: string) => `${patientBase}/${id}${orthodontics}`,
			},
		},
	};
}
