import { useTranslation } from "react-i18next";
import Select, { ActionMeta, SingleValue } from "react-select";
import { StatusRequest } from "src/@types";
import { DropdownIndicator } from "./DropdownIndicator";
import { StatusDropdownItem } from "./StatusDropdownItem";

type StatusDropdownProps<StatusVariants extends string> = {
	currentStatus: number;
	statusList: StatusRequest<StatusVariants>[];
	statusObject: { [key: number]: StatusRequest<StatusVariants> };
	handleChange:
		| ((
				newValue: SingleValue<StatusRequest<StatusVariants>>,
				actionMeta: ActionMeta<StatusRequest<StatusVariants>>
		  ) => void)
		| undefined;
};

export function StatusDropdown<StatusVariants extends string>({
	currentStatus: currentStatusProp,
	statusList,
	statusObject,
	handleChange,
}: StatusDropdownProps<StatusVariants>) {
	const { i18n } = useTranslation();

	return (
		<Select<StatusRequest<StatusVariants>>
			defaultValue={statusObject[currentStatusProp]}
			options={statusList}
			onChange={handleChange}
			menuPortalTarget={document.body}
			isSearchable={false}
			hideSelectedOptions
			isRtl={i18n.dir() === "rtl"}
			components={{
				Option: (props) => (
					<StatusDropdownItem<StatusVariants>
						optionProps={props}
						statusList={statusList}
						statusObject={statusObject}
					/>
				),
				DropdownIndicator: (props) => (
					<DropdownIndicator<StatusVariants>
						dropdownIndicator={props}
						statusObject={statusObject}
					/>
				),
			}}
			styles={{
				indicatorSeparator: (provided) => ({
					...provided,
					display: "none",
				}),
				control: (provided, props) => ({
					...provided,
					border: "none",
					boxShadow: "none",
					background:
						statusObject[props.getValue()[0].value].backgroundColor ||
						"transparent",
					textTransform: "capitalize",
					lineHeight: "1.1875rem",
					borderRadius: "0.375rem",
					fontSize: "0.75rem",
					fontWeight: 600,
					width: i18n.language === "en" ? "105px" : "130px",
					padding: "0.375rem 0.5rem",
					cursor: "pointer",
					textAlign: "center",
				}),
				valueContainer: (provided) => ({
					...provided,
					padding: 0,
					paddingRight: i18n.dir() === "ltr" ? "0.1rem" : "0",
					paddingLeft: i18n.dir() === "rtl" ? "0.1rem" : "0",
				}),
				singleValue: (provided, props) => ({
					...provided,
					color: statusObject[props.getValue()[0].value].color,
				}),
				menu: (provided) => ({
					...provided,
					marginTop: "5px",
					borderRadius: "0.375rem",
					boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
					border: "1px solid #E5E5E5",
					width: "max-content",
					padding: 0,
					right: i18n.dir() === "rtl" ? 0 : "auto",
				}),
			}}
		/>
	);
}
