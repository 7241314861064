type ProfileImgProps = {
	username: string;
	bgColor?: string | undefined;
	imgsrc?: string | undefined;
	size?: Size;
	style?: React.CSSProperties | undefined;
};

type Size = "sm" | "md" | "lg" | "xl";

export const ProfileImg = ({
	imgsrc,
	username,
	bgColor,
	style,
	size = "md",
}: ProfileImgProps) => {
	const renderImgSize = () => {
		switch (size) {
			case "sm":
				return "";
			case "md":
				return "w-8 h-8";
			case "lg":
				return "w-10 h-10";
			case "xl":
				return "w-14 h-14 text-base";
			default:
				return "w-8 h-8";
		}
	};

	return (
		<>
			{imgsrc ? (
				<div className={`flex-shrink-0 ${renderImgSize()}`}>
					<img
						className="h-full w-full rounded-full"
						src={imgsrc}
						alt={username}
					/>
				</div>
			) : (
				<span
					className={`center block flex-shrink-0 rounded-full text-center text-sm font-semibold capitalize ${
						bgColor ? bgColor : "bg-lightBlue text-darkBlue"
					} ${renderImgSize()}`}
					style={style}
				>
					{username.charAt(0)}
				</span>
			)}
		</>
	);
};
