import { UseFormSetValue } from "react-hook-form";
import { UseFormWatch } from "react-hook-form/dist/types";
import { Quarter } from "./teeth";

type TeethRowView = {
	type: "view";
	selectedTeeth: Record<number, boolean>;
	setValue?: never;
	watch?: never;
};

type TeethRowSelect = {
	type: "select";
	setValue: UseFormSetValue<any>;
	watch: UseFormWatch<any>;
	selectedTeeth?: never;
};
type TeethRowProps = (TeethRowView | TeethRowSelect) & {
	quarter: Quarter[];
};
export function TeethRow({
	type = "select",
	watch,
	setValue,
	quarter,
	selectedTeeth,
}: TeethRowProps) {
	const teeth = type === "select" && watch ? watch("teeth") : selectedTeeth;

	return (
		<div className="flex items-center gap-2 py-3 px-3">
			{quarter.map(({ label, value }) => (
				<span
					key={value}
					className={`
						rounded py-1 px-2 text-base leading-[1.1875rem] transition
						${type === "select" ? "cursor-pointer" : "cursor-default"}
						${teeth[value] ? "bg-primary text-nativeWhite" : "text-dark hover:bg-gray-300"}
					`}
					onClick={
						type === "select" && setValue
							? () => setValue("teeth", { ...teeth, [value]: !teeth[value] })
							: undefined
					}
				>
					{label}
				</span>
			))}
		</div>
	);
}
