export const UnsettledIcon = ({
	width = "26",
	height = "26",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.8334 8.04948V14.5495C23.8334 16.1745 23.2917 17.5287 22.3384 18.482C21.3959 19.4245 20.0417 19.9661 18.4167 19.9661V22.2737C18.4167 23.1403 17.4526 23.6603 16.7376 23.1837L11.9167 19.9661H9.62009C9.70675 19.6411 9.75008 19.3053 9.75008 18.9587C9.75008 17.8537 9.32758 16.8353 8.63425 16.0662C7.85425 15.1778 6.69508 14.6253 5.41675 14.6253C4.20341 14.6253 3.09842 15.1237 2.30759 15.9362C2.21009 15.5028 2.16675 15.037 2.16675 14.5495V8.04948C2.16675 4.79948 4.33341 2.63281 7.58341 2.63281H18.4167C21.6667 2.63281 23.8334 4.79948 23.8334 8.04948Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.74992 18.9583C9.74992 20.2583 9.17577 21.4175 8.2766 22.2083C7.50743 22.88 6.51075 23.2917 5.41659 23.2917C3.02242 23.2917 1.08325 21.3525 1.08325 18.9583C1.08325 17.5933 1.71159 16.3692 2.70825 15.5783C3.45575 14.9825 4.39825 14.625 5.41659 14.625C7.81075 14.625 9.74992 16.5642 9.74992 18.9583Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.68742 17.6045V19.2295L4.33325 20.042"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.20825 11.375H16.7916"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SettlementIcon = ({
	width = "26",
	height = "27",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.8333 9.45949V5.08283C23.8333 3.36033 23.14 2.66699 21.4175 2.66699H17.0408C15.3183 2.66699 14.625 3.36033 14.625 5.08283V9.45949C14.625 11.182 15.3183 11.8753 17.0408 11.8753H21.4175C23.14 11.8753 23.8333 11.182 23.8333 9.45949Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.375 9.73033V4.81199C11.375 3.28449 10.6816 2.66699 8.95913 2.66699H4.58246C2.85996 2.66699 2.16663 3.28449 2.16663 4.81199V9.71949C2.16663 11.2578 2.85996 11.8645 4.58246 11.8645H8.95913C10.6816 11.8753 11.375 11.2578 11.375 9.73033Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.375 21.9175V17.5408C11.375 15.8183 10.6816 15.125 8.95913 15.125H4.58246C2.85996 15.125 2.16663 15.8183 2.16663 17.5408V21.9175C2.16663 23.64 2.85996 24.3333 4.58246 24.3333H8.95913C10.6816 24.3333 11.375 23.64 11.375 21.9175Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.25 17.292H22.75"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M16.25 21.625H22.75"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export const TopCompanyIcon = ({
	width = "26",
	height = "26",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5833 9.75033C20.5833 11.3212 20.1175 12.762 19.3158 13.9645C18.1458 15.6978 16.2933 16.922 14.1375 17.2362C13.7691 17.3012 13.39 17.3337 13 17.3337C12.61 17.3337 12.2308 17.3012 11.8625 17.2362C9.70663 16.922 7.85413 15.6978 6.68413 13.9645C5.88246 12.762 5.41663 11.3212 5.41663 9.75033C5.41663 5.55783 8.80746 2.16699 13 2.16699C17.1925 2.16699 20.5833 5.55783 20.5833 9.75033Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.0209 20.0088L21.2334 20.4313C20.8325 20.5288 20.5184 20.8321 20.4317 21.233L20.0525 22.8255C19.8467 23.6921 18.7417 23.9521 18.1675 23.2696L13 17.3329L7.83254 23.2804C7.25838 23.9629 6.15338 23.7029 5.94754 22.8363L5.56838 21.2438C5.47088 20.8429 5.15671 20.5288 4.76671 20.4421L2.97921 20.0196C2.15588 19.8246 1.86338 18.7954 2.45921 18.1996L6.68421 13.9746C7.85421 15.7079 9.70671 16.9321 11.8625 17.2463C12.2309 17.3113 12.61 17.3438 13 17.3438C13.39 17.3438 13.7692 17.3113 14.1375 17.2463C16.2934 16.9321 18.1459 15.7079 19.3159 13.9746L23.5409 18.1996C24.1367 18.7846 23.8442 19.8138 23.0209 20.0088Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6284 6.47801L14.2676 7.75633C14.3542 7.92967 14.5817 8.10301 14.7876 8.13551L15.9467 8.3305C16.6834 8.44967 16.8567 8.99134 16.3259 9.52217L15.4267 10.4213C15.2751 10.573 15.1884 10.8655 15.2426 11.0822L15.5026 12.198C15.7084 13.0755 15.2426 13.4222 14.4626 12.9563L13.3792 12.3172C13.1842 12.198 12.8592 12.198 12.6642 12.3172L11.5809 12.9563C10.8009 13.4113 10.3351 13.0755 10.5409 12.198L10.8009 11.0822C10.8442 10.8763 10.7684 10.573 10.6167 10.4213L9.71755 9.52217C9.18672 8.99134 9.36005 8.4605 10.0967 8.3305L11.2559 8.13551C11.4509 8.10301 11.6784 7.92967 11.7651 7.75633L12.4042 6.47801C12.7184 5.78467 13.2817 5.78467 13.6284 6.47801Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LeastSettlementIcon = ({
	width = "24",
	height = "24",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 13.7502C9.5 14.7202 10.25 15.5002 11.17 15.5002H13.05C13.85 15.5002 14.5 14.8202 14.5 13.9702C14.5 13.0602 14.1 12.7302 13.51 12.5202L10.5 11.4702C9.91 11.2602 9.51001 10.9402 9.51001 10.0202C9.51001 9.18023 10.16 8.49023 10.96 8.49023H12.84C13.76 8.49023 14.51 9.27023 14.51 10.2402"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 7.5V16.5"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17 3V7H21"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22 2L17 7"
				stroke="#141414"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const AwaitingApprovalIcon = ({
	width = "28",
	height = "28",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.6667 6.99967H16.3334C18.6667 6.99967 18.6667 5.83301 18.6667 4.66634C18.6667 2.33301 17.5 2.33301 16.3334 2.33301H11.6667C10.5 2.33301 9.33337 2.33301 9.33337 4.66634C9.33337 6.99967 10.5 6.99967 11.6667 6.99967Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6667 4.69043C22.5517 4.90043 24.5 6.33543 24.5 11.6671V18.6671C24.5 23.3338 23.3333 25.6671 17.5 25.6671H10.5C4.66667 25.6671 3.5 23.3338 3.5 18.6671V11.6671C3.5 6.3471 5.44833 4.90043 9.33333 4.69043"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14 21.0003C16.5774 21.0003 18.6667 18.911 18.6667 16.3337C18.6667 13.7563 16.5774 11.667 14 11.667C11.4227 11.667 9.33337 13.7563 9.33337 16.3337C9.33337 18.911 11.4227 21.0003 14 21.0003Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.2917 14.875V15.96C14.2917 16.3683 14.0817 16.7533 13.7201 16.9633L12.8334 17.5"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ApprovedIcon = ({
	width = "28",
	height = "28",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.6667 6.99967H16.3334C18.6667 6.99967 18.6667 5.83301 18.6667 4.66634C18.6667 2.33301 17.5 2.33301 16.3334 2.33301H11.6667C10.5 2.33301 9.33337 2.33301 9.33337 4.66634C9.33337 6.99967 10.5 6.99967 11.6667 6.99967Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6667 4.69043C22.5517 4.90043 24.5 6.33543 24.5 11.6671V18.6671C24.5 23.3338 23.3333 25.6671 17.5 25.6671H10.5C4.66667 25.6671 3.5 23.3338 3.5 18.6671V11.6671C3.5 6.3471 5.44833 4.90043 9.33333 4.69043"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6667 15.1667C18.6667 16.0417 18.4217 16.87 17.99 17.57C17.185 18.9233 15.7034 19.8333 14 19.8333C12.2967 19.8333 10.8151 18.9233 10.0101 17.57C9.57839 16.87 9.33337 16.0417 9.33337 15.1667C9.33337 12.5883 11.4217 10.5 14 10.5C16.5784 10.5 18.6667 12.5883 18.6667 15.1667Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.1816 15.1658L13.3366 16.3208L15.8216 14.0225"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const RejectedIcon = ({
	width = "28",
	height = "28",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.3334 18.8534L11.7134 14.2334"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.2866 14.2803L11.6666 18.9003"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6667 6.99967H16.3334C18.6667 6.99967 18.6667 5.83301 18.6667 4.66634C18.6667 2.33301 17.5 2.33301 16.3334 2.33301H11.6667C10.5 2.33301 9.33337 2.33301 9.33337 4.66634C9.33337 6.99967 10.5 6.99967 11.6667 6.99967Z"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.6667 4.69043C22.5517 4.90043 24.5 6.33543 24.5 11.6671V18.6671C24.5 23.3338 23.3333 25.6671 17.5 25.6671H10.5C4.66667 25.6671 3.5 23.3338 3.5 18.6671V11.6671C3.5 6.3471 5.44833 4.90043 9.33333 4.69043"
				stroke="#292D32"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const AwaitingSettlementIcon = ({
	width = "26",
	height = "26",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.8333 8.04948V14.5495C23.8333 16.1745 23.2916 17.5287 22.3383 18.482C21.3958 19.4245 20.0416 19.9661 18.4166 19.9661V22.2737C18.4166 23.1403 17.4525 23.6603 16.7375 23.1837L11.9166 19.9661H9.61996C9.70663 19.6411 9.74996 19.3053 9.74996 18.9587C9.74996 17.8537 9.32746 16.8353 8.63413 16.0662C7.85413 15.1778 6.69496 14.6253 5.41663 14.6253C4.20329 14.6253 3.0983 15.1237 2.30746 15.9362C2.20996 15.5028 2.16663 15.037 2.16663 14.5495V8.04948C2.16663 4.79948 4.33329 2.63281 7.58329 2.63281H18.4166C21.6666 2.63281 23.8333 4.79948 23.8333 8.04948Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.75004 18.9583C9.75004 20.2583 9.17589 21.4175 8.27672 22.2083C7.50756 22.88 6.51087 23.2917 5.41671 23.2917C3.02254 23.2917 1.08337 21.3525 1.08337 18.9583C1.08337 17.5933 1.71171 16.3692 2.70837 15.5783C3.45587 14.9825 4.39837 14.625 5.41671 14.625C7.81087 14.625 9.75004 16.5642 9.75004 18.9583Z"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.68754 17.6045V19.2295L4.33337 20.042"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.20837 11.375H16.7917"
				stroke="#141414"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const PendingApprovalIcon = ({
	width = "28",
	height = "28",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3272_12638)">
				<path
					d="M25.3544 9.79312V16.03C25.3544 17.5893 24.8346 18.8886 23.9199 19.8034C23.0155 20.7077 21.7162 21.2275 20.157 21.2275V23.4416C20.157 24.2731 19.2318 24.7721 18.5458 24.3148L13.9201 21.2275H11.7164C11.7995 20.9156 11.8411 20.5934 11.8411 20.2607C11.8411 19.2005 11.4357 18.2234 10.7704 17.4853C10.022 16.633 8.90974 16.1028 7.68315 16.1028C6.51893 16.1028 5.45866 16.581 4.69984 17.3606C4.60628 16.9448 4.5647 16.4978 4.5647 16.03V9.79312C4.5647 6.67467 6.64367 4.5957 9.76212 4.5957H20.157C23.2754 4.5957 25.3544 6.67467 25.3544 9.79312Z"
					stroke="#F3A72E"
					strokeWidth="1.55"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.8413 20.2595C11.8413 21.5069 11.2904 22.6191 10.4276 23.378C9.68955 24.0224 8.73321 24.4174 7.68333 24.4174C5.38607 24.4174 3.52539 22.5568 3.52539 20.2595C3.52539 18.9497 4.12829 17.7751 5.08462 17.0163C5.80186 16.4446 6.70621 16.1016 7.68333 16.1016C9.98059 16.1016 11.8413 17.9622 11.8413 20.2595Z"
					stroke="#F3A72E"
					strokeWidth="1.55"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.94315 18.96V20.5192L6.6438 21.2988"
					stroke="#F3A72E"
					strokeWidth="1.55"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.3213 12.9844H18.5977"
					stroke="#F3A72E"
					strokeWidth="1.55"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3272_12638">
					<rect
						width="26.8667"
						height="26.8667"
						fill="white"
						transform="translate(0.56665 0.150391)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
