import { WithContext as ReactTags } from "react-tag-input";
import { UseFormSetValue, Path, UseFormWatch } from "react-hook-form";
import { Style } from "src/@types";

const KeyCodes = { comma: 188, enter: 13 };
const delimiters = [KeyCodes.comma, KeyCodes.enter];

type TagType = { id: string; text: string };

type CustomTagsInputProps = {
	label?: string;
	name: Path<any>;
	setValue: UseFormSetValue<any>;
	watch: UseFormWatch<any>;
	styles?: {
		container: Style;
	};
};

export function CustomTagsInput({
	label,
	name,
	setValue,
	watch,
	styles,
}: CustomTagsInputProps) {
	const tags: TagType[] = watch(name);

	const handleDelete = (i: number) => {
		setValue(
			name,
			tags.filter((tag, index) => index !== i)
		);
	};

	const handleAddition = (tag: TagType) => {
		setValue(name, [...tags, tag]);
	};

	const handleDrag = (tag: TagType, currPos: number, newPos: number) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		setValue(name, newTags);
	};

	return (
		<section
			className={styles?.container.className}
			style={styles?.container.style}
		>
			{label && (
				<label
					htmlFor={`${name}-tags`}
					className="mb-2.5 text-base font-bold text-dark"
				>
					{label}
				</label>
			)}

			<ReactTags
				tags={tags}
				delimiters={delimiters}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleDrag={handleDrag}
				inputFieldPosition="inline"
				autocomplete
				placeholder="add"
				id={`${name}-tags`}
				allowUnique
				classNames={{
					tags: "py-3",
					tagInputField:
						"h-8 bg-primary px-2 text-nativeWhite text-base font-normal border-none outline-none w-24",
					selected: "flex items-center flex-wrap items-center gap-3",
					tag: "block h-8 bg-primary text-nativeWhite text-base font-normal flex items-center justify-center gap-2.5 px-2 rounded",
					remove: "text-lg relative",
				}}
			/>
		</section>
	);
}
