import moment from "src/config/moment";
import { useTranslation } from "react-i18next";
import { AppointmentsStatusEnum, Event, ServiceEnum } from "src/@types";
import { localizer } from "../custom/localizer";
import { APPOINTMENT_STATUS_OBJECT, services } from "src/constants/index";
import ReactTooltip from "react-tooltip";

type EventProps = {
	event: Event;
};

export const EventComponent = ({ event }: EventProps) => {
	const { t } = useTranslation("global");

	const renderType = () => {
		const service = services(t).find(
			(service) => service.id === event.resource?.type
		);

		return service?.name;
	};

	return moment(event.end).diff(event.start, "minutes") < 30 ? (
		<SmallCard event={event} renderType={renderType} />
	) : (
		<BigCard event={event} renderType={renderType} />
	);
};

type CardProps = {
	event: Event;
	renderType: () => string | undefined;
};

function SmallCard({ event, renderType }: CardProps) {
	const { t, i18n } = useTranslation("global");

	return (
		<div className="flex items-center gap-2">
			<div className="w-[90px] flex-shrink-0 truncate text-sm">
				{event.resource?.patient?.name.length > 12 && (
					<ReactTooltip id="patientName" effect="solid" />
				)}
				<span
					data-tip={
						event.resource?.patient?.name.length > 12
							? event.resource?.patient?.name
							: null
					}
					data-for={
						event.resource?.patient?.name.length > 12 ? "patientName" : null
					}
				>
					{event.resource?.patient?.name}
				</span>
			</div>

			<span className="text-sm">-</span>

			<span className="flex-shrink-0 text-sm">
				{event.start && localizer.format(event.start, "h.mm", i18n.language)} :
				{event.end && localizer.format(event.end, "h.mm A", i18n.language)}
			</span>

			<div className="flex items-center gap-2 ms-auto">
				{event.resource?.type && (
					<span className="rounded-lg border border-current bg-transparent p-1 text-xs">
						{renderType()}
					</span>
				)}

				<div className="flex-shrink-0">
					<ReactTooltip id="type" effect="solid" />

					<span
						data-tip={t(
							APPOINTMENT_STATUS_OBJECT()[
								event.resource?.status as AppointmentsStatusEnum
							].label,
							{ ns: "scheduler" }
						)}
						data-for="type"
					>
						{
							APPOINTMENT_STATUS_OBJECT()[
								event.resource?.status as AppointmentsStatusEnum
							].icon
						}
					</span>
				</div>

				{event.resource?.notes && (
					<div className="flex-shrink-0 text-sm">
						<span>
							<ReactTooltip id="note" effect="solid" />

							<span
								className="text-xs"
								data-tip={event.resource.notes}
								data-for="note"
							>
								ℹ️
							</span>
						</span>
					</div>
				)}
			</div>
		</div>
	);
}

function BigCard({ event, renderType }: CardProps) {
	const { i18n } = useTranslation("global");

	return (
		<div className="flex h-full flex-col text-start">
			<div className="mb-2 flex items-center gap-2">
				<p className="truncate capitalize leading-6">
					{event.resource?.patient?.name}{" "}
					{event.resource?.patient?.customId ? (
						<span className="text-sm">
							[{event.resource?.patient?.customId}]
						</span>
					) : null}
				</p>

				<span>-</span>

				<span className="flex-shrink-0">
					{event.start &&
						event.end &&
						localizer.format(event.start, "h:mm A", i18n.language)}
				</span>

				<span>
					{
						APPOINTMENT_STATUS_OBJECT()[
							event.resource?.status as AppointmentsStatusEnum
						].icon
					}
				</span>
			</div>

			<div className="align-center text-xs font-normal">
				{event.resource?.type && (
					<span className="rounded-lg border border-current bg-transparent p-1">
						{renderType()}
					</span>
				)}

				{event.resource?.clinic && (
					<span className=" ">
						{event.resource.notes && (
							<span className="ms-2">
								<ReactTooltip id="note" effect="solid" />

								{event.resource.notes.length < 20 ? (
									<span
										className="text-xs"
										data-tip={event.resource.notes}
										data-for="note"
									>
										- ℹ️ {event.resource.notes}
									</span>
								) : (
									<>
										<span data-tip={event.resource.notes} data-for="note">
											ℹ️ {event.resource.notes.slice(0, 20)}...
										</span>
									</>
								)}
							</span>
						)}
					</span>
				)}
			</div>
		</div>
	);
}
