import { XAxis, YAxis } from "recharts";

const axisProps = {
	tick: {
		color: "#86909C",
		fontSize: "0.75rem",
		fontWeight: "bold",
	},
	tickLine: false,
	axisLine: false,
};

export const xAxisProps = {
	...axisProps,
};

export const yAxisProps = {
	...axisProps,
};
