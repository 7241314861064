import { SVGProps } from "react";

export const ChevronLeftIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 18L9 12L15 6"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronRightIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 18L15 12L9 6"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronRightBold = ({
	width,
	height,
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 8 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 13L7 7L1 1"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronDownIcon = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 9L12 15L18 9"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronSort = ({ width, height }: SVGProps<SVGAElement>) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.71484 14.5714L12.0006 18.8571L16.2863 14.5714M16.2863 9.42857L12.0006 5.14285L7.71484 9.42857"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export function ChevronLongLeftIcon({
	width = 33,
	height = 33,
}: SVGProps<SVGAElement>) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 33 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M29.9583 15.6503H6.51033L13.5677 8.59298C13.695 8.46998 13.7966 8.32286 13.8665 8.16019C13.9363 7.99751 13.9731 7.82256 13.9747 7.64552C13.9762 7.46848 13.9425 7.2929 13.8754 7.12904C13.8084 6.96518 13.7094 6.81631 13.5842 6.69112C13.459 6.56593 13.3101 6.46693 13.1463 6.39989C12.9824 6.33285 12.8068 6.29911 12.6298 6.30065C12.4528 6.30219 12.2778 6.33897 12.1151 6.40885C11.9525 6.47873 11.8053 6.5803 11.6823 6.70765L2.349 16.041C2.09904 16.291 1.95862 16.6301 1.95862 16.9836C1.95862 17.3372 2.09904 17.6763 2.349 17.9263L11.6823 27.2596C11.9338 27.5025 12.2706 27.6369 12.6202 27.6339C12.9698 27.6308 13.3042 27.4906 13.5514 27.2434C13.7986 26.9962 13.9389 26.6618 13.9419 26.3122C13.9449 25.9626 13.8105 25.6258 13.5677 25.3743L6.51033 18.317H29.9583C30.3119 18.317 30.6511 18.1765 30.9011 17.9264C31.1512 17.6764 31.2917 17.3373 31.2917 16.9836C31.2917 16.63 31.1512 16.2909 30.9011 16.0408C30.6511 15.7908 30.3119 15.6503 29.9583 15.6503Z"
				fill="#0077B4"
			/>
		</svg>
	);
}
