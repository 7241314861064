import { StatisticCard } from "./StatisticCard/StatisticCard";

export function StatisticCards({
	cards,
}: {
	cards: React.ComponentProps<typeof StatisticCard>[];
}) {
	return (
		<section className="mb-4 flex justify-between gap-[1.125rem]">
			{cards.map((card, index) => (
				<StatisticCard
					key={index}
					title={card.title}
					value={card.value}
					valueLabel={card.valueLabel}
					icon={card.icon}
					button={card.button}
				/>
			))}
		</section>
	);
}
