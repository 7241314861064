import { base } from "./global";

const authBase = base + "/auth";
const login = authBase + "/login";

export const AUTH = {
	BASE: authBase,

	LOGIN: login,
	FORGOT_PASSWORD: login + "/forgotPassword",
	RESET_PASSWORD: login + "/resetPassword",
	VERIFICATION_CODE: login + "/verificationCode",
	LOGIN_SUCCESSFULLY: login + "/loginSuccessfully",

	REGISTER: authBase + "/register",
};
