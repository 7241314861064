import { Row } from "react-table";
import { useTableBodyContext } from "../TableBodyProvider";
import { getClassName, getStyle } from "../utils";

export function TBodyCeil({ row }: { row: Row<any> }) {
	const { styles } = useTableBodyContext();

	return (
		<>
			{row.cells.map((cell) => {
				const { key, ...restCellProps } = cell.getCellProps();

				return (
					<td
						{...restCellProps}
						key={key}
						className={`
							${cell.column.id === "more" ? "print:hidden" : ""}
							${getClassName(styles?.tbody?.tr?.td?.className, cell)} 
						`}
						style={getStyle(styles?.tbody?.tr?.td?.style, cell)}
					>
						{cell.render("Cell")}
					</td>
				);
			})}
		</>
	);
}
