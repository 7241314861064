import moment from "moment";
import { PatientFilePlanType, PatientFileType } from "src/@types";

export const createEmptyPlan = (
	patientId: PatientFileType["id"],
	planName: string
): PatientFilePlanType => {
	const emptyPlan: PatientFilePlanType = {
		id: 0,
		name: planName,
		createdAt: moment().toISOString(),
		updatedAt: moment().toISOString(),
		totalPrice: 0,
		diagnoses: [],
		patientId,
		procedures: {
			stages: [
				{
					id: 0,
					name: "Stage 1",
					procedures: [],
					totalPrice: 0,
				},
			],
		},
		teeth: Array.from({ length: 32 }, (_, i) => i + 1).map((n) => ({
			number: n,
			type: "permanent",
		})),
	};

	return emptyPlan;
};
