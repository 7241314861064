import { useTranslation } from "react-i18next";
import { AppointmentsStatusEnum, UserType } from "src/@types";
import { useAppDispatch, useAppSelector, updateEventStatus } from "src/redux";
import {
	BtnWithIcon,
	Dropdown,
	ListItem,
	EditIcon,
	SMSIcon,
	TrashIcon,
	WhatsappIcon,
	ProfileInfoCard,
	BillIcon,
} from "src/components";
import ReactTooltip from "react-tooltip";
import { APPOINTMENT_STATUS, ROUTES } from "src/constants";
import { useHistory } from "react-router-dom";
import { useForAllRoutes } from "src/hooks";

type DetailsHeaderProps = {
	setPopup: React.Dispatch<React.SetStateAction<boolean>>;
	openDeletePopup: (deleteData?: any) => void;
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DetailsHeader = ({
	setPopup,
	openDeletePopup,
	setEdit,
}: DetailsHeaderProps) => {
	const history = useHistory();
	const route = useForAllRoutes();
	const { t, i18n } = useTranslation("scheduler");
	const { selectedEvent: event } = useAppSelector((state) => state.events);
	const { clinicName } = useAppSelector((state) => state.auth.user.admin);

	const dispatch = useAppDispatch();

	const renderStatusDropdown = (status: AppointmentsStatusEnum | undefined) => {
		switch (status) {
			case AppointmentsStatusEnum.NOT_CONFIRMED:
				return "secondary";
			case AppointmentsStatusEnum.CONFIRMED:
				return "primary";
			case AppointmentsStatusEnum.ATTENDED:
				return "success";
			case AppointmentsStatusEnum.NOT_ATTENDED:
				return "warning";
			case AppointmentsStatusEnum.CANCELED:
				return "danger";
			default:
				return "";
		}
	};

	const changeStatusHandler = (value: number) => {
		if (event && event.resource?.id) {
			dispatch(
				updateEventStatus({ eventId: event.resource.id, status: value })
			);
		}
	};

	const deleteHandler = () => {
		openDeletePopup();
		setPopup(false);
	};

	const editHandler = () => {
		setEdit(true);
		setPopup(false);
	};

	const whatsappNumber = event?.resource?.patient?.phone?.startsWith("+")
		? event?.resource?.patient?.phone
		: `+2${event?.resource?.patient?.phone}`;
	const appointmentDate = event?.start
		? new Date(event.start).toLocaleDateString("es-CL")
		: "";
	const appointmentTime = event?.start
		? new Date(event.start).toLocaleTimeString("en-US", {
				hour: "2-digit",
				minute: "2-digit",
		  })
		: "";

	const handleWhatsappConfirm = () => {
		const apiConfirmationUrl = `https://staging.beclarified.com/appointments/confirmation/${encodeURIComponent(
			event?.resource?.token
		)}`;

		const confirmationMessage = `تم حجز موعدكم لدى  ${clinicName}
		%0A
		يوم ${appointmentDate} الساعة ${appointmentTime}
		%0A
		لتأكيد الحجز تلقائيًا، يُرجى زيارة الرابط التالي 
		%0A
		في حالة عدم تفعيل الرابط، يُرجى إضافة الرقم إلى جهات الاتصال أو قُم بالرد ب 1
		%0A
		${apiConfirmationUrl}`;

		const confirmationUrl = `https://wa.me/${whatsappNumber}?text=${confirmationMessage}`;

		window.open(confirmationUrl, "_blank");
	};

	return (
		<>
			<div className="px-5 py-5">
				<div className="align-center mb-4 w-full justify-between">
					<div className="align-center">
						{event &&
							event.resource?.patient?.name &&
							event.resource?.patient?.phone && (
								<ProfileInfoCard
									id={event.resource.patient.id}
									userName={event.resource.patient.name}
									name={event.resource?.patient?.name}
									phone={event.resource.patient.phone}
								/>
							)}

						<div className="w-36">
							<Dropdown
								value={event ? event.resource?.status : 0}
								title={t("Appointments.Appointment_details.Header.StatusTitle")}
								onChange={changeStatusHandler}
								position={i18n.dir() === "rtl" ? "bottom-right" : "bottom-left"}
								color={renderStatusDropdown(event ? event.resource?.status : 0)}
								designedAsLabel
							>
								{APPOINTMENT_STATUS().map((status, i) => (
									<ListItem
										value={status.value}
										displayedName={t(status.label)}
										key={i}
									>
										<div className="align-center text-gray-800">
											<span className={`${status.icon ? "ms-1 me-2" : ""}`}>
												{status.icon}
											</span>
											<span>{t(status.label)}</span>
										</div>
									</ListItem>
								))}
							</Dropdown>
						</div>
					</div>
					<div></div>
					<div className="align-center me-10">
						<button
							className="text-gray-600 me-4 hover:text-darkBlue"
							data-tip={"Edit"}
							data-for="edit"
							onClick={editHandler}
						>
							<EditIcon />
						</button>
						<ReactTooltip type="info" id="edit" />
						<button
							className="text-gray-600 hover:text-red2"
							data-tip={"Delete"}
							data-for="delete"
							onClick={deleteHandler}
						>
							<TrashIcon width={24} height={24} />
						</button>
						<ReactTooltip type="error" id="delete" />
					</div>
				</div>
				<div>
					<p className="2xl:text-md mb-4 text-base font-semibold text-gray-800 text-start">
						{t("Appointments.Appointment_details.Confirmation.Title")}:
					</p>
					<div className="flex">
						<div className="flex w-[50%]">
							<BtnWithIcon
								size="sm"
								variant="primary"
								rounded="rounded-lg"
								icon={<SMSIcon width="18" height="18" />}
								className="text-sm text-white me-3 2xl:text-base"
							>
								{t("Appointments.Appointment_details.Confirmation.SMS")}
							</BtnWithIcon>
							<BtnWithIcon
								size="sm"
								variant="primary"
								rounded="rounded-lg"
								icon={<WhatsappIcon width="18" height="18" />}
								className="text-sm text-white me-3 2xl:text-base"
								onClick={handleWhatsappConfirm}
							>
								{t("Appointments.Appointment_details.Confirmation.WhatsApp")}
							</BtnWithIcon>
						</div>

						<div className="flex w-[50%] flex-row-reverse">
							<BtnWithIcon
								size="sm"
								variant="primary"
								rounded="rounded-lg"
								icon={<BillIcon width="18" height="18" />}
								className="float-right text-sm text-white 2xl:text-base"
								onClick={() => {
									history.push(
										ROUTES[route].CREATE_INVOICES +
											`?patientId=${event?.resource?.patient?.id}&invoiceType=custom_Payment`
									);
								}}
							>
								{t("Appointments.Appointment_details.Financial.Transaction")}
							</BtnWithIcon>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
