import { AppSpinner } from "src/components";
import { AppGrid, MainCol, SidebarCol } from "../AppGrid/AppGrid";
import { Header } from "../Header/Header";
import { Sidebar } from "../Sidebar/Sidebar";

type AppLayoutProps = {
	isAppLoading: boolean;
	children: React.ReactNode;
};

export function AppLayout({ isAppLoading, children }: AppLayoutProps) {
	return isAppLoading ? (
		<AppSpinner />
	) : (
		<AppGrid>
			<SidebarCol>
				<Sidebar />
			</SidebarCol>

			<Header />

			<MainCol>{children}</MainCol>
		</AppGrid>
	);
}
