import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import NoInvoiceImg from "src/assets/svg/no_invoices.svg";

type NoInvoicesProps = {
	clickHandler: React.ComponentProps<typeof NoData>["clickHandler"];
};

export function NoInvoices({ clickHandler }: NoInvoicesProps) {
	const { t } = useTranslation("patientFile");

	return (
		<div>
			<NoData
				imgAlt="no invoices"
				imgSrc={NoInvoiceImg}
				notFoundMsg={t("PatientFile.Invoice.No_Invoices")}
				btn={t("PatientFile.Invoice.Add_Invoice")}
				clickHandler={clickHandler}
			/>
		</div>
	);
}
