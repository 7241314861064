export const SkeletonTable = () => {
	return (
		<table className="w-full table-fixed shadow">
			<thead>
				<tr>
					<SkeletonTableHeader />
					<SkeletonTableHeader />
					<SkeletonTableHeader />
					<SkeletonTableHeader />
					<SkeletonTableHeader />
					<SkeletonTableHeader />
				</tr>
			</thead>
			<tbody>
				{[0, 1, 2, 3, 4, 5].map((_, i) => {
					return (
						<tr key={i}>
							<SkeletonTableData />
							<SkeletonTableData />
							<SkeletonTableData />
							<SkeletonTableData />
							<SkeletonTableData />
							<SkeletonTableData />
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const SkeletonTableData = () => {
	return (
		<td className=" h-12 animate-pulse border-2 border-white  bg-gray-50"></td>
	);
};

const SkeletonTableHeader = () => {
	return (
		<th className="h-14   animate-pulse border-2 border-white  bg-gray-50 "></th>
	);
};
