import { Controller } from "react-hook-form";
import { Container } from "src/layout";
import {
	Avatar,
	Btn,
	CustomInput,
	CustomPhoneInput,
	DateInput,
	Dropdown,
	ErrorMsg,
	FileInput,
	Input,
	Label,
	ListItem,
	RadioBtn,
	Spinner,
} from "src/components";
import { useTranslation } from "react-i18next";
import moment from "src/config/moment";
import { insuranceSelector, useAppSelector } from "src/redux";
import { countryList } from "src/constants/index";
import { useAddNewPatientForm } from "./useAddNewPatientForm";
import { Emergency } from "./Emergency/Emergency";
import { useHistory } from "react-router-dom";

const AddNewPatient = () => {
	const { t, i18n } = useTranslation("patientList");
	const { t: translation } = useTranslation("scheduler");
	const { insurances } = useAppSelector(insuranceSelector);
	const history = useHistory();

	const {
		control,
		errors,
		handleSubmit,
		isValid,
		register,
		setValue,
		trigger,
		onAddPatient,
		isEditMode,
		initialLoading,
		isDirty,
	} = useAddNewPatientForm(); // () => history.goBack()

	if (initialLoading) return <Spinner />;

	return (
		<Container>
			<div className="bg-white px-5 py-8">
				<form onSubmit={handleSubmit(onAddPatient)}>
					<div className="w-4/5">
						<div className="mb-4 flex">
							<div className="me-8">
								<Avatar />
							</div>
							<div className="self-center">
								<FileInput id="picture" />
							</div>
						</div>

						<div className="mb-4 flex">
							<div className="w-1/2 me-4">
								<Input
									type="text"
									id="name"
									name="name"
									register={register}
									placeholder={t("Add_Patient.Patient_Name.Placeholder")}
									label={t("Add_Patient.Patient_Name.Label")}
									requiredStar
									variant="filled"
									inputSize="md"
									error={errors.name}
									autoFocus
								/>
							</div>

							<div className="w-1/2">
								<Controller
									control={control}
									name="phone"
									render={({
										field: { onChange, onBlur, value },
										fieldState: { error },
									}) => (
										<>
											<CustomPhoneInput
												label={t("Add_Patient.Phone_Number.Label")}
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												error={error}
												variant="filled"
												requiredStar
											/>
										</>
									)}
								/>
							</div>
						</div>

						<div className="mb-4 flex">
							<div className="w-1/2 me-4 ">
								<Label className="mb-4">{t("Add_Patient.Gender.Label")}</Label>
								<div className=" flex ">
									<RadioBtn
										id="male"
										register={register}
										name="gender"
										label={t("Add_Patient.Gender.Male")}
										value={"0"}
									/>
									<RadioBtn
										id="female"
										register={register}
										name="gender"
										label={t("Add_Patient.Gender.Female")}
										value={"1"}
									/>
								</div>
								{errors.gender?.message && (
									<ErrorMsg>{t(errors.gender.message)}</ErrorMsg>
								)}
							</div>

							<div className="w-1/2 ">
								<Controller
									control={control}
									name="age"
									render={({
										field: { onChange, onBlur, value, name },
										fieldState: { error },
									}) => (
										<CustomInput
											type="number"
											name={name}
											id="age"
											label={t("Add_Patient.Age.Label")}
											placeholder={t("Add_Patient.Age.Placeholder")}
											error={error}
											min={1}
											max={125}
											variant="filled"
											inputSize="md"
											requiredStar
											onBlur={onBlur}
											value={value}
											onChange={(e) => {
												const currentValue = e.target.value;

												onChange(currentValue);
												if (Number(currentValue) < 125) {
													setValue(
														"birthDate",
														moment()
															.subtract(Number(currentValue), "years")
															.toDate()
													);
													trigger(["age", "birthDate"]);
												}
											}}
										/>
									)}
								/>
							</div>
						</div>

						<div className="mb-4 flex">
							<div className="w-1/2 me-4 ">
								<Controller
									control={control}
									name="birthDate"
									render={({
										field: { onChange, onBlur, value, name },
										fieldState: { error },
									}) => (
										<DateInput
											id="birthDate"
											onChange={(value) => {
												setValue("age", moment().diff(value, "years"));
												onChange(value);
												trigger("age");
											}}
											value={value}
											name={name}
											error={error}
											onBlur={onBlur}
											variant="filled"
											inputSize="md"
											label={t("Add_Patient.DateOfBirth.Label")}
											requiredStar
											maxDate={new Date()}
											placeholder="DD/MM/YYYY"
											dateFormat="d/M/yyyy"
											textDirection="ltr"
											year
											popperPlacement={
												i18n.dir() === "rtl" ? "bottom-end" : "bottom-start"
											}
										/>
									)}
								/>
							</div>

							<div className="w-1/2 ">
								<Input
									type="number"
									name="customId"
									id="customId"
									label={t("Add_Patient.Custom_Id.Label")}
									placeholder={t("Add_Patient.Custom_Id.Placeholder")}
									register={register}
									error={errors.customId}
									variant="filled"
									inputSize="md"
								/>
							</div>
						</div>

						<div className="mb-4 flex">
							<div className="w-1/2 me-4 ">
								<div className=" ">
									<Controller
										control={control}
										name="insurance"
										render={({
											field: { onChange, onBlur, value, name, ref },
											fieldState: { invalid, isTouched, isDirty, error },
										}) => (
											<Dropdown
												title={t("Add_Patient.Insurance.Placeholder")}
												label={t("Add_Patient.Insurance.Label")}
												value={value}
												onChange={onChange}
												error={error}
												onBlur={onBlur}
												variant="filled"
												size="lg"
												defaultOption
											>
												{insurances &&
													Object.values(insurances).map((insurance) => (
														<ListItem
															value={insurance.id}
															displayedName={insurance.name}
															key={insurance.id}
														>
															<div className="text-base text-dark 2xl:text-lg 3xl:text-xl">
																{insurance.name}
															</div>
														</ListItem>
													))}
											</Dropdown>
										)}
									/>
								</div>
							</div>

							<div className="w-1/2 ">
								<Input
									type="text"
									name="email"
									id="email"
									label={t("Add_Patient.Email.Label")}
									placeholder={t("Add_Patient.Email.Placeholder")}
									register={register}
									error={errors.email}
									variant="filled"
									inputSize="md"
								/>
							</div>
						</div>

						<div className="mb-4 flex">
							<div className="w-1/2 me-4 ">
								<Input
									type="text"
									name="employment"
									id="employment"
									label={t("Add_Patient.Employment.Label")}
									placeholder={t("Add_Patient.Employment.Placeholder")}
									register={register}
									error={errors.employment}
									variant="filled"
									inputSize="md"
								/>
							</div>

							<div className="w-1/2">
								<Controller
									control={control}
									name="nationality"
									render={({
										field: { onChange, onBlur, value, name, ref },
										fieldState: { invalid, isTouched, isDirty, error },
									}) => (
										<Dropdown
											title={t("Add_Patient.Nationality.Placeholder")}
											label={t("Add_Patient.Nationality.Label")}
											value={value}
											onChange={onChange}
											error={error}
											onBlur={onBlur}
											variant="filled"
											size="lg"
											defaultOption
										>
											{countryList.map((country, i) => (
												<ListItem
													value={country.code}
													displayedName={
														i18n.language === "ar"
															? country.natianality_ar
															: country.nationality_en
													}
													key={i}
												>
													<div className="text-base text-dark 2xl:text-lg 3xl:text-xl">
														{i18n.language === "ar"
															? country.natianality_ar
															: country.nationality_en}
													</div>
												</ListItem>
											))}
										</Dropdown>
									)}
								/>
							</div>
						</div>

						<div className="mb-4 flex items-center">
							<div className="w-1/2 me-4">
								<Input
									type="text"
									name="address"
									id="address"
									label={t("Add_Patient.Address.Label")}
									placeholder={t("Add_Patient.Address.Placeholder")}
									register={register}
									error={errors.address}
									variant="filled"
									inputSize="md"
								/>
							</div>
						</div>

						<Emergency
							register={register}
							control={control}
							isEditMode={isEditMode}
						/>
					</div>

					<div className="flex justify-end">
						<Btn
							className={`w-28 ${!isValid || !isDirty ? "opacity-60" : ""}`}
							type="submit"
							variant="primary"
							size="md"
							disabled={!isValid || !isDirty}
						>
							{translation(
								"Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Save"
							)}
						</Btn>
					</div>
				</form>
			</div>
		</Container>
	);
};

export default AddNewPatient;
