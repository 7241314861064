import { FieldError, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "src/components";

type PatientNameProps = {
	register: UseFormRegister<any>;
	error: FieldError | undefined;
};
export const PatientName = ({ register, error }: PatientNameProps) => {
	const { t } = useTranslation("scheduler");

	return (
		<div className="mb-4">
			<Input
				type="text"
				name="name"
				id="name"
				label={t("Appointments.Add_Appointment_Form.Inputs.Patient_Name.Label")}
				placeholder={t(
					"Appointments.Add_Appointment_Form.Inputs.Patient_Name.Placeholder"
				)}
				register={register}
				error={error}
				requiredStar
				variant="standard"
				inputSize="sm"
			/>
		</div>
	);
};
