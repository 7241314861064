type LoaderProps = {
	className?: string;
};

export const Loader = ({ className }: LoaderProps) => {
	return (
		<div className="flex items-center justify-center">
			<div className={`animate-spin text-white ${className}`}>
				<RotatingVector />
			</div>
		</div>
	);
};

const RotatingVector = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2183_13253)">
				<path
					d="M22.1036 11.3813C22.0914 11.121 21.9764 10.8761 21.7837 10.7006C21.591 10.5251 21.3365 10.4333 21.0762 10.4454C20.8158 10.4576 20.571 10.5726 20.3955 10.7653C20.2199 10.958 20.1281 11.2125 20.1403 11.4728C20.2229 13.2299 19.7129 14.9638 18.6919 16.3962C17.6709 17.8286 16.1983 18.8764 14.5104 19.3715C12.8225 19.8666 11.0172 19.7802 9.38427 19.1262C7.75136 18.4722 6.38553 17.2885 5.50602 15.7651C4.62651 14.2418 4.28434 12.4671 4.53441 10.726C4.78449 8.9848 5.61232 7.37817 6.88502 6.16393C8.15772 4.9497 9.80148 4.19829 11.5525 4.03031C13.3034 3.86233 15.0601 4.28753 16.5404 5.23765C16.649 5.30731 16.7703 5.35489 16.8974 5.37767C17.0244 5.40045 17.1547 5.39799 17.2807 5.37043C17.4068 5.34286 17.5262 5.29074 17.6321 5.21703C17.7381 5.14332 17.8284 5.04946 17.8981 4.94083C17.9678 4.83219 18.0153 4.7109 18.0381 4.58387C18.0609 4.45685 18.0584 4.32658 18.0309 4.20051C18.0033 4.07444 17.9512 3.95503 17.8775 3.8491C17.8038 3.74317 17.7099 3.6528 17.6013 3.58314C15.7507 2.39614 13.555 1.86524 11.3666 2.07565C9.17815 2.28607 7.12385 3.22559 5.53335 4.74344C3.94286 6.26129 2.9084 8.26947 2.596 10.4457C2.28358 12.6219 2.71134 14.84 3.8106 16.744C4.90987 18.648 6.61691 20.1275 8.65778 20.945C10.6986 21.7626 12.955 21.8708 15.0648 21.2523C17.1745 20.6338 19.0153 19.3245 20.2917 17.5345C21.5682 15.7444 22.2063 13.5775 22.1036 11.3813Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2183_13253">
					<rect width="24" height="24" fill="currentColor" />
				</clipPath>
			</defs>
		</svg>
	);
};
