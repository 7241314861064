import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import noPatientsImg from "src/assets/svg/no_patients.svg";
import { useForAllRoutes } from "src/hooks";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/constants";

export function NoPatients() {
	const { t } = useTranslation("patientList");
	const history = useHistory();
	const route = useForAllRoutes();

	return (
		<NoData
			imgSrc={noPatientsImg}
			imgAlt={"no patient list"}
			notFoundMsg={t("Patient_List.No_Patients.Msg")}
			desc={t("Patient_List.No_Patients.CTA")}
			btn={t("Patient_List.No_Patients.Add_Patient_Btn")}
			clickHandler={() => history.push(ROUTES[route].ADD_NEW_PATIENT)}
		/>
	);
}
