import { PatientFilePlanType } from "src/@types";
import { updatePlan } from "../plan";

type ChangeToothTypeParamsType = {
	toothNumber: PatientFilePlanType["teeth"][number]["number"];
	toothType: PatientFilePlanType["teeth"][number]["type"];
};

export const changeToothType = ({
	toothNumber,
	toothType,
}: ChangeToothTypeParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			return {
				...planToUpdate,
				teeth: planToUpdate.teeth.map((tooth) => {
					if (tooth.number === toothNumber) {
						return { ...tooth, type: toothType };
					}

					return tooth;
				}),
			};
		},
	});
};
