import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	UserType,
	UserCredentialType,
	UserRoleType,
	AuthTokenType,
	UserBasicInfoType,
} from "src/@types";
import jwt_decode from "jwt-decode";
import axios from "src/config/axios";

export const fetchUser = createAsyncThunk<
	UserType,
	void,
	{ rejectValue: string }
>("auth/fetchUser", async (_, thunkAPI) => {
	try {
		const { data }: { data: UserType } = await axios.get("user/", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

type BlockAndUnBlockReturn = { success: boolean; blocked: boolean };

export const blockOrUnBlockUser = createAsyncThunk<
	BlockAndUnBlockReturn & { user: UserBasicInfoType },
	UserBasicInfoType,
	{ rejectValue: string }
>("auth/blockOrUnBlockUser", async (user, thunkAPI) => {
	try {
		const { data }: { data: BlockAndUnBlockReturn } = await axios.post(
			`/users/${user?.id}/block/${!user?.blocked}`,
			{ signal: thunkAPI.signal }
		);

		return { ...data, user };
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

export const fetchAllUsers = createAsyncThunk<
	UserBasicInfoType[],
	void,
	{ rejectValue: string }
>("auth/fetchAllUsers", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get<UserBasicInfoType[]>("store/users", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Something went wrong, try again later");
	}
});

type AuthenticateReturnType = AuthTokenType & { userRole: UserRoleType };

export const authenticate = createAsyncThunk<
	AuthenticateReturnType,
	UserCredentialType,
	{ rejectValue: string }
>("auth/authenticate", async (data, thunkAPI) => {
	const formData = new FormData();

	formData.append("username", data.username);
	formData.append("password", data.password);

	try {
		const { data: dataRes }: { data: AuthTokenType } = await axios({
			method: "POST",
			url: "/token",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			data: formData,
			signal: thunkAPI.signal,
		});

		type TokenDecodedType = {
			exp: number;
			id: number;
			manager: number;
			role: UserRoleType;
			status: number;
			wssUrl: string;
		};

		const decoded: TokenDecodedType = jwt_decode(dataRes?.access_token);

		localStorage.setItem("token", dataRes.access_token);
		localStorage.setItem("userRole", decoded.role);

		return { ...dataRes, userRole: decoded.role };
	} catch (error) {
		if (error instanceof Error) return thunkAPI.rejectWithValue(error.message);

		return thunkAPI.rejectWithValue("Something went wrong");
	}
});
