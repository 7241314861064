import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "src/constants/index";
import { useAppDispatch, patientListActions } from "src/redux";
import { PlusIcon, NavTab, NavTabs, BtnWithIcon } from "src/components";
import { useForAllRoutes } from "src/hooks";

export const PatientListTabs = () => {
	const [showBtn, setShowBtn] = useState(false);
	const { t } = useTranslation("patientList");
	const dispatch = useAppDispatch();
	const route = useForAllRoutes();

	useEffect(() => {
		if (location.pathname === ROUTES[route].PATIENTS_GROUPS) {
			setShowBtn(true);
		}
	}, [location]);

	return (
		<div className="mb-5 flex items-center justify-between px-4 2xl:mb-7 3xl:mb-9">
			<NavTabs>
				<NavTab
					to={ROUTES[route].PATIENTS + "/"}
					onClick={() => setShowBtn(false)}
				>
					{t("Patient_List.Tabs.Main")}
				</NavTab>
				<NavTab
					to={ROUTES[route].PATIENTS_GROUPS}
					onClick={() => setShowBtn(true)}
				>
					{t("Patient_List.Tabs.Groups")}
				</NavTab>
			</NavTabs>

			{showBtn && (
				<BtnWithIcon
					variant="primary"
					icon={<PlusIcon />}
					rounded="rounded-lg"
					size="md"
					className="flex-shrink-0 text-sm 3xl:text-lg"
					onClick={() =>
						dispatch(
							patientListActions.openAddPatientGroupModal({ mode: "ADD" })
						)
					}
				>
					{t("Patient_List.Tabs.Add_Group")}
				</BtnWithIcon>
			)}
		</div>
	);
};
