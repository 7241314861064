import { RootState } from "../../store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Insurance, InsuranceDictionary } from "src/@types";
import axios from "src/config/axios";

export const fetchInsurances = createAsyncThunk<
	Insurance[],
	void,
	{
		rejectValue: string;
	}
>("insurances/fetchInsurances", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get("store/insurance/", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

interface InitialState {
	loading: boolean;
	insurances: InsuranceDictionary | null;
	errorMsg: string;
}

const initialState: InitialState = {
	loading: true,
	insurances: null,
	errorMsg: "",
};

const insuranceSlice = createSlice({
	name: "insurances",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchInsurances.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchInsurances.rejected, (state, action) => {
			state.errorMsg = action.payload || "";
			state.loading = false;
		});

		builder.addCase(fetchInsurances.fulfilled, (state, action) => {
			state.insurances = action.payload.reduce(
				(obj, insurance) => ({ ...obj, [insurance.id]: insurance }),
				{}
			);
			state.loading = false;
		});
	},
});

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const insuranceSelector = (state: RootState) => state.insurances;

export const insurancesReducer = insuranceSlice.reducer;
