import { useAppSelector } from "src/redux";
import { Btn } from "src/components";
import logoImage from "src/assets/images/collapsed-logo.png";

type GetStartedScreenProps = {
	goToPersonalInfo: () => void;
};

export function GetStartedScreen({ goToPersonalInfo }: GetStartedScreenProps) {
	const { user } = useAppSelector((s) => s.auth);

	return (
		<section className="mt-24 flex flex-col items-center justify-center">
			<h2 className="text-center text-[2.125rem] font-semibold capitalize leading-10 text-dark">
				Hi {user?.name}, welcome to
			</h2>

			<div className="w-[13.5rem]">
				<img src={logoImage} alt="Beclarified" className="w-full" />
			</div>

			<p className="mb-12 text-base leading-[1.1875rem] text-gray-800">
				Let&apos;s find out how we can help you, in less than a minute
			</p>

			<Btn
				variant="primary"
				size="lg"
				className="px-[1.875rem]"
				onClick={goToPersonalInfo}
			>
				Get Started
			</Btn>
		</section>
	);
}
