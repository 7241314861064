import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface schedulerState {
	slotModal: boolean;
	isAddAppointmentOpen: boolean;
	selectedClinic: number;
	slots: number;
	step: number;
}

const initialState: schedulerState = {
	isAddAppointmentOpen: false,
	selectedClinic: 1,
	slotModal: false,
	slots: 4,
	step: 15,
};

const schedulerSlice = createSlice({
	name: "scheduler",
	initialState,
	reducers: {
		openAddAppointmentModal: (
			state: schedulerState,
			action: { payload: { selectedClinic?: number } }
		) => {
			state.isAddAppointmentOpen = true;
			state.selectedClinic = action?.payload?.selectedClinic || 1;
			document.body.classList.remove("noscroll");
		},

		closeAddAppointmentModal: (state: schedulerState) => {
			state.isAddAppointmentOpen = false;
			state.selectedClinic = 1;
			document.body.classList.add("noscroll");
		},

		toggleSlotModal: (
			state: schedulerState,
			action: PayloadAction<boolean>
		) => {
			state.slotModal = action.payload;
		},

		changeSlotsTo5Mins: (state: schedulerState) => {
			state.slots = 12;
			state.step = 5;
			state.slotModal = false;
		},

		changeSlotsTo15Mins: (state: schedulerState) => {
			state.slots = 4;
			state.step = 15;
			state.slotModal = false;
		},

		changeSlotsTo30Mins: (state: schedulerState) => {
			state.slots = 2;
			state.step = 30;
			state.slotModal = false;
		},

		changeSlotsTo60Mins: (state: schedulerState) => {
			state.slots = 1;
			state.step = 60;
			state.slotModal = false;
		},
	},
});

export const schedulerActions = schedulerSlice.actions;

export const schedulerReducer = schedulerSlice.reducer;
