import { useTranslation } from "react-i18next";
import {
	CameraIcon,
	ImageCropperPopup,
	useImageCropperPopup,
} from "src/components";
import { BtnWithIcon } from "../Btn/Btn";

type UploadImageBtnProps = {
	url: string;
	formDataKey: string;
	handleSuccess?: (image: any) => void;
};

export function UploadImageBtn({
	formDataKey,
	url,
	handleSuccess,
}: UploadImageBtnProps) {
	const { t } = useTranslation("settings");

	const { imageCropperPopupProps, onSelectFile } = useImageCropperPopup({
		formDataKey,
		url,
		handleSuccess,
	});

	return (
		<div>
			<label htmlFor="uploadImageBtn" className="block w-max cursor-pointer">
				<BtnWithIcon
					variant="primary-outline"
					size="md"
					icon={<CameraIcon />}
					rounded="rounded-lg"
					className="pointer-events-none border-darkGray py-2 text-xs capitalize text-darkGray"
					style={{
						lineHeight: "1.125rem",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
					}}
				>
					{t("Settings.Profile_Header.Upload_Image")}
				</BtnWithIcon>
			</label>

			<input
				type="file"
				accept="image/*"
				className="sr-only"
				id="uploadImageBtn"
				onChange={onSelectFile}
			/>

			<ImageCropperPopup {...imageCropperPopupProps} />
		</div>
	);
}
