import { NoData } from "./NoData";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { schedulerActions, useAppDispatch } from "src/redux";
import { ROUTES } from "src/constants";
import noVisitsImg from "src/assets/svg/no_visits.svg";
import { useForAllRoutes } from "src/hooks";

export function NoVisits() {
	const { t } = useTranslation("patientFile");
	const { id: patientId } = useParams<{ id?: string }>();
	const route = useForAllRoutes();
	const history = useHistory();
	const dispatch = useAppDispatch();

	return (
		<div>
			<NoData
				imgAlt="no visits"
				imgSrc={noVisitsImg}
				notFoundMsg={t("PatientFile.Visits.No_Visits")}
				btn={t("PatientFile.Visits.Add_Appointment")}
				clickHandler={() => {
					history.push(`${ROUTES[route].SCHEDULER}?patient=${patientId}`);
					dispatch(schedulerActions.openAddAppointmentModal({}));
				}}
			/>
		</div>
	);
}
