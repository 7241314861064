import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Btn, CustomPhoneInput } from "src/components";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import { phoneValidation } from "src/utils";
import { useAxios } from "src/hooks";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/constants";

type ForgotPasswordFormType = {
	phone: string;
};

const schema = yup.object().shape({
	phone: phoneValidation(),
});

export const ForgotPassword = () => {
	const history = useHistory();

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ForgotPasswordFormType>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: { phone: "" },
	});

	const { makeRequest } = useAxios({
		runNow: false,
		configs: { method: "POST" },
	});

	const handleForgetPassword = (data: ForgotPasswordFormType) => {
		makeRequest({
			configs: { url: "/forgot-password", params: { phone: data.phone } },
			handleSuccess: () => {
				history.push(
					`${ROUTES.AUTH.VERIFICATION_CODE}?phone=${data.phone}&type=forgotPassword`
				);
			},
		});
	};

	return (
		<FormWrapper>
			<form
				onSubmit={handleSubmit(handleForgetPassword)}
				noValidate
				autoComplete="off"
			>
				<div className="mb-5">
					<div className="mb-8">
						<h3 className="mb-3 text-center text-2xl  font-semibold text-dark 2xl:text-3xl ">
							Password recovery
						</h3>
						<p className="whitespace-nowrap text-center text-sm text-gray-800">
							Please enter the mobile number you used for registration
						</p>
					</div>
				</div>

				<div className="mb-8">
					<Controller
						control={control}
						name="phone"
						render={({
							field: { onChange, onBlur, value },
							fieldState: { error },
						}) => (
							<>
								<CustomPhoneInput
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									variant="basic"
									label="Mobile number"
								/>

								<p className="mt-2 text-sm text-gray-800">
									We’ll send a message with a verification code to this number.
								</p>
							</>
						)}
					/>
				</div>

				<Btn
					variant="primary"
					size="md"
					type="submit"
					rounded="rounded-lg"
					className={` mb-3 w-full text-lg ${
						isValid ? "opacity-100" : "cursor-not-allowed opacity-60"
					}`}
					disabled={!isValid}
				>
					Send Code
				</Btn>
			</form>
		</FormWrapper>
	);
};
