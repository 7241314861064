import { forwardRef, useEffect, useRef } from "react";
import { CustomCheckBox } from "src/components";

export const IndeterminateCheckbox = forwardRef(
	({ indeterminate, ...rest }: any, ref: any) => {
		const defaultRef = useRef<any>();
		const resolvedRef = ref || defaultRef;

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return <CustomCheckBox inputSize="sm" ref={resolvedRef} {...rest} />;
	}
);
