type PieceInfoProps = {
	label: string;
	value?: string;
	className?: string;
};

export const PieceInfo = ({ label, value, className }: PieceInfoProps) => {
	return (
		<div className={`flex w-52 items-center ${className}`}>
			<div className="flex">
				<span className="text-sm font-semibold text-muted me-3">{label}</span>
				<span className="text-sm font-semibold text-gray-800">{value}</span>
			</div>
		</div>
	);
};
