import { PatientProfileState } from "../patientProfileSlice";
import { PedoTeethDictionary } from "src/constants";

type convertSelectedTeethToStringParams = {
	state: PatientProfileState;
	key: "diagnoses" | "procedures";
	newItemType: string;
};

// ? What this function do
// * First: Filter selected teeth to get unique teeth with unique [ procedure or diagnosis ] only
// * Second: Convert selected teeth object to string
export function convertSelectedTeethToArray({
	state,
	key,
	newItemType,
}: convertSelectedTeethToStringParams): number[] {
	return Object.entries(state.selectedTeeth)
		.filter((tooth) => {
			if (key === "diagnoses") {
				state.selectedPlan?.diagnoses.forEach((diagnose) => {
					if (
						diagnose?.teeth?.includes(+tooth[0]) &&
						diagnose.diagnosisType === newItemType
					) {
						tooth[1] = false;
					}
				});
			}

			if (key === "procedures") {
				state.selectedPlan?.procedures.stages.forEach((stage) => {
					stage.procedures.forEach((procedure) => {
						if (
							procedure.teeth?.includes(+tooth[0]) &&
							procedure.procedureType === newItemType
						) {
							tooth[1] = false;
						}
					});
				});
			}

			if (state.selectedPlanTeethDictionary?.[+tooth[0]]?.type === "pedo") {
				if (PedoTeethDictionary[+tooth[0]] === null) {
					tooth[1] = false;
				}
			}

			return tooth[1] === true;
		})
		.map((tooth) => +tooth[0]);
}
