import axios from "src/config/axios";
import { RootState } from "../../store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StockProductType } from "src/@types";

export const fetchStockProducts = createAsyncThunk<
	StockProductType[],
	void,
	{ rejectValue: string }
>("stock/fetchStockProducts", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get<StockProductType[]>("/store/products", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

interface InitialState {
	loading: boolean;
	errorMsg: string;
	stockProducts: StockProductType[] | null;
	stockProductsDictionary: { [key: number]: StockProductType } | null;
}

const initialState: InitialState = {
	loading: true,
	errorMsg: "",
	stockProducts: null,
	stockProductsDictionary: null,
};

const stockSlice = createSlice({
	name: "stock",
	initialState,
	reducers: {
		createProduct: (state, action) => {
			const product = action.payload;

			state.stockProducts?.unshift(product);
			state.stockProductsDictionary![product.id] = product;
		},

		updateProduct: (state, action) => {
			const product = action.payload;

			const productIndex = state.stockProducts?.findIndex(
				(product) => product.id === product.id
			);

			if (productIndex !== undefined) {
				state.stockProducts![productIndex] = product;
				state.stockProductsDictionary![product.id] = product;
			}
		},

		deleteProduct: (state, action) => {
			const productId = action.payload;

			if (state.stockProducts) {
				state.stockProducts = state.stockProducts?.filter(
					(product) => product.id !== productId
				);
			}

			delete state.stockProductsDictionary![productId];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStockProducts.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchStockProducts.rejected, (state, action) => {
			state.errorMsg = action.payload || "";
			state.loading = false;
		});

		builder.addCase(fetchStockProducts.fulfilled, (state, action) => {
			state.stockProducts = action.payload;
			state.stockProductsDictionary = action.payload.reduce(
				(acc, product) => ({ ...acc, [product.id]: product }),
				{}
			);

			state.loading = false;
		});
	},
});

export const stockActions = stockSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectModal = (state: RootState) => state.loggedUserInfo
export const stockSelector = (state: RootState) => state.stock;
export const stockReducer = stockSlice.reducer;
