import { WritableDraft } from "immer/dist/internal";
import { PatientProfileState } from "../patientProfileSlice";

export const upperBarReducers = {
	openUpperBar: (state: WritableDraft<PatientProfileState>) => {
		state.isUpperBarOpen = true;
		if (state.selectedProcedure) state.selectedProcedure = null;
	},

	closeUpperBar: (state: WritableDraft<PatientProfileState>) => {
		if (state.selectedProcedure) state.selectedProcedure = null;
		if (state.selectedTeeth) state.selectedTeeth = {};
		state.isUpperBarOpen = false;
	},
};
