import {
	base,
	getSharedRoutes,
	getAssistantAndDentist,
	getAssistantAndAdmin,
} from "./global";
import { getPatientRoutes } from "./patientRoutes";
import { getSettingsRoutes } from "./settingsRoutes";

const assistantBase = base + "/assistant";

export const ASSISTANT = {
	BASE: assistantBase,

	...getPatientRoutes(assistantBase),
	...getSharedRoutes(assistantBase),
	...getAssistantAndDentist(assistantBase),
	...getAssistantAndAdmin(assistantBase),
	...getSettingsRoutes(assistantBase),
};
