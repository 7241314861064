import { useTranslation } from "react-i18next";
import { NoData } from "./NoData";
import noProceduresImg from "src/assets/svg/no_procedures.svg";

export function NoProcedures() {
	const { t } = useTranslation("patientFile");

	return (
		<NoData
			imgAlt="No procedures"
			imgSrc={noProceduresImg}
			notFoundMsg={t("PatientFile.Procedures.No_Procedures")}
		/>
	);
}
