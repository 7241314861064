import { PatientFilePlanWithoutIdType, PatientFileStageType } from "src/@types";
import { updatePlan } from "../plan";

type ChangeStageParamsType = {
	stageId?: PatientFileStageType["id"];
};

export const changeStage = ({ stageId }: ChangeStageParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			const selectedProcedure = state.patientProfile.selectedProcedure;

			if (selectedProcedure) {
				let oldStage: PatientFileStageType | undefined;
				let selectedStage: PatientFileStageType | undefined;

				// * Extract the selected stage and the old stage
				state.patientProfile.selectedPlan?.procedures.stages.forEach(
					(stage) => {
						if (stage.id === stageId) selectedStage = stage;
						if (stage.id === state?.patientProfile.selectedProcedure?.stageId)
							oldStage = stage;
					}
				);

				if (selectedStage && oldStage) {
					const updatedPlan: PatientFilePlanWithoutIdType = {
						...planToUpdate,
						procedures: {
							stages: planToUpdate.procedures.stages.map((stage) => {
								if (stage.id === oldStage?.id) {
									return {
										...stage,
										procedures: stage.procedures.filter(
											(procedure) => procedure.id !== selectedProcedure?.id
										),
									};
								}

								if (stage.id === selectedStage?.id) {
									if (selectedProcedure) {
										return {
											...stage,
											procedures: [
												...stage.procedures,
												{
													...selectedProcedure,
													stageId: selectedStage?.id,
												},
											],
										};
									}
								}

								return stage;
							}),
						},
					};

					return updatedPlan;
				}
			}

			return planToUpdate;
		},
	});
};
