import { PatientFilePlanWithoutIdType, PatientFileStageType } from "src/@types";
import { updatePlan } from "../plan";

type UpdateStageParamsType = {
	stageId?: PatientFileStageType["id"];
	newStageName: PatientFileStageType["name"];
};

export const updateStage = ({
	stageId,
	newStageName,
}: UpdateStageParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate) => {
			const stageToUpdate = planToUpdate.procedures.stages.find(
				(stage) => stage.id === stageId
			);

			if (!stageToUpdate) throw new Error("Stage not found");

			const newStage: PatientFileStageType = {
				...stageToUpdate,
				name: newStageName,
			};

			const updatedPlan: PatientFilePlanWithoutIdType = {
				...planToUpdate,
				procedures: {
					stages: planToUpdate.procedures.stages.map((stage) =>
						stage.id === stageId ? newStage : stage
					),
				},
			};

			return updatedPlan;
		},
	});
};
