import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { PatientFileType } from "src/@types";
import { useAxios, useQuery } from "src/hooks";
import {
	addNewPatient,
	insuranceSelector,
	updatePatient,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import { cleanObjects } from "src/utils";
import { NewPatientForm, schema } from "./form";

export function useAddNewPatientForm(on_add?: () => void) {
	const { t, i18n } = useTranslation("patientList");
	const { t: translation } = useTranslation("scheduler");
	const { insurances } = useAppSelector(insuranceSelector);
	const dispatch = useAppDispatch();

	const {
		register,
		handleSubmit,
		control,
		trigger,
		reset,
		setValue,
		formState: { errors, isValid, isDirty },
	} = useForm<NewPatientForm>({
		mode: "onChange",
		resolver: yupResolver(schema(translation)),
		defaultValues: {
			nationality: "EG",
			gender: "0",
			insurance: insurances?.[0]?.id || 0,
			age: 0,
		},
	});

	const query = useQuery();
	const patientId = query.get("patientId");
	const isEditMode = patientId !== null && patientId !== undefined;

	const { initialLoading } = useAxios<PatientFileType>({
		runNow: isEditMode,
		configs: { url: `/patients/${patientId}` },
		handleSuccess: (data) => {
			const editDefaultValues = {
				name: data.name || "",
				phone: data.phone
					? isValidPhoneNumber(data.phone)
						? data.phone
						: "+2" + data.phone
					: "",
				gender: `${data.gender}`,
				birthDate: new Date(data.birthDate),
				age: data.birthDate ? moment().diff(data.birthDate, "years") : 0,
				email: data.email || "",
				picture: data.picture || "",
				address: data.address || "",
				customId: data.customId,
				insurance: data.insurance,
				employment: data.employment || "",
				nationality: data.nationality || "",
				emergencyName: data.emergencyName || "",
				emergencyPhone: data.emergencyPhone || "",
			};

			reset(editDefaultValues);
			trigger();
		},
	});

	const onAddPatient = (data: NewPatientForm) => {
		if (data.age) delete data.age;

		const form = cleanObjects(data);

		form.birthDate = moment(form.birthDate).format("YYYY-MM-DD");
		form.insurance = Number(form.insurance);
		form.customId = Number(form.customId);
		form.gender = Number(form.gender);

		if (isEditMode) {
			dispatch(updatePatient({ patientId: +patientId, patient: form })).then(
				() => {
					toast.success(t("Add_Patient.Messages.Updated"));
				}
			);
		}

		if (!isEditMode) {
			dispatch(addNewPatient(form)).then(() => {
				reset();
				toast.success(t("Add_Patient.Messages.Added"));
				on_add && on_add();
			});
		}
	};

	return {
		register,
		handleSubmit,
		control,
		trigger,
		reset,
		setValue,
		errors,
		isValid,
		onAddPatient,
		isEditMode,
		initialLoading,
		isDirty,
	};
}
