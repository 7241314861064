import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneNumberInput } from "../CustomInput/PatientPhone/PatientPhone";
import { DateAndTime } from "../CustomInput/DateAndtime/DateAndTime";
import { DoctorName } from "../CustomInput/DoctorName/DoctorName";
import { ClinicDropdown } from "../CustomInput/ClinicDropdown/ClinicDropdown";
import { ServicesDropdown } from "../CustomInput/ServicesDropdown/ServicesDropdown";
import { NotesInput } from "../CustomInput/NotesInput/NotesInput";
import { SubmitBtn } from "../CustomInput/SubmitBtn/SubmitBtn";
import { PatientName } from "../CustomInput/PatientName/PatientName";
import { PatientAge } from "../CustomInput/PatientAge/PatientAge";
import { GenderInput } from "../CustomInput/GenderInput/GenderInput";
import {
	addNewPatient,
	addEvent,
	dentistsSelector,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import { Patient } from "src/@types";
import moment from "src/config/moment";
import { phoneValidation } from "src/utils";
import { getStartAndEndDate } from "src/features";
import { toast } from "react-toastify";

type NewPatientFormType = {
	startDate: Date | string | null;
	startTime: Date | string | null;
	endTime: Date | string | null;
	dentist: string;
	clinic: string;
	type: string;
	notes: string;
	name: string;
	phone: string;
	age: number | "";
	gender: string;
};

type NewPatientFormProps = {
	mode: "ADD" | "EDIT";
};

export const NewPatientForm = ({ mode }: NewPatientFormProps) => {
	const { t } = useTranslation("scheduler");
	const {
		events: { start, end, addingLoading },
		patients: { addingLoading: addingPatientLoading },
		dentists: { dentists },
		scheduler: { selectedClinic },
	} = useAppSelector((state) => state);

	const dispatch = useAppDispatch();
	const schema = yup.object().shape({
		startDate: yup.date().required("required"),
		startTime: yup.date().default(null).nullable().required("Required"),
		endTime: yup
			.date()
			.nullable()
			.default(null)
			.required("Required")
			.min(
				yup.ref("startTime"),
				"Appointments.Add_Appointment_Form.Inputs.End_Time.Errors"
			),
		dentist: yup.string().required("Required"),
		name: yup.string().trim().required("Required"),
		clinic: yup.string().required("Required"),
		type: yup.string().required("Required"),
		age: yup
			.number()
			.positive()
			.typeError(t("Appointments.Add_Appointment_Form.Inputs.Required"))
			.min(
				1,
				t("Appointments.Add_Appointment_Form.Inputs.Patient_Age.Errors.Min")
			)
			.max(
				125,
				t("Appointments.Add_Appointment_Form.Inputs.Patient_Age.Errors.Max")
			),
		phone: phoneValidation(
			t(
				"Appointments.Add_Appointment_Form.Inputs.Patient_Phone.Errors.Invalid_Phone"
			)
		),
	});

	const {
		register,
		handleSubmit,
		control,
		reset,
		trigger,
		setValue,
		formState: { errors, isDirty, isValid, isSubmitSuccessful },
	} = useForm<NewPatientFormType>({
		mode: "onSubmit",
		resolver: yupResolver(schema),
		defaultValues: {
			startDate: new Date(),
			startTime: null,
			endTime: null,
			name: "",
			phone: "",
			gender: "0",
			dentist: dentists ? Object.values(dentists)[0].id.toString() : "",
			clinic: "1",
			type: "1",
			notes: "",
		},
	});

	const addAppointment = ({
		name,
		phone,
		age,
		startDate,
		endTime,
		startTime,
		notes,
		clinic,
		dentist,
		type,
	}: any) => {
		const patient: Patient = {
			name: name,
			phone: phone,
			birthDate: moment().subtract(Number(age), "years").format("YYYY-MM-DD"),
		};

		dispatch(addNewPatient(patient))
			.unwrap()
			.then(({ id }) => {
				const { starts, ends } = getStartAndEndDate(
					startDate,
					endTime,
					startTime
				);
				const event = {
					patientId: id,
					starts,
					ends,
					dentist,
					clinic,
					type,
					notes,
					status: 0,
				};

				dispatch(addEvent(event)).then(() => {
					reset();
					toast.success(" تم إضافة الموعد بنجاح");
				});
			})
			.catch((error) => {
				toast.error("خطأ في إضافة الموعد ");
			});
	};

	useEffect(() => {
		if (mode === "ADD" && selectedClinic) {
			setValue("clinic", `${selectedClinic}`);
		}
	}, [selectedClinic]);

	useEffect(() => {
		if (start && end) {
			setValue("startDate", new Date(start));
			setValue("startTime", new Date(start));
			setValue("endTime", new Date(end));
		}
	}, [start, end]);

	return (
		<form className="form px-5 py-2" onSubmit={handleSubmit(addAppointment)}>
			<DateAndTime control={control} errors={errors} trigger={trigger} />
			<div className="mt-4">
				<PatientName register={register} error={errors.name} />
				<GenderInput register={register} error={errors.gender} />
				<PatientAge register={register} error={errors.age} />
				<PhoneNumberInput control={control} />
				<DoctorName control={control} />
				<ClinicDropdown control={control} />
				<ServicesDropdown control={control} />
				<NotesInput register={register} error={errors.notes} />
				<SubmitBtn
					mode={mode}
					loading={addingLoading || addingPatientLoading}
					isDirty={isDirty}
					isValid={Object.keys(errors).length === 0 && isValid}
					isSubmitSuccessful={isSubmitSuccessful}
				/>
			</div>
		</form>
	);
};
