import { useEffect, useState } from "react";
import {
	Calendar,
	CalendarProps,
	ToolbarProps,
	View,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { localizer } from "./custom/localizer";
import { formats } from "./custom/formats";
import { eventPropGetter } from "./custom/eventPropsGetter";
import { useTranslation } from "react-i18next";
import "./style/scheduler.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
	deleteEvent,
	fetchEvents,
	useAppDispatch,
	useAppSelector,
} from "src/redux";
import moment from "src/config/moment";
import {
	AppointmentDetails,
	Popup,
	DeletePopup,
	useDeletePopup,
} from "src/components";
import { TimeGutterHeader } from "./Components/TimeGutterHeader";
import { Toolbar } from "./Components/Toolbar";
import { EventComponent } from "./Components/Event";
import { EventInWeekView } from "./Components/EventInWeekView";
import { useReduxAsync } from "src/hooks";
import { useCalender } from "./useCalender";
import { FilterPopup } from "./FilterPopup/FilterPopup";
import { Event } from "src/@types";
import { toast } from "react-toastify";

export enum FilterType {
	Dentist = "dentist",
	Clinic = "clinic",
	Date = "date",
}

const allViews: View[] = ["day", "week"];

type SchedulerProps = {
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Scheduler = ({ setEdit }: SchedulerProps) => {
	const { t, i18n } = useTranslation("scheduler");
	const dispatch = useAppDispatch();

	const {
		scheduler: { step, slots },
		events: { events, selectedEvent },
		auth: {
			user: {
				admin: { workStarts: workStartsState, workEnds: workEndsState },
			},
		},
	} = useAppSelector((state) => state);

	const { clinics } = useAppSelector((state) => state.auth.user.admin);
	const [clinicsNumber, setClinicsNumber] = useState(clinics);
	const rooms = Array.from(Array(clinicsNumber).keys()).map((i) => ({
		id: i + 1,
		title: `${t("Scheduler.Body.Clinic")} ${i + 1}`,
	}));
	const [filteredClinics, setFilteredClinics] = useState<string[]>(
		rooms.map((room) => room.id.toString())
	);

	const unfilteredClinics = rooms.filter((room) =>
		filteredClinics.includes(room.id.toString())
	);
	const resources: Resource[] = unfilteredClinics.map((room, index) => ({
		id: room.id,
		title: `${t("Scheduler.Body.Clinic")} ${index + 1}`,
	}));

	useEffect(() => {
		setClinicsNumber(clinics);
		setResourcesState(
			Array.from(Array(clinicsNumber).keys()).map((i) => ({
				id: i + 1,
				title: `${t("Scheduler.Body.Clinic")} ${i + 1}`,
			}))
		);
		setFilteredClinics(rooms.map((room) => room.id.toString()));
	}, [clinics, clinicsNumber]);

	// * Popups
	const [popup, setPopup] = useState(false);
	const { closeDeletePopup, deletePopup, openDeletePopup } = useDeletePopup();

	const deleteEventHandler = () => {
		if (selectedEvent && selectedEvent.resource?.id) {
			dispatch(deleteEvent(selectedEvent.resource.id)).then(() => {
				toast.success(t("Scheduler.Messages.Appointment_Deleted_Successfully"));
			});
			closeDeletePopup();
		}
	};

	// * Calendar state
	const [currentView, setCurrentView] = useState<View>("day");
	const [currentDate, setCurrentDate] = useState<Date | undefined>();

	// * Filter states
	const [filterPopup, setFilterPopup] = useState(false);
	const [filteredDentists, setFilteredDentists] = useState<string[]>([]);

	const modifiedEvents: Event[] = events
		.filter((event) => {
			if (filteredDentists.length === 0) {
				return true;
			}
			if (
				event.resource?.dentist &&
				filteredDentists.includes(event.resource?.dentist?.id.toString())
			) {
				return filteredDentists.includes(event.resource.dentist.id.toString());
			}
		})
		.filter((event) => {
			if (filteredClinics.length === 0) {
				return true;
			}
			if (
				event.resource?.clinic &&
				filteredClinics.includes(event.resource?.clinic.toString())
			) {
				return filteredClinics.includes(event.resource?.clinic.toString());
			}
		})
		.map((event) => ({
			...event,
			start: event.start && new Date(event.start),
			end: event.end && new Date(event.end),
			resourceId: event.resourceId ? event.resourceId : event.resource?.clinic,
		}));

	useReduxAsync(
		fetchEvents({
			startDate: moment().startOf("week").format("YYYY-MM-DD"),
			endDate: moment().endOf("week").format("YYYY-MM-DD"),
		})
	);

	const {
		handleSelectEvent,
		moveEvent,
		resizeEvent,
		onNavigate,
		onDrillDown,
		addNewEventHandler,
	} = useCalender({
		setCurrentDate,
		setPopup,
		setCurrentView,
	});

	const openFilterPopup = () => setFilterPopup(true);

	type Resource = {
		id: number;
		title: string;
	};

	const DnDCalendar = withDragAndDrop<Event, Resource>(
		Calendar as React.ComponentType<CalendarProps<Event, Resource>>
	);
	const [resourcesState, setResourcesState] = useState(resources);

	const workStarts = workStartsState ? workStartsState : 8;
	const workEnds = workEndsState ? workEndsState : 17;
	const workingHours = workEnds - workStarts;
	const momentCurrent = moment();

	const currentTimeIndicator = document.querySelector(
		".rbc-current-time-indicator"
	);

	const [scrolled, setScrolled] = useState(false);

	if (currentTimeIndicator && !scrolled) {
		currentTimeIndicator.scrollIntoView();
		setScrolled(true);
	}

	return (
		<>
			<div className="relative h-full w-full">
				<div className="h-[80vh] overflow-auto pt-16">
					<DnDCalendar
						resources={currentView === "day" ? resourcesState : undefined}
						localizer={localizer}
						events={modifiedEvents}
						startAccessor="start"
						endAccessor="end"
						views={allViews}
						defaultView={currentView}
						defaultDate={new Date()}
						date={currentDate}
						drilldownView="day"
						style={{
							height: `${workingHours * 15}rem`,
							backgroundColor: "white",
						}}
						rtl={i18n.language === "ar"}
						culture={i18n.language}
						min={momentCurrent
							.set("hours", workStarts)
							.set("minutes", 0)
							.toDate()}
						max={
							workEnds === 24
								? momentCurrent.endOf("day").toDate()
								: momentCurrent.set("hours", workEnds).toDate()
						}
						formats={formats}
						tooltipAccessor="title"
						components={{
							timeGutterHeader: TimeGutterHeader,
							toolbar: (props: ToolbarProps) => (
								<Toolbar
									{...props}
									openFilterPopup={openFilterPopup}
									filtersCount={
										filteredDentists.length + filteredClinics.length
									}
								/>
							),
							day: {
								header: () => <div className="hidden">-</div>,
								event: EventComponent,
							},
							week: {
								event: EventInWeekView,
							},
						}}
						slotPropGetter={() => ({
							className: "px-5 font-semibold text-base text-gray-600",
						})}
						eventPropGetter={eventPropGetter}
						dayLayoutAlgorithm="no-overlap"
						timeslots={slots}
						step={step}
						onSelectEvent={handleSelectEvent}
						onSelectSlot={addNewEventHandler}
						onDrillDown={onDrillDown}
						onNavigate={onNavigate}
						onEventDrop={moveEvent}
						onEventResize={resizeEvent}
						onView={(view: View) => setCurrentView(view)}
						resizable={true}
						selectable
						draggableAccessor={() => true}
						showMultiDayTimes={false}
						onDragOver={(event: any) => console.log(event)}
					/>
				</div>
			</div>

			{selectedEvent !== null && (
				<Popup show={popup} close={() => setPopup(false)}>
					<AppointmentDetails
						setPopup={setPopup}
						openDeletePopup={openDeletePopup}
						setEdit={setEdit}
					/>
				</Popup>
			)}

			{selectedEvent !== null && (
				<DeletePopup
					desc={t("Appointments.Appointment_details.Header.Delete_Msg")}
					closeDeletePopup={closeDeletePopup}
					isOpen={deletePopup.isOpen}
					onDelete={deleteEventHandler}
				/>
			)}

			<FilterPopup
				filterPopup={filterPopup}
				setFilterPopup={setFilterPopup}
				filteredClinics={filteredClinics}
				filteredDentists={filteredDentists}
				setFilteredClinics={setFilteredClinics}
				setFilteredDentists={setFilteredDentists}
			/>
		</>
	);
};
