import { TFunction } from "i18next";
import {
	LabOrderStatusEnum,
	LabOrderStatusType,
	StatusRequest,
} from "src/@types";

type LabStatusType = StatusRequest<LabOrderStatusType>;

const ASSIGNED = (t: TFunction): LabStatusType => ({
	label: t("Order_Status.Assigned"),
	value: LabOrderStatusEnum.ASSIGNED,
	backgroundColor: "#0078B41C",
	color: "#0078B4",
});

const WAITING = (t: TFunction): LabStatusType => ({
	label: t("Order_Status.Waiting"),
	value: LabOrderStatusEnum.WAITING,
	backgroundColor: "#FCF3D7",
	color: "#CF912D",
});

const RECEIVED = (t: TFunction): LabStatusType => ({
	label: t("Order_Status.Received"),
	value: LabOrderStatusEnum.RECEIVED,
	backgroundColor: "#0F897B1C",
	color: "#0F897B",
});

const RE_ASSIGNED = (t: TFunction): LabStatusType => ({
	label: t("Order_Status.Re_assigned"),
	value: LabOrderStatusEnum.RE_ASSIGNED,
	backgroundColor: "#FEE4E2",
	color: "#C83B48",
});

const DELIVERED = (t: TFunction): LabStatusType => ({
	label: t("Order_Status.Delivered"),
	value: LabOrderStatusEnum.DELIVERED,
	backgroundColor: "#DCF7E3",
	color: "#21913E",
});

export const LAB_ORDER_STATUS = (t: TFunction): LabStatusType[] => [
	ASSIGNED(t),
	WAITING(t),
	RECEIVED(t),
	RE_ASSIGNED(t),
	DELIVERED(t),
];

export const LAB_ORDER_STATUS_OBJECT = (
	t: TFunction
): {
	[key: number]: LabStatusType;
} => ({
	[LabOrderStatusEnum.ASSIGNED]: ASSIGNED(t),
	[LabOrderStatusEnum.WAITING]: WAITING(t),
	[LabOrderStatusEnum.RECEIVED]: RECEIVED(t),
	[LabOrderStatusEnum.RE_ASSIGNED]: RE_ASSIGNED(t),
	[LabOrderStatusEnum.DELIVERED]: DELIVERED(t),
});
