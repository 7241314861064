export const colorPlates = [
	"solitude",
	"aquaSpring",
	"voilet",
	"linen",
	"orange",
	"sky",
	"oyster",
	"amour",
	"carla",
	"sandCastle",
	"purple",
	"pink",
	"lime",
	"blood",
	"slate",
];

export const getformates = (color?: string) => {
	return { bg: `bg-${color}-light`, text: `text-${color}-dark` };
};
