import {
	PatientFilePlanType,
	PatientFileSelectedPlanTeethDictionaryType,
} from "src/@types";

export function convertSelectedPlanTeethToDictionary({
	plan,
}: {
	plan: PatientFilePlanType;
}): PatientFileSelectedPlanTeethDictionaryType {
	const selectedPlanTeethDictionary: PatientFileSelectedPlanTeethDictionaryType =
		plan.teeth.reduce((acc, tooth) => {
			return { ...acc, [tooth.number]: tooth };
		}, {});

	return selectedPlanTeethDictionary;
}
