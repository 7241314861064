import { SkeletonElement } from "./SkeletonElement";
import { SkeletonTable } from "./SkeletonTable";

export const PatientListSkeleton = () => {
	return (
		<div className="px-10 pt-6  pb-14">
			<div className="mb-10 flex items-center  justify-between">
				<div className="flex items-center">
					<SkeletonElement className="h-8 w-32 me-6" />
					<SkeletonElement className="h-8 w-32" />
				</div>
			</div>
			<div className="bg-white py-4 px-6 shadow">
				<SkeletonElement type="title" className="mb-4" />
				<div className="flex items-center  justify-between">
					<SkeletonElement type="title" />
					<SkeletonElement type="squreSm" />
				</div>
			</div>
			<div className="mt-10">
				<SkeletonTable />
			</div>
		</div>
	);
};
