import { useLocation } from "react-router-dom";
import { ROUTES } from "src/constants";

export function useForAllRoutes() {
	const location = useLocation();
	const isAssistant = location.pathname.includes(ROUTES.ASSISTANT.BASE);
	const isDentist = location.pathname.includes(ROUTES.DENTIST.BASE);
	const isAdmin = location.pathname.includes(ROUTES.ADMIN.BASE);

	if (isAssistant) return "ASSISTANT";
	if (isDentist) return "DENTIST";
	if (isAdmin) return "ADMIN";

	return "ASSISTANT";
}

export function useAdminAssistantRoute() {
	const location = useLocation();
	const isAssistant = location.pathname.includes(ROUTES.ASSISTANT.BASE);
	const isAdmin = location.pathname.includes(ROUTES.ADMIN.BASE);

	if (isAssistant) return "ASSISTANT";
	if (isAdmin) return "ADMIN";

	return "ADMIN";
}

export function useAdminDentistRoute() {
	const location = useLocation();
	const isDentist = location.pathname.includes(ROUTES.DENTIST.BASE);
	const isAdmin = location.pathname.includes(ROUTES.ADMIN.BASE);

	if (isDentist) return "DENTIST";
	if (isAdmin) return "ADMIN";

	return "ADMIN";
}

export function useAssistantDentistRoute() {
	const location = useLocation();
	const isAssistant = location.pathname.includes(ROUTES.ASSISTANT.BASE);
	const isDentist = location.pathname.includes(ROUTES.DENTIST.BASE);

	if (isAssistant) return "ASSISTANT";
	if (isDentist) return "DENTIST";

	return "ASSISTANT";
}
