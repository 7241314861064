import { useTranslation } from "react-i18next";
import { NoData } from "./NoData";
import noResultsImg from "src/assets/svg/no_results.svg";

export function NoResults() {
	const { t } = useTranslation("layout");

	return (
		<NoData
			imgAlt="no results"
			imgSrc={noResultsImg}
			notFoundMsg={t("No_Results")}
		/>
	);
}
