import React from "react";
import { TableBodyComponentProps } from "./TableBody.type";

type TableBodyContextValue = Omit<
	TableBodyComponentProps,
	"getTableProps" | "variant" | "tableGroup" | "alignments"
>;

export const TableBodyContext = React.createContext(
	{} as TableBodyContextValue
);

type TableBodyProviderProps = {
	value: TableBodyContextValue;
	children: React.ReactNode;
};

export function TableBodyProvider({ value, children }: TableBodyProviderProps) {
	return (
		<TableBodyContext.Provider value={value}>
			{children}
		</TableBodyContext.Provider>
	);
}

export function useTableBodyContext() {
	const context = React.useContext(TableBodyContext);

	return context;
}
