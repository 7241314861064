import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDeletePopup } from "src/components";

type UseEditableTableProps<DataType> = {
	data: DataType[] | any[] | null;
	schema: any;
	emptyItem: DataType | any;
	emptyId?: number | null;
	createItem: (
		data: DataType,
		setData: React.Dispatch<React.SetStateAction<DataType[] | any[]>>
	) => void;
	editItem: (
		serviceId: number | null,
		updatedItem: DataType,
		setEditDataId: React.Dispatch<React.SetStateAction<number | null>>,
		setData: React.Dispatch<React.SetStateAction<DataType[] | any[]>>
	) => void;
};

export function useEditableTable<DataType>({
	data: dataProp,
	emptyItem,
	emptyId = -1,
	schema,
	createItem,
	editItem,
}: UseEditableTableProps<DataType>) {
	const [data, setData] = useState<DataType[] | any[]>(dataProp || []);
	const [editDataId, setEditDataId] = useState<number | null>(null);

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	const { closeDeletePopup, deletePopup, openDeletePopup } = useDeletePopup<{
		id: number;
	}>();

	// * Check if there is a service is adding/editing
	const inAddProcess = data[0]?.id === emptyId;
	const inEditProcess = editDataId !== null;

	// * Handle input render [ add, edit ] service
	const shouldRenderInput = (id: number): boolean => {
		return id === editDataId || id === emptyId;
	};

	// * Show message if there is a service is adding/editing
	const shouldAddOrEdit = () => {
		if (inEditProcess) {
			toast.error("Please save or cancel the current edit process");

			return false;
		} else if (inAddProcess) {
			toast.error("Please save or cancel the current add process");

			return false;
		}

		return true;
	};

	// * Add input row to be able to add new service
	const enableCreateItem = () => {
		if (!shouldAddOrEdit()) return;
		setData((prev) => [emptyItem, ...prev]);
	};

	// * Edit service
	const enableEditItem = (data: DataType | any) => {
		if (!shouldAddOrEdit()) return;
		setEditDataId((prev) => (prev !== data.id ? data.id : null));
		reset(data);
	};

	// * Cancel Process
	const cancelProcess = () => {
		if (inAddProcess) setData((prev) => [...prev.slice(1)]);
		else if (inEditProcess) setEditDataId(null);

		reset({});
	};

	// * Handle submit [ add, edit ] service
	const onSubmit = async (values: any) => {
		if (inAddProcess && !inEditProcess) {
			createItem(values, setData);
		} else if (!inAddProcess && inEditProcess) {
			editItem(editDataId, values, setEditDataId, setData);
		}

		reset({});
	};

	return {
		data,
		setData,
		form: { control, errors, register, handleSubmit },
		delete: { deletePopup, closeDeletePopup, openDeletePopup },
		columnsDependencies: [inAddProcess, inEditProcess],
		enableCreateItem,
		enableEditItem,
		shouldRenderInput,
		cancelProcess,
		onSubmit,
	};
}
