import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	DoctorName,
	SearchPatient,
	Btn,
	Dropdown,
	ListItem,
	Input,
	PopupHeader,
} from "src/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { TeethSelect } from "../../TeethSelect/TeethSelect";
import { AddNewOrder, defaultValues, labOrderSchema } from "./formData";
import { LabOrderCreateType, LabOrderType } from "src/@types";
import { useAppSelector } from "src/redux";
import { SelectOrder } from "./SelectOrder/SelectOrder";
import { useAxios } from "src/hooks";
import { toast } from "react-toastify";

type AddOrderProps = {
	closePopup: () => void;
	addOrder: (element: LabOrderType) => void;
	patientId?: number;
};

export const AddOrderForm = ({
	closePopup,
	addOrder,
	patientId: patientIdProp,
}: AddOrderProps) => {
	const { labs } = useAppSelector((s) => s.labs);
	const { t: trans } = useTranslation("layout");
	const { t } = useTranslation("lab");
	const [patientId, setPatientId] = useState<number | undefined>(patientIdProp);
	const [selectedOrder, setSelectedOrder] = useState<
		LabOrderType | undefined
	>();

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
		watch,
	} = useForm<AddNewOrder>({
		mode: "onChange",
		resolver: yupResolver(labOrderSchema),
		defaultValues,
	});

	const orderPrice = watch("price");
	const orderQuantity = watch("quantity");

	useEffect(() => {
		setValue("total", (+orderPrice || 0) * +orderQuantity);
	}, [orderPrice, orderQuantity]);

	const { makeRequest } = useAxios<LabOrderType, LabOrderCreateType>({
		runNow: false,
		configs: { url: "/labs/orders", method: "POST" },
		handleSuccess: (data) => {
			toast.success(t("Orders.Messages.Order_Added"));
			addOrder(data);
			closePopup();
		},
		handleError: (error) => {
			toast.error(t("Orders.Messages.Error", { error }));
		},
	});

	const onAddNewOrder = (formData: AddNewOrder) => {
		const teeth = Object.entries(formData.teeth).reduce((acc, [key, value]) => {
			if (value) acc.push(+key);

			return acc;
		}, [] as number[]);

		if (!patientId) {
			toast.error(t("Orders.Messages.Patient_Not_Selected"));

			return;
		}

		if (patientId) {
			const data = {
				labId: formData.labId,
				order: formData.order,
				patientId,
				dentistId: formData.dentist,
				notes: formData.notes,
				price: formData.total,
				teeth,
				status: 0,
			};

			makeRequest({ configs: { data } });
		}
	};

	return (
		<div className="divide-y px-8 py-4">
			<PopupHeader
				title={t("Orders.Add_New_Order.Add_Order")}
				close={closePopup}
			/>

			<form onSubmit={handleSubmit(onAddNewOrder)} className="pt-4">
				{/* Choose Lab */}
				<div className="mb-[1.875rem]">
					<Controller
						control={control}
						name="labId"
						render={({
							field: { onChange, onBlur, value, name, ref },
							fieldState: { invalid, isTouched, isDirty, error },
						}) => (
							<Dropdown
								title={t("Orders.Add_New_Order.Lab.Label")}
								label={t("Orders.Add_New_Order.Lab.Placeholder")}
								value={value}
								onChange={onChange}
								error={error}
								onBlur={onBlur}
								requiredStar
							>
								{labs?.map((lab, i) => (
									<ListItem value={lab.id} displayedName={lab.name} key={i}>
										<div>{lab.name}</div>
									</ListItem>
								))}
							</Dropdown>
						)}
					/>
				</div>

				{/* Order Name & Order Price & Quantity & Total Price */}
				<section className="mb-[1.875rem] flex gap-7">
					{/* Order Name */}
					<div>
						<SelectOrder
							labId={watch("labId")}
							selectedOrder={selectedOrder}
							setSelectedOrder={setSelectedOrder}
							control={control}
							register={register}
							setValue={setValue}
							error={errors.patientId}
							variant="standard"
							inputSize="sm"
						/>
					</div>

					{/* Order Price */}
					<div>
						<Input
							type="number"
							register={register}
							name="price"
							id="price"
							label={t("Orders.Add_New_Order.Order_Price.Label")}
							placeholder={t("Orders.Add_New_Order.Order_Price.Placeholder")}
							error={errors.price}
							requiredStar
							inputSize="sm"
							variant="standard"
						/>
					</div>

					{/* Order Quantity */}
					<div>
						<Input
							type="number"
							register={register}
							name="quantity"
							id="quantity"
							label={t("Orders.Add_New_Order.Order_Quantity.Label")}
							placeholder={t("Orders.Add_New_Order.Order_Quantity.Placeholder")}
							error={errors.quantity}
							requiredStar
							inputSize="sm"
							variant="standard"
							disabled={watch("labId") === undefined}
						/>
					</div>

					{/* Total Price */}
					<div>
						<Input
							type="text"
							register={register}
							name="total"
							id="total"
							label={t("Orders.Add_New_Order.Order_Total.Label")}
							placeholder={t("Orders.Add_New_Order.Order_Total.Placeholder")}
							error={errors.total}
							inputSize="sm"
							variant="standard"
							disabled
							readOnly
						/>
					</div>
				</section>

				{/* Teeth Select */}
				<div className="mb-[1.875rem]">
					<TeethSelect type="select" setValue={setValue} watch={watch} />
				</div>

				{/* Patient Name */}
				<div className="mb-[1.875rem]">
					<SearchPatient
						patientId={patientId}
						setPatientId={setPatientId}
						control={control}
						register={register}
						setValue={setValue}
						error={errors.patientId}
						variant="standard"
						inputSize="sm"
					/>
				</div>

				{/* Doctor Name */}
				<div className="mb-[1.875rem]">
					<DoctorName control={control} />
				</div>

				{/* Notes */}
				<div className="mb-[3.6875rem]">
					<Input
						type="text"
						name="notes"
						id="notes"
						label={t("Orders.Add_New_Order.Notes.Label")}
						placeholder={t("Orders.Add_New_Order.Notes.Placeholder")}
						register={register}
						error={errors.notes}
						variant="standard"
						inputSize="sm"
					/>
				</div>

				{/* Submit Button */}
				<div className="flex justify-center">
					<Btn type="submit" size="sm" variant="primary" className="w-[300px]">
						{trans("Btns.Add")}
					</Btn>
				</div>
			</form>
		</div>
	);
};
