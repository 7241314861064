export enum ProcedureStatusEnum {
	PLANNED = 0,
	INPROGRESS = 1,
	COMPLETED = 2,
}

export const getProcedureStatusName = (value: ProcedureStatusEnum) => {
	switch (value) {
		case ProcedureStatusEnum.PLANNED:
			return "Planned";
		case ProcedureStatusEnum.INPROGRESS:
			return "In Progress";
		case ProcedureStatusEnum.COMPLETED:
			return "Completed";

		default:
			return "-";
	}
};

export const getProcedureStatusColor = (value: ProcedureStatusEnum) => {
	switch (value) {
		case ProcedureStatusEnum.PLANNED:
			return "#C83B48";
		case ProcedureStatusEnum.INPROGRESS:
			return "#CF912D";

		case ProcedureStatusEnum.COMPLETED:
			return "#21913E";

		default:
			return "#ffffff";
	}
};
