import {
	PatientFilePlanWithoutIdType,
	PatientFileProcedureType,
} from "src/@types";
import { RootState } from "src/redux/store/store";

type HandleUpdateAssignedToParamsType = {
	planToUpdate: PatientFilePlanWithoutIdType;
	assignedTo: PatientFileProcedureType["assignedTo"];
	state: RootState;
};

export function handleUpdateAssignedTo({
	planToUpdate,
	assignedTo,
	state,
}: HandleUpdateAssignedToParamsType): PatientFilePlanWithoutIdType {
	const selectedProcedure = state?.patientProfile?.selectedProcedure;

	return {
		...planToUpdate,
		updatedAt: new Date().toISOString(),
		procedures: {
			stages: planToUpdate.procedures.stages.map((stage) => {
				if (stage.id === selectedProcedure?.stageId) {
					return {
						...stage,
						procedures: stage.procedures.map((procedure) => {
							if (procedure.id === selectedProcedure?.id) {
								return {
									...procedure,
									assignedTo: assignedTo || procedure.assignedTo,
									updatedAt: new Date().toISOString(),
									updatedBy: state.auth.user?.id,
								};
							}

							return procedure;
						}),
					};
				}

				return stage;
			}),
		},
	};
}
