import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Btn, Popup, TrashIcon } from "src/components";

type WarningPopupProps = {
	isOpen: boolean;
	closeWarningPopup: () => void;
	onConfirm: () => void;
	desc: string;
};

export function WarningPopup({
	isOpen,
	closeWarningPopup,
	onConfirm,
	desc,
}: WarningPopupProps) {
	const { t } = useTranslation("global");

	return (
		<Popup
			show={isOpen}
			close={closeWarningPopup}
			width="446px"
			className="rounded-[10px] bg-nativeWhite py-7 px-[3.75rem]"
		>
			<h2 className="mb-2.5 text-center font-poppins text-xl font-medium capitalize leading-[1.375rem] text-dark">
				{t("Warning_Popup.Title")}
			</h2>

			<p className="leading-31.375rem mb-10 text-center font-poppins text-sm font-medium capitalize text-gray-600">
				{desc}
			</p>

			<div className="flex place-content-center gap-4">
				<Btn
					variant="secondary"
					size="md"
					className="bg- !w-[9.6875rem] bg-[#C1C1C1] !py-[0.90625rem] text-base font-medium leading-5 text-nativeWhite"
					rounded="rounded-md"
					onClick={closeWarningPopup}
				>
					{t("Warning_Popup.Buttons.Cancel")}
				</Btn>

				<Btn
					type="button"
					variant="secondary"
					size="md"
					className="!w-[9.6875rem] bg-[#E12D3A] !py-[0.90625rem] text-base font-medium leading-5 text-nativeWhite"
					rounded="rounded-md"
					onClick={onConfirm}
				>
					{t("Warning_Popup.Buttons.Confirm")}
				</Btn>
			</div>
		</Popup>
	);
}

export function useWarningPopup<WarningDataType = any>() {
	const [warningPopup, setWarningPopup] = useState<{
		isOpen: boolean;
		warningData: WarningDataType | null;
	}>({
		isOpen: false,
		warningData: null,
	});

	const openWarningPopup = (warningData?: WarningDataType) => {
		setWarningPopup({ isOpen: true, warningData: warningData || null });
	};

	const closeWarningPopup = () => {
		setWarningPopup({ isOpen: false, warningData: null });
	};

	return { warningPopup, openWarningPopup, closeWarningPopup };
}
