import axios from "src/config/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceCreateType, ServiceType } from "src/@types";

export const fetchServices = createAsyncThunk<
	ServiceType[],
	void,
	{ rejectValue: string }
>("services/fetchServices", async (_, thunkAPI) => {
	try {
		const { data } = await axios.get<ServiceType[]>("store/services", {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

export const createService = createAsyncThunk<
	ServiceType,
	ServiceCreateType,
	{ rejectValue: string }
>("services/createService", async (newService, thunkAPI) => {
	try {
		const { data } = await axios.post("/services", newService, {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

export const updateService = createAsyncThunk<
	ServiceType,
	{ serviceId: ServiceType["id"]; updatedService: Partial<ServiceCreateType> },
	{ rejectValue: string }
>("services/updateService", async ({ serviceId, updatedService }, thunkAPI) => {
	try {
		const { data } = await axios.put(`/services/${serviceId}`, updatedService, {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});

export const deleteService = createAsyncThunk<
	ServiceType,
	{ serviceId: ServiceType["id"] },
	{ rejectValue: string }
>("services/deleteService", async ({ serviceId }, thunkAPI) => {
	try {
		const { data } = await axios.delete(`/services/${serviceId}`, {
			signal: thunkAPI.signal,
		});

		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue("Oops, something went wrong");
	}
});
