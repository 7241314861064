export function getSettingsRoutes(baseRoute: string) {
	const settingsBase = baseRoute + "/settings";

	return {
		SETTINGS: {
			BASE: settingsBase,
			LOGIN_SETTINGS: {
				BASE: settingsBase + "/login-settings",
			},
		},
	};
}
