import { RootState } from "../../store/store";
import axios from "src/config/axios";
import {
	Appointment,
	AppointmentFetched,
	AppointmentHistory,
	DentistDictionary,
	Detail,
	Event,
	PatientDictionary,
} from "src/@types";
import { formattedDate } from "src/features";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchEvents = createAsyncThunk<
	{
		data: AppointmentFetched[];
		patients: PatientDictionary | null;
		dentists: DentistDictionary | null;
	},
	{
		startDate: Date | string;
		endDate: Date | string;
	},
	{
		rejectValue: Detail;
	}
>("events/fetchEvents", async ({ startDate, endDate }, thunkAPI) => {
	try {
		const { patients, dentists } = thunkAPI.getState() as RootState;

		const { data } = await axios.get("/appointments", {
			params: {
				start_date: startDate,
				end_date: endDate,
			},
			signal: thunkAPI.signal,
		});

		return {
			data,
			patients: patients.patients,
			dentists: dentists.dentists,
		};
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

export const addEvent = createAsyncThunk<
	{
		data: AppointmentFetched;
		patients: PatientDictionary | null;
		dentists: DentistDictionary | null;
	},
	any,
	{
		rejectValue: Detail;
	}
>("events/addEvent", async (formData, thunkAPI) => {
	try {
		const { patients, dentists } = thunkAPI.getState() as RootState;
		const { data } = await axios.post("/appointments", formData, {
			signal: thunkAPI.signal,
		});

		return {
			data,
			patients: patients.patients,
			dentists: dentists.dentists,
		};
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

export const updateEventStatus = createAsyncThunk<
	{
		eventId: number;
		status: number;
		updates: AppointmentHistory[];
	},
	{
		eventId: number;
		status: number;
	},
	{
		rejectValue: Detail;
	}
>("events/updateEventStatus", async ({ eventId, status }, thunkAPI) => {
	try {
		const { data } = await axios.put(
			`/appointments/${eventId}`,
			{
				status: status,
			},
			{
				signal: thunkAPI.signal,
			}
		);

		return {
			eventId,
			status,
			updates: data.updates,
		};
	} catch (error: any) {
		toast.error("خطأ في تحديث الحالة");

		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

export const updateEvent = createAsyncThunk<
	{
		eventId: number;
		data: AppointmentFetched;
		patients: PatientDictionary | null;
		dentists: DentistDictionary | null;
	},
	{
		eventId: number;
		updated: any;
	},
	{
		rejectValue: Detail;
	}
>("events/updateEvent", async ({ eventId, updated }, thunkAPI) => {
	try {
		const { patients, dentists } = thunkAPI.getState() as RootState;
		const { data } = await axios.put(`/appointments/${eventId}`, updated, {
			signal: thunkAPI.signal,
		});

		return {
			data,
			eventId,
			patients: patients.patients,
			dentists: dentists.dentists,
		};
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});

type ReturnEvent = {
	allDay?: boolean;
	title?: string;
	start?: string;
	end?: string;
	resourceId?: number;
	resource?: Appointment;
};

export const updateEventResizing = createAsyncThunk<
	{
		event: ReturnEvent;
		start: Date | string;
		end: Date | string;
	},
	{
		event: Event;
		start: Date | string;
		end: Date | string;
		oldEvents: Event[];
	},
	{
		rejectValue: {
			error: Detail;
			oldEvents: Event[];
		};
	}
>(
	"events/updateEventResizing",
	async ({ event, start, end, oldEvents }, thunkAPI) => {
		const formattedStart = formattedDate(start);
		const formattedEnd = formattedDate(end);
		const formattedEvent = {
			...event,
			start: formattedStart,
			end: formattedEnd,
		} as any;

		try {
			await axios.put(
				`/appointments/${event.resource?.id}`,
				{
					starts: formattedStart,
					ends: formattedEnd,
				},
				{ signal: thunkAPI.signal }
			);

			return {
				event: formattedEvent,
				start: formattedStart,
				end: formattedEnd,
			};
		} catch (error: any) {
			toast.error("خطأ في تغيير الموعد");

			return thunkAPI.rejectWithValue({
				error: error.response.data.detail,
				oldEvents,
			});
		}
	}
);

export const updateEventMoving = createAsyncThunk<
	{
		event: ReturnEvent;
		start: Date | string;
		end: Date | string;
		resourceId: number;
	},
	{
		event: Event;
		start: Date | string;
		end: Date | string;
		resourceId: number;
		oldEvents: Event[];
	},
	{
		rejectValue: {
			error: Detail;
			oldEvents: Event[];
		};
	}
>(
	"events/updateEventMoving",
	async ({ event, start, end, resourceId, oldEvents }, thunkAPI) => {
		const formattedStart = formattedDate(start);
		const formattedEnd = formattedDate(end);
		/* to store event in redux date should be string */
		const formattedEvent = {
			...event,
			start: formattedStart,
			end: formattedEnd,
			resourceId,
		};

		try {
			await axios.put(
				`/appointments/${event.resource?.id}`,
				{
					starts: formattedStart,
					ends: formattedEnd,
					clinic: resourceId,
				},
				{ signal: thunkAPI.signal }
			);

			return {
				event: formattedEvent,
				start: formattedStart,
				end: formattedEnd,
				resourceId,
			};
		} catch (error: any) {
			toast.error("خطأ في تغيير الموعد");

			return thunkAPI.rejectWithValue({
				error: error.response.data.detail,
				oldEvents,
			});
		}
	}
);

export const deleteEvent = createAsyncThunk<
	number,
	number,
	{
		rejectValue: Detail;
	}
>("events/deleteEvent", async (id, thunkAPI) => {
	try {
		const { data } = await axios.delete(`/appointments/${id}`, {
			signal: thunkAPI.signal,
		});

		if (data.status === "success") {
			return id;
		}

		return 0;
	} catch (error: any) {
		toast.error("خطأ في حذف الموعد");

		return thunkAPI.rejectWithValue(error.response.data.detail);
	}
});
