import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NotificationBtn } from "src/components";
import { HeaderRow } from "../AppGrid/AppGrid";
import { LoggedInUser } from "./LoggedInUser/LoggedInUser";
import { ROUTES } from "src/constants/index";
import { useAppSelector } from "src/redux";

export const Header = () => {
	const { t } = useTranslation("layout");
	const location = useLocation();
	const headerTitle = useAppSelector((state) => state.headerTitle.headerTitle);

	return (
		<HeaderRow>
			<header className="align-center justify-between py-4 px-12 2xl:py-5 3xl:py-7">
				<h3 className="text-2xl font-semibold text-dark 2xl:text-2xl">
					{t(headerTitle)}
				</h3>
				<div className="align-center">
					{/* {location.pathname.startsWith(ROUTES.ASSISTANT.BASE) && (
						// <NotificationBtn notificationCount={3} />
					)} */}

					<LoggedInUser />
				</div>
			</header>
		</HeaderRow>
	);
};
