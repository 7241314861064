import { Dentist, PatientBasicInfo } from "src/@types";
import { ProfileImg } from "src/components";

type PatientNamePhoneProps = {
	user: PatientBasicInfo | Dentist;
	className?: string;
};
export const PatientNamePhone = ({
	user,
	className,
}: PatientNamePhoneProps) => {
	return (
		<div className={`align-center justify-between py-1 ${className}`}>
			<div className="w-7/12">
				<div className="align-center">
					<span className="me-2">
						<ProfileImg username={user.name} />
					</span>
					<p className="truncate text-sm text-dark 3xl:text-base">
						{user.name}
					</p>
				</div>
			</div>
			<div className="w-5/12">
				<span className="truncate text-sm text-gray-600 ps-2 ms-auto 3xl:text-base">
					{user.phone}
				</span>
			</div>
		</div>
	);
};
