import { Style } from "src/@types";
import { TrendingDownIcon, TrendingUpIcon } from "src/components";

type RateProps = Style & {
	arrow: "up" | "down";
	rate: string;
};

export function Rate({ arrow, rate, className, style }: RateProps) {
	return (
		<div
			className={`flex items-center gap-1 leading-5 ${
				arrow === "up" ? "text-mint-dark" : ""
			} ${className ? className : ""}`}
			style={{
				fontSize: "0.8125rem",
				color: arrow === "down" ? "#F10505" : "#44C160",
				...style,
			}}
		>
			<span>{rate}</span>
			<span>{arrow === "up" ? <TrendingUpIcon /> : <TrendingDownIcon />}</span>
		</div>
	);
}
