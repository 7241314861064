import { Redirect, Route, RouteProps } from "react-router-dom";
import { ROUTES } from "../constants/index";

type PrivateRouterProps = {
	isAuth?: boolean;
} & RouteProps;

export const PrivateRouter = ({
	isAuth,
	...routeProps
}: PrivateRouterProps) => {
	return isAuth ? (
		<Route {...routeProps} />
	) : (
		<Redirect
			to={{
				pathname: ROUTES.AUTH.LOGIN,
				state: {
					from: routeProps.location,
				},
			}}
		/>
	);
};
