import { useTranslation } from "react-i18next";
import { NoData } from "../NoData";
import NoStockImg from "src/assets/svg/no_stock.svg";

type NoAvailableStockProps = {
	clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function NoAvailableStock({ clickHandler }: NoAvailableStockProps) {
	const { t } = useTranslation("stock");

	return (
		<NoData
			imgAlt="no stock"
			imgSrc={NoStockImg}
			notFoundMsg={t("Stock.No_Table_Data.No_Stock")}
			desc={t("Stock.No_Table_Data.StockMsg")}
			btn={t("Stock.Available_Stock.Add_Stock")}
			clickHandler={clickHandler}
		/>
	);
}
