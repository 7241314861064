import {
	PatientFilePlanWithoutIdType,
	PatientFileProcedureType,
} from "src/@types";
import { generateUniqueIntegerId } from "src/utils";
import { patientProfileActions } from "../../patientProfileSlice";
import { convertSelectedTeethToArray } from "../../utils";
import { updatePlan } from "../plan";

type CreateProcedureParamsType = {
	procedureType: PatientFileProcedureType["procedureType"];
};

export const createProcedure = ({
	procedureType,
}: CreateProcedureParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state) => {
			const procedurePrice =
				state?.services?.serviceDictionaryByName?.[procedureType]?.price || 0;

			if (
				state.patientProfile.selectedPlan &&
				state.patientProfile.selectedTeeth
			) {
				const newProcedure: PatientFileProcedureType = {
					id: generateUniqueIntegerId(),
					notes: "",
					basePrice: procedurePrice,
					discount: 0,
					total: procedurePrice,
					status: 0,
					assignedTo: state.auth.user.id,
					createdBy: state.auth.user.id,
					updatedBy: state.auth.user.id,
					updatedAt: new Date().toISOString(),
					procedureType,
					stageId: state.patientProfile.selectedPlan.procedures.stages[0].id,
					teeth: convertSelectedTeethToArray({
						state: state.patientProfile,
						key: "procedures",
						newItemType: procedureType,
					}),
				};

				const updatedPlan: PatientFilePlanWithoutIdType = {
					...planToUpdate,
					totalPrice: planToUpdate.totalPrice + newProcedure.total,
					updatedAt: new Date().toISOString(),
					procedures: {
						stages: planToUpdate.procedures.stages.map((stage) => {
							if (stage.id === newProcedure.stageId) {
								return {
									...stage,
									totalPrice: stage.totalPrice + newProcedure.total,
									procedures: [...stage.procedures, newProcedure],
								};
							}

							return stage;
						}),
					},
				};

				return updatedPlan;
			}

			return planToUpdate;
		},
		handleSuccess: (plan, dispatch) => {
			dispatch(patientProfileActions.createProcedureSuccess());
		},
	});
};
