import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useAppDispatch, schedulerActions } from "src/redux";
import { Backdrop } from "../../Backdrop/Backdrop";
import {
	AddNoteIcon,
	BigPlusIcon,
	CalendarIcon,
	UserPlusIcon,
	BillIcon,
	StockIcon,
} from "src/components";
import { ROUTES } from "../../../../constants/index";

const actionsMenu = [
	{
		actionIcon: <BillIcon width="20" height="20" />,
		actionName: "Scheduler.Actions_Menu.Create_Bill",
		route: "#",
	},
	{
		actionIcon: <UserPlusIcon width="20" height="20" />,
		actionName: "Scheduler.Actions_Menu.New_Patient",
		route: ROUTES.ASSISTANT.ADD_NEW_PATIENT,
	},
	{
		actionIcon: <AddNoteIcon width="20" height="20" />,
		actionName: "Scheduler.Actions_Menu.Add_Note",
		route: "#",
	},
	{
		actionIcon: <StockIcon width="20" height="20" />,
		actionName: "Scheduler.Actions_Menu.Add_To_Stock",
		route: "#",
	},
];

export const ActionsMenu = () => {
	const [isACtionsMenuOpen, setIsACtionsMenuOpen] = useState(false);
	const { t } = useTranslation("scheduler");
	const dispatch = useAppDispatch();

	const listRef = useRef<HTMLUListElement>(null!);

	return (
		<>
			<span
				className={
					"center fixed bottom-8 z-70 h-14 w-14 cursor-pointer rounded-full bg-primary text-white end-8"
				}
				onClick={() => setIsACtionsMenuOpen((prev) => !prev)}
			>
				<BigPlusIcon width="34" height="34" />
			</span>

			<Backdrop
				show={isACtionsMenuOpen}
				clickOutside={() => setIsACtionsMenuOpen(false)}
			/>

			<CSSTransition
				in={isACtionsMenuOpen}
				timeout={300}
				classNames="transition"
				unmountOnExit
				onEnter={() => setIsACtionsMenuOpen(true)}
				onExited={() => setIsACtionsMenuOpen(false)}
				nodeRef={listRef}
			>
				<ul
					ref={listRef}
					className={
						"fixed bottom-20 z-70 w-60 divide-y divide-lightGray  bg-white shadow-md end-14"
					}
				>
					<li>
						<button
							onClick={() =>
								dispatch(schedulerActions.openAddAppointmentModal({}))
							}
							className="align-center w-full py-3 px-2 text-primary hover:bg-gray-200"
						>
							<span className="text-primary me-3">
								<CalendarIcon width="20" height="20" />
							</span>
							<span className="text-sm font-semibold">
								{t("Scheduler.Actions_Menu.Add_Appointment")}
							</span>
						</button>
					</li>

					{actionsMenu.map((action, i) => (
						<li key={i}>
							<Link
								to={action.route}
								className=" align-center py-3 px-2 text-primary hover:bg-gray-200"
							>
								<span className="text-primary me-3">{action.actionIcon}</span>
								<span className="text-sm font-semibold">
									{t(action.actionName)}
								</span>
							</Link>
						</li>
					))}
				</ul>
			</CSSTransition>
		</>
	);
};
