import { Method } from "axios";
import { TFunction } from "react-i18next";
import { toast } from "react-toastify";

export function handleAPIMessages(
	type: "success" | "error",
	t: TFunction<"global", undefined>,
	apiMethod: Method | undefined,
	errorMessage?: string
) {
	type Message = {
		[key in Method]?: string;
	};

	const SuccessMessages: Message = {
		GET: t("Messages.Success.Default"),
		get: t("Messages.Success.Default"),

		POST: t("Messages.Success.Add"),
		post: t("Messages.Success.Add"),

		PUT: t("Messages.Success.Edit"),
		put: t("Messages.Success.Edit"),

		DELETE: t("Messages.Success.Delete"),
		delete: t("Messages.Success.Delete"),
	};

	const ErrorMessages: Message = {
		GET: t("Messages.Error.Default", { error: errorMessage }),
		get: t("Messages.Error.Default", { error: errorMessage }),

		POST: t("Messages.Error.Add", { error: errorMessage }),
		post: t("Messages.Error.Add", { error: errorMessage }),

		PUT: t("Messages.Error.Edit", { error: errorMessage }),
		put: t("Messages.Error.Edit", { error: errorMessage }),

		DELETE: t("Messages.Error.Delete", { error: errorMessage }),
		delete: t("Messages.Error.Delete", { error: errorMessage }),
	};

	if (apiMethod) {
		if (type === "success") {
			toast.success(SuccessMessages[apiMethod]);
		}

		if (type === "error") {
			toast.error(ErrorMessages[apiMethod]);
		}
	}
}
