import { Dentist } from "src/@types";
import { ColorCoding } from "../ColorCoding/ColorCoding";
import { ProfileImg } from "../ProfileImg/ProfileImg";

type DentistNameColorProps = {
	dentist: Dentist;
};
export const DentistNameColor = ({ dentist }: DentistNameColorProps) => {
	return (
		<div className="align-center w-full justify-between py-1.5">
			<div className="align-center">
				<span className="me-2">
					<ProfileImg
						username={dentist.name}
						imgsrc={dentist.picture}
						style={{ width: "30px", height: "30px" }}
					/>
				</span>
				<p className="text-sm text-dark 3xl:text-base">{dentist.name}</p>
			</div>
			<ColorCoding color={dentist.formates} />
		</div>
	);
};
