export const StarIcon = ({ width, height }: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 0.461426L17.1432 10.1352H27.3148L19.0858 16.1139L22.229 25.7877L14 19.809L5.77101 25.7877L8.9142 16.1139L0.685208 10.1352H10.8568L14 0.461426Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export const StarIcon1 = ({
	width = "48",
	height = "46",
}: React.SVGProps<SVGAElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 48 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.7505 3.16387L28.5586 17.9616L28.7269 18.4798H29.2719H44.8311L32.2434 27.6253L31.8026 27.9456L31.971 28.4639L36.779 43.2616L24.1913 34.1161L23.7505 33.7958L23.3096 34.1161L10.7219 43.2616L15.53 28.4639L15.6984 27.9456L15.2576 27.6253L2.66986 18.4798H18.2291H18.774L18.9424 17.9616L23.7505 3.16387Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const StarFilledIcon = ({
	width = "14",
	height = "13",
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.13882 0.15918L8.72952 5.03412H13.8772L9.71263 8.047L11.3033 12.9219L7.13882 9.90906L2.9743 12.9219L4.565 8.047L0.400481 5.03412H5.54811L7.13882 0.15918Z"
				fill="#FB983D"
			/>
		</svg>
	);
};

export const StarEmptyIcon = ({
	width = "14",
	height = "13",
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.01089 0.15918L8.60159 5.03412H13.7492L9.5847 8.047L11.1754 12.9219L7.01089 9.90906L2.84637 12.9219L4.43707 8.047L0.272551 5.03412H5.42018L7.01089 0.15918Z"
				fill="#919699"
			/>
		</svg>
	);
};
