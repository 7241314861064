import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "src/constants";
import { useForAllRoutes } from "src/hooks";
import { ProfileImg } from "../../ProfileImg/ProfileImg";

type ProfileInfoCardProps = {
	id: number;
	userName: string;
	phone: string;
	name: string;
};

export const ProfileInfoCard = ({
	id,
	userName,
	phone,
	name,
}: ProfileInfoCardProps) => {
	const route = useForAllRoutes();
	const { i18n } = useTranslation();

	return (
		<div className="align-center me-5">
			<ProfileImg username={userName} />

			<div className="text-base font-semibold ms-3 2xl:text-lg">
				<Link
					to={`${ROUTES[route].PATIENT.LINK(id.toString())}`}
					className="font-semibold text-primary"
					style={{ color: "#000" }}
				>
					{name.split(" ").slice(0, 2).join(" ")}
				</Link>
				<p
					className={`text-sm text-gray-600 2xl:text-base ${
						i18n.language === "ar" ? "text-right" : ""
					}`}
					dir="ltr"
				>
					{phone}
				</p>
			</div>
		</div>
	);
};
