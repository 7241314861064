import { useTranslation } from "react-i18next";
import { NoData } from "../NoData";
import NoCartImg from "src/assets/svg/no_cart.svg";

export function NoPurchasesList() {
	const { t } = useTranslation("stock");

	return (
		<NoData
			imgAlt="no purchases"
			imgSrc={NoCartImg}
			notFoundMsg={t("Stock.No_Table_Data.Empty_Shopping_List")}
			desc={t("Stock.No_Table_Data.ShoppingMsg")}
		/>
	);
}
