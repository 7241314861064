export const base = "";
export const patient = "/patient";
export const invoices = "/invoices";
export const lab = "/lab";
export const procedures = "/procedures";

export const patients = "/patients";
const patientsGroups = patients + "/groups";

const addNewPatient = "/addNewPatient";
const scheduler = "/scheduler";

export function getSharedRoutes(baseRoute: string) {
	return {
		PATIENTS: baseRoute + patients,
		PATIENTS_GROUPS: baseRoute + patientsGroups,
		ADD_NEW_PATIENT: baseRoute + addNewPatient,

		INVOICES: baseRoute + invoices,
		CREATE_INVOICES: baseRoute + invoices + "/create",

		SCHEDULER: baseRoute + scheduler,
		PRINT_APPOINTMENTS: baseRoute + "/print-appointments",

		TASKS: {
			BASE: baseRoute + tasks,
		},
	};
}

export function getAssistantAndDentist(baseRoute: string) {
	return {
		LAB: {
			BASE: baseRoute + lab,
		},
	};
}

const stock = "/stock";
const financial = "/financial";
const tasks = "/tasks";

export function getAssistantAndAdmin(baseRoute: string) {
	return {
		FINANCIAL: {
			BASE: baseRoute + financial,
			EXPENSES: baseRoute + financial + "/expenses",
		},

		STOCK: {
			BASE: baseRoute + stock,
			CONSUMPTION: baseRoute + stock + "/consumption",
			PURCHASES_LIST: baseRoute + stock + "/purchases-list",
			OPERATIONS_HISTORY: baseRoute + stock + "/operations-history",
		},
	};
}
