import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { StockProductTableType } from "src/@types";
import { Badge } from "../Badge/Badge";
import { AddAppointmentTabs, AppoitmentDetailsTabs } from "./allTabs.type";
import style from "./Tab.module.css";

type TabProps<tab> = {
	children: ReactNode;
	className?: string | undefined;
	tab: tab;
	onChangeTab: React.Dispatch<React.SetStateAction<tab>>;
	selectedTab: tab;
};

export const Tab = <
	t extends AddAppointmentTabs | AppoitmentDetailsTabs | StockProductTableType
>({
	children,
	className,
	tab,
	selectedTab,
	onChangeTab,
}: TabProps<t>) => {
	return (
		<h4
			className={`
				m-auto cursor-pointer py-3 text-base font-semibold text-gray-600 hover:border-b-4 hover:text-gray-700 3xl:py-4 3xl:text-xl
				${
					selectedTab === tab
						? "rounded border-b-4 border-primary !text-primary hover:text-dark"
						: ""
				}
				${className}
			`}
			onClick={() => onChangeTab(tab)}
		>
			{children}
		</h4>
	);
};

type NavTabProps = {
	children: ReactNode;
	className?: string | undefined;
	to: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
	badge?: number;
	exact?: boolean;
};

export const NavTab = ({
	children,
	className,
	to,
	onClick,
	exact = true,
	badge = 0,
}: NavTabProps) => {
	return (
		<NavLink
			to={to}
			className={`
				flex items-center gap-2 pb-2 text-base font-bold capitalize text-gray-600 3xl:text-xl
				${className}
			`}
			activeClassName={style.activeTab}
			exact={exact}
			onClick={onClick}
		>
			{children}

			{badge !== 0 && (
				<Badge size="sm" variant="primary-sm">
					{badge}
				</Badge>
			)}
		</NavLink>
	);
};
