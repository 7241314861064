import { Fragment } from "react";
import { Style } from "src/@types";
import { BlockIcon, EditIcon, EyeOnIcon, TrashIcon } from "src/components/Svg";

type ActionButtonsProps = {
	viewButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
	editButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
	deleteButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
	blockButtonClickHandler?: React.MouseEventHandler<HTMLButtonElement>;

	iconSize?: { width?: number | string; height?: number | string };
	alignment?: "start" | "center" | "end";
	styles?: {
		buttons?: {
			viewButton?: Style;
			editButton?: Style;
			deleteButton?: Style;
			blockButton?: Style;
		};
	};
	otherProps?: {
		buttons?: {
			viewButton?: React.DetailedHTMLProps<
				React.ButtonHTMLAttributes<HTMLButtonElement>,
				HTMLButtonElement
			>;
			editButton?: React.DetailedHTMLProps<
				React.ButtonHTMLAttributes<HTMLButtonElement>,
				HTMLButtonElement
			>;
			deleteButton?: React.DetailedHTMLProps<
				React.ButtonHTMLAttributes<HTMLButtonElement>,
				HTMLButtonElement
			>;
			blockButton?: React.DetailedHTMLProps<
				React.ButtonHTMLAttributes<HTMLButtonElement>,
				HTMLButtonElement
			>;
		};
	};
};

export function ActionButtons({
	viewButtonClickHandler,
	editButtonClickHandler,
	blockButtonClickHandler,
	deleteButtonClickHandler,
	styles,
	otherProps,
	iconSize = { width: 20, height: 20 },
	alignment = "start",
}: ActionButtonsProps) {
	const buttons: {
		Icon: ({ width, height }: React.SVGProps<SVGAElement>) => JSX.Element;
		onClick?: React.MouseEventHandler<HTMLButtonElement>;
		key: "viewButton" | "editButton" | "blockButton" | "deleteButton";
	}[] = [
		{
			Icon: EyeOnIcon,
			onClick: viewButtonClickHandler,
			key: "viewButton",
		},
		{
			Icon: EditIcon,
			onClick: editButtonClickHandler,
			key: "editButton",
		},
		{
			Icon: BlockIcon,
			onClick: blockButtonClickHandler,
			key: "blockButton",
		},
		{
			Icon: TrashIcon,
			onClick: deleteButtonClickHandler,
			key: "deleteButton",
		},
	];

	return (
		<div className={`flex items-center justify-${alignment} gap-2`}>
			{buttons.map(({ Icon, onClick, key }) => {
				return (
					<Fragment key={key}>
						{onClick && (
							<button
								type="button"
								className={`text-gray-800 ${styles?.buttons?.[key]?.className}`}
								style={styles?.buttons?.[key]?.style}
								onClick={onClick}
								{...otherProps?.buttons?.[key]}
							>
								<Icon {...iconSize} />
							</button>
						)}
					</Fragment>
				);
			})}
		</div>
	);
}
