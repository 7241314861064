import { CheckCircle, MissedIcon, XCloseIcon } from "src/components";

type StatusType = "confirmed" | "missed" | "canceled" | "total";

const renderOption = (status: StatusType) => {
	switch (status) {
		case "total":
			return {
				title: "Total appointments",
				textColor: "text-primary",
				bgColor: "bg-lightBlue",
			};
		case "confirmed":
			return {
				title: "Attended",
				textColor: "text-green2",
				bgColor: "bg-lime-light",
				icon: <CheckCircle />,
			};
		case "missed":
			return {
				title: "Missed",
				textColor: "text-yellow2",
				bgColor: "bg-orange-light",
				icon: <MissedIcon width={20} height={20} />,
			};
		case "canceled":
			return {
				title: "Canceled",
				textColor: "text-[#9F1724]",
				bgColor: "bg-lightRed",
				icon: <XCloseIcon width={20} height={20} />,
			};
		default:
			return;
	}
};

type AppointmentStatusProps = {
	status: StatusType;
	number: number;
};

export const AppointmentStatus = ({
	status,
	number,
}: AppointmentStatusProps) => {
	return (
		<div className="flex h-28 w-full flex-col justify-evenly rounded-lg bg-white py-5 px-6 shadow">
			<div className="flex items-center">
				{renderOption(status)?.icon && (
					<AppointmentStatusIcon status={status} type="overview" />
				)}

				<h5
					className={`text-sm font-semibold ${renderOption(status)?.textColor}`}
				>
					{renderOption(status)?.title}
				</h5>
			</div>
			<p className={`text-lg ps-1  ${renderOption(status)?.textColor}`}>
				{number}
			</p>
		</div>
	);
};

type DentistAppointmentStatusProps = {
	status: StatusType;
	number: number;
};

export function DentistAppointmentStatus({
	status,
	number,
}: DentistAppointmentStatusProps) {
	return (
		<div>
			{renderOption(status)?.icon && (
				<AppointmentStatusIcon status={status} type="patient" />
			)}

			<span
				className={`pt-1 text-sm font-semibold capitalize leading-6
					${renderOption(status)?.textColor}
				`}
			>
				{renderOption(status)?.title} ({number})
			</span>
		</div>
	);
}

type AppointmentStatusIconProps = {
	status: StatusType;
	type?: "patient" | "overview";
};

const AppointmentStatusIcon = ({
	status,
	type = "overview",
}: AppointmentStatusIconProps) => {
	return (
		<span
			className={`flex h-10 w-10 items-center justify-center rounded-lg me-3 ${
				type === "patient" && "m-auto"
			} ${renderOption(status)?.textColor} ${renderOption(status)?.bgColor}`}
		>
			{renderOption(status)?.icon}
		</span>
	);
};
