import { useTableBodyContext } from "../TableBodyProvider";
import { getClassName, getStyle } from "../utils";
import { THeadCeil } from "./THeadCeil";

export function THead() {
	const { headerGroups, styles } = useTableBodyContext();

	return (
		<thead className={styles?.thead?.className} style={styles?.thead?.style}>
			{headerGroups.map((headerGroup) => {
				const { key, ...restHeaderGroupProps } =
					headerGroup.getHeaderGroupProps();

				return (
					<tr
						key={key}
						{...restHeaderGroupProps}
						className={getClassName(styles?.thead?.tr?.className)}
						style={getStyle(styles?.thead?.tr?.style)}
					>
						<THeadCeil headerGroup={headerGroup} />
					</tr>
				);
			})}
		</thead>
	);
}
