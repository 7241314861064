import moment from "src/config/moment";

export function getLastMonth(lang: string) {
	const startDate = moment()
		.locale(lang)
		.subtract(1, "month")
		.format("YYYY-MM-DD");

	const endDate = moment().locale(lang).add("1", "day").format("YYYY-MM-DD");

	return { startDate, endDate };
}
