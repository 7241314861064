import { NoData } from "./NoData";
import noIncomesImg from "src/assets/svg/no_incomes.svg";
import { useTranslation } from "react-i18next";
import { useForAllRoutes } from "src/hooks";
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/constants";

export function NoIncomes() {
	const { t } = useTranslation("financial");
	const history = useHistory();
	const route = useForAllRoutes();

	return (
		<NoData
			imgAlt="no incomes"
			imgSrc={noIncomesImg}
			notFoundMsg={t("Financial.No_Incomes.MSG")}
			btn={t("Financial.No_Incomes.CTA")}
			clickHandler={() => history.push(ROUTES[route].CREATE_INVOICES)}
		/>
	);
}
