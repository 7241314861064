import { useTranslation } from "react-i18next";
import {
	BtnWithIcon,
	CustomInput,
	Dropdown,
	ListItem,
	PlusIcon,
	PrinterIcon,
	SearchIcon,
} from "src/components";
import { DateRange } from "src/features";
import { TableHeaderProps } from "./TableHeader.type";

export function TableHeader({
	title,
	count,
	searchProps,
	categoryFilter,
	handlePrint,
	dateStates,
	button,
	selectedFlatRows,
}: TableHeaderProps) {
	const { t } = useTranslation("layout");

	const hasCategoryFilter = categoryFilter ? true : false;
	const hasDateFilter = dateStates ? true : false;
	const hasButton = button ? true : false;
	const hasPrint = handlePrint ? true : false;

	return (
		<header className="pb-4 2xl:pb-5">
			<div className="flex items-center">
				<p className="font-semibold me-2 2xl:text-lg 3xl:text-xl">
					{t("Total")}
				</p>
				<span className="text-lg font-bold capitalize text-primary 2xl:text-xl 3xl:text-2xl">
					{title} {count}
				</span>
			</div>

			<div className="mt-4 flex items-center justify-between">
				<div className="w-1/3">
					<CustomInput
						type="text"
						name="search"
						id="search"
						placeholder={
							searchProps?.placeholder ||
							t("Search_Placeholder", { ns: "global" })
						}
						value={searchProps?.globalFilterState.globalFilter || ""}
						onChange={(e) =>
							searchProps?.globalFilterState.setGlobalFilter(e.target.value)
						}
						variant="filled"
						icon={<SearchIcon />}
						autoFocus
					/>
				</div>

				<div className="flex items-center gap-6">
					{hasPrint && (
						<button className="text-muted" onClick={handlePrint}>
							<PrinterIcon width={24} height={24} />
						</button>
					)}

					{hasCategoryFilter && (
						<div className="w-64">
							<Dropdown
								variant="filled"
								size="lg"
								value={JSON.stringify(categoryFilter?.value)}
								onChange={(value) => {
									categoryFilter?.onChange(JSON.parse(value));
								}}
							>
								{categoryFilter?.categories?.map((item, index) => {
									return (
										<ListItem
											value={JSON.stringify(item)}
											displayedName={item.label}
											key={index}
										>
											<div className="3xl:text-x truncate px-2 py-1 text-base text-dark">
												{item.label}
											</div>
										</ListItem>
									);
								})}
							</Dropdown>
						</div>
					)}

					{hasDateFilter && dateStates && (
						<DateRange dateStates={dateStates} variant="primary" />
					)}

					{hasButton && (
						<BtnWithIcon
							variant={"primary"}
							icon={<PlusIcon />}
							rounded="rounded-lg"
							size="md"
							className="flex-shrink-0 text-sm 3xl:text-lg"
							onClick={(e) => button?.onClick(e, selectedFlatRows)}
							{...button?.buttonProps}
						>
							{button?.text}
						</BtnWithIcon>
					)}
				</div>
			</div>
		</header>
	);
}
