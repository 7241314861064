import { useState } from "react";

export function useToggle(
	defaultValue?: boolean
): [boolean, typeof toggleValue] {
	const [value, setValue] = useState(defaultValue ? defaultValue : false);

	function toggleValue<T>(newValue?: boolean | T) {
		if (newValue !== value) {
			setValue((currentValue) =>
				typeof newValue === "boolean" ? newValue : !currentValue
			);
		}
	}

	return [value, toggleValue];
}
