import {
	InsuranceRequestStatus,
	InsuranceStatusEnum,
	StatusRequest,
} from "src/@types";

const APPROVAL: StatusRequest<InsuranceRequestStatus> = {
	label: "approval",
	value: 0,
	backgroundColor: "rgba(0, 120, 180, 0.11)",
	color: "#0077B4",
};

const SETTLED: StatusRequest<InsuranceRequestStatus> = {
	label: "settled",
	value: 1,
	backgroundColor: "#DCF7E3",
	color: "#21913E",
};

const PENDING: StatusRequest<InsuranceRequestStatus> = {
	label: "pending",
	value: 2,
	backgroundColor: "#FDDBA459",
	color: "#CF912D",
};

const REJECTED: StatusRequest<InsuranceRequestStatus> = {
	label: "rejected",
	value: 3,
	backgroundColor: "#FEE4E2",
	color: "#C83B48",
};

export const INSURANCE_STATUS: StatusRequest<InsuranceRequestStatus>[] = [
	APPROVAL,
	SETTLED,
	PENDING,
	REJECTED,
];

export const INSURANCE_STATUS_OBJECT = {
	[InsuranceStatusEnum.APPROVAL]: APPROVAL,
	[InsuranceStatusEnum.SETTLED]: SETTLED,
	[InsuranceStatusEnum.PENDING]: PENDING,
	[InsuranceStatusEnum.REJECTED]: REJECTED,
};
