export enum ServiceEnum {
	Diagnosis = 1,
	CheckUp = 2,
	Emergency = 3,
}

export enum ServicesCategoryEnum {
	CONSULTATION = "consultation",
	EXAMINATION = "examination",
	OTHER = "other",
}
