import { ReactNode, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { Label } from "../Label/Label";
import "./PhoneInput.css";

type PhoneInputProps = {
	label?: ReactNode;
	error?: FieldError | undefined;
	value: any;
	onChange: (value?: any) => void;
	onBlur: (event: React.FocusEvent<HTMLElement, Element>) => void;
	size?: "md" | "sm" | "lg";
	variant?: "filled" | "standard" | "basic";
	requiredStar?: boolean;
	labelClassName?: string;
	disabled?: boolean;
	hasValue?: boolean;
};

const blurStyles = {
	label: {
		left: "5.25rem",
		top: ".5rem",
	},
	input: {},
};

const focusStyles = {
	label: {
		top: "-11px",
		left: "",
		fontSize: "14px",
		background: "#FEFEFE",
	},
	input: { borderColor: "#0077B4" },
};

export const CustomPhoneInput = ({
	label,
	error,
	onChange,
	onBlur,
	value,
	variant,
	size,
	requiredStar,
	labelClassName,
	disabled,
	hasValue,
}: PhoneInputProps) => {
	const [styles, setStyles] = useState(hasValue ? focusStyles : blurStyles);

	const focusHandler = () => {
		setStyles(focusStyles);
	};

	const inputRef = useRef(null);

	const renderVariant = () => {
		switch (variant) {
			case "filled":
				return "input--filled input-border";
			case "standard":
				return "input--standard";
			case "basic":
				return "input--basic";
			default:
				return "input--standard";
		}
	};

	const renderSize = () => {
		switch (size) {
			case "sm":
				return "h-11";
			case "md":
				return "h-11";
			case "lg":
				return "p-3 ";
			default:
				return "p-1.5";
		}
	};

	return (
		<div className=" relative ">
			{label && (
				<Label
					htmlFor="phone-input"
					required={requiredStar}
					variant={variant}
					error={error}
					style={styles.label}
					className={labelClassName}
				>
					{label}
				</Label>
			)}

			<div dir="ltr">
				<PhoneInput
					placeholder="000 000 0000"
					defaultCountry="EG"
					international
					countryCallingCodeEditable={false}
					id="phone-input"
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					className={`phone-wrapper text-sm ps-2  ${
						error ? "input--error" : ""
					} ${renderSize()} ${renderVariant()}`}
					ref={inputRef}
					withCountryCallingCode={false}
					onFocus={focusHandler}
					style={styles.input}
					autoComplete="off"
					disabled={disabled}
				/>
			</div>

			{error && error.type === "isPhone" && (
				<ErrorMsg>{error.message}</ErrorMsg>
			)}
		</div>
	);
};
