import { useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BtnWithIcon, CustomPhoneInput, Input, PlusIcon } from "src/components";
import { NewPatientForm } from "../form";

type EmergencyProps = {
	isEditMode: boolean;
	register: UseFormRegister<NewPatientForm>;
	control: Control<NewPatientForm, any>;
};

export function Emergency({ isEditMode, register, control }: EmergencyProps) {
	const { t } = useTranslation("patientList");
	const [addEmergency, setAddEmergency] = useState(isEditMode);

	return (
		<>
			{addEmergency ? (
				<h4
					className="mb-4 cursor-pointer font-semibold text-dark"
					onClick={() => setAddEmergency(false)}
				>
					{t("Add_Patient.Emergency_Title.Shown")}
				</h4>
			) : (
				<BtnWithIcon
					type="button"
					variant="text-primary"
					icon={<PlusIcon />}
					className="!p-0"
					iconClassName="me-3"
					onClick={() => setAddEmergency(true)}
				>
					{t("Add_Patient.Emergency_Title.Add")}
				</BtnWithIcon>
			)}

			{addEmergency && (
				<div className="mb-4  flex">
					<div className="w-1/2 me-4">
						<Input
							type="text"
							id="emergencyName"
							name="emergencyName"
							register={register}
							label={t("Add_Patient.Emergency_Name.Label")}
							placeholder={t("Add_Patient.Emergency_Name.Placeholder")}
							variant="filled"
							inputSize="md"
						/>
					</div>
					<div className="w-1/2">
						<div className="">
							<Controller
								control={control}
								name="emergencyPhone"
								render={({
									field: { onChange, onBlur, value },
									fieldState: { error },
								}) => (
									<>
										<CustomPhoneInput
											label={t("Add_Patient.Phone_Number.Label")}
											onChange={onChange}
											onBlur={onBlur}
											value={value}
											error={error}
											variant="filled"
										/>
									</>
								)}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
