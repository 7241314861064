import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { RolesEnum, UserCredentialType } from "src/@types";
import { useAppDispatch, authenticate } from "src/redux";
import { PasswordInput, Btn, CustomPhoneInput } from "src/components";
import { toast } from "react-toastify";
import { FormWrapper } from "./FormWrapper/FormWrapper";
import { ROUTES } from "src/constants/index";

interface stateType {
	from: { pathname: string };
}

export const LoginPage = () => {
	const history = useHistory();
	const location = useLocation<stateType>();
	const dispatch = useAppDispatch();
	const schema = yup.object().shape({
		username: yup.string().required("Required field"),
		password: yup.string().required("Required field"),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isSubmitted, isValid },
	} = useForm<UserCredentialType>({
		mode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: { username: "", password: "" },
	});

	const handleChangeRoute = (base: string, path: string) => {
		history.replace(
			`${
				location.state &&
				location.state.from &&
				location.state.from.pathname.startsWith(base)
					? location.state.from.pathname
					: path
			}`
		);
	};

	const authHandler = (data: UserCredentialType) => {
		dispatch(authenticate(data))
			.unwrap()
			.then(({ userRole: role }) => {
				if (role === RolesEnum.DENTIST) {
					handleChangeRoute(ROUTES.DENTIST.BASE, ROUTES.DENTIST.HOME);
				}

				if (role === RolesEnum.ASSISTANT) {
					handleChangeRoute(ROUTES.ASSISTANT.BASE, ROUTES.ASSISTANT.SCHEDULER);
				}

				if (role === RolesEnum.ADMIN) {
					handleChangeRoute(ROUTES.ADMIN.BASE, ROUTES.ADMIN.HOME);
				}
			})
			.catch((error) => {
				toast.error("Incorrect username or password");
			});
	};

	return (
		<FormWrapper>
			<form onSubmit={handleSubmit(authHandler)} noValidate autoComplete="off">
				<div className="mb-5">
					<h3 className="mb-8 text-center text-2xl  font-semibold text-dark 2xl:text-3xl">
						Login to Beclarified
					</h3>

					<Controller
						control={control}
						name="username"
						render={({
							field: { onChange, onBlur, value },
							fieldState: { error },
						}) => (
							<>
								<CustomPhoneInput
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									error={error}
									variant="basic"
									label="Mobile number"
								/>
							</>
						)}
					/>
				</div>
				<div className="mb-8">
					<PasswordInput
						name="password"
						id="password"
						placeholder="Password"
						register={register}
						error={errors.password}
						variant="basic"
						inputSize="md"
						label="Password"
					/>
				</div>

				<Btn
					variant="primary"
					size="md"
					type="submit"
					rounded="rounded-lg"
					className={` mb-3 w-full text-lg ${
						isValid && !isSubmitted
							? "opacity-100"
							: "cursor-not-allowed opacity-60"
					}`}
					disabled={!isValid || isSubmitted}
				>
					Login
				</Btn>
				<Link
					to={ROUTES.AUTH.FORGOT_PASSWORD}
					className="text-left  text-primary"
				>
					Forgot password?
				</Link>
				<p className="mt-5 text-center">
					Do not have an account?{" "}
					<Link
						to={ROUTES.AUTH.REGISTER}
						className="text-lg font-semibold text-primary"
					>
						Sign up
					</Link>
				</p>
			</form>
		</FormWrapper>
	);
};
