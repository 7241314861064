import ReactCrop, { Crop, PercentCrop, PixelCrop } from "react-image-crop";
import { Popup } from "../Popup/Popup";
import { Loader, Btn } from "src/components";
import { useTranslation } from "react-i18next";

type ImageCropperPopupProps = {
	crop: Crop | undefined;
	onChange: (c: PixelCrop, percentCrop: PercentCrop) => void;
	onCropComplete: (crop: Crop) => void;
	imgSrc: string;
	onImageLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
	imageRef: React.RefObject<HTMLImageElement>;
	loading: boolean;
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
	popup: boolean;
	setPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ImageCropperPopup({
	crop,
	imageRef,
	imgSrc,
	loading,
	onChange,
	onCropComplete,
	onImageLoad,
	popup,
	setPopup,
	onSubmit,
}: ImageCropperPopupProps) {
	const { t } = useTranslation("global");

	return (
		<Popup
			show={popup}
			close={() => setPopup(false)}
			className="rounded-lg bg-nativeWhite p-4"
			width="600px"
		>
			<form onSubmit={onSubmit}>
				<div className="mb-4 flex items-center justify-center">
					<ReactCrop
						crop={crop}
						onChange={onChange}
						onComplete={onCropComplete}
						minHeight={100}
						minWidth={100}
						circularCrop={true}
						aspect={1}
						className="rounded-lg"
					>
						<img
							src={imgSrc}
							alt="Image crop"
							onLoad={onImageLoad}
							ref={imageRef}
						/>
					</ReactCrop>
				</div>

				<div className="flex justify-end gap-3">
					{loading && <Loader className="!text-primary" />}

					<Btn
						type="button"
						variant="secondary"
						className={`w-24 ${loading ? "cursor-not-allowed" : ""}`}
						onClick={() => setPopup(false)}
						disabled={loading}
					>
						{t("Buttons.Cancel")}
					</Btn>
					<Btn
						type="submit"
						variant={!loading ? "primary" : "secondary"}
						className={`w-24 ${loading ? "cursor-not-allowed" : ""}`}
						disabled={loading}
					>
						{t("Buttons.Save")}
					</Btn>
				</div>
			</form>
		</Popup>
	);
}
