import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	centerCrop,
	Crop,
	makeAspectCrop,
	PercentCrop,
	PixelCrop,
} from "react-image-crop";
import { toast } from "react-toastify";
import { useAxios } from "src/hooks";

function centerAspectCrop(
	mediaWidth: number,
	mediaHeight: number,
	aspect: number
) {
	return centerCrop(
		makeAspectCrop({ unit: "%", width: 90 }, aspect, mediaWidth, mediaHeight),
		mediaWidth,
		mediaHeight
	);
}

type UseImageCropperPopupProps = {
	url: string;
	formDataKey: string;
	handleSuccess?: (image: any) => void;
};

export function useImageCropperPopup({
	formDataKey,
	url,
	handleSuccess,
}: UseImageCropperPopupProps) {
	const { t } = useTranslation("global");
	const [popup, setPopup] = useState(false);

	const [imgSrc, setImgSrc] = useState<string>("");
	const [crop, setCrop] = useState<Crop>();
	const [croppedImage, setCroppedImage] = useState<File>();

	const imageRef = useRef<HTMLImageElement>(null);

	const onChange = (c: PixelCrop, percentCrop: PercentCrop) => {
		setCrop(percentCrop);
	};

	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.

			const file = e.target.files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setImgSrc(reader.result?.toString() ?? "");
			};

			reader.readAsDataURL(file);

			setPopup(true);

			e.target.value = "";
		}
	}

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		const { width, height } = e.currentTarget;

		setCrop(centerAspectCrop(width, height, 1));
	}

	const onCropComplete = (crop: Crop) => {
		if (imageRef.current && crop.width && crop.height) {
			getCroppedImg(imageRef.current, crop);
		}
	};

	const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		canvas.width = crop.width;
		canvas.height = crop.height;

		const ctx = canvas.getContext("2d");

		ctx?.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		const reader = new FileReader();

		canvas.toBlob((blob) => {
			if (!blob) return;

			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				dataURLtoFile(reader.result as string, "cropped_image.jpg");
			};
		});
	};

	const dataURLtoFile = (dataUrl: string, filename: string) => {
		const arr = dataUrl.split(",");
		const mime = arr[0].match(/:(.*?);/)?.[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		const croppedImage = new File([u8arr], filename, { type: mime });

		setCroppedImage(croppedImage);
	};

	const { makeRequest, loading } = useAxios({
		runNow: false,
		configs: {
			method: "PUT",
		},
		handleSuccess: (data) => {
			handleSuccess?.(data);
			setPopup(false);
			toast.success(t("Messages.Upload_Image.Success"));
		},
		handleError: () => {
			toast.error(t("Messages.Upload_Image.Error"));
		},
	});

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const formData = new FormData();

		formData.append(formDataKey, croppedImage as Blob);

		makeRequest({ configs: { url, data: formData } });
	};

	return {
		onSelectFile,
		imageCropperPopupProps: {
			croppedImage,
			onChange,
			crop,
			onCropComplete,
			imgSrc,
			onImageLoad,
			imageRef,
			loading,
			onSubmit,
			popup,
			setPopup,
		},
	};
}
