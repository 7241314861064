import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { toast } from "react-toastify";
import { PatientFilePlanType, PatientFilePlanWithoutIdType } from "src/@types";
import { RootState } from "src/redux/store/store";
import { AxiosResponse } from "axios";

export const updatePlan = createAsyncThunk<
	{ plan: PatientFilePlanType },
	{
		// * If no plaId then we use selectedPlan id
		planId?: PatientFilePlanType["id"];
		updatedPlan: (
			planToUpdate: PatientFilePlanWithoutIdType,
			state: RootState,
			dispatch: ThunkDispatch<RootState, unknown, AnyAction>
		) => PatientFilePlanWithoutIdType;
		handleSuccess?: (
			plan: PatientFilePlanType,
			dispatch: ThunkDispatch<RootState, unknown, AnyAction>
		) => void;
	},
	{ rejectValue: string; state: RootState }
>(
	"patientProfile/updatePlan",
	async ({ planId: planIdProp, updatedPlan, handleSuccess }, thunkAPI) => {
		const planId = planIdProp
			? planIdProp
			: thunkAPI.getState()?.patientProfile?.selectedPlan?.id;

		try {
			const plans = thunkAPI.getState().patientProfile.plans;
			const planToUpdate = plans?.find((plan) => plan.id === planId);

			if (plans && planToUpdate) {
				const { id, ...oldPlan } = planToUpdate;

				const { data: plan } = await axios.put<
					PatientFilePlanType,
					AxiosResponse<PatientFilePlanType>,
					PatientFilePlanWithoutIdType
				>(
					`/patients/${oldPlan.patientId}/plans/${planId}`,
					updatedPlan(oldPlan, thunkAPI.getState(), thunkAPI.dispatch)
				);

				handleSuccess?.(plan, thunkAPI.dispatch);

				return { plan };
			} else {
				toast.error("Something went wrong, Please try again");

				return thunkAPI.rejectWithValue(
					"Something went wrong, Please try again"
				);
			}
		} catch (error) {
			toast.error("Something went wrong, Please try again");

			return thunkAPI.rejectWithValue("Something went wrong, Please try again");
		}
	}
);
