import { useEffect } from "react";

type UseBlockChangesProps = {
	condition: boolean;
};

export function useBlockChanges({ condition }: UseBlockChangesProps) {
	// * Block user from closing the tab if session is active
	useEffect(() => {
		if (condition) window.onbeforeunload = () => true;
		else window.onbeforeunload = () => undefined;

		return () => {
			window.onbeforeunload = () => undefined;
		};
	}, [condition]);
}
