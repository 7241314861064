import { TFunction } from "react-i18next";
import { TaskTypeEnum, TasksTypeStatusType, StatusRequest } from "src/@types";

type TasksStatusRequestType = StatusRequest<TasksTypeStatusType>;

const FOLLOW_UP = (t: TFunction<"tasks">): TasksStatusRequestType => ({
	label: t("Tasks.Tasks_Type.Follow_Up"),
	value: TaskTypeEnum.FOLLOW_UP,
	backgroundColor: "#0077B43B",
	color: "#006289",
});

export const TASKS_TYPE_STATUS = (
	t: TFunction<"tasks">
): TasksStatusRequestType[] => [FOLLOW_UP(t)];

export const TASKS_TYPE_STATUS_OBJECT = (
	t: TFunction<"tasks">
): {
	[key: number]: TasksStatusRequestType;
} => ({
	[TaskTypeEnum.FOLLOW_UP]: FOLLOW_UP(t),
});
