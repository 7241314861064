import { useTranslation } from "react-i18next";
import { Btn, Loader } from "src/components";

type SubmitBtnProps = {
	isDirty?: boolean;
	isValid?: boolean;
	loading?: boolean;
	isSubmitSuccessful?: boolean;
	errorMsg?: string;
	mode: "EDIT" | "ADD";
};

export const SubmitBtn = ({
	isDirty,
	isValid,
	loading,
	isSubmitSuccessful,
	errorMsg,
	mode,
}: SubmitBtnProps) => {
	const { t } = useTranslation("scheduler");

	return (
		<div className="my-4">
			<Btn
				variant="primary"
				type="submit"
				className={`w-full text-xl disabled:cursor-not-allowed ${
					!isDirty && !isValid ? "opacity-60" : ""
				} ${loading ? "opacity-60" : ""}`}
				disabled={!isDirty && !isValid}
			>
				{loading ? (
					<div className="flex items-center justify-center ">
						<span>
							<Loader />
						</span>
						<span className="text-lg ms-3 2xl:text-xl 3xl:text-2xl">
							{mode === "ADD"
								? t(
										"Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Saving"
								  )
								: t(
										"Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Updating"
								  )}
						</span>
					</div>
				) : (
					<div className="text-xl 2xl:text-2xl">
						{isSubmitSuccessful && !errorMsg
							? mode === "ADD"
								? t("Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Saved")
								: t(
										"Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Updated"
								  )
							: mode === "ADD"
							? t("Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Save")
							: t("Appointments.Add_Appointment_Form.Inputs.Submit_Btn.Update")}
					</div>
				)}
			</Btn>
		</div>
	);
};
